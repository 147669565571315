import { Map, fromJS } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'
import { CANCEL_SUBSCRIPTION } from '_modules/payments/actions'

import {
  GET_USER_ACTIVITIES,
  RESET_USER_PRESENTATION_INSIGHTS,
  CREATE_USER_INSIGHTS_EMAIL,
} from './actions'

export const INITIAL_STATE = Map()

export const userPresentationInsights = createReducer(INITIAL_STATE, {
  [GET_USER_ACTIVITIES.FULFILLED]: (state, { payload }) => fromJS(payload),

  [RESET_USER_PRESENTATION_INSIGHTS]: () => INITIAL_STATE,

  [CREATE_USER_INSIGHTS_EMAIL.FULFILLED]: (state, { meta }) =>
    state.setIn(['user', 'email'], meta.payload.email),

  [CANCEL_SUBSCRIPTION.FULFILLED]: () => INITIAL_STATE,
})
