import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import Spinner from 'react-spinkit'

import styles from './styles.css'

const Loading = ({ className, spinnerClassName, style }) => (
  <div className={classnames(styles['spinner-div'], className)} style={style}>
    <Spinner name="ball-clip-rotate" color=" #e82f72" fadeIn="none" className={spinnerClassName} />
  </div>
)

Loading.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  spinnerClassName: PropTypes.string,
}

Loading.defaultProps = {
  className: '',
  style: {},
  spinnerClassName: '',
}

export default Loading
