import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import styles from './styles.css'

export const FontSize = {
  EXTRA_SMALL: 'extra-small',
  MID_SMALL: 'mid-small',
  SMALL: 'small',
  LARGE: 'large',
  EXTRA_LARGE: 'extra-large',
}

export const MediaSize = {
  EXTRA_SMALL: 'media-extra-small',
  SMALL: 'media-small',
  MEDIUM: 'media-medium',
  LARGE: 'media-large',
  EXTRA_LARGE: 'media-extra-large',
}

export const Color = {
  WHITE: 'white',
  DARK_GRAY_BLUE: 'dark-gray-blue',
  COOL_BLUE: 'cool-blue',
  STEEL_GRAY: 'steel_gray',
}

const MediaBlock = ({
  media,
  text,
  fontSize,
  mediaSize,
  color,
  underlined,
  classNames,
  textClassName,
  mediaClassName,
}) => (
  <div className={classnames(styles['media-block'], classNames)}>
    {media && (
      <svg
        alt="media"
        className={classnames(styles.media, styles[color], styles[mediaSize], mediaClassName)}
      >
        <use xlinkHref={media} />
      </svg>
    )}
    <p
      className={classnames(
        styles.text,
        styles[fontSize],
        styles[color],
        [underlined && styles.underlined],
        textClassName
      )}
    >
      {text}
    </p>
  </div>
)

MediaBlock.propTypes = {
  media: PropTypes.string,
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.oneOf(Object.keys(FontSize).map(key => FontSize[key])),
  mediaSize: PropTypes.oneOf(Object.keys(MediaSize).map(key => MediaSize[key])),
  color: PropTypes.oneOf(Object.keys(Color).map(key => Color[key])),
  underlined: PropTypes.bool,
  textClassName: PropTypes.string,
  mediaClassName: PropTypes.string,
  classNames: PropTypes.string,
}

MediaBlock.defaultProps = {
  media: null,
  fontSize: FontSize.SMALL,
  mediaSize: MediaSize.LARGE,
  color: Color.DARK_GRAY_BLUE,
  underlined: false,
  textClassName: '',
  mediaClassName: '',
  classNames: '',
}

export default MediaBlock
