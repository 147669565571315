import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import cookies from 'react-cookies'

import Button, { ButtonType, ButtonTheme } from '_components/button'
import { closeAlert, openAlert } from '_modules/alert-message/actions'
import { updateUser } from '_modules/user/actions'
import withBrowserInformation from '_hocs/with-browser-information'
import {
  createOneSignalNotificationAlert,
  oneSignalSubscriptionChange,
  oneSignalNotificationChange,
  oneSignalGetUserId,
} from '_utils/onesignal'
import { COOKIES_OPTIONS } from '_utils/constants'

import styles from './styles.css'

export const AlertType = {
  FAILED_PDF: 4,
  CHANGES_SAVED: 5,
  WEB_PUSH_NOTIFICATION: 6,
  PRESENTATION_NOT_SHARED: 7,
  WEB_PUSH_NOTIFICATION_NOT_SUPPORTED: 8,
  WEB_PUSH_NOTIFICATION_PERMISSION_DENIED: 9,
  VIDEO_UPLOAD_NOT_ALLOWED: 12,
  FILE_UPLOAD_NOT_ALLOWED: 13,
  SET_DEFAULT_LOGO_QUESTION: 14,
  LINK_EXISTENT_ACCOUNT_ERROR: 15,
  LINK_ACCOUNT_GENERIC_ERROR: 16,
  ARCHIVE_SHARED_LINK: 17,
  UNARCHIVE_SHARED_LINK: 18,
  UPDATE_EMAIL_NOTIFICATION: 19,
  EMAIL_WITHOUT_SUBJECT: 20,
  ADD_PROFILE: 21,
  CANCEL_SUBSCRIPTION_SUCCESSFUL: 22,
  SUBSCRIPTION_UPDATE_SUCCESSFUL: 23,
  SUBSCRIPTION_UPDATE_REJECTED: 24,
  CANCEL_SUBSCRIPTION_REJECTED: 25,
  MESSAGE_REQUIRED: 26,
  CUSTOM: 27,
}

const mapStateToProps = ({ alert }) => ({
  onClick: alert.get('onClick'),
  onSuccessClick: alert.get('onSuccessClick'),
  timer: alert.get('timer'),
  buttonLabel: alert.get('buttonLabel'),
  successLabel: alert.get('successLabel'),
  isOpen: alert.get('isOpen'),
  type: alert.get('type'),
  recipient: alert.get('recipient'),
  message: alert.get('message'),
  isError: alert.get('isError'),
})

const mapDispatchToProps = {
  closeAlert,
  openAlert,
  updateUser,
}

class AlertMessage extends PureComponent {
  static propTypes = {
    isInternetExplorer: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onSuccessClick: PropTypes.func,
    openAlert: PropTypes.func.isRequired,
    timer: PropTypes.number,
    buttonLabel: PropTypes.string,
    successLabel: PropTypes.string,
    isOpen: PropTypes.bool,
    closeAlert: PropTypes.func.isRequired,
    type: PropTypes.number.isRequired,
    updateUser: PropTypes.func.isRequired,
    recipient: PropTypes.string.isRequired,
    message: PropTypes.string,
    isError: PropTypes.bool,
  }

  static defaultProps = {
    onClick: undefined,
    onSuccessClick: () => {},
    timer: undefined,
    buttonLabel: '',
    successLabel: '',
    isOpen: false,
    message: '',
    isError: false,
  }

  componentDidMount() {
    if (this.props.timer) {
      this.dismissAlert = setTimeout(this.props.closeAlert, this.props.timer)
    }

    if (this.props.type === AlertType.WEB_PUSH_NOTIFICATION) {
      oneSignalSubscriptionChange(this.handleSubscriptionChange)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.timer) {
      this.dismissAlert = setTimeout(this.props.closeAlert, nextProps.timer)
    }

    if (this.props.isOpen && !nextProps.isOpen) {
      clearTimeout(this.dismissAlert)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.dismissAlert)
  }

  onSuccessClick = () => {
    const { closeAlert: dispatchCloseAlert, openAlert: dispatchOpenAlert } = this.props

    if (AlertType.WEB_PUSH_NOTIFICATION) {
      oneSignalNotificationChange(createOneSignalNotificationAlert(dispatchOpenAlert))
      cookies.save('web_push_prompted', true, COOKIES_OPTIONS)
      dispatchCloseAlert()
    }

    this.props.onSuccessClick()
  }

  handleSubscriptionChange = isSubscribed => {
    if (isSubscribed) {
      oneSignalGetUserId(this.handleOneSignalUserId)
    }
  }

  handleOneSignalUserId = userId => {
    this.props.updateUser({
      onesignal_player_id: userId,
      web_push_notifications: true,
    })
  }

  dismiss = () => {
    const { closeAlert: dispatchCloseAlert, onClick } = this.props

    if (AlertType.WEB_PUSH_NOTIFICATION) {
      cookies.save('web_push_prompted', true, COOKIES_OPTIONS)
    }

    if (onClick) {
      onClick()
    }

    dispatchCloseAlert()
  }

  renderContent = () => {
    switch (this.props.type) {
      case AlertType.FAILED_PDF:
        return `Can't load the PDF file.`
      case AlertType.CHANGES_SAVED:
        return `Changes Saved`
      case AlertType.WEB_PUSH_NOTIFICATION:
        return `Do you want to receive web push notifications?`
      case AlertType.PRESENTATION_NOT_SHARED:
        return 'This presentation has not been shared with any recipients yet'
      case AlertType.WEB_PUSH_NOTIFICATION_NOT_SUPPORTED:
        return 'This browser does not support notifications'
      case AlertType.WEB_PUSH_NOTIFICATION_PERMISSION_DENIED:
        return 'You have to allow notifications on your browser settings'
      case AlertType.VIDEO_UPLOAD_NOT_ALLOWED:
        return "We can't upload videos right now, please upload to YouTube or Vimeo and insert via the Link tab"
      case AlertType.FILE_UPLOAD_NOT_ALLOWED:
        return 'This file type is not allowed to be uploaded'
      case AlertType.SET_DEFAULT_LOGO_QUESTION:
        return 'Your logo was uploaded successfully. Would you like to set this as your default logo?'
      case AlertType.LINK_EXISTENT_ACCOUNT_ERROR:
        return 'A separate account already exists for the social account you are trying to link. Please contact Josie on Intercom for assistance.'
      case AlertType.LINK_ACCOUNT_GENERIC_ERROR:
        return 'An error occurred when trying to link your account'
      case AlertType.ARCHIVE_SHARED_LINK:
        return `${this.props.recipient} moved to Archive`
      case AlertType.UNARCHIVE_SHARED_LINK:
        return `${this.props.recipient} removed from Archive`
      case AlertType.EMAIL_WITHOUT_SUBJECT:
        return 'The subject of the email is required'
      case AlertType.UPDATE_EMAIL_NOTIFICATION:
        return this.props.message
      case AlertType.ADD_PROFILE:
        return 'Please fill in your first and last names, these are required fields'
      case AlertType.CANCEL_SUBSCRIPTION_SUCCESSFUL:
        return 'Subscription canceled successfully.'
      case AlertType.SUBSCRIPTION_UPDATE_SUCCESSFUL:
        return 'Billing period successfully updated.'
      case AlertType.SUBSCRIPTION_UPDATE_REJECTED:
        return 'Something went wrong with editing your subscription, please try again!'
      case AlertType.CANCEL_SUBSCRIPTION_REJECTED:
        return 'Something went wrong with canceling your subscription, please try again!'
      case AlertType.MESSAGE_REQUIRED:
        return 'Message field is required!'
      case AlertType.CUSTOM:
        return this.props.message
      default:
        return null
    }
  }

  render() {
    const { isInternetExplorer, isOpen, buttonLabel, successLabel, isError } = this.props

    return (
      <section
        className={classnames(isInternetExplorer ? styles['ie-alert'] : styles.alert, {
          [styles.open]: isOpen,
          [styles['error-alert']]: isError,
        })}
      >
        <div className={styles['alert-content']}>{this.renderContent()}</div>
        <div className={styles['actions-container']}>
          {successLabel && (
            <Button
              type={ButtonType.BUTTON}
              theme={ButtonTheme.FILLED_WHITE}
              onClick={this.onSuccessClick}
              classNames={styles.action}
              removeFlexBasis
            >
              {successLabel}
            </Button>
          )}
          {buttonLabel && (
            <Button
              type={ButtonType.BUTTON}
              theme={ButtonTheme.FILLED_WHITE}
              onClick={this.dismiss}
              classNames={styles.action}
              removeFlexBasis
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </section>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withBrowserInformation(AlertMessage))
