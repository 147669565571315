import PropTypes from 'prop-types'
import React, { useState, useRef, useCallback, useMemo } from 'react'
import DropboxChooser from 'react-dropbox-chooser'
import { connect, useSelector } from 'react-redux'
import classnames from 'classnames'
import mime, { extension as convertExtension } from 'mime-types'

import ButtonNew, { BUTTON_THEME } from '_components/button-new'
import MediaBlock, { Color, FontSize, MediaSize } from '_components/media-block'
import FileUpload from '_hocs/file-upload'
import { openAlert } from '_modules/alert-message/actions'
import { imageExtensions } from '_utils/files'
import GooglePicker from '_components/google-picker'
// TODO: Commented box out since it's not working. Uncomment when redirect is fixed
// import BoxPicker from '_components/box-picker'
import Laptop from '_assets/icons/laptop.svg'
import Drive from '_assets/icons/google-drive-logo.svg'
import Unsplash from '_assets/icons/unsplash.svg'
import Dropbox from '_assets/icons/dropbox_logo.svg'
import Facebook from '_assets/icons/facebook-letter.svg'
import {
  GOOGLE_CLIENT_ID,
  GOOGLE_API_KEY,
  DROPBOX_APP_KEY,
  FLAG_SHOW_FACEBOOK_BUTTON,
} from '_config/environment'
import UnsplashPicker from '_components/unsplash-picker'
import loadImage from '_utils/load-image'
import FacebookLogin from '_components/facebook-login'
import { LOGIN_FACEBOOK } from '_modules/user/actions'
import { userSelector } from '_modules/user/selectors'
import useFetchCall from '_hooks/use-fetch-call'

import styles from './styles.css'

const ImageUpload = ({
  className,
  extensions,
  openAlert: dispatchOpenAlert,
  selectFile,
  useUnsplash,
  applyDefaultLogo,
}) => {
  const fileRef = useRef(null)

  const [unsplashModalIsOpen, setUnsplashModalIsOpen] = useState(false)

  const user = useSelector(userSelector)

  const onDownloadFileComplete = useCallback(
    file => {
      const reader = new FileReader()

      reader.onloadend = () => {
        selectFile(reader.result, file)
      }

      reader.readAsDataURL(file)
    },
    [selectFile]
  )

  const selectImage = useCallback(() => {
    fileRef.current.click()
  }, [])

  const toggleUnsplashModal = useCallback(() => {
    setUnsplashModalIsOpen(prevState => !prevState)
  }, [])

  const handleImageChange = useCallback(
    validateFile => event => {
      event.preventDefault()

      const file = event.target.files[0]

      if (!validateFile(file)) {
        return
      }

      if (file.type === 'image/tiff') {
        selectFile(null, file)
        return
      }

      loadImage(file, selectFile)
    },
    [selectFile]
  )

  const onUpdateUserPicture = useCallback(
    (picture, fileName) => {
      fetch(picture)
        .then(res => res.blob())
        .then(blob => {
          const { type } = blob
          const file = new File([blob], fileName, { type })
          selectFile(picture, file)
        })
    },
    [selectFile]
  )

  const onClickFacebookButton = useCallback(() => {
    onUpdateUserPicture(user.get('facebook_account_picture'), 'facebookPicture')
  }, [onUpdateUserPicture, user])

  const renderFacebookButton = useCallback(
    ({ onClick }) => (
      <ButtonNew
        theme={BUTTON_THEME.TRANSPARENT_BACKGROUND}
        className={styles.button}
        onClick={user.get('facebook_account_picture') ? onClickFacebookButton : onClick}
      >
        <MediaBlock
          text="Facebook"
          color={Color.DARK_GRAY_BLUE}
          fontSize={FontSize.SMALL}
          mediaSize={MediaSize.MEDIUM}
          media={Facebook}
        />
      </ButtonNew>
    ),
    [onClickFacebookButton, user]
  )

  const getExtensionsWithDot = useMemo(() => extensions.map(extension => `.${extension}`), [
    extensions,
  ])

  const mimeTypes = useMemo(() => extensions.map(extension => mime.lookup(extension)), [extensions])

  // COMPONENT
  const getFileTypes = useMemo(
    () =>
      extensions.map(extension => ({
        extension,
        mimeType: mime.lookup(extension),
      })),
    [extensions]
  )

  const setDefaultLogo = useCallback(() => {
    if (user.get('logo')) {
      fetch(user.get('logo'))
        .then(res => res.blob())
        .then(blob => {
          const fileType = blob ? blob.type : ''
          const fileName = `logo.${convertExtension(fileType)}`

          const newFile = new File([blob], fileName, { type: fileType })
          applyDefaultLogo(newFile)
        })
    }
  }, [applyDefaultLogo, user])

  useFetchCall(LOGIN_FACEBOOK, onClickFacebookButton, () => {}) // TODO: add toast

  return (
    <FileUpload
      fileTypes={getFileTypes}
      googleDriveExportMimeType="image/jpeg"
      onDownloadFileComplete={onDownloadFileComplete}
      openAlert={dispatchOpenAlert}
      // eslint-disable-next-line react/jsx-no-bind
      render={({
        // TODO: Commented box out since it's not working. Uncomment when redirect is fixed
        // downloadBoxFile,
        downloadDropboxFile,
        downloadUnsplashFile,
        onGoogleDriveAction,
        validateFile,
      }) => (
        <div className={classnames(styles['actions-container'], className)}>
          <ButtonNew
            theme={BUTTON_THEME.TRANSPARENT_BACKGROUND}
            className={styles.button}
            onClick={selectImage}
          >
            <input
              type="file"
              onChange={handleImageChange(validateFile)}
              accept={mimeTypes.join(',')}
              ref={fileRef}
              className={styles['select-from-computer']}
            />
            <MediaBlock
              text="Upload"
              color={Color.DARK_GRAY_BLUE}
              fontSize={FontSize.SMALL}
              mediaSize={MediaSize.LARGE}
              media={Laptop}
            />
          </ButtonNew>
          <DropboxChooser
            appKey={DROPBOX_APP_KEY}
            linkType="direct"
            success={downloadDropboxFile}
            extensions={getExtensionsWithDot}
            multiselect={false}
          >
            <ButtonNew theme={BUTTON_THEME.TRANSPARENT_BACKGROUND} className={styles.button}>
              <MediaBlock
                text="Dropbox"
                color={Color.DARK_GRAY_BLUE}
                fontSize={FontSize.SMALL}
                mediaSize={MediaSize.LARGE}
                media={Dropbox}
              />
            </ButtonNew>
          </DropboxChooser>
          <GooglePicker
            clientId={GOOGLE_CLIENT_ID}
            developerKey={GOOGLE_API_KEY}
            scope={['https://www.googleapis.com/auth/drive.readonly']}
            onChange={onGoogleDriveAction}
            authImmediate={false}
            navHidden
            mimeTypes={mimeTypes}
            viewId="FOLDERS"
          >
            <ButtonNew theme={BUTTON_THEME.TRANSPARENT_BACKGROUND} className={styles.button}>
              <MediaBlock
                text="Google drive"
                color={Color.DARK_GRAY_BLUE}
                fontSize={FontSize.SMALL}
                mediaSize={MediaSize.LARGE}
                media={Drive}
              />
            </ButtonNew>
          </GooglePicker>
          {/* TODO: Commented box out since it's not working. Uncomment when redirect is fixed */}
          {/* <BoxPicker
            buttonClassName={styles.button}
            onFileSelected={downloadBoxFile}
            fontSize={FontSize.SMALL}
            mediaSize={MediaSize.LARGE}
          /> */}
          {useUnsplash && (
            <ButtonNew
              theme={BUTTON_THEME.TRANSPARENT_BACKGROUND}
              className={styles.button}
              onClick={toggleUnsplashModal}
            >
              <MediaBlock
                text="Unsplash"
                color={Color.DARK_GRAY_BLUE}
                fontSize={FontSize.SMALL}
                mediaSize={MediaSize.LARGE}
                media={Unsplash}
              />
            </ButtonNew>
          )}
          {FLAG_SHOW_FACEBOOK_BUTTON && <FacebookLogin renderComponent={renderFacebookButton} />}
          {applyDefaultLogo && user.get('logo') && (
            <ButtonNew
              theme={BUTTON_THEME.TRANSPARENT_BACKGROUND}
              className={classnames(styles.button, styles['button-text'])}
              onClick={setDefaultLogo}
            >
              Apply default logo
            </ButtonNew>
          )}
          {unsplashModalIsOpen && (
            <UnsplashPicker
              isOpen={unsplashModalIsOpen}
              onRequestClose={toggleUnsplashModal}
              downloadUnsplashFile={downloadUnsplashFile}
            />
          )}
        </div>
      )}
    />
  )
}

ImageUpload.propTypes = {
  extensions: PropTypes.arrayOf(PropTypes.string),
  openAlert: PropTypes.func.isRequired,
  selectFile: PropTypes.func.isRequired,
  useUnsplash: PropTypes.bool,
  className: PropTypes.string,
  applyDefaultLogo: PropTypes.func,
}

ImageUpload.defaultProps = {
  useUnsplash: false,
  className: '',
  extensions: imageExtensions,
  applyDefaultLogo: undefined,
}

// CONTAINER

const mapDispatchToProps = { openAlert }

export default connect(null, mapDispatchToProps)(React.memo(ImageUpload))
