import PropTypes from 'prop-types'
import React from 'react'

import Svg from '_components/svg'
import DownArrow from '_assets/icons/down_arrow.svg'
import HamburgerIcon from '_assets/icons/hamburger_menu.svg'
import CloseIcon from '_assets/icons/close.svg'
import useWindowSize from '_hooks/use-window-size'

import styles from './styles.css'

const UserMenu = ({ userPicture, openUserDropdown, menuOpen }) => {
  const { isDesktop } = useWindowSize()

  return (
    <button className={styles['user-menu-wrapper']} onClick={openUserDropdown} type="button">
      {isDesktop && (
        <React.Fragment>
          <img src={userPicture} alt="User avatar" className={styles['user-picture']} />
          <Svg icon={DownArrow} className={styles.arrow} />
        </React.Fragment>
      )}
      <Svg icon={menuOpen ? CloseIcon : HamburgerIcon} className={styles['hamburger-icon']} />
    </button>
  )
}

UserMenu.propTypes = {
  userPicture: PropTypes.string.isRequired,
  openUserDropdown: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool,
}

UserMenu.defaultProps = {
  menuOpen: false,
}

export default React.memo(UserMenu)
