import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

const Slider = ({ minValue, maxValue, scale, value, onChange, label, color, name, className }) => {
  const renderSliderStyle = useMemo(() => {
    const backgroundSize = `${((value - minValue) * 100) / (maxValue - minValue)}% 100%`
    return {
      backgroundSize,
      backgroundImage: color,
    }
  }, [color, maxValue, minValue, value])

  const renderLabelPosition = useMemo(() => {
    const positionLeft = `${((value - minValue) * 100) / (maxValue - minValue)}%`
    return { left: `calc(${positionLeft} + 1rem`, transform: 'translateX(-50%)', top: '2rem' }
  }, [maxValue, minValue, value])

  return (
    <div className={classnames(styles.container, className)}>
      <input
        type="range"
        min={minValue}
        max={maxValue}
        step={scale}
        value={value}
        onChange={onChange}
        className={styles.slider}
        style={renderSliderStyle}
        name={name}
      />
      {label && (
        <div className={styles.label} style={renderLabelPosition}>
          {label}
        </div>
      )}
    </div>
  )
}

Slider.propTypes = {
  className: PropTypes.string,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
}

Slider.defaultProps = {
  className: '',
  label: '',
  color: 'linear-gradient(var(--pink), var(--pink))',
  name: '',
}

export default React.memo(Slider)
