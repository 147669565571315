import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import promise from 'redux-promise-middleware'
import { routerMiddleware } from 'react-router-redux'
import createLogger from 'redux-logger'
import cookies from 'react-cookies'
import { fromJS } from 'immutable'
import * as Sentry from '@sentry/react'

import { cookieAuth } from '_utils/constants'

import { errorMiddleware } from '../../private-dependencies/react-utils/middlewares'
import unauthorizedMiddleware from '../middlewares/unauthorized'
import rootReducer from '../modules/reducers'

const logger = createLogger({
  level: 'info',
  collapsed: true,
})

const router = routerMiddleware(browserHistory)

const sentryReduxEnhancer = Sentry.createReduxEnhancer()
const cookiesAuth = cookieAuth()
const DEFAULT_STATE = {
  user:
    fromJS(cookies.load('user')) &&
    fromJS(cookies.load('user')).set('auth_token', cookies.load(cookiesAuth)),
}

/**
 * Creates a preconfigured store.
 */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) // eslint-disable-line
    : compose

const configureStore = (preloadedState = DEFAULT_STATE) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunk, errorMiddleware, unauthorizedMiddleware, promise(), router, logger),
      sentryReduxEnhancer
    )
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../modules/reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('../modules/reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

export default configureStore
