import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import { Asset } from '_models'
// import useModalPosition from '_hooks/use-modal-position'
import CloseIcon from '_assets/icons/close-masonry.svg'
import Svg from '_components/svg'

import styles from './styles.css'

// TODO: Improve positioning of the modal based on the containerBounding
// const HEADER_HEIGHT = 64

const AutoSummarizeSelectionModal = ({
  isOpen,
  onSummarize,
  asset,
  onCancel,
  // containerBounding,
}) => {
  const modalRef = useRef()
  // const [getStyle] = useModalPosition(containerBounding, modalRef, 'top')

  const handleSummarize = useCallback(() => {
    onSummarize()
  }, [onSummarize])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  // useEffect(() => {
  //   if (modalRef && modalRef.current) {
  //     const { height } = modalRef.current.getBoundingClientRect()
  //     const { top: containerTop } = containerBounding
  //     window.scrollTo(0, containerTop - height - HEADER_HEIGHT)
  //   }
  // }, [modalRef, containerBounding])

  return (
    <div
      className={isOpen ? styles.modal : styles['modal-closed']}
      ref={modalRef}
      // style={getStyle}
      id={`summarize-modal-${asset.get('id')}`}
    >
      <div className={styles.container}>
        <button
          aria-label="close modal"
          onClick={onCancel}
          className={styles['close-button']}
          type="button"
        >
          <Svg icon={CloseIcon} className={styles.icon} />
        </button>
        <h2 className={styles.title}>AI auto-summarizer text</h2>
        <div className={styles.content}>
          <p className={styles['asset-title']}>
            {asset.getIn(['aiSummarizerResult', 'title'], '')}
          </p>
          <p className={styles['asset-description']}>
            {asset.getIn(['aiSummarizerResult', 'description'], '')}
          </p>
        </div>
        <div className={styles['buttons-container']}>
          <Button
            onClick={handleCancel}
            className={styles['cancel-button']}
            theme={BUTTON_THEME.LINK_BUTTON}
          >
            Discard
          </Button>
          <Button onClick={handleSummarize} className={styles['summarize-button']}>
            Replace it
          </Button>
        </div>
      </div>
    </div>
  )
}

AutoSummarizeSelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  containerBounding: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
  onSummarize: PropTypes.func.isRequired,
  asset: PropTypes.instanceOf(Asset).isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default AutoSummarizeSelectionModal
