import { defineAction } from '_privateDependencies/react-utils/utils'
import * as userService from '_services/user'

export const USER_AUTOCOMPLETE = defineAction('USER_AUTOCOMPLETE')
export const CLEAR_AUTOCOMPLETE = 'CLEAR_AUTOCOMPLETE'

export const requestUserAutocomplete = name => (dispatch, getState) =>
  dispatch({
    type: USER_AUTOCOMPLETE.ACTION,
    payload: userService.userAutocomplete(getState().user.get('auth_token'))({
      name,
    }),
  })

export const clearUserAutocomplete = () => dispatch => {
  dispatch({
    type: CLEAR_AUTOCOMPLETE,
  })
}
