import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import useWindowSize from '_hooks/use-window-size'

import styles from './styles.css'

const Announcement = ({ title, description, image, link }) => {
  const { isMobile } = useWindowSize()

  const getImageStyle = useMemo(() => {
    if (!image) return {}
    return {
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    }
  }, [image])

  return (
    <div
      className={classnames(styles.container, {
        [styles['with-image']]: !!image && !isMobile,
      })}
    >
      {image && !isMobile && <div className={styles.image} style={getImageStyle} />}
      <div className={styles.content}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <div
          className={styles.description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {link && (
          <Button
            theme={BUTTON_THEME.PRIMARY}
            href={link}
            className={styles.button}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </Button>
        )}
      </div>
    </div>
  )
}

Announcement.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
}

Announcement.defaultProps = {
  title: '',
  description: '',
  image: '',
  link: '',
}

export default Announcement
