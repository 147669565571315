import { GET_SHARE_LINK_BY_HASH } from './actions'

export const individualLinkSelector = state => state.shareLinkNew.get('individualLink')

export const publicLinkSelector = state => state.shareLinkNew.get('publicLink')

export const isPublicLinkSelector = state => state.shareLinkNew.get('currentLinkIsPublic')

export const isUnpublishLinkActionSelector = state =>
  state.shareLinkNew.get('isUnpublishLinkAction')

export const isGetShareLinkByHashLoadingSelector = state =>
  state.loading.get(GET_SHARE_LINK_BY_HASH.ACTION)
