export const getYoutubeVideoId = url => {
  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|shorts\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  return url.match(youtubeRegex) ? RegExp.$1 : false
}

export const getVimeoVideoId = url => {
  const vimeoRegex = /^(?:https?:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
  return url.match(vimeoRegex) ? RegExp.$3 : false
}

export const isSubdomain = url => {
  const regex = /^([a-z]+:\/{2})?([\w-]+\.[\w-]+\.\w+)$/

  return !!url.match(regex)
}

export const isValidPhoneNumber = phoneNumber => {
  const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  return phoneNumber ? !!phoneNumber.match(regex) : false
}
