import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import ArrowDownIcon from '_assets/icons/arrow-down.svg'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import InputSwitch from '_components/switch/input-switch'
import AddRecipientIcon from '_assets/icons/plus_circle.svg'
import EmailInput from '_components/email-input'
import LabeledSwitch from '_components/switch/labeled-switch'
import Label from '_components/share-link-modal/label'
import { isFreeSelector } from '_modules/user/selectors'
import Input from '_components/ui-kit/input'
import Svg from '_components/svg'
import useModal from '_hooks/use-modal'
import { FLAG_SHOW_AUTO_REMINDERS } from '_config/environment'

import styles from './styles.css'

const ShareEmailPageOne = ({
  state,
  changeName,
  changeEmail,
  changeNameAndEmail,
  removeRecipient,
  addRecipient,
  onActivityUpdatesChange,
  onRequireEmailChange,
  onPreventDownloadChange,
  nextButtonAction,
  onPasswordProtectChange,
  onChangePassword,
  onPreventFeedbackChange,
  toggleAutomaticRemindersSwitch,
  onChangeValuesAutomaticRemindersSwitch,
  toggleRequirePhoneNumberSwitch,
  onChangeValues,
}) => {
  const isFreeUser = useSelector(isFreeSelector)
  const [isAdvanceSettingToggle, toggleAdvanceSettingOpen] = useModal()

  const renderEmailInputs = useCallback(
    () =>
      state.recipients.map(({ number, nameError, email, emailError, name }, index) => (
        <EmailInput
          emailId={`recipient-email-${index}`}
          nameId={`recipient-name-${index}`}
          number={number}
          name={name}
          nameError={nameError}
          email={email}
          emailError={emailError}
          changeName={changeName}
          changeEmail={changeEmail}
          changeNameAndEmail={changeNameAndEmail}
          removeInput={removeRecipient}
          namePlaceholder="Name"
          emailPlaceholder="Email"
          useSuggestions
          isOnlyRecipient={state.recipients.length === 1}
        />
      )),
    [changeEmail, changeName, changeNameAndEmail, removeRecipient, state.recipients]
  )

  return (
    <div className={styles.container}>
      <h3 className={styles.subtitle}>Add recipients and send email with Highnote</h3>
      <div className={styles['email-input-container']}>
        <div className={styles['email-input-wrapper']}>
          <p className={styles['name-title']}>Name</p>
          <p className={styles['email-title']}>Email Address</p>
        </div>
        <div className={styles['list-email-wrapper']}>{renderEmailInputs()}</div>
        <Button
          id="add-another-recipient-button"
          theme={BUTTON_THEME.SECONDARY}
          className={styles['add-recipient-button']}
          onClick={addRecipient}
          size={BUTTON_SIZE.SMALL}
          startIcon={AddRecipientIcon}
        >
          Add another recipient
        </Button>
      </div>
      <div className={styles['switch-container']}>
        <LabeledSwitch
          id="activity-updates-switch"
          isChecked={state.activityUpdates}
          label="Receive analytics by email"
          onChange={onActivityUpdatesChange}
          className={styles['switch-row']}
          disabled={isFreeUser}
        />
        <LabeledSwitch
          isChecked={state.requireEmail}
          label={<Label name="Require email to view" isFreeUser={isFreeUser} />}
          onChange={onRequireEmailChange}
          className={classnames(styles['switch-row'], styles['switch-row-reminders'])}
          disabled={isFreeUser}
        />
        {state.requireEmail && (
          <Input
            name="requireEmailMessage"
            placeholder="Enter your requested message"
            onChange={onChangeValues}
            value={state.requireEmailMessage}
          />
        )}
        <LabeledSwitch
          isChecked={state.requirePhoneNumber}
          label={<Label name="Require phone number to view" isFreeUser={isFreeUser} />}
          onChange={toggleRequirePhoneNumberSwitch}
          className={classnames(styles['switch-row'], styles['switch-row-reminders'])}
          disabled={isFreeUser}
        />
        {state.requirePhoneNumber && (
          <Input
            name="requirePhoneNumberMessage"
            placeholder="Enter your requested message"
            onChange={onChangeValues}
            value={state.requirePhoneNumberMessage}
          />
        )}
        <div className={styles['advance-setting-wrapper']}>
          <p className={styles['advance-title']}>Advanced</p>
          <button
            className={styles['advance-setting-button']}
            onClick={toggleAdvanceSettingOpen}
            type="button"
          >
            <Svg
              icon={ArrowDownIcon}
              className={classnames(styles['advance-setting-icon'], {
                [styles['icon-inverted']]: isAdvanceSettingToggle,
              })}
            />
          </button>
        </div>
        <div
          className={classnames(styles['advance-setting'], {
            [styles['show-advance-settings']]: isAdvanceSettingToggle,
          })}
        >
          <LabeledSwitch
            isChecked={state.preventDownload}
            label={<Label name="Disable asset download" isFreeUser={isFreeUser} />}
            onChange={onPreventDownloadChange}
            className={styles['switch-row']}
            disabled={isFreeUser}
          />
          <LabeledSwitch
            isChecked={state.preventFeedback}
            label={<Label name="Disable feedback" />}
            onChange={onPreventFeedbackChange}
            className={styles['switch-row']}
          />
          <InputSwitch
            isChecked={state.passwordProtect}
            onChangeSwitch={onPasswordProtectChange}
            onChangeInput={onChangePassword}
            label={<Label name="Password protect" isFreeUser={isFreeUser} />}
            inputError={state.passwordError}
            inputText={state.password}
            className={styles['switch-row']}
            disabled={isFreeUser}
          />
          {FLAG_SHOW_AUTO_REMINDERS && (
            <React.Fragment>
              <LabeledSwitch
                isChecked={state.hasAutoReminder}
                label={<Label name="Send automatic reminders" />}
                onChange={toggleAutomaticRemindersSwitch}
                className={classnames(styles['switch-row'], styles['switch-row-reminders'])}
                tooltip="Reminders will be sent for up to 30 days."
              />
              {state.hasAutoReminder && (
                <React.Fragment>
                  <Input
                    label="Days before sending first reminder:"
                    name="daysUntilNextReminder"
                    value={state.daysUntilNextReminder}
                    className={styles['input-reminder']}
                    onChange={onChangeValuesAutomaticRemindersSwitch}
                    error={state.daysUntilNextReminderError}
                  />
                  <Input
                    label="Days between reminders"
                    name="reminderInterval"
                    value={state.reminderInterval}
                    className={styles['input-reminder']}
                    onChange={onChangeValuesAutomaticRemindersSwitch}
                    error={state.reminderIntervalError}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      <Button
        id="go-to-email-modal-page-two"
        onClick={nextButtonAction}
        className={styles['next-button']}
      >
        Next Step
      </Button>
    </div>
  )
}

ShareEmailPageOne.propTypes = {
  state: PropTypes.shape({
    recipients: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        nameError: PropTypes.string,
        number: PropTypes.number,
        emailError: PropTypes.string,
      })
    ),
    activityUpdates: PropTypes.bool,
    passwordProtect: PropTypes.bool,
    requireEmail: PropTypes.bool,
    preventDownload: PropTypes.bool,
    passwordError: PropTypes.bool,
    password: PropTypes.string,
    preventFeedback: PropTypes.bool,
    hasAutoReminder: PropTypes.bool,
    daysUntilNextReminder: PropTypes.string,
    reminderInterval: PropTypes.string,
    daysUntilNextReminderError: PropTypes.bool,
    reminderIntervalError: PropTypes.bool,
    requirePhoneNumber: PropTypes.bool,
    requireEmailMessage: PropTypes.string,
    requirePhoneNumberMessage: PropTypes.string,
  }).isRequired,
  changeName: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changeNameAndEmail: PropTypes.func.isRequired,
  removeRecipient: PropTypes.func.isRequired,
  addRecipient: PropTypes.func.isRequired,
  onActivityUpdatesChange: PropTypes.func.isRequired,
  onRequireEmailChange: PropTypes.func.isRequired,
  onPreventDownloadChange: PropTypes.func.isRequired,
  nextButtonAction: PropTypes.func.isRequired,
  onPasswordProtectChange: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onPreventFeedbackChange: PropTypes.func.isRequired,
  toggleAutomaticRemindersSwitch: PropTypes.func.isRequired,
  onChangeValuesAutomaticRemindersSwitch: PropTypes.func.isRequired,
  toggleRequirePhoneNumberSwitch: PropTypes.func.isRequired,
  onChangeValues: PropTypes.func.isRequired,
}

export default ShareEmailPageOne
