import BarcelonaIcon from '_assets/icons/barcelona-icon.svg'
import StandardIcon from '_assets/icons/standard-icon.svg'

export const PRESENTATION_STYLE_STANDARD = 'standard'
export const PRESENTATION_STYLE_BARCELONA = 'barcelona'
export const PRESENTATION_STYLE_OUTLINE = 'outline'

export const getCurrentStyleName = {
  [PRESENTATION_STYLE_STANDARD]: 'Standard',
  [PRESENTATION_STYLE_BARCELONA]: 'Barcelona',
  [PRESENTATION_STYLE_OUTLINE]: 'New',
}

export const getCurrentStyleIcon = {
  [PRESENTATION_STYLE_STANDARD]: StandardIcon,
  [PRESENTATION_STYLE_BARCELONA]: BarcelonaIcon,
}
