import { List, fromJS } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'

import {
  LIST_SHARED_LINKS,
  UPDATE_SHARED_LINK,
  DELETE_SHARED_LINK,
  BULK_DELETE_SHARED_LINK,
  UNARCHIVE_SHARED_LINK,
} from './actions'

const INITIAL_STATE = List()

export const presentationRecipients = createReducer(INITIAL_STATE, {
  [LIST_SHARED_LINKS.FULFILLED]: (state, { payload }) => state.clear().merge(payload),

  [UPDATE_SHARED_LINK.FULFILLED]: (state, { payload }) => {
    const index = state.findIndex(recipient => recipient.get('id') === payload.id)
    return state.set(index, fromJS(payload))
  },

  [DELETE_SHARED_LINK.FULFILLED]: (state, { meta: { sharedLink } }) => {
    const index = state.findIndex(recipient => recipient.get('id') === sharedLink)
    return state.setIn([index, 'disabled'], true)
  },

  [UNARCHIVE_SHARED_LINK.FULFILLED]: (state, { meta: { sharedLink } }) => {
    const index = state.findIndex(recipient => recipient.get('id') === sharedLink)
    return state.setIn([index, 'disabled'], false)
  },

  [BULK_DELETE_SHARED_LINK.FULFILLED]: (state, { meta: { linksList } }) =>
    state.map(recipient => {
      if (linksList.includes(recipient.get('id'))) {
        return recipient.set('disabled', true)
      }
      return recipient
    }),
})
