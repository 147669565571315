import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import styles from './styles.css'

class UnsplashPickerPhoto extends PureComponent {
  static propTypes = {
    altDescription: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    urls: PropTypes.shape({
      thumb: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    altDescription: undefined,
    className: undefined,
  }

  onClick = event => {
    const { id, onClick } = this.props
    onClick(event, id)
  }

  onClickAuthorLink = event => {
    event.stopPropagation()
  }

  render() {
    const { altDescription, className, urls, user } = this.props

    return (
      <button
        className={classNames(className, styles.container)}
        onClick={this.onClick}
        type="button"
      >
        <img alt={altDescription || undefined} src={urls.thumb} />
        <a
          className={styles.author}
          href={`${user.links.html}?utm_source=HighNote&utm_medium=referral`}
          onClick={this.onClickAuthorLink}
          rel="nofollow noopener noreferrer"
          tabIndex="-1"
          target="_blank"
        >
          {user.name}
        </a>
      </button>
    )
  }
}

export default UnsplashPickerPhoto
