import { useEffect } from 'react'

export const useBeforeUnload = trigger => {
  useEffect(() => {
    if (trigger) {
      window.onbeforeunload = () => ''
    } else {
      window.onbeforeunload = () => undefined
    }
  }, [trigger])
}
