import { List } from 'immutable'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classnames from 'classnames'

import TextEditor from '_components/text-editor'
import Avatar from '_assets/avatar@3x.png'
import AssetVisualizationFrame from '_components/asset-visualization-frame'
import { isDesktopViewPort } from '_utils/window'
import gridStyles from '_styles/grid.css'

import styles from './styles.css'

class AssetVisualizationSection extends PureComponent {
  static propTypes = {
    convertedFiles: PropTypes.instanceOf(List),
    description: PropTypes.string,
    authorName: PropTypes.string,
    authorAvatar: PropTypes.string,
    thumbnail: PropTypes.string,
    assetType: PropTypes.string,
    assetLink: PropTypes.string,
    updateAssetPagesViewed: PropTypes.func,
    isLoadingAsset: PropTypes.bool.isRequired,
    onLoadAsset: PropTypes.func.isRequired,
    updateVideoViewDuration: PropTypes.func,
    isSingleAssetPreview: PropTypes.bool,
  }

  static defaultProps = {
    convertedFiles: List(),
    description: 'No comments made',
    authorName: 'Author',
    thumbnail: '',
    authorAvatar: undefined,
    assetType: undefined,
    assetLink: undefined,
    isSingleAssetPreview: false,
    updateAssetPagesViewed: () => {},
    updateVideoViewDuration: () => {},
  }

  mobileContent = () => {
    const {
      convertedFiles,
      description,
      authorName,
      authorAvatar,
      thumbnail,
      assetType,
      assetLink,
      updateAssetPagesViewed,
      isLoadingAsset,
      onLoadAsset,
      updateVideoViewDuration,
      isSingleAssetPreview,
    } = this.props
    return (
      <div className={classnames(gridStyles.Grid, gridStyles['Grid--alignCenter'])}>
        <div className={styles.wrapper}>
          {!isSingleAssetPreview && (
            <div className={classnames(gridStyles.Grid, styles['comment-container'])}>
              <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFit'])}>
                <img
                  src={authorAvatar || Avatar}
                  alt="Avatar"
                  className={classnames(styles.avatar)}
                />
              </div>
              <div
                className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFill'])}
                style={{ position: 'relative' }}
              >
                <p
                  className={classnames(styles['author-comment'])}
                >{`Comment from ${authorName}:`}</p>
                <TextEditor
                  readOnly
                  toolbarHidden
                  wrapperClassName={styles['description-wrapper']}
                  inputClassName={styles['asset-description']}
                  initialState={description}
                />
              </div>
            </div>
          )}
          <AssetVisualizationFrame
            convertedFiles={convertedFiles}
            thumbnail={thumbnail}
            assetType={assetType}
            assetLink={assetLink}
            updateAssetPagesViewed={updateAssetPagesViewed}
            isLoadingAsset={isLoadingAsset}
            onLoadAsset={onLoadAsset}
            updateVideoViewDuration={updateVideoViewDuration}
          />
        </div>
      </div>
    )
  }

  desktopContent = () => {
    const {
      convertedFiles,
      description,
      authorName,
      authorAvatar,
      thumbnail,
      assetType,
      assetLink,
      updateAssetPagesViewed,
      isLoadingAsset,
      onLoadAsset,
      updateVideoViewDuration,
      isSingleAssetPreview,
    } = this.props
    return (
      <div className={classnames(gridStyles.Grid, gridStyles['Grid--alignCenter'])}>
        <div className={classnames(gridStyles['Grid-cell'], styles.wrapper)}>
          {!isSingleAssetPreview && (
            <div
              className={classnames(
                gridStyles.Grid,
                gridStyles['Grid--alignMiddle'],
                styles['comment-container']
              )}
            >
              <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFit'])}>
                <img
                  src={authorAvatar || Avatar}
                  alt="Avatar"
                  className={classnames(styles.avatar)}
                />
              </div>
              <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFill'])}>
                <p
                  className={classnames(styles['author-comment'])}
                >{`Comment from ${authorName}:`}</p>
                <TextEditor
                  readOnly
                  toolbarHidden
                  inputClassName={styles['asset-description']}
                  initialState={description}
                />
              </div>
            </div>
          )}
          <AssetVisualizationFrame
            convertedFiles={convertedFiles}
            thumbnail={thumbnail}
            assetType={assetType}
            assetLink={assetLink}
            updateAssetPagesViewed={updateAssetPagesViewed}
            isLoadingAsset={isLoadingAsset}
            onLoadAsset={onLoadAsset}
            updateVideoViewDuration={updateVideoViewDuration}
          />
        </div>
      </div>
    )
  }

  render() {
    return isDesktopViewPort() ? this.desktopContent() : this.mobileContent()
  }
}

export default AssetVisualizationSection
