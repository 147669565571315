import React, { Fragment, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import humps from 'humps'
import { fromJS } from 'immutable'

import Loading from '_components/loading'
import TextEditor, { HIERARCHY_TYPES_ELEMENTS, convertTag } from '_components/text-editor'
import { getPresentation, GET_PRESENTATION } from '_modules/presentations/actions'
import { presentationSelector } from '_modules/presentations/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import {
  PRESENTATION_STYLE_STANDARD,
  PRESENTATION_STYLE_BARCELONA,
} from '_constants/presentation-styles'
import { ASSET_TYPE } from '_constants/presentation'
import { FormAsset, SeparatorAsset, TextAsset } from '_components/asset'
import { clientPresentationSelector } from '_modules/presentation-client/selectors'
import UserPicture from '_assets/avatar@3x.png'
import { hasHtmlTags, checkTitle } from '_utils/helpers'

import HeaderSection from './header-section'
import CoverSection from './cover-section'
import styles from './styles.css'
import StandardStyleCard from './standard-style-section'
import BarcelonaStyleCard from './barcelona-style-section'

const PresentationPreview = ({
  params: { id },
  loadingClassName,
  isClientView,
  assetInsightsId,
  clearAssetInsightsId,
  registerAssetInsights,
  updateAssetPagesViewed,
  updateAssetViewDuration,
}) => {
  const presentation = useSelector(state => {
    if (isClientView) {
      /* TODO: Add camelization directly in the presentation client module.
      It was not possible to do it now because in production the old decamelized view is still being used */
      return fromJS(humps.camelizeKeys(clientPresentationSelector(state).toJS()))
    }

    return presentationSelector(state, id, false)
  })

  const dispatch = useDispatch()

  const [isLoading] = useFetchCall(GET_PRESENTATION)

  const generateTitle = useCallback(asset => {
    if (asset.get('title') && !hasHtmlTags(asset.get('title'))) {
      return `<h2>${asset.get('title')}</h2>`
    }

    if (checkTitle(asset.get('title'))) {
      return convertTag(asset.get('title'), HIERARCHY_TYPES_ELEMENTS.SUBTITLE)
    }
    return asset.get('title')
  }, [])

  const renderAssetsSection = useCallback(
    asset => {
      const title = generateTitle(asset)

      const stylesCard = {
        [PRESENTATION_STYLE_STANDARD]: (
          <StandardStyleCard
            key={`asset-${asset.get('id')}`}
            asset={asset}
            presentation={presentation}
            assetInsightsId={assetInsightsId}
            clearAssetInsightsId={clearAssetInsightsId}
            registerAssetInsights={registerAssetInsights}
            updateAssetPagesViewed={updateAssetPagesViewed}
            updateAssetViewDuration={updateAssetViewDuration}
            title={title}
          />
        ),
        [PRESENTATION_STYLE_BARCELONA]: (
          <BarcelonaStyleCard
            key={`asset-${asset.get('id')}`}
            asset={asset}
            presentation={presentation}
            assetInsightsId={assetInsightsId}
            clearAssetInsightsId={clearAssetInsightsId}
            registerAssetInsights={registerAssetInsights}
            updateAssetPagesViewed={updateAssetPagesViewed}
            updateAssetViewDuration={updateAssetViewDuration}
            title={title}
          />
        ),
      }

      return stylesCard[presentation.get('style')]
    },
    [
      generateTitle,
      assetInsightsId,
      clearAssetInsightsId,
      presentation,
      registerAssetInsights,
      updateAssetPagesViewed,
      updateAssetViewDuration,
    ]
  )
  const renderAsset = useCallback(
    ({ assetType, index, asset, presentationId }) => {
      const [type] = assetType ? assetType.split('_') : ['']
      switch (type) {
        case ASSET_TYPE.CONTACT:
          return <FormAsset index={index} asset={asset} presentationId={presentationId} isPreview />
        case ASSET_TYPE.SEPARATOR:
          return (
            <SeparatorAsset index={index} asset={asset} presentationId={presentationId} isPreview />
          )
        case ASSET_TYPE.TEXT:
          return <TextAsset index={index} asset={asset} presentationId={presentationId} isPreview />
        default:
          break
      }

      return renderAssetsSection(asset)
    },
    [renderAssetsSection]
  )

  useEffect(() => {
    if (isClientView) return

    dispatch(getPresentation(Number(id)))
  }, [dispatch, id, isClientView])

  if (isLoading || !presentation) {
    return <Loading className={classnames(styles.loading, loadingClassName)} />
  }

  return (
    <section className={styles['presentation-preview-wrapper']}>
      {!isClientView && (
        <div className={styles.header}>
          <HeaderSection className={styles['header-content']} presentation={presentation} />
        </div>
      )}
      <CoverSection
        className={styles.cover}
        presentation={presentation}
        isClientView={isClientView}
      />
      {presentation.get('openingMsg') && (
        <TextEditor
          readOnly
          toolbarHidden
          wrapperClassName={styles['opening-msg']}
          inputClassName={styles['initial-message']}
          initialState={presentation.get('openingMsg')}
        />
      )}
      <div className={styles.content}>
        {presentation.get('assets').map((asset, index) => (
          <Fragment key={`asset-${asset.get('id')}`}>
            {renderAsset({
              assetType: asset.get('type'),
              index,
              asset,
              presentationId: presentation.get('id'),
            })}
          </Fragment>
        ))}
      </div>
      {presentation.get('closingMsg') && (
        <div className={styles['closing-msg']}>
          {presentation.get('picturePosition') !== 'no_picture' && (
            <img
              alt="Author"
              src={
                presentation.get('picture') ||
                presentation.getIn(['user', 'picture']) ||
                UserPicture
              }
              className={styles['image-close-msg']}
            />
          )}
          <TextEditor
            readOnly
            toolbarHidden
            wrapperClassName={styles['input-close-msg']}
            inputClassName={styles.message}
            initialState={presentation.get('closingMsg')}
          />
        </div>
      )}
    </section>
  )
}

PresentationPreview.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  loadingClassName: PropTypes.string,
  isClientView: PropTypes.bool,
  assetInsightsId: PropTypes.number,
  clearAssetInsightsId: PropTypes.func,
  registerAssetInsights: PropTypes.func,
  updateAssetPagesViewed: PropTypes.func,
  updateAssetViewDuration: PropTypes.func,
}

PresentationPreview.defaultProps = {
  loadingClassName: '',
  isClientView: false,
  assetInsightsId: -1,
  clearAssetInsightsId: () => {},
  registerAssetInsights: () => {},
  updateAssetPagesViewed: () => {},
  updateAssetViewDuration: () => {},
}

export default React.memo(PresentationPreview)
