import { defineAction } from '_privateDependencies/react-utils/utils'
import * as insightsService from '_services/insights'

export const GET_USER_PRESENTATION_INSIGHTS = defineAction('GET_USER_PRESENTATION_INSIGHTS')
export const RESET_USER_PRESENTATION_INSIGHTS = 'RESET_USER_PRESENTATION_INSIGHTS'
export const CREATE_USER_INSIGHTS_EMAIL = defineAction('CREATE_USER_INSIGHTS_EMAIL')
export const GET_USER_ACTIVITIES = defineAction('GET_USER_ACTIVITIES')

export const getUserPresentationInsights = (presentationId, shareLink) => (dispatch, getState) =>
  dispatch({
    type: GET_USER_PRESENTATION_INSIGHTS.ACTION,
    payload: insightsService.getUserPresentationInsights(getState().user.get('auth_token'))(
      presentationId,
      shareLink
    ),
  })

export const getUserActivities = activityId => (dispatch, getState) =>
  dispatch({
    type: GET_USER_ACTIVITIES.ACTION,
    payload: insightsService.getUserActivities(getState().user.get('auth_token'))(activityId),
  })

export const resetUserPresentationInsights = () => ({
  type: RESET_USER_PRESENTATION_INSIGHTS,
})

export const createUserInsightsEmail = (presentationId, shareLink, payload) => (
  dispatch,
  getState
) =>
  dispatch({
    type: CREATE_USER_INSIGHTS_EMAIL.ACTION,
    payload: insightsService.createUserInsightsEmail(getState().user.get('auth_token'))(
      presentationId,
      shareLink,
      payload
    ),
    meta: {
      payload,
    },
  })
