import { List } from 'immutable'
import { schema } from 'normalizr'

import Model from './model'

class Presentation extends Model({
  archived: undefined,
  assets: List(),
  background: undefined,
  backgroundColor: undefined,
  backgroundVideoUrl: undefined,
  backgroundCropped: undefined,
  backgroundMeta: undefined,
  backgroundThumbnail: undefined,
  backgroundOpacity: undefined,
  borderColor: undefined,
  category: undefined,
  closingMsg: undefined,
  company: undefined,
  createdAt: undefined,
  deleted: undefined,
  favorite: undefined,
  folder: undefined,
  id: undefined,
  lastAccess: undefined,
  logo: undefined,
  numberOfAccess: undefined,
  numberOfInvites: undefined,
  numberOfFeedback: undefined,
  openingMsg: undefined,
  picture: undefined,
  picturePosition: undefined,
  read: undefined,
  shareDate: undefined,
  shareUrl: undefined,
  sharedBy: undefined,
  sharedByUser: undefined,
  isWebpage: undefined,
  style: undefined,
  template: undefined,
  timesCloned: undefined,
  title: undefined,
  titleIsHidden: undefined,
  unsplashAuthor: undefined,
  unsplashAuthorLink: undefined,
  updatedAt: undefined,
  user: undefined,
  userName: undefined,
  userNameIsHidden: undefined,
  percentageUpload: undefined,
  isComplete: undefined,
  cleanTitle: undefined,
  createdByTextColor: undefined,
  isSharedWithEmail: undefined,
  hasPendingAssets: undefined,
  hasSharedPresentation: undefined,
}) {}

export default Presentation

const presentationNewSchema = new schema.Entity(
  'result',
  {},
  {
    idAttribute: 'id',
    processStrategy: ({ id, ...entity }) => new Presentation({ id, ...entity }),
  }
)

export const presentationSchema = [presentationNewSchema]
