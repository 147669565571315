import { Map, fromJS } from 'immutable'
import humps from 'humps'

import { createReducer } from '_privateDependencies/react-utils/utils'

import { GET_TEMPLATES } from './actions'

const INITIAL_STATE = Map({})

export default createReducer(INITIAL_STATE, {
  [GET_TEMPLATES.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return fromJS(camelizedPayload)
  },
})
