import * as templatesService from '_services/templates'
import { defineAction } from '_privateDependencies/react-utils/utils'

export const GET_TEMPLATES = defineAction('GET_TEMPLATES')

export const getTemplates = () => (dispatch, getState) =>
  dispatch({
    type: GET_TEMPLATES.ACTION,
    payload: templatesService.getTemplates(getState().user.get('auth_token')),
  })
