import Fetch from '_privateDependencies/fetch-utils'
import { API_URL } from '_config/environment'

const request = new Fetch(API_URL)

export const post = request.post.bind(request)
export const get = request.get.bind(request)
export const patch = request.patch.bind(request)
export const put = request.put.bind(request)
export const upload = request.upload.bind(request)
export const del = request.delete.bind(request)
export const generic = request.request.bind(request)

export default request
