import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'

import ImageUpload from '_components/image-upload'
import { imageExtensions } from '_utils/files'

import styles from './styles.css'

const PresentationLogoUploadModal = ({
  hasRemoveOption,
  onRemove,
  selectFile,
  applyDefaultLogo,
  ...restProps
}) => (
  <Modal
    {...restProps}
    className={styles.modal}
    contentLabel="Presentation logo upload"
    overlayClassName={styles.overlay}
    ariaHideApp={false}
  >
    <ImageUpload
      extensions={imageExtensions}
      selectFile={selectFile}
      applyDefaultLogo={applyDefaultLogo}
    />
    {hasRemoveOption && (
      <button className={styles['remove-logo-button']} onClick={onRemove} type="button">
        Remove logo
      </button>
    )}
  </Modal>
)

PresentationLogoUploadModal.propTypes = {
  hasRemoveOption: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  selectFile: PropTypes.func.isRequired,
  applyDefaultLogo: PropTypes.func,
}

PresentationLogoUploadModal.defaultProps = {
  applyDefaultLogo: undefined,
}

export default PresentationLogoUploadModal
