import { pointer, trackOffset, transform } from 'popmotion'
import PropTypes from 'prop-types'
import React from 'react'

export default class Switch extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    handleColor: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    offColor: PropTypes.string,
    onColor: PropTypes.string,
    pendingOnColor: PropTypes.string,
    pendingOffColor: PropTypes.string,
    labelOnColor: PropTypes.string,
    labelOffColor: PropTypes.string,
    readOnly: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    handleHeight: PropTypes.number,
    ariaDescribedby: PropTypes.string,
  }

  static defaultProps = {
    checked: false,
    disabled: false,
    id: undefined,
    handleColor: 'white',
    name: undefined,
    onChange: () => {},
    offColor: 'white',
    onColor: '#4cd964',
    pendingOnColor: '#4cd964',
    pendingOffColor: '#dfdfdf',
    labelOnColor: '#ffffff',
    labelOffColor: '#99a5b2',
    readOnly: false,
    width: 54,
    height: 31,
    handleHeight: 27,
    className: undefined,
    ariaDescribedby: undefined,
  }

  constructor(props) {
    super(props)

    this.state = {
      isDragging: false,
      offset: null,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.setRef = this.setRef.bind(this)
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.handleMouseUp)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  setRef(ref) {
    this.ref = ref
  }

  getHandleCursor() {
    if (this.isDisabled()) {
      return 'default'
    }

    return this.state.isDragging ? '-webkit-grabbing' : '-webkit-grab'
  }

  getOffset() {
    if (this.state.isDragging) {
      return this.state.offset
    }

    return this.props.checked ? this.getOffsetWidth() : 0
  }

  getOffsetProgress() {
    return this.getOffset() / this.getOffsetWidth()
  }

  getOffsetWidth() {
    return this.props.width - this.props.handleHeight - 3
  }

  handleClick(event) {
    if (this.isDisabled()) {
      return
    }
    // handle case when the switch is clicked
    this.clickChange(event.currentTarget.name)
  }

  handleMouseDown(e) {
    e.stopPropagation()
    if (this.isDisabled()) {
      return
    }

    this.pointerTracker = pointer(e).start()

    this.offsetTracker = trackOffset(this.pointerTracker.x, {
      from: this.getOffset(),
      onUpdate: transform.pipe(transform.clamp(0, this.getOffsetWidth()), offset =>
        this.setState({ offset })
      ),
    }).start()

    this.setState({
      isDragging: true,
      offset: this.getOffset(),
    })
  }

  handleMouseUp(e) {
    e.stopPropagation()
    const prevOffset = this.props.checked ? this.getOffsetWidth() : 0

    if (this.pointerTracker && this.offsetTracker) {
      this.pointerTracker.stop()
      this.offsetTracker.stop()
    }

    if (!this.state.isDragging || this.state.offset === prevOffset) {
      this.setState({
        isDragging: false,
        offset: null,
      })
      return
    }

    const checked = () => {
      if (this.getOffsetProgress() >= 0.5) {
        return !this.props.checked
      }
      return this.props.checked
    }

    this.setState({
      isDragging: false,
      offset: null,
    })

    e.preventDefault()

    if (checked) {
      this.clickChange(this.props.name)
    }
  }

  clickChange(name) {
    if (this.ref.parentNode && this.ref.parentNode.tagName.toLowerCase() === 'label') {
      // if the parent is a label, we don't need to emit the change event ourselves
      return
    }
    this.props.onChange(name)
  }

  isDisabled() {
    return this.props.disabled || this.props.readOnly
  }

  render() {
    const {
      checked,
      className,
      id,
      width,
      height,
      handleHeight,
      name,
      onColor,
      offColor,
      pendingOffColor,
      pendingOnColor,
      labelOnColor,
      labelOffColor,
      handleColor,
      ariaDescribedby,
    } = this.props
    const { isDragging } = this.state

    const color = checked ? onColor : offColor
    const borderColor = checked ? pendingOnColor : pendingOffColor
    const handleTopMargin =
      handleHeight >= height
        ? ((handleHeight - height) * -1) / 2 - 1
        : (height - 2 - handleHeight) / 2
    const handleCursor = this.getHandleCursor()
    const labelColor = checked ? labelOnColor : labelOffColor
    const labelPosition = checked ? 'left' : 'right'
    const labelPadding = checked ? '0 0 0 8px' : '0 5px 0 0'

    return (
      <button
        className={className}
        id={id}
        onClick={this.handleClick}
        ref={this.setRef}
        readOnly={this.isDisabled()}
        name={name}
        style={{
          backgroundColor: color,
          border: `1px solid ${borderColor}`,
          borderRadius: height / 2,
          padding: '0',
          outline: 'none',
          boxShadow: `inset 0 0 0 ${this.getOffset()}px ${color}`,
          boxSizing: 'border-box',
          display: 'inline-block',
          height: `${height}px`,
          opacity: this.isDisabled() ? 0.5 : 1,
          position: 'relative',
          transition: isDragging ? null : '0.2s',
          userSelect: 'none',
          width: `${width}px`,
        }}
        aria-describedby={ariaDescribedby}
        type="button"
      >
        <div
          name={name}
          readOnly
          style={{
            display: 'inline-block',
            boxSizing: 'border-box',
            marginTop: '1.5px',
            color: labelColor,
            fontSize: '7px',
            fontWeight: 'bold',
            letterSpacing: '0.7px',
            textAlign: labelPosition,
            width: '100%',
            padding: labelPadding,
            border: 'none',
            backgroundColor: 'transparent',
          }}
        />
        <div
          onMouseDown={this.handleMouseDown}
          readOnly
          role="button"
          tabIndex="-1"
          name={name}
          style={{
            backgroundColor: `${handleColor}`,
            borderRadius: '100%',
            border: '1px solid #fdfdfd',
            outline: 'none',
            boxShadow: '0 2px 4px #717f80',
            boxSizing: 'border-box',
            cursor: handleCursor,
            display: 'inline-block',
            height: `${handleHeight}px`,
            left: this.getOffset(),
            position: 'absolute',
            top: `${handleTopMargin}px`,
            transition: isDragging ? null : '0.2s',
            width: `${handleHeight}px`,
          }}
        />
      </button>
    )
  }
}
