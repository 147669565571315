import humps from 'humps'
import cookies from 'react-cookies'

import { getYoutubeVideoId, getVimeoVideoId, isSubdomain } from '_utils/regex'
import { LOGO_URL } from '_config/environment'
import {
  COOKIES_OPTIONS,
  cookieAuth,
  OPERATING_SYSTEM,
  SUBSCRIPTION_PLAN_INTERVAL,
  DEFAULT_IMAGE_THUMB,
} from '_utils/constants'

export const createFormData = (data, shouldDecamelize = false) => {
  const formData = new FormData()

  Object.keys(data).forEach(field => {
    const fieldValue = data[field]
    const formDataValue = (() => {
      if (!fieldValue) {
        return ''
      }

      if (fieldValue instanceof Blob || typeof fieldValue !== 'object') {
        return fieldValue
      }

      return JSON.stringify(fieldValue)
    })()

    formData.append(shouldDecamelize ? humps.decamelize(field) : field, formDataValue)
  })

  return formData
}

const URL_REGEX = /(https?:\/\/(?:(www\.)?|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:(www\.)?|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|(www\.)?[a-zA-Z0-9]+\.[^\s]{2,})/i

export const validateUrl = value => URL_REGEX.test(value)

const transformURL = url => {
  const urlLowerCased = url.toLowerCase()

  if (/^https?:\/\//.test(urlLowerCased)) {
    return url
  }

  if (/^www\./.test(urlLowerCased)) {
    return `http://${url}`
  }

  if (isSubdomain(url)) {
    return `http://${url}`
  }

  return `http://www.${url}`
}

export const createUrlPayload = url => {
  const urlTransformed = transformURL(url)
  const basePayload = {
    file: null,
    website: urlTransformed,
  }

  const youtubeVideoId = getYoutubeVideoId(urlTransformed)
  if (youtubeVideoId) {
    return {
      ...basePayload,
      thumbnailUrl: `https://img.youtube.com/vi/${youtubeVideoId}/sddefault.jpg`,
      type: 'video',
    }
  }

  const vimeoVideoId = getVimeoVideoId(url)
  if (vimeoVideoId) {
    return {
      ...basePayload,
      thumbnailUrl: `https://vimeo.com/${vimeoVideoId}`,
      type: 'video',
    }
  }

  return {
    ...basePayload,
    thumbnailUrl: urlTransformed,
    type: 'website',
  }
}

export const capitalizeString = string => string[0].toUpperCase() + string.slice(1).toLowerCase()

export const getStripeCurrency = value => {
  const amount = Number.isNaN(value) ? 0 : value / 100

  if (!String(amount).includes('.')) {
    return Number(amount.toFixed(2))
  }

  return amount
}

export const getInterval = value => {
  if (value) {
    return value === SUBSCRIPTION_PLAN_INTERVAL.MONTH ? 'MO' : 'Y'
  }

  return ''
}

export const currencyFormat = (value, removeSymbol = false) => {
  if (value) {
    const formattedValue = Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    if (removeSymbol) {
      return formattedValue.replace(/\$/g, '')
    }
    return formattedValue
  }
  return '$0.00'
}

export const getURLPathName = (location, position = 1) => {
  const splittedPathname = location.pathname.split(['/']).filter(path => path)
  return splittedPathname[splittedPathname.length - position]
}

export const cleanObject = object =>
  Object.keys(object)
    .filter(key => object[key] !== undefined)
    .reduce((acc, key) => Object.assign(acc, { [key]: object[key] }), {})

export const formatTiersPrice = tiers => {
  const formattedTiers = tiers.reduce((prev, tier, index) => {
    let interval
    if (index === 0) {
      interval = [1, tier.upTo]
    }
    if (index === tiers.length - 1) {
      interval = [prev[index - 1].interval[1] + 1, Infinity]
    }
    if (index > 0 && index < tiers.length - 1) {
      interval = [prev[index - 1].interval[1] + 1, tiers[index].upTo]
    }

    return [
      ...prev,
      {
        price: getStripeCurrency(tier.amount),
        interval,
        formattedPrice: currencyFormat(getStripeCurrency(tier.amount)),
      },
    ]
  }, [])
  return formattedTiers
}

export const getPlatform = () => {
  const platform =
    navigator &&
    (
      (navigator.userAgentData && navigator.userAgentData.platform) ||
      navigator.platform ||
      'unknown'
    ).toLowerCase()

  return Object.values(OPERATING_SYSTEM).find(item => {
    if (item.includes(platform)) {
      return item
    }

    if (item.includes('window') && item.includes('phone')) {
      return OPERATING_SYSTEM.PLATFORM_WIN_PHONE
    }

    return ''
  })
}

export const objectsAreEqual = (object1, object2) =>
  JSON.stringify(Object.values(object1).sort()) === JSON.stringify(Object.values(object2).sort())

export const getFirstPromoterCodeFormat = code => {
  if (code) {
    const fpCode = code.split('_r_')
    return fpCode[1] ? fpCode[1] : null
  }
  return null
}
export const getQueryString = params =>
  Object.keys(params)
    .map(k => {
      if (Array.isArray(params[k])) {
        return params[k].map(val => `${encodeURIComponent(k)}=${encodeURIComponent(val)}`).join('&')
      }

      return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
    })
    .join('&')

export const generateEmailPast = (url, thumbnail, title, author) => {
  let output = '<a style="display: block;'
  const imageBackground = thumbnail || DEFAULT_IMAGE_THUMB

  if (url) {
    output += `background: #F9FAFB;
        border-radius: 4px;
        padding: 16px;
        width: 472px;
        height: 360px;
        box-sizing: border-box;
        text-decoration: none;"
        href=${url} target="_blank"
      `
  } else {
    output += 'text-decoration: none;"'
  }

  output += '>'

  if (imageBackground) {
    output += `<img style="width: 440px;
        height: 236px;
        object-fit: cover;
        object-position: center;"
        src=${imageBackground}
      />`
  }

  if (LOGO_URL) {
    output += `<span style='display:flex;
      align-items:center;
      margin-top: 18px;
      color: #999DA3;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;'>
        <img
        src=${LOGO_URL}
        style="width: 18px;
        height: 18px;
        object-fit: cover;
        object-position: center;
        margin-right: 10px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;"
      />
      highnote.io
    </span>`
  }

  if (title) {
    output += `<span style="color: #141414;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;">
        ${title}
      </span>`
  }

  if (author) {
    output += `<span style="color: #545D65;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: block;">
        Created by <b>${author}</b>
      </span>`
  }

  output += '</a><br /><br />'

  return output
}

export const convertStringToRbga = colorString => {
  if (colorString) {
    const colorValues = colorString.match(/\d+/g)
    return {
      r: Number(colorValues[0]),
      g: Number(colorValues[1]),
      b: Number(colorValues[2]),
      a: Number(colorValues[3]) || 1,
    }
  }

  return null
}

export const addUserCookies = (user, authToken) => {
  const cookiesAuth = cookieAuth()
  if (authToken) {
    cookies.save(cookiesAuth, authToken, COOKIES_OPTIONS)
  }
  cookies.save('user', user, COOKIES_OPTIONS)
}

export const removeUserCookies = () => {
  const cookiesAuth = cookieAuth()

  cookies.remove(cookiesAuth, COOKIES_OPTIONS)
  cookies.remove('user', COOKIES_OPTIONS)
  cookies.remove('authUrl', COOKIES_OPTIONS)
}

export const verifyCaptcha = async token => {
  try {
    const response = await fetch('/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
    return response.status === 204
  } catch (error) {
    return false
  }
}

export const doneForYouOptions = crossSell => {
  if (crossSell) {
    const options = crossSell
      .map(item => ({
        id: item.get('id'),
        value: item.get('id'),
        label: item.get('name'),
      }))
      .toArray()
    return [{ id: '0', label: '-', value: '0' }].concat(options)
  }
  return []
}

export const hasHtmlTags = text => /<[^>]*>/.test(text)

export const checkTitle = title => {
  const regexForTagP = /<p\b[^>]*>[\s\S]*?<\/p>/

  return regexForTagP.test(title)
}

export const generateFormDataToUploadOnS3 = ({ fields, file }) => {
  const formData = new FormData()

  formData.append('key', fields.key)
  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId)
  formData.append('x-amz-security-token', fields['x-amz-security-token'])
  formData.append('policy', fields.policy)
  formData.append('signature', fields.signature)
  formData.append('acl', fields.acl)
  formData.append('file', file)

  return formData
}
