import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

export const MENU_STYLE = {
  DARK: 'dark',
  LIGHT: 'light',
  LIGHT_GREY: 'light-grey',
}

export const submenuPropType = PropTypes.arrayOf(
  PropTypes.shape({
    sectionStyle: PropTypes.oneOf(Object.values(MENU_STYLE)).isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
  })
)

const Submenu = ({ sectionList, className }) => (
  <div className={classnames(styles.container, className)}>
    {sectionList.map((section, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${section.sectionStyle}-${index}`} className={styles[section.sectionStyle]}>
        {section.list.map(menuItem => (
          <button
            key={menuItem.name}
            className={styles.icon}
            onClick={menuItem.onClick}
            type="button"
          >
            <svg alt={menuItem.name}>
              <use xlinkHref={menuItem.icon} />
            </svg>
            <p className={styles['menu-item-text']}>{menuItem.name}</p>
          </button>
        ))}
      </div>
    ))}
  </div>
)

Submenu.propTypes = {
  className: PropTypes.string,
  sectionList: submenuPropType.isRequired,
}

Submenu.defaultProps = {
  className: '',
}

export default Submenu
