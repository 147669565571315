import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loadScript from 'load-script'
import cookies from 'react-cookies'

import { COOKIES_OPTIONS } from '_utils/constants'

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js'

let scriptLoadingStarted = false

export default class GoogleChooser extends Component {
  static propTypes = {
    children: PropTypes.node,
    clientId: PropTypes.string.isRequired,
    developerKey: PropTypes.string.isRequired,
    scope: PropTypes.arrayOf(PropTypes.string),
    viewId: PropTypes.string,
    authImmediate: PropTypes.bool,
    onChange: PropTypes.func,
    multiselect: PropTypes.bool,
    navHidden: PropTypes.bool,
    disabled: PropTypes.bool,
    mimeTypes: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
  }

  static defaultProps = {
    children: null,
    onChange: () => {},
    scope: ['https://www.googleapis.com/auth/drive.readonly'],
    viewId: 'DOCS',
    authImmediate: false,
    multiselect: false,
    navHidden: false,
    disabled: false,
    mimeTypes: [],
    className: '',
  }

  componentDidMount() {
    if (this.isGoogleReady()) {
      this.onApiLoad()
    } else if (!scriptLoadingStarted) {
      scriptLoadingStarted = true
      loadScript(GOOGLE_SDK_URL, this.onApiLoad)
    }
  }

  onChoose = () => {
    if (
      !this.isGoogleReady() ||
      !this.isGoogleAuthReady() ||
      !this.isGooglePickerReady() ||
      this.props.disabled
    ) {
      return
    }

    const oauthToken = cookies.load('googleAccessToken', COOKIES_OPTIONS)

    if (oauthToken) {
      this.createPicker(oauthToken)
    } else {
      this.doAuth()
    }
  }

  onApiLoad = () => {
    if (this.isGoogleReady()) {
      window.gapi.load('auth')
      window.gapi.load('picker')
    }
  }

  doAuth = () => {
    window.gapi.auth.authorize(
      {
        client_id: this.props.clientId,
        scope: this.props.scope,
        immediate: this.props.authImmediate,
      },
      response => {
        if (response.access_token) {
          cookies.save('googleAccessToken', response.access_token, {
            maxAge: Number(response.expires_in),
            ...COOKIES_OPTIONS,
          })
          this.createPicker(response.access_token)
        }
      }
    )
  }

  isGoogleReady = () => !!window.gapi

  isGoogleAuthReady = () => !!window.gapi.auth

  isGooglePickerReady = () => !!window.google.picker

  createPicker(oauthToken) {
    const googleViewId = window.gapi.picker.api.ViewId[this.props.viewId]
    const view = new window.google.picker.View(googleViewId)

    if (this.props.mimeTypes) {
      view.setMimeTypes(this.props.mimeTypes.join(','))
    }

    if (!view) {
      throw new Error("Can't find view by viewId")
    }

    const picker = new window.google.picker.PickerBuilder()
      .addView(view)
      .setSelectableMimeTypes(this.props.mimeTypes.join(','))
      .setOAuthToken(oauthToken)
      .setDeveloperKey(this.props.developerKey)
      .setCallback(this.props.onChange)

    if (this.props.navHidden) {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
    }

    if (this.props.multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
    }

    picker.build().setVisible(true)
  }

  render() {
    return (
      <div
        onClick={this.onChoose}
        role="button"
        tabIndex={0}
        className={this.props.className}
        aria-hidden="true"
      >
        {this.props.children ? (
          this.props.children
        ) : (
          <button type="button">Open google chooser</button>
        )}
      </div>
    )
  }
}
