import UAParser from 'ua-parser-js'

const userAgentParserResult = new UAParser().getResult()

export const MAX_WIDTH_MEDIUM_VIEWPORT = 1024

export const MAX_WIDTH_SMALL_VIEWPORT = 480

export const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  userAgentParserResult.os.name
)
