import moment from 'moment-timezone'

export const correctDateTimezone = date => {
  const momentSanFrancisco = moment.tz(date, 'US/Pacific')
  const localDatetimeString = momentSanFrancisco
    .clone()
    .local()
    .format()
  return localDatetimeString
}

export const formatNotificationDate = date => {
  const correctDate = correctDateTimezone(date)
  return moment().diff(moment(correctDate), 'hours', true) > 24
    ? moment(correctDate).format('dddd, MM/DD/YY [at] hh:mmA')
    : moment(correctDate).fromNow()
}
