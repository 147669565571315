import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import AutoResizeTextArea from 'react-textarea-autosize'

import Avatar from '_assets/avatar@3x.png'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'

import styles from './styles.css'

const setRecipientString = recipients =>
  recipients
    .filter(recipient => recipient.name)
    .map(recipient => recipient.name)
    .join(', ')

const ShareEmailPageTwo = ({
  author,
  authorJobDetails,
  authorPicture,
  changeMessage,
  changeSubject,
  isSharing,
  message,
  readOnly,
  recipients,
  sendEmail,
  subject,
  title,
  messageError,
  backAction,
  id,
}) => (
  <Fragment>
    <div className={styles['recipients-container']}>
      <div className={styles['recipient-cell']}>
        <p className={classNames(styles['recipients-title'])}>Recipients:</p>
        <p className={classNames(styles['recipients-names'])}>{setRecipientString(recipients)}</p>
      </div>
      <div className={styles['recipient-cell']}>
        <p className={classNames(styles['subject-title'])}>Subject:</p>
        <input
          className={classNames(styles['subject-input'])}
          value={subject}
          onChange={changeSubject}
          disabled={readOnly}
          type="text"
        />
      </div>
    </div>
    <div className={styles['message-container']}>
      {!readOnly && <p className={classNames(styles['edit-message'])}>Edit your message below</p>}
      <div className={styles['message-box']}>
        <p className={styles['presentation-title']}>{title}</p>
        <AutoResizeTextArea
          value={message}
          onChange={changeMessage}
          minRows={4}
          className={classNames(styles['message-input'], {
            [styles.error]: messageError && !message,
          })}
          disabled={readOnly}
        />
        <Button
          theme={BUTTON_THEME.SECONDARY}
          size={BUTTON_SIZE.SMALL}
          className={styles['view-presentation-button']}
          to={`/presentations/preview/${id}`}
          target="_blank"
        >
          View Presentation
        </Button>
        <div className={styles['author-box']}>
          <img src={authorPicture || Avatar} alt="avatar" className={styles.avatar} />
          <div className={styles['author-details']}>
            <p className={styles['sent-by-text']}>Created by</p>
            <p className={styles.author}>{author}</p>
            <p className={styles['job-details']}>{authorJobDetails}</p>
          </div>
        </div>
      </div>
    </div>
    {!readOnly && (
      <div className={styles.actions}>
        <Button
          theme={BUTTON_THEME.SECONDARY}
          className={styles['back-button']}
          onClick={backAction}
        >
          Back
        </Button>

        <Button
          className={styles['send-button']}
          id="send-email-button"
          onClick={sendEmail}
          isLoading={isSharing}
          disabled={isSharing}
        >
          Send
        </Button>
      </div>
    )}
  </Fragment>
)

ShareEmailPageTwo.propTypes = {
  author: PropTypes.string,
  authorJobDetails: PropTypes.string,
  authorPicture: PropTypes.string,
  changeMessage: PropTypes.func,
  changeSubject: PropTypes.func,
  id: PropTypes.number,
  isSharing: PropTypes.bool,
  message: PropTypes.string,
  messageError: PropTypes.bool,
  readOnly: PropTypes.bool,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      number: PropTypes.number,
    })
  ).isRequired,
  sendEmail: PropTypes.func,
  subject: PropTypes.string,
  title: PropTypes.string,
  backAction: PropTypes.func,
}

ShareEmailPageTwo.defaultProps = {
  author: 'Author',
  authorJobDetails: 'Job | Company',
  authorPicture: undefined,
  changeMessage: () => {},
  changeSubject: () => {},
  id: undefined,
  isSharing: false,
  message: 'message',
  messageError: false,
  readOnly: false,
  sendEmail: () => {},
  subject: 'subject',
  title: 'default',
  backAction: () => {},
}

export default ShareEmailPageTwo
