import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close-masonry.svg'

import styles from './styles.css'

const Modal = ({
  className,
  contentClass,
  footerClass,
  icon,
  title,
  children,
  footer,
  dismiss,
  isOpen,
  hasHeaderDivider,
  headerClassName,
  hasCloseButton,
  titleClassName,
}) => (
  <ReactModal
    contentLabel="Dynamic content"
    isOpen={isOpen}
    className={classnames(styles.modal, className)}
    overlayClassName={styles.overlay}
    bodyOpenClassName={styles['no-scroll']}
    ariaHideApp={false}
  >
    <section className={styles.body}>
      <div
        className={classnames(
          styles.header,
          { [styles['header-with-icon']]: icon, [styles['has-close-button']]: hasCloseButton },
          headerClassName
        )}
      >
        {icon && (
          <svg alt="Modal icon" className={styles.icon}>
            <use xlinkHref={icon} />
          </svg>
        )}
        <h1 className={classnames(styles.title, titleClassName)}>{title}</h1>
        {hasCloseButton && (
          <div className={styles['close-action']}>
            <button
              aria-label="close"
              onClick={dismiss}
              className={styles['close-button']}
              type="button"
            >
              <svg aria-hidden="true" className={styles['close-icon']}>
                <use xlinkHref={CloseIcon} />
              </svg>
            </button>
          </div>
        )}
      </div>
      {hasHeaderDivider && <div className={styles['horizontal-line']} />}
      <div className={classnames(styles.content, contentClass)}>{children}</div>
      {footer && <div className={classnames(styles.footer, footerClass)}>{footer}</div>}
    </section>
  </ReactModal>
)

Modal.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  footer: PropTypes.node,
  contentClass: PropTypes.string,
  footerClass: PropTypes.string,
  dismiss: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  hasHeaderDivider: PropTypes.bool,
  headerClassName: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  titleClassName: PropTypes.string,
}

Modal.defaultProps = {
  className: '',
  icon: '',
  contentClass: '',
  footerClass: '',
  dismiss: () => {},
  children: null,
  footer: null,
  hasHeaderDivider: true,
  headerClassName: '',
  hasCloseButton: true,
  titleClassName: '',
}

export default React.memo(Modal)
