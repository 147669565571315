import React from 'react'
import PropTypes from 'prop-types'

const Svg = ({ icon, className }) => (
  <svg aria-hidden="true" className={className}>
    <use xlinkHref={icon} />
  </svg>
)

Svg.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export default React.memo(Svg)
