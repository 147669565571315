import React, { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'

import Loading from '_components/loading'
import { ALERT_TYPE } from '_components/toast'
import {
  getPresentation,
  GET_PRESENTATION,
  UPDATE_PRESENTATION,
  ARCHIVE_PRESENTATION,
} from '_modules/presentations/actions'
import {
  presentationSelector,
  getPresentationLoadingSelector,
  updatePresentationLoadingSelector,
} from '_modules/presentations/selectors'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { isEditAssetLoadingSelector } from '_modules/assets/selectors'
import usePrevious from '_hooks/use-previous'
import useFetchCall from '_hooks/use-fetch-call'
import EditSharedPresentationModal from '_components/edit-shared-presentation-modal'
import useToggle from '_hooks/use-modal'
import { useBeforeUnload } from '_hooks/use-before-unload'
import { useRouteLeave } from '_hooks/use-route-leave'

import styles from './styles.css'
import PresentationHeader from './presentation-header'
import PresentationCoverSection from './presentation-cover-section'
import PresentationAssetsSection from './presentation-assets-section'

const PresentationEdit = ({ params: { presentationId }, router, route, location }) => {
  const [isEditSharedPresentationModalOpen, onToggleEditSharedPresentationModal] = useToggle()

  const presentation = useSelector(state => presentationSelector(state, presentationId))
  const isGettingPresentation = useSelector(getPresentationLoadingSelector)
  const wasGettingPresentation = usePrevious(isGettingPresentation)

  const isUpdatingPresentation = useSelector(updatePresentationLoadingSelector)
  const isEditAssetLoading = useSelector(isEditAssetLoadingSelector)

  const dispatch = useDispatch()

  const handleSuccessArchiveConfirmationModal = useCallback(() => {
    browserHistory.push('/presentations')
  }, [])

  const handleFailArchiveConfirmationModal = useCallback(() => {
    dispatch(openToasterAlert(ALERT_TYPE.ERROR, 'Failed to archive presentation'))
  }, [dispatch])

  useFetchCall(
    ARCHIVE_PRESENTATION,
    handleSuccessArchiveConfirmationModal,
    handleFailArchiveConfirmationModal
  )

  const isAIGenerated = useMemo(() => {
    const { query } = location

    return query && query.ai
  }, [location])

  useEffect(() => {
    if (!isGettingPresentation && wasGettingPresentation && isAIGenerated) {
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.SUCCESS,
          message: `Your presentation is ready! HighnoteAI has generated an initial draft, complete with text and images. 
          Now, it's your turn to make it your own. Customize the content and choose additional images as you see fit.`,
          timeAutoClose: 10000,
          position: 'top-center',
        })
      )
    }
  }, [
    dispatch,
    isAIGenerated,
    isGettingPresentation,
    location,
    presentation,
    presentationId,
    wasGettingPresentation,
  ])
  useEffect(() => {
    if (!presentation || (presentation && !presentation.isComplete)) {
      dispatch(getPresentation(presentationId))
    }
  }, [dispatch, presentation, presentationId])

  const onUpdatePresentationRejected = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong.',
      })
    )
  }, [dispatch])

  const onGetPresentationReject = useCallback(error => {
    if (error.get('detail')) {
      browserHistory.replace('/404')
    }
  }, [])

  useFetchCall(UPDATE_PRESENTATION, () => {}, onUpdatePresentationRejected)
  const [, getPresentationError] = useFetchCall(GET_PRESENTATION, () => {}, onGetPresentationReject)

  useEffect(() => {
    if (
      !isGettingPresentation &&
      wasGettingPresentation &&
      presentation &&
      presentation.size &&
      presentation.get('numberOfInvites')
    ) {
      onToggleEditSharedPresentationModal()
    }
  }, [
    presentation,
    isGettingPresentation,
    wasGettingPresentation,
    onToggleEditSharedPresentationModal,
  ])

  // add an alert if the presentation is saving
  useRouteLeave({
    router,
    route,
    trigger:
      isEditAssetLoading ||
      isUpdatingPresentation ||
      (presentation && presentation.get('hasPendingAssets')),
  })
  useBeforeUnload(
    isEditAssetLoading ||
      isUpdatingPresentation ||
      (presentation && presentation.get('hasPendingAssets'))
  )

  if (
    ((!wasGettingPresentation || isGettingPresentation || getPresentationError.size) &&
      !presentation) ||
    (presentation && !presentation.isComplete)
  ) {
    return <Loading className={styles.loading} />
  }

  return (
    <section className={styles['presentation-wrapper']}>
      <PresentationHeader
        presentation={presentation}
        isEditSharedPresentationModalOpen={isEditSharedPresentationModalOpen}
      />
      <PresentationCoverSection presentation={presentation} isAIGenerated={isAIGenerated} />
      <PresentationAssetsSection presentation={presentation} isAIGenerated={isAIGenerated} />
      {isEditSharedPresentationModalOpen && (
        <EditSharedPresentationModal dismiss={onToggleEditSharedPresentationModal} />
      )}
    </section>
  )
}

PresentationEdit.propTypes = {
  params: PropTypes.shape({ presentationId: PropTypes.string }).isRequired,
  router: PropTypes.shape({ setRouteLeaveHook: PropTypes.func.isRequired }).isRequired,
  route: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({ ai: PropTypes.string }),
  }).isRequired,
}

export default React.memo(PresentationEdit)
