import { Map, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'

import { createReducer } from '_privateDependencies/react-utils/utils'

import { USER_AUTOCOMPLETE, CLEAR_AUTOCOMPLETE } from './actions'

const INITIAL_STATE = Map()

export const minimumUserSchema = new schema.Entity(
  'minimumUserSchema',
  {},
  {
    idAttribute: 'email',
  }
)

export const results = new schema.Entity('results', {
  results: [minimumUserSchema],
})

export const autocomplete = createReducer(INITIAL_STATE, {
  [USER_AUTOCOMPLETE.FULFILLED]: (state, { payload }) =>
    fromJS(normalize(payload, results).entities.minimumUserSchema) || Map(),

  [CLEAR_AUTOCOMPLETE]: () => INITIAL_STATE,
})
