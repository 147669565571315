import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

import { EDIT_ASSET, ENHANCE_ASSET } from './actions'

export const presentationAssetsSelector = state => state.assets.get('assets', List([]))

export const listPresentationAssetsSelector = state => state.assets.get('search')

export const getPresentationAssetsSelector = createSelector(
  presentationAssetsSelector,
  listPresentationAssetsSelector,
  (presentationAssets, assetIds) => assetIds && assetIds.map(id => presentationAssets.get(`${id}`))
)

export const getPresentationAssetByIdSelector = (state, assetId) =>
  state.assets.getIn(['assets', String(assetId)])

export const getCurrentAssetIndexSelector = state => state.assets.get('currentAssetIndex')

export const getPresentationAssetSelector = (state, id) =>
  state.assets.get('assets').find(asset => asset.get('id') === id)

export const isEditAssetLoadingSelector = state => state.loading.get(EDIT_ASSET.ACTION)

export const editAssetErrorSelector = state => state.error.get(EDIT_ASSET.ACTION, Map())

export const enhanceAssetErrorSelector = state => state.error.get(ENHANCE_ASSET.ACTION, Map())

export const enhanceAssetLoadingSelector = state => state.loading.get(ENHANCE_ASSET.ACTION)

export const enhanceAssetSelector = state => state.assets.get('enhance')
