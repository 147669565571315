import React, { useState, useRef, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { List } from 'immutable'

import Svg from '_components/svg'
import ZoomOut from '_assets/icons/ic-minus.svg'
import ZoomIn from '_assets/icons/ic-zoom-in.svg'
import FullScreen from '_assets/icons/ic-fullscreen.svg'
import withBrowserInformation from '_hocs/with-browser-information'

import styles from './styles.css'

const MAX_SCALE = 3
const MIN_SCALE = 0.7
const MARGIN_TOP_CHILDREN = 10
const MODAL_ID = 'asset-visualization-modal'

const ZoomImage = ({
  children,
  contentClassName,
  showPageInfo,
  maxScale,
  minScale,
  isSafari,
  isIos,
}) => {
  const [zoom, setZoom] = useState(1.0)
  const [currentPage, setCurrentPage] = useState(1)
  const [fullScreenActive, setFullScreenActive] = useState(false)
  const wrapperRef = useRef(null)
  const ref = useRef(null)
  const containerRef = useRef(null)
  const transformWrapperRef = useRef(null)

  const getNewPosition = useCallback(
    (currentSize, scale) => (currentSize - currentSize * scale) / 2,
    []
  )

  useEffect(() => {
    if (transformWrapperRef.current) {
      const newPositionX = getNewPosition(
        transformWrapperRef.current.instance.contentComponent.offsetWidth,
        zoom
      )
      transformWrapperRef.current.setTransform(
        newPositionX,
        transformWrapperRef.current.state.positionY,
        zoom
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewPosition, zoom])

  const handleScroll = useCallback(
    event => {
      const { target } = event
      const { scrollHeight } = target
      const scrollPosition = target.scrollTop

      const childSize = children.first().ref.current.offsetHeight

      if (target.offsetHeight + target.scrollTop >= scrollHeight) {
        setCurrentPage(children.size)
        return
      }

      const newCurrentPage =
        Math.floor(scrollPosition / (Number(childSize) + MARGIN_TOP_CHILDREN)) + 1

      if (newCurrentPage > children.size) {
        return
      }

      setCurrentPage(newCurrentPage)
    },
    [children]
  )

  const onZoomIn = useCallback(() => setZoom(prev => prev + 0.1), [])

  const onZoomOut = useCallback(() => setZoom(prev => prev - 0.1), [])

  const onExitFullscreen = useCallback(() => {
    if (containerRef && containerRef.current) {
      setFullScreenActive(prevState => !prevState)
      document.exitFullscreen()
    }
  }, [])

  const onFullscreen = useCallback(() => {
    if (containerRef.current && ref.current) {
      if (fullScreenActive) {
        onExitFullscreen()
        return
      }

      containerRef.current.requestFullscreen({ navigationUI: 'show' })
      setFullScreenActive(prevState => !prevState)
    }
  }, [fullScreenActive, onExitFullscreen])

  useEffect(() => {
    ref.current = document.querySelector(`#${MODAL_ID}`)
  }, [])

  useEffect(() => {
    const wrapperRefCurrent = wrapperRef.current
    if (wrapperRefCurrent) wrapperRefCurrent.addEventListener('scroll', handleScroll)

    return () => {
      wrapperRefCurrent.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <TransformWrapper
      panning={{ disabled: zoom === 1 }}
      wheel={{ disabled: true }}
      doubleClick={{ disabled: true }}
      ref={transformWrapperRef}
      initialScale={1}
      centerZoomedOut
      pinch={{ disabled: true }}
    >
      <div className={styles.container} ref={containerRef}>
        <TransformComponent contentClass={classnames(styles.content, contentClassName)}>
          <div className={styles.wrapper} ref={wrapperRef}>
            {children}
          </div>
        </TransformComponent>
        <div className={styles.toolbar}>
          {showPageInfo && (
            <p className={styles['page-information']}>{`${currentPage} of ${
              children.size ? children.size : 1
            }`}</p>
          )}
          <div className={styles['action-wrapper']}>
            <button
              onClick={onZoomIn}
              className={styles.button}
              disabled={Number(zoom) > maxScale}
              type="button"
            >
              <Svg icon={ZoomIn} className={styles.icon} />
            </button>
            <span className={styles.zoom}>{Math.round(zoom * 100)}%</span>
            <button
              onClick={onZoomOut}
              className={styles.button}
              disabled={Number(zoom) < minScale}
              type="button"
            >
              <Svg icon={ZoomOut} className={styles.icon} />
            </button>
            {!isSafari && !isIos && (
              <button className={styles.button} onClick={onFullscreen} type="button">
                <Svg icon={FullScreen} className={styles.icon} />
              </button>
            )}
          </div>
        </div>
      </div>
    </TransformWrapper>
  )
}

ZoomImage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.instanceOf(List),
  ]).isRequired,
  contentClassName: PropTypes.string,
  showPageInfo: PropTypes.bool,
  maxScale: PropTypes.number,
  minScale: PropTypes.number,
  isSafari: PropTypes.bool,
  isIos: PropTypes.bool,
}

ZoomImage.defaultProps = {
  contentClassName: '',
  showPageInfo: true,
  maxScale: MAX_SCALE,
  minScale: MIN_SCALE,
  isSafari: false,
  isIos: false,
}

export default withBrowserInformation(React.memo(ZoomImage))
