import memoizeOne from 'memoize-one'
import mime from 'mime-types'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import cookies from 'react-cookies'

import { AlertType } from '_components/alert-message'
import { contentTypeIsJSON } from '_privateDependencies/fetch-utils'
import { FILE_TYPES_ALLOWED, isGoogle, convertGoogleType } from '_utils/files'

const BASE_OPEN_ALERT_PARAMS = { isOpen: true, timer: 5000 }

const getFileTypesMimeTypes = memoizeOne(fileTypes => fileTypes.map(fileType => fileType.mimeType))

class FileUpload extends PureComponent {
  static propTypes = {
    fileTypes: PropTypes.arrayOf(
      PropTypes.shape({
        extension: PropTypes.string,
        mimeType: PropTypes.string,
      })
    ),
    googleDriveExportMimeType: PropTypes.string,
    onDownloadFileBegin: PropTypes.func,
    onDownloadFileComplete: PropTypes.func,
    onDownloadFileError: PropTypes.func,
    onGoogleDriveCancel: PropTypes.func,
    openAlert: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
  }

  static defaultProps = {
    fileTypes: FILE_TYPES_ALLOWED,
    googleDriveExportMimeType: 'application/pdf',
    onDownloadFileBegin: () => {},
    onDownloadFileComplete: () => {},
    onDownloadFileError: () => {},
    onGoogleDriveCancel: () => {},
  }

  onDownloadFileComplete = (blob, name) => {
    const { type } = blob
    const filename = isGoogle(type)
      ? `${name.slice(0, name.lastIndexOf('.'))}.${mime.extension(type)}`
      : name

    this.props.onDownloadFileComplete(new File([blob], filename, { type }))
  }

  onGoogleDriveAction = data => {
    switch (data.action) {
      case 'picked': {
        this.downloadGoogleDriveFile(
          data.docs[0],
          id => `https://www.googleapis.com/drive/v3/files/${id}?alt=media`,
          (id, mimeType) =>
            `https://www.googleapis.com/drive/v3/files/${id}/export?mimeType=${mimeType}`
        )
        break
      }
      case 'cancel':
        this.props.onGoogleDriveCancel()
        break
      default:
        break
    }
  }

  onGoogleDriveError = (error, file) => {
    const { googleDriveExportMimeType, onDownloadFileError } = this.props

    if (
      typeof error === 'object' &&
      error.error &&
      error.error.message ===
        'Only files with binary content can be downloaded. Use Export with Google Docs files.'
    ) {
      this.downloadGoogleDriveFile(
        file,
        id =>
          `https://www.googleapis.com/drive/v3/files/${id}/export?mimeType=${googleDriveExportMimeType}`
      )
    } else {
      onDownloadFileError(error)
    }
  }

  downloadBoxFile = file => {
    const { extension, id, name } = file
    const mimeType = mime.lookup(extension)

    if (!this.validateFile({ type: mimeType })) {
      return
    }

    const token = cookies.load('boxAccessToken')
    const sanitizedName = name.split(' ').join('_')

    this.downloadFile(
      new Request(`/downloadBox/${id}?accessToken=${token}`),
      sanitizedName,
      this.onDownloadFileComplete,
      this.props.onDownloadFileError,
      file
    )
  }

  downloadUnsplashFile = file => {
    const { name, url } = file
    const sanitizedName = `${name.split(' ').join('_')}.jpeg`

    this.downloadFile(
      new Request(url),
      sanitizedName,
      this.onDownloadFileComplete,
      this.props.onDownloadFileError,
      file
    )
  }

  downloadDropboxFile = files => {
    const { link, name } = files[0]
    const extension = name.split('.').pop()
    const mimeType = mime.lookup(extension)

    if (!this.validateFile({ type: mimeType })) {
      return
    }

    const sanitizedName = name.split(' ').join('_')

    this.downloadFile(
      new Request(link),
      sanitizedName,
      this.onDownloadFileComplete,
      this.props.onDownloadFileError,
      files
    )
  }

  downloadFile = (request, name, onComplete, onError, file) => {
    this.props.onDownloadFileBegin()

    fetch(request)
      .then(res => {
        if (res.status !== 200) {
          throw res
        }
        return res.blob()
      })
      .then(blob => {
        onComplete(blob, name)
      })
      .catch(res => {
        if (contentTypeIsJSON(res.headers.get('content-type'))) {
          res.json().then(message => onError(message, file))
        } else {
          onError(res.statusText, file)
        }
      })
  }

  downloadGoogleDriveFile = (file, createUrl, exportUrl) => {
    const token = cookies.load('googleAccessToken')

    const { id, name, mimeType } = file
    if (!this.validateFile({ type: mimeType })) {
      return
    }

    let sanitizedName = name.split(' ').join('_')
    sanitizedName = sanitizedName.split('/').join('_')

    const isMimeTypeGoogle = isGoogle(mimeType)

    if (!mime.lookup(sanitizedName)) {
      const extension = mime.extension(isMimeTypeGoogle ? convertGoogleType(mimeType) : mimeType)
      sanitizedName = `${sanitizedName}.${extension}`
    }

    const url = isMimeTypeGoogle ? exportUrl(id, convertGoogleType(mimeType)) : createUrl(id)
    const options = {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }

    this.downloadFile(
      new Request(url, options),
      sanitizedName,
      this.onDownloadFileComplete,
      this.onGoogleDriveError,
      file
    )
  }

  showNotAllowedFileTypeAlert = mimeType => {
    const { openAlert } = this.props

    if (/^video\//.test(mimeType)) {
      openAlert({
        ...BASE_OPEN_ALERT_PARAMS,
        type: AlertType.VIDEO_UPLOAD_NOT_ALLOWED,
      })
      return
    }

    openAlert({
      ...BASE_OPEN_ALERT_PARAMS,
      type: AlertType.FILE_UPLOAD_NOT_ALLOWED,
    })
  }

  validateFile = file => {
    if (!file) {
      return false
    }

    const { type } = file
    const mimeTypes = getFileTypesMimeTypes(this.props.fileTypes)

    if (!mimeTypes.includes(type) && type !== '') {
      this.showNotAllowedFileTypeAlert(type)
      return false
    }

    return true
  }

  render() {
    return this.props.render({
      downloadBoxFile: this.downloadBoxFile,
      downloadUnsplashFile: this.downloadUnsplashFile,
      downloadDropboxFile: this.downloadDropboxFile,
      onGoogleDriveAction: this.onGoogleDriveAction,
      validateFile: this.validateFile,
    })
  }
}

export default FileUpload
