import { post, patch, get } from './request'

export const addPresentationViewDuration = (payload, params) =>
  post(['presentation-activity', 'view-duration'], { params }, payload)

export const presentationActivity = (payload, params) =>
  post(['presentation-activity'], { params }, payload)

export const assetActivity = (payload, params) => post(['asset-activity'], { params }, payload)

export const updateAssetInsights = (assetId, payload, params) =>
  patch(['asset-activity', assetId], { params }, payload)

export const listUserInsightsActivities = key => (nextPage = null) => {
  const params = {
    ...(nextPage ? { page: nextPage } : {}),
  }

  return get(['activities'], {
    key,
    params,
  })
}

export const getPresentationInsights = key => presentationId =>
  get(['presentation-insights', presentationId], { key })

export const getUserPresentationInsights = key => (presentationId, shareLink) =>
  get(['presentation-insights', presentationId, 'user', shareLink], { key })

export const getUserActivities = key => activityId => get(['activities', activityId], { key })

export const viewPresentationActivity = key => presentationActivityId =>
  post(['activities', presentationActivityId, 'viewed'], { key })

export const getNotifications = key => get(['notifications'], { key })

export const markAllAsRead = key => post(['notifications', 'mark-all'], { key })

export const markAsRead = key => id => post(['notifications', id, 'read'], { key })

export const loadMoreNotifications = key => nextPage =>
  get(['notifications'], {
    key,
    params: {
      page: nextPage,
    },
  })

export const createUserInsightsEmail = key => (presentationId, shareLink, payload) =>
  post(['presentation-insights', presentationId, 'user', shareLink, 'add-email'], { key }, payload)

export const createComment = key => payload => post(['feedback'], { key }, payload)
