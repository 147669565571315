import React, { useCallback, useState, useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import debounce from 'lodash.debounce'
import Draft from 'draft-js'

import HideIcon from '_assets/icons/hide.svg'
import ShowIcon from '_assets/icons/show.svg'
import ReorderAssetIcon from '_assets/icons/reorder.svg'
import Svg from '_components/svg'
import Button from '_components/button-round'
import Asset from '_models/asset'
import { DELETE_ASSET, EDIT_ASSET, editPresentationAsset } from '_modules/assets/actions'
import { ALERT_TYPE, ALERT_MESSAGE } from '_components/toast'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import useFetchCall from '_hooks/use-fetch-call'
import useToggle from '_hooks/use-modal'
import WysiwygTextfield from '_components/wysiwyg-textfield'
import TextEditor from '_components/text-editor'
import { ASSET_TYPE } from '_constants/presentation'

import HideAsset from '../components/hide-asset'
import AssetMoreActions from '../components/asset-more-actions'

import styles from './styles.css'

const ENTER_KEY_CODE = 13

export const SeparatorAsset = ({ isPreview, asset, presentationId, index }) => {
  const dispatch = useDispatch()
  const [isHidingAsset, toggleHidingAsset] = useState(false)
  const [isDeletingAsset, onToggleIsDeletingAsset] = useToggle()

  const onSuccess = useCallback(() => {
    if (isHidingAsset) {
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.SUCCESS,
          message: ALERT_MESSAGE.SUCCESS.DEFAULT,
        })
      )
    }
    toggleHidingAsset(false)
  }, [dispatch, isHidingAsset])

  const onReject = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: ALERT_MESSAGE.ERROR.DEFAULT,
      })
    )
    toggleHidingAsset(false)
  }, [dispatch])

  useFetchCall(EDIT_ASSET, onSuccess, () => onReject())

  const onHideAsset = useCallback(() => {
    const isHidden = asset.get('type').includes('_hidden')

    dispatch(
      editPresentationAsset({
        id: asset.get('id'),
        presentationId,
        params: {},
        payload: { type: isHidden ? ASSET_TYPE.SEPARATOR : `${ASSET_TYPE.SEPARATOR}_hidden` },
      })
    )
    toggleHidingAsset(true)
  }, [asset, dispatch, presentationId])

  const onDeleteSuccess = useCallback(() => {
    if (isDeletingAsset) {
      onToggleIsDeletingAsset()
    }
  }, [isDeletingAsset, onToggleIsDeletingAsset])

  const onDeleteReject = useCallback(() => {
    if (isDeletingAsset) {
      onToggleIsDeletingAsset()
    }

    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong.',
      })
    )
  }, [dispatch, isDeletingAsset, onToggleIsDeletingAsset])
  const handleInputChange = useCallback(
    debounce(
      (name, value) =>
        dispatch(
          editPresentationAsset({
            id: asset.get('id'),
            presentationId,
            params: {},
            payload: { [name]: value },
          })
        ),
      300
    ),
    []
  )

  const onInputChange = useCallback(
    ({ name, value, plainText }) => {
      if (plainText) {
        handleInputChange(name, value)
        return
      }
      handleInputChange(name, plainText)
    },
    [handleInputChange]
  )

  const keyBindingFn = useCallback(event => {
    if (!event.metaKey && event.keyCode === ENTER_KEY_CODE) {
      return false
    }

    return Draft.getDefaultKeyBinding(event)
  }, [])

  useFetchCall(DELETE_ASSET, onDeleteSuccess, onDeleteReject)

  const renderContent = useMemo(
    () => (
      <div
        className={classnames(styles['separator-container'], {
          [styles['is-preview']]: isPreview,
        })}
      >
        <hr
          className={classnames(
            styles['separator-line'],
            {
              [styles['hidden-asset']]: asset.get('type').includes('_hidden'),
            },
            {
              [styles['is-preview']]: isPreview,
            }
          )}
        />
        {asset.get('title') && (
          <TextEditor
            readOnly
            toolbarHidden
            wrapperClassName={styles.title}
            inputClassName={styles['title-input-preview']}
            initialState={asset.get('title')}
          />
        )}
      </div>
    ),
    [asset, isPreview]
  )

  return isPreview ? (
    <div
      className={classnames(styles['separator-asset-wrapper'], {
        [styles['is-preview']]: isPreview,
      })}
    >
      {renderContent}
    </div>
  ) : (
    <Draggable key={`asset-${asset.get('id')}`} draggableId={String(asset.get('id'))} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={styles['separator-asset-wrapper']}
        >
          <div className={styles['drag-container']} {...provided.dragHandleProps}>
            <Svg icon={ReorderAssetIcon} className={styles['drag-icon']} />
          </div>
          <div
            className={classnames(styles.container, {
              [styles.hidden]: asset.get('isHidden'),
            })}
          >
            {asset.get('isHidden') && <HideAsset asset={asset} presentationId={presentationId} />}
            <div className={styles['separator-container']}>
              <hr
                className={classnames(styles['separator-line'], {
                  [styles['hidden-asset']]: asset.get('type').includes('_hidden'),
                })}
              />
              <Button
                type="button"
                theme="transparent-gray"
                onClick={onHideAsset}
                className={
                  asset.get('type').includes('_hidden')
                    ? styles['separator-show-button']
                    : styles['separator-hide-button']
                }
                startIcon={asset.get('isHidden') ? ShowIcon : HideIcon}
                startIconClassName={styles['hide-icon']}
                isLoading={isHidingAsset}
              >
                {asset.get('type').includes('_hidden') ? 'Show line' : 'Hide line'}
              </Button>
            </div>

            <WysiwygTextfield
              className={styles.title}
              inputClassName={styles['title-input']}
              name="title"
              maxLength={78}
              placeholder="Write your text here  (optional)"
              value={asset.get('title')}
              onChange={onInputChange}
              keyBindingFn={keyBindingFn}
              presentationId={presentationId}
              assetId={asset.get('id')}
            />
            <AssetMoreActions
              asset={asset}
              className={styles['asset-actions']}
              presentationId={presentationId}
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}

SeparatorAsset.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isPreview: PropTypes.bool,
  presentationId: PropTypes.number.isRequired,
}

SeparatorAsset.defaultProps = {
  isPreview: false,
}
