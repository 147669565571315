import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'

export const LOADER_COLOR = {
  DARKISH_PINK: '#e82f72',
  NAVY: 'var(--navy)',
  PINK: '#e62e71',
  GRAY: '#9b9b9b',
  BLACK: 'black',
  WHITE: 'white',
}

export const LOADER_SIZE = {
  SMALL: 12,
  BUTTON: 16,
  NORMAL: 20,
  PAGE: 30,
}

const LoaderSpinner = ({ className, color, size }) => (
  <Loader type="TailSpin" className={className} color={color} height={size} width={size} />
)

LoaderSpinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

LoaderSpinner.defaultProps = {
  className: '',
  color: LOADER_COLOR.PINK,
  size: LOADER_SIZE.PAGE,
}

export default React.memo(LoaderSpinner)
