import PropTypes from 'prop-types'
import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { Link, browserHistory } from 'react-router'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import ReactGA from 'react-ga'
import cookies from 'react-cookies'

import SimpleModal, { SimpleModalTheme } from '_components/simple-modal'
import withBrowserInformation from '_hocs/with-browser-information'
import Button, { ButtonTheme, ButtonType } from '_components/button'
import TextInput, { InputTheme, InputType } from '_components/labeled-text-input'
import {
  signUpEmail,
  SIGN_UP_EMAIL,
  loginFacebook,
  LOGIN_FACEBOOK,
  loginGoogle,
  LOGIN_GOOGLE,
  resetUser,
} from '_modules/user/actions'
import Danger from '_assets/icons/danger_circle.svg'
import Info from '_assets/icons/info_circle.svg'
import Logo from '_assets/logo/negative-logo.svg'
import FacebookLogo from '_assets/icons/facebook.svg'
import GoogleLogo from '_assets/icons/google.svg'
import {
  FB_ID,
  GOOGLE_LOGIN_CLIENT_ID,
  LANDING_PAGE_URL,
  GA_ENABLED,
  FLAG_REQUIRE_NAME_IN_REGISTER,
  FLAG_SHOW_FACEBOOK_BUTTON,
  FLAG_SHOW_GOOGLE_BUTTON,
  API_URL,
} from '_config/environment'
import validateEmail from '_utils/email-validation'
import { COOKIES_OPTIONS } from '_utils/constants'
import { getFirstPromoterCodeFormat } from '_utils/helpers'

import styles from './styles.css'

const mapStateToProps = ({ loading, error, user }) => ({
  errors: error.get(SIGN_UP_EMAIL.ACTION),
  isSigningUpWithEmail: !!loading.get(SIGN_UP_EMAIL.ACTION),
  isLoginInWithFacebook: !!loading.get(LOGIN_FACEBOOK.ACTION),
  facebookLoginErrors: error.get(LOGIN_FACEBOOK.ACTION),
  isLoginInWithGoogle: !!loading.get(LOGIN_GOOGLE.ACTION),
  googleLoginErrors: error.get(LOGIN_GOOGLE.ACTION),
  user,
})

const mapDispatchToProps = { signUpEmail, loginFacebook, loginGoogle, resetUser }

class SignUp extends PureComponent {
  static propTypes = {
    isChrome: PropTypes.bool.isRequired,
    isInternetExplorer: PropTypes.bool.isRequired,
    isMac: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isWindows: PropTypes.bool.isRequired,
    signUpEmail: PropTypes.func.isRequired,
    isSigningUpWithEmail: PropTypes.bool.isRequired,
    errors: PropTypes.instanceOf(Map),
    loginFacebook: PropTypes.func.isRequired,
    isLoginInWithFacebook: PropTypes.bool.isRequired,
    facebookLoginErrors: PropTypes.instanceOf(Map),
    loginGoogle: PropTypes.func.isRequired,
    isLoginInWithGoogle: PropTypes.bool.isRequired,
    googleLoginErrors: PropTypes.instanceOf(Map),
    user: PropTypes.instanceOf(Map),
    location: PropTypes.shape({
      state: PropTypes.shape({
        hash: PropTypes.string,
      }),
      query: PropTypes.shape({
        user_token: PropTypes.string,
        affiliate: PropTypes.string,
        rr: PropTypes.string,
        invite: PropTypes.string,
      }),
    }),
    resetUser: PropTypes.func.isRequired,
  }

  static contextTypes = { store: PropTypes.shape({}) }

  static defaultProps = {
    user: Map(),
    errors: Map(),
    facebookLoginErrors: Map(),
    googleLoginErrors: Map(),
    location: {},
  }

  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    error: '',
    isEmailDisabled: false,
  }

  componentDidMount() {
    const { user } = this.props
    if (user.get('auth_token')) {
      if (user.get('show_onboarding')) {
        browserHistory.push('/onboarding/usage')
      } else {
        browserHistory.push('/presentations')
      }
    }
    if (this.props.location.query.invite) {
      const { invite } = this.props.location.query

      fetch(`${API_URL}/user/my-invite/`, {
        method: 'POST',
        body: JSON.stringify({ invite }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(({ email, first_name: firstName, last_name: lastName, message }) => {
          this.setState({
            email,
            error: message,
            isEmailDisabled: true,
            ...(firstName !== null && { firstName }),
            ...(lastName !== null && { lastName }),
          })
        })
        .catch(error => {
          console.error('Error fetching data:', error)
          this.setState({ error: 'Something went wrong', isEmailDisabled: true })
        })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props
    const emailSignup =
      this.props.isSigningUpWithEmail && !nextProps.isSigningUpWithEmail && !nextProps.errors.size
        ? 'Email'
        : ''

    const facebookSignUp =
      this.props.isLoginInWithFacebook &&
      !nextProps.isLoginInWithFacebook &&
      !nextProps.facebookLoginErrors.size
        ? 'Facebook'
        : ''

    const googleSignUp =
      this.props.isLoginInWithGoogle &&
      !nextProps.isLoginInWithGoogle &&
      !nextProps.googleLoginErrors.size
        ? 'Google'
        : ''

    if (emailSignup || facebookSignUp || googleSignUp) {
      if (GA_ENABLED) {
        ReactGA.event({
          category: 'User',
          action: 'Create an Account',
          label: emailSignup || facebookSignUp || googleSignUp,
        })
      }

      if (window.fbq != null) {
        window.fbq('track', 'Lead')
      }

      if (location && location.state && location.state.hash) {
        browserHistory.push({
          pathname: '/presentations',
          state: { hash: location.state.hash, isSignUp: true },
        })
      } else {
        browserHistory.push({ pathname: '/onboarding/usage', state: { isSignUp: true } })
      }
    }
  }

  onSubmit = e => {
    e.preventDefault()
    const { firstName, lastName, email, password } = this.state
    const { location } = this.props

    let payload = {
      email: email.toLowerCase(),
      password1: password,
      password2: password,
    }
    if (location && location.query && location.query.user_token) {
      payload = {
        ...payload,
        user_token: location.query.user_token,
      }
    }

    if (location && location.state && location.state.hash) {
      payload = {
        ...payload,
        hash: location.state.hash,
      }
    }

    if (FLAG_REQUIRE_NAME_IN_REGISTER) {
      payload = {
        ...payload,
        first_name: firstName,
        last_name: lastName,
      }
    }

    const fpromCode = this.getFirstPromoterCode()

    if (fpromCode) {
      payload = {
        ...payload,
        fpr: fpromCode,
      }
    }

    const rrCode = this.getQuery('rr')

    if (rrCode) {
      payload = {
        ...payload,
        rr: rrCode,
      }
    }

    const hubspotutk = cookies.load('hubspotutk', COOKIES_OPTIONS)

    if (hubspotutk) {
      payload = {
        ...payload,
        hubspotutk,
      }
    }

    if (this.props.location.query.invite) {
      payload = {
        ...payload,
        invite: this.props.location.query.invite,
      }
    }

    if (validateEmail(email)) {
      this.setState({ error: 'Please enter a valid email address.' })
      return
    }
    cookies.remove('_fprom_code', COOKIES_OPTIONS)
    this.props.resetUser()
    this.props.signUpEmail(payload)
  }

  onFacebookSignUp = data => {
    const fpromCode = this.getFirstPromoterCode()
    const hubspotutk = cookies.load('hubspotutk', COOKIES_OPTIONS)

    const rrCode = this.props.location.query.rr

    if (data.accessToken) {
      const payload = {
        access_token: data.accessToken,
      }

      this.props.loginFacebook(payload, {
        fpr: fpromCode,
        rr: rrCode,
        ...(hubspotutk && {
          hubspotutk,
        }),
      })
    }
  }

  onGoogleSignUp = data => {
    const fpromCode = this.getFirstPromoterCode()
    const rrCode = this.props.location.query.rr

    const hubspotutk = cookies.load('hubspotutk', COOKIES_OPTIONS)

    if (data.accessToken) {
      const payload = {
        access_token: data.accessToken,
      }

      this.props.loginGoogle(payload, {
        fpr: fpromCode,
        rr: rrCode,
        ...(hubspotutk && {
          hubspotutk,
        }),
      })
    }
  }

  getFirstPromoterCode = () =>
    getFirstPromoterCodeFormat(cookies.load('_fprom_code', COOKIES_OPTIONS)) ||
    this.props.location.query.affiliate

  getQuery = query => {
    const { location } = this.props

    return location && location.query && location.query[query]
  }

  getErrors = () => {
    const { errors, facebookLoginErrors, googleLoginErrors } = this.props
    const { error } = this.state

    if (errors.size > 0) {
      return errors.first()
    }
    if (facebookLoginErrors.size > 0) {
      return facebookLoginErrors.first()
    }
    if (googleLoginErrors.size > 0) {
      return googleLoginErrors.first()
    }
    if (error) {
      return error
    }

    return null
  }

  handleInput = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
      error: '',
    })
  }

  renderWarning = () => {
    const displayError = this.getErrors()
    if (displayError) {
      return (
        <div className={styles['error-container']}>
          <svg alt="!" className={styles.danger}>
            <use xlinkHref={Danger} />
          </svg>
          <p className={styles['error-message']}>{displayError}</p>
        </div>
      )
    }

    const { isChrome, isMac, isMobile, isWindows } = this.props
    const isNotChrome = !isMobile && !isChrome && (isWindows || isMac)

    if (isNotChrome) {
      return (
        <div className={styles['info-container']}>
          <svg alt="!" className={styles.info}>
            <use xlinkHref={Info} />
          </svg>
          <p className={styles['info-message']}>
            Highnote lovingly designed for Google Chrome! Try and use Chrome for the best
            experience!
          </p>
        </div>
      )
    }

    return null
  }

  render() {
    const { firstName, lastName, email, password, isEmailDisabled } = this.state
    const {
      isInternetExplorer,
      isSigningUpWithEmail,
      isLoginInWithFacebook,
      isLoginInWithGoogle,
    } = this.props

    return (
      <div className={isInternetExplorer ? styles['ie-background'] : styles.background}>
        <section className={styles.section}>
          <SimpleModal theme={SimpleModalTheme.DARK_GRAY_BLUE} classNames={styles['login-modal']}>
            <form onSubmit={this.onSubmit} className={styles.form}>
              <a href={LANDING_PAGE_URL}>
                <svg alt="Project HighNote" className={styles.logo}>
                  <use xlinkHref={Logo} />
                </svg>
              </a>
              {this.renderWarning()}
              <h1 className={styles.title}>CREATE AN ACCOUNT</h1>
              {FLAG_REQUIRE_NAME_IN_REGISTER && (
                <Fragment>
                  <TextInput
                    type={InputType.TEXT}
                    theme={InputTheme.CLOUDY_BLUE}
                    label="First Name"
                    onChange={this.handleInput}
                    name="firstName"
                    value={firstName}
                    classNames={styles.field}
                  />
                  <TextInput
                    type={InputType.TEXT}
                    theme={InputTheme.CLOUDY_BLUE}
                    label="Last Name"
                    onChange={this.handleInput}
                    name="lastName"
                    value={lastName}
                    classNames={styles.field}
                  />
                </Fragment>
              )}
              <TextInput
                type={InputType.TEXT}
                theme={InputTheme.CLOUDY_BLUE}
                label="Email Address"
                onChange={this.handleInput}
                name="email"
                value={email}
                classNames={styles.field}
                disabled={isEmailDisabled}
              />
              <TextInput
                type={InputType.PASSWORD}
                theme={InputTheme.CLOUDY_BLUE}
                label="Password"
                onChange={this.handleInput}
                name="password"
                value={password}
                classNames={styles.password}
              />
              <Button
                type={ButtonType.SUBMIT}
                theme={ButtonTheme.PINK}
                disabled={isSigningUpWithEmail || !email || !password}
                onClick={this.onSubmit}
                isLoading={isSigningUpWithEmail}
                classNames={styles.signup}
              >
                SIGN UP
              </Button>
              <div className={styles['or-container']}>
                <div className={styles.divider} />
                <p className={styles.or}>OR</p>
                <div className={styles.divider} />
              </div>
              {FLAG_SHOW_FACEBOOK_BUTTON && (
                <FacebookLogin
                  appId={FB_ID}
                  version={3.2}
                  callback={this.onFacebookSignUp}
                  // eslint-disable-next-line react/jsx-no-bind
                  render={renderProps => (
                    <Button
                      classNames={styles.facebook}
                      isLoading={isLoginInWithFacebook}
                      {...renderProps}
                    >
                      <div className={styles.children}>
                        <svg alt="FB" className={styles.logo}>
                          <use xlinkHref={FacebookLogo} />
                        </svg>
                        <p>Continue with Facebook</p>
                      </div>
                    </Button>
                  )}
                />
              )}
              {FLAG_SHOW_GOOGLE_BUTTON && (
                <GoogleLogin
                  clientId={GOOGLE_LOGIN_CLIENT_ID}
                  onSuccess={this.onGoogleSignUp}
                  // eslint-disable-next-line react/jsx-no-bind
                  render={renderProps => (
                    <Button
                      classNames={styles.google}
                      isLoading={isLoginInWithGoogle}
                      {...renderProps}
                    >
                      <div className={styles.children}>
                        <svg alt="GOOGLE" className={styles.logo}>
                          <use xlinkHref={GoogleLogo} />
                        </svg>
                        <p>Sign in with Google</p>
                      </div>
                    </Button>
                  )}
                />
              )}
              <p className={styles.login}>
                {'Already have an account? '}
                <Link to="/login" className={styles.login}>
                  Log In
                </Link>
              </p>
            </form>
          </SimpleModal>
        </section>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withBrowserInformation(SignUp))
