import loadImage from 'blueimp-load-image'

export default (file, callback) =>
  loadImage(
    file,
    canvas => {
      if (canvas && typeof canvas.toBlob === 'function') {
        canvas.toBlob(blob => {
          callback(null, new File([blob], file.name, { type: file.type }))
        })
      } else {
        callback(null, file)
      }
    },
    { orientation: true, canvas: true }
  )
