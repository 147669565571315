import { Map } from 'immutable'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import classnames from 'classnames'

import { AlertType } from '_components/alert-message'
import { openAlert } from '_modules/alert-message/actions'
import VisibilitySensor from '_components/visibility-sensor'
import { cachePdf } from '_modules/pdf-cache/actions'

import styles from './styles.css'

const mapStateToProps = ({ pdfCache }) => ({
  pdfCache,
})

const mapDispatchToProps = {
  openAlert,
  cachePdf,
}

class PDFViewer extends PureComponent {
  static propTypes = {
    pdf: PropTypes.string.isRequired,
    onVisibilityChange: PropTypes.func,
    className: PropTypes.string,
    onLoad: PropTypes.func,
    openAlert: PropTypes.func.isRequired,
    cachePdf: PropTypes.func.isRequired,
    pdfCache: PropTypes.instanceOf(Map),
  }

  static defaultProps = {
    className: undefined,
    onVisibilityChange: () => {},
    onLoad: () => {},
    pdfCache: Map(),
  }

  constructor(props) {
    super(props)
    this.state = {
      pdfToRender: props.pdfCache.get(props.pdf, props.pdf),
      numPages: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pdf !== this.props.pdf) {
      this.setState({
        pdfToRender: nextProps.pdfCache.get(nextProps.pdf, nextProps.pdf),
      })
    }
  }

  onDocumentLoadSuccess = pdf => {
    pdf.getData().then(data => this.props.cachePdf(this.props.pdf, new Blob([data])))
    this.setState(
      {
        numPages: pdf.numPages,
      },
      () => {
        this.props.onLoad()
      }
    )
  }

  onLoadError = () => {
    this.openAlertMessage()
  }

  onSourceError = () => {
    this.openAlertMessage()
  }

  getPageWidth = () => {
    const viewportWidth = window.innerWidth
    if (viewportWidth <= 1024 && viewportWidth > 768) {
      return Math.min(viewportWidth - 202, 750)
    }
    if (viewportWidth <= 768) {
      return viewportWidth - 72
    }
    return 750
  }

  openAlertMessage = () => {
    this.props.openAlert({
      type: AlertType.FAILED_PDF,
      isOpen: true,
      timer: 5000,
    })
  }

  preventRightClick = e => {
    e.preventDefault()
  }

  render() {
    const { numPages, pdfToRender } = this.state
    const { className, onVisibilityChange } = this.props
    const pdfWidth = this.getPageWidth()

    return (
      <Document file={pdfToRender} onLoadSuccess={this.onDocumentLoadSuccess} className={className}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className={classnames(styles['pdf-page'])}
            renderAnnotations={false}
            renderTextLayer={false}
            onContextMenu={this.preventRightClick}
            width={pdfWidth}
            onLoadError={this.onLoadError}
            onSourceError={this.onSourceError}
          >
            <VisibilitySensor onChange={onVisibilityChange} refName={index + 1} />
          </Page>
        ))}
      </Document>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewer)
