import { schema } from 'normalizr'
import { List } from 'immutable'

import Model from './model'

class Folder extends Model({
  id: undefined,
  name: undefined,
  numberOfPresentations: undefined,
  presentations: List(),
}) {}

export default Folder

const folderNewSchema = new schema.Entity(
  'result',
  {},
  {
    idAttribute: 'id',
    processStrategy: ({ id, ...entity }) => new Folder({ id, ...entity }),
  }
)

export const folderSchema = [folderNewSchema]
