import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'

import { error, loading } from '_privateDependencies/react-utils/reducers'

import { user } from './user/reducers'
import { clientPresentation } from './presentation-client/reducers'
import { shareLink } from './share-link/reducers'
import { shareLink as shareLinkNew } from './share-link-new/reducers'
import { insights } from './insights/reducers'
import { presentationInsights } from './insights-presentation/reducers'
import { userPresentationInsights } from './insights-user-presentation/reducers'
import { editAssets } from './edit-assets/reducers'
import { autocomplete } from './autocomplete/reducers'
import { alert } from './alert-message/reducers'
import { presentationRecipients } from './manage-recipients/reducers'
import browser from './browser/reducer'
import pdfCache from './pdf-cache/reducer'
import payments from './payments/reducer'
import presentations from './presentations/reducer'
import toasterAlert from './toaster-alert/reducer'
import folders from './folders/reducer'
import assets from './assets/reducer'
import templates from './templates/reducer'
import contacts from './contacts/reducer'
import announcements from './announcements/reducers'

const rootReducer = combineReducers({
  routing,
  user,
  clientPresentation,
  shareLinkNew,
  shareLink,
  insights,
  presentationInsights,
  userPresentationInsights,
  editAssets,
  autocomplete,
  error,
  loading,
  alert,
  browser,
  presentationRecipients,
  pdfCache,
  payments,
  presentations,
  toasterAlert,
  folders,
  assets,
  templates,
  contacts,
  announcements,
})

export default rootReducer
