import React from 'react'
import PropTypes from 'prop-types'

import ClockIcon from '_assets/icons/ic_clock.svg'
import Svg from '_components/svg'
import Button from '_components/button-round'

import styles from './styles.css'

const TrialBanner = ({ daysLeft }) => (
  <div className={styles.container}>
    <Svg icon={ClockIcon} className={styles.icon} />
    <p className={styles.description}>
      <span className={styles.days}>
        {daysLeft} {daysLeft > 1 ? 'days' : 'day'}
      </span>{' '}
      left in your free trial
    </p>
    <Button to="/payment" className={styles.button}>
      Upgrade now
    </Button>
  </div>
)

TrialBanner.propTypes = {
  daysLeft: PropTypes.number.isRequired,
}

export default React.memo(TrialBanner)
