import { defineAction } from '_privateDependencies/react-utils/utils'
import * as paymentsService from '_services/payments'

export const GET_CREDIT_CARD = defineAction('GET_CREDIT_CARD')
export const ADD_CREDIT_CARD = defineAction('ADD_CREDIT_CARD')
export const START_SUBSCRIPTION = defineAction('START_SUBSCRIPTION')
export const CANCEL_SUBSCRIPTION = defineAction('CANCEL_SUBSCRIPTION')
export const CHANGE_SUBSCRIPTION = defineAction('CHANGE_SUBSCRIPTION')
export const VALIDATE_COUPON_CODE = defineAction('VALIDATE_COUPON_CODE')
export const GET_PLAN_BY_ID = defineAction('GET_PLAN_BY_ID')
export const SKIP_SUBSCRIPTION_PAST_DUE = 'SKIP_SUBSCRIPTION_PAST_DUE'
export const SKIP_UPGRADE_BANNER = 'SKIP_UPGRADE_BANNER'
export const CLEAR_COUPON_CODE = 'CLEAR_COUPON_CODE'

export const getCreditCard = () => (dispatch, getState) =>
  dispatch({
    type: GET_CREDIT_CARD.ACTION,
    payload: paymentsService.getCreditCard(getState().user.get('auth_token')),
  })

export const addCreditCard = payload => (dispatch, getState) =>
  dispatch({
    type: ADD_CREDIT_CARD.ACTION,
    payload: paymentsService.addCreditCard(getState().user.get('auth_token'))({
      cardToken: payload.cardToken,
    }),
    meta: {
      cardId: payload.cardId,
    },
  })

export const startSubscription = payload => (dispatch, getState) =>
  dispatch({
    type: START_SUBSCRIPTION.ACTION,
    payload: paymentsService.startSubscription(getState().user.get('auth_token'))(payload),
  })

export const cancelSubscription = () => (dispatch, getState) =>
  dispatch({
    type: CANCEL_SUBSCRIPTION.ACTION,
    payload: paymentsService.cancelSubscription(getState().user.get('auth_token')),
  })

export const changeSubscription = payload => (dispatch, getState) =>
  dispatch({
    type: CHANGE_SUBSCRIPTION.ACTION,
    payload: paymentsService.changeSubscription(getState().user.get('auth_token'))(payload),
  })

export const validateCouponCode = payload => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_COUPON_CODE.ACTION,
    payload: paymentsService.validateCouponCode(getState().user.get('auth_token'))(payload),
  })

export const getPlanById = payload => dispatch =>
  dispatch({
    type: GET_PLAN_BY_ID.ACTION,
    payload: paymentsService.getPlanById()(payload),
    meta: payload,
  })

export const clearCouponCode = dispatch =>
  dispatch({
    type: CLEAR_COUPON_CODE,
  })

export const skipSubscriptionPastDue = dispatch =>
  dispatch({
    type: SKIP_SUBSCRIPTION_PAST_DUE,
  })

export const skipUpgradeBanner = dispatch =>
  dispatch({
    type: SKIP_UPGRADE_BANNER,
  })
