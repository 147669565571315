import { defineAction } from '_privateDependencies/react-utils/utils'
import * as announcementService from '_services/announcement'

export const GET_ANNOUNCEMENTS = defineAction('GET_ANNOUNCEMENTS')

export const getAnnouncements = () => dispatch =>
  dispatch({
    type: GET_ANNOUNCEMENTS.ACTION,
    payload: announcementService.getAnnouncements(),
  })
