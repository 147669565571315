import cookies from 'react-cookies'

import { SUBSCRIPTION_STATUS } from '_constants/user'

import { PATHS, COOKIES_OPTIONS, cookieAuth } from './constants'
import { checkIfIsAllowedUser } from './user'

export const isIndependentScreen = (
  pathname,
  hasAuthToken = true,
  wasSharedByPremiumUser = false
) =>
  /\/presentations\/\b([a-f0-9]{40})\b/.test(pathname) ||
  (!hasAuthToken && pathname === '/404') ||
  (!hasAuthToken && pathname === '/client/presentations/404') ||
  pathname === '/login' ||
  pathname === '/sign-up' ||
  pathname === '/referral' ||
  pathname === '/set-password' ||
  pathname === '/forgot-password' ||
  pathname === '/check-email' ||
  pathname.includes('/change-password') ||
  pathname.includes('/create-a-team') ||
  pathname.includes('/sign-up-embeddable') ||
  pathname.includes('/payment') ||
  pathname.includes(PATHS.TRIAL_EXPIRED) ||
  pathname.includes(PATHS.PAYMENT_PROBLEM) ||
  pathname.includes('/login-embeddable') ||
  pathname.includes('/onboarding') ||
  wasSharedByPremiumUser

export const isLoggedIn = (user, location = undefined) => {
  if (location && location.action && location.action === 'POP') {
    return user && !!user.get('auth_token')
  }
  const cookiesAuth = cookieAuth()
  return cookies.load(cookiesAuth)
}

export const userLoginRequired = (store, path = '') => (nextState, replace) => {
  const { location } = nextState

  const { user } = store.getState()
  const isAllowedUser = checkIfIsAllowedUser(user)
  const isLoggedUser = isLoggedIn(user, location)

  if (!isLoggedUser) {
    const cookiesAuth = cookieAuth()
    cookies.remove(cookiesAuth, COOKIES_OPTIONS)
    cookies.remove('user', COOKIES_OPTIONS)
    cookies.remove('authUrl', COOKIES_OPTIONS)
    replace({
      pathname: '/login',
      query: {
        redirect: nextState.location.pathname + nextState.location.search,
      },
      state: nextState.location.state,
    })
  }

  if (!isAllowedUser && !path.includes('payment')) {
    replace({ pathname: PATHS.TRIAL_EXPIRED })
  }

  if (
    user &&
    user.size > 0 &&
    (user.get('subscription_status') === SUBSCRIPTION_STATUS.PAST_DUE ||
      user.get('subscription_status') === SUBSCRIPTION_STATUS.UNPAID) &&
    isLoggedUser &&
    !path.includes('payment')
  ) {
    replace({ pathname: PATHS.PAYMENT_PROBLEM })
  }
}

export const showTrialView = store => (nextState, replace) => {
  const { user } = store.getState()
  const { location } = nextState
  const isLoggedUser = isLoggedIn(user, location)
  const isAllowedUser = checkIfIsAllowedUser(user)

  if (!isLoggedUser) {
    replace({ pathname: '/login' })
  }

  if (isAllowedUser && isLoggedUser) {
    replace({ pathname: '/presentations' })
  }
}

export const showPaymentProblem = store => (nextState, replace) => {
  const { user } = store.getState()
  const { location } = nextState
  const isLoggedUser = isLoggedIn(user, location)

  if (!isLoggedUser) {
    replace({ pathname: '/login' })
  }

  if (
    user &&
    user.size > 0 &&
    user.get('subscription_status') === SUBSCRIPTION_STATUS.ACTIVE &&
    isLoggedUser
  ) {
    replace({ pathname: '/presentations' })
  }
}

export const getNormalizedQuery = (queries, query) =>
  Object.keys(queries).filter(key => key.toLowerCase() === query)[0]
