/* eslint-disable jsx-a11y/alt-text */

import { List } from 'immutable'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import ZoomImage from '_components/zoom-image'

import styles from './styles.css'

const INTERSECTION_THRESHOLD = 0.25

class ImageViewer extends PureComponent {
  constructor(props) {
    super(props)

    const { images, onImageVisibilityChange } = this.props

    this.imagesRefs = images.map(() => React.createRef())
    this.observers = images.map(
      (image, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              onImageVisibilityChange(entry.intersectionRatio >= INTERSECTION_THRESHOLD, index + 1)
            })
          },
          { threshold: INTERSECTION_THRESHOLD }
        )
    )
  }

  componentDidMount() {
    this.imagesRefs.forEach((ref, index) => {
      const observe = () => this.observers.get(index).observe(ref.current)

      if (ref.current.nodeType !== 1) {
        return
      }
      if (ref.current.complete) {
        observe()
      } else {
        ref.current.addEventListener('load', () => {
          if (!ref.current || ref.current.nodeType !== 1) {
            return
          }
          observe()
        })
      }
    })
  }

  render() {
    const { images } = this.props

    return (
      <ZoomImage>
        {images.map((image, index) => (
          <img className={styles.image} key={image} ref={this.imagesRefs.get(index)} src={image} />
        ))}
      </ZoomImage>
    )
  }
}

ImageViewer.propTypes = {
  images: PropTypes.instanceOf(List),
  onImageVisibilityChange: PropTypes.func.isRequired,
}

ImageViewer.defaultProps = {
  images: List(),
}

export default ImageViewer
