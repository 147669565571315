import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Input from '_components/ui-kit/input'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import VideoIcon from '_assets/icons/video.svg'
import { getVimeoVideoId, getYoutubeVideoId } from '_utils/regex'

import styles from './styles.css'

const Video = ({ onClick, currentUrl }) => {
  const [videoUrl, setVideoUrl] = useState('')
  const [error, setError] = useState('')

  const onInputChange = useCallback(event => {
    const { value } = event.target
    setVideoUrl(value)
  }, [])

  const verifyUrl = useCallback(() => {
    if (getVimeoVideoId(videoUrl) || getYoutubeVideoId(videoUrl)) {
      setError(null)
      onClick(videoUrl)
      return
    }

    setError('This is not a supported video URL. Supported hosts: YouTube and Vimeo.')
  }, [videoUrl, onClick])

  return (
    <div className={styles['container-video']}>
      <Input
        id="generated-link"
        value={videoUrl}
        onChange={onInputChange}
        label="Enter URL"
        error={error}
      />
      <div className={styles['container-video-button']}>
        <Button theme={BUTTON_THEME.PRIMARY} startIcon={VideoIcon} onClick={verifyUrl}>
          SAVE
        </Button>
        <p className={styles['warning-text']}>The URL must link to a YouTube or Vimeo video.</p>
      </div>
      {currentUrl && (
        <div className={styles['container-video-current-url']}>
          <h2 className={styles['current-url-title']}>Current URL:</h2>
          <a href={currentUrl} className={styles['current-url']}>
            {currentUrl}
          </a>
        </div>
      )}
    </div>
  )
}

Video.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentUrl: PropTypes.string.isRequired,
}

export default Video
