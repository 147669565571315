export const OPEN_TOASTER = 'OPEN_TOASTER'
export const CLOSE_TOASTER = 'CLOSE_TOASTER'

export const openToasterAlert = payload => dispatch =>
  dispatch({
    type: OPEN_TOASTER,
    payload,
  })

export const closeToasterAlert = () => dispatch =>
  dispatch({
    type: CLOSE_TOASTER,
  })
