import { Map } from 'immutable'

import {
  RETRIEVE_CLIENT_PRESENTATION,
  SAVE_CLIENT_PRESENTATION,
  FORWARD_CLIENT_PRESENTATION,
  REQUIRE_EMAIL_PRESENTATION,
} from './actions'

export const clientPresentationSelector = state =>
  state.clientPresentation.get('clientPresentation')

export const isRetrievingClientPresentationSelector = state =>
  !!state.loading.get(RETRIEVE_CLIENT_PRESENTATION.ACTION)

export const retrievePresentationErrorSelector = state =>
  state.error.get(RETRIEVE_CLIENT_PRESENTATION.ACTION, Map())

export const isSavingPresentationSelector = state =>
  !!state.loading.get(SAVE_CLIENT_PRESENTATION.ACTION)

export const savePresentationErrorSelector = state =>
  state.error.get(SAVE_CLIENT_PRESENTATION.ACTION, Map())

export const isForwardingPresentationSelector = state =>
  !!state.loading.get(FORWARD_CLIENT_PRESENTATION.ACTION)

export const forwardPresentationErrorSelector = state =>
  state.error.get(FORWARD_CLIENT_PRESENTATION.ACTION, Map())

export const isRequiringEmailSelector = state =>
  !!state.loading.get(REQUIRE_EMAIL_PRESENTATION.ACTION)

export const requireEmailErrorSelector = state =>
  state.error.get(REQUIRE_EMAIL_PRESENTATION.ACTION, Map())
