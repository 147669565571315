import React, { forwardRef, useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import InputMask from 'react-input-mask'

import Button from '_components/button-new'
import Svg from '_components/svg'
import ViewIcon from '_assets/icons/view.svg'

import styles from './styles.css'

const PASSWORD_TYPE = 'password'

const Input = forwardRef(
  (
    {
      className,
      textareaClassName,
      inputClassName,
      id,
      label,
      value,
      onChange,
      error,
      mask,
      onBlur,
      type,
      disabled,
      insideButtonIcon,
      onClickButton,
      classNameButton,
      startIcon,
      endIcon,
      onFocus,
      ...inputProps
    },
    ref
  ) => {
    const [currentType, setCurrentType] = useState(type)
    const [isFocus, setIsFocus] = useState(false)

    const onPasswordButtonClick = useCallback(() => {
      if (currentType === PASSWORD_TYPE) {
        return setCurrentType('text')
      }

      return setCurrentType(type)
    }, [type, currentType])

    const handleFocus = useCallback(
      event => {
        setIsFocus(true)
        if (onFocus) {
          onFocus(event)
        }
      },
      [onFocus]
    )

    const handleBlur = useCallback(
      event => {
        setIsFocus(false)
        if (onBlur) {
          onBlur(event)
        }
      },
      [onBlur]
    )

    const renderInput = useMemo(() => {
      if (type === 'textarea') {
        return (
          <textarea
            className={classnames(styles.input, textareaClassName, { [styles.error]: error })}
            onChange={onChange}
            value={value}
            id={id}
            disabled={disabled}
            type={type}
            {...inputProps}
          />
        )
      }

      if (onClickButton) {
        return (
          <div className={styles['button-wrapper']}>
            <input
              ref={ref}
              className={classnames(styles.input, { [styles.error]: error })}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              id={id}
              disabled={disabled}
              type={type}
              {...inputProps}
            />
            {value && (
              <Button
                onClick={onClickButton}
                className={classnames(styles['internal-button'], classNameButton)}
              >
                <Svg icon={insideButtonIcon} className={styles['internal-icon']} />
              </Button>
            )}
          </div>
        )
      }

      return (
        <div className={styles['input-container']} key={`input-${id}`}>
          {startIcon && (
            <Svg
              className={classnames(styles['start-icon'], {
                [styles.focus]: isFocus,
              })}
              icon={startIcon}
            />
          )}
          {endIcon && (
            <Svg
              className={classnames(styles['end-icon'], {
                [styles.focus]: isFocus,
              })}
              icon={endIcon}
            />
          )}
          <input
            ref={ref}
            className={classnames(
              styles.input,
              {
                [styles.error]: error,
                [styles['has-start-icon']]: !!startIcon,
              },
              inputClassName
            )}
            onChange={onChange}
            onBlur={handleBlur}
            value={value}
            id={id}
            type={currentType}
            disabled={disabled}
            onFocus={handleFocus}
            {...inputProps}
          />
          {type === PASSWORD_TYPE && (
            <button
              type="button"
              className={styles['password-button']}
              onClick={onPasswordButtonClick}
            >
              <Svg
                className={classnames(
                  styles['password-icon'],
                  {
                    [styles['password-checked']]: currentType !== PASSWORD_TYPE,
                  },
                  {
                    [styles.focus]: isFocus,
                  }
                )}
                icon={ViewIcon}
              />
            </button>
          )}
        </div>
      )
    }, [
      type,
      onClickButton,
      id,
      startIcon,
      isFocus,
      endIcon,
      ref,
      error,
      inputClassName,
      onChange,
      handleBlur,
      value,
      currentType,
      disabled,
      handleFocus,
      inputProps,
      onPasswordButtonClick,
      textareaClassName,
      classNameButton,
      insideButtonIcon,
    ])

    return (
      <div className={classnames(styles['input-wrapper'], className)}>
        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-for
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        {mask ? (
          <InputMask
            mask={mask}
            maskChar=""
            disabled={disabled}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
          >
            {() => (
              <input
                ref={ref}
                id={id}
                className={classnames(styles.input, { [styles.error]: error })}
                {...inputProps}
                type={type}
              />
            )}
          </InputMask>
        ) : (
          [renderInput]
        )}
        {error && <span className={styles['error-message']}>{error}</span>}
      </div>
    )
  }
)

Input.propTypes = {
  className: PropTypes.string,
  textareaClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  mask: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  insideButtonIcon: PropTypes.node,
  onClickButton: PropTypes.func,
  classNameButton: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  inputClassName: PropTypes.string,
}

Input.defaultProps = {
  className: '',
  textareaClassName: '',
  error: null,
  label: null,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  mask: null,
  type: '',
  disabled: false,
  insideButtonIcon: null,
  onClickButton: null,
  classNameButton: '',
  startIcon: null,
  endIcon: null,
  inputClassName: undefined,
}

export default React.memo(Input)
