import { ASPECT_RATIO } from '_components/edit-picture-modal/picture-adjustment'

const DEFAULT_ZOOM = 1.4

export const STEPS = {
  ADJUST: 1,
  PREVIEW: 2,
}

export const INITIAL_STATE = {
  currentStep: STEPS.ADJUST,
  aspectRatio: ASPECT_RATIO.SQUARE,
  zoomScale: DEFAULT_ZOOM,
  position: { x: 0.5, y: 0.5 },
}

export const UPDATE_STATE = 'UPDATE_STATE'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
