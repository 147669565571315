import React, { useMemo, useCallback, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { Map } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import Modal from '_components/ui-kit/modal'
import EnvelopeIcon from '_assets/icons/envelope.svg'
import Svg from '_components/svg'
import { GA_ENABLED } from '_config/environment'
import validateName from '_utils/name-validation'
import validateEmail from '_utils/email-validation'
import { AlertType } from '_components/alert-message'
import { openAlert } from '_modules/alert-message/actions'
import { individualLinkSelector } from '_modules/share-link-new/selectors'
import { getShareLinkByHash, GET_SHARE_LINK_BY_HASH } from '_modules/share-link-new/actions'
import { userSelector } from '_modules/user/selectors'
import useFetchCall from '_hooks/use-fetch-call'

import EmailModalPageOne from './email-modal-page-one'
import EmailModalPageTwo from './email-modal-page-two'
import { reducer, UPDATE_STATE } from './reducer'
import styles from './styles.css'

const SPINNER_COLOR = 'var(--pink)'

const ShareEmailModalNew = ({
  onClose,
  presentation,
  sendEmail,
  isSharing,
  isEdit,
  content,
  recipient,
  shareLink,
}) => {
  const dispatch = useDispatch()
  const individualLink = useSelector(individualLinkSelector)
  const user = useSelector(userSelector)

  const initialState = useMemo(
    () => ({
      pageTwo: false,
      recipients: [
        {
          number: 1,
          name: recipient.name ? recipient.name : '',
          nameError: undefined,
          email: recipient.email ? recipient.email : '',
          emailError: undefined,
        },
      ],
      title:
        (isEdit ? content.title : presentation.get('cleanTitle') || presentation.get('title')) ||
        'No title',
      subject:
        (isEdit ? content.subject : presentation.get('cleanTitle') || presentation.get('title')) ||
        'No Subject',
      message: isEdit
        ? content.message
        : "I've created a presentation for you. You can access it using the link below.",
      passwordProtect: false,
      passwordError: false,
      password: undefined,
      activityUpdates: true,
      requireEmail: false,
      preventDownload: false,
      preventFeedback: false,
      hasAutoReminder: false,
      daysUntilNextReminder: '1',
      reminderInterval: '1',
      daysUntilNextReminderError: false,
      reminderIntervalError: false,
      requireEmailMessage: '',
      requirePhoneNumber: false,
      requirePhoneNumberMessage: '',
    }),
    [
      content.message,
      content.subject,
      content.title,
      isEdit,
      presentation,
      recipient.email,
      recipient.name,
    ]
  )
  const [state, localDispatch] = useReducer(reducer, initialState)

  const onPasswordChange = useCallback(event => {
    const { value } = event.target
    localDispatch({
      type: UPDATE_STATE,
      payload: { password: value, passwordError: false },
    })
  }, [])

  const getAuthorFullName = useMemo(() => {
    if (isEdit) {
      return `${user.get('first_name')} ${user.get('last_name')}`
    }
    if (shareLink) {
      return `${individualLink.getIn(['author', 'firstName'])} ${individualLink.getIn([
        'author',
        'lastName',
      ])}`
    }

    // TODO: normalize the attributes
    const firstName =
      presentation.getIn(['user', 'firstName']) || presentation.getIn(['user', 'first_name'])
    const lastName =
      presentation.getIn(['user', 'lastName']) || presentation.getIn(['user', 'last_name'])

    const fullName = firstName.concat(' ').concat(lastName)
    return fullName
  }, [individualLink, isEdit, presentation, shareLink, user])

  const changeName = useCallback(
    (name, number) => {
      const newRecipients = state.recipients.map(item => {
        if (item.number === number) {
          return { ...item, name }
        }
        return item
      })
      localDispatch({
        type: UPDATE_STATE,
        payload: { recipients: newRecipients },
      })
    },
    [state.recipients]
  )

  const changeEmail = useCallback(
    (email, number) => {
      const newRecipients = state.recipients.map(item => {
        if (item.number === number) {
          return { ...item, email }
        }
        return item
      })
      localDispatch({
        type: UPDATE_STATE,
        payload: { recipients: newRecipients },
      })
    },
    [state.recipients]
  )

  const changeNameAndEmail = useCallback(
    (name, email, number) => {
      const newRecipients = state.recipients.map(item => {
        if (item.number === number) {
          return { ...item, name, email }
        }
        return item
      })
      localDispatch({
        type: UPDATE_STATE,
        payload: { recipients: newRecipients },
      })
    },
    [state.recipients]
  )

  const addRecipient = useCallback(() => {
    const newRecipient = {
      number: state.recipients.length + 1,
    }
    const newRecipients = [...state.recipients, newRecipient]
    localDispatch({
      type: UPDATE_STATE,
      payload: { recipients: newRecipients },
    })
  }, [state.recipients])

  const removeRecipient = useCallback(
    number => {
      const newRecipients = [...state.recipients]
      if (newRecipients.length > 1) {
        newRecipients.splice(number - 1, 1)
        for (let i = 0; i < newRecipients.length; i += 1) {
          newRecipients[i].number = i + 1
        }
      }

      localDispatch({
        type: UPDATE_STATE,
        payload: { recipients: newRecipients },
      })
    },
    [state.recipients]
  )

  const togglePageTwo = useCallback(() => {
    const errorMessage = []
    const trimmedRecipients = state.recipients.map(item => ({
      ...item,
      email: item.email ? item.email.trim() : '',
    }))

    const newRecipients = trimmedRecipients.map(item => {
      const nameError = validateName(item.name)
      const emailError = validateEmail(item.email) || (!item.email ? 'Email field is required' : '')

      if (nameError) {
        errorMessage.push(nameError)
      }
      if (emailError) {
        errorMessage.push(emailError)
      }

      return {
        ...item,
        nameError,
        emailError,
      }
    })

    if (state.passwordProtect && !state.password) {
      errorMessage.push('Please enter a password')
    }

    if (state.hasAutoReminder && !state.daysUntilNextReminder) {
      errorMessage.push('Please enter a number for days before sending first reminder')
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          daysUntilNextReminderError: !state.daysUntilNextReminderError,
        },
      })
    }

    if (state.hasAutoReminder && !state.reminderInterval) {
      errorMessage.push('Please enter a number of days between reminders')
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          reminderIntervalError: !state.reminderIntervalError,
        },
      })
    }

    if (errorMessage.length) {
      const errorAlertMessage = [...new Set(errorMessage)]
      const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })
      dispatch(
        openAlert({
          type: AlertType.CUSTOM,
          message: formatter.format(errorAlertMessage),
          isOpen: true,
          timer: 4000,
        })
      )

      localDispatch({
        type: UPDATE_STATE,
        payload: { recipients: newRecipients },
      })
      return
    }

    localDispatch({
      type: UPDATE_STATE,
      payload: { pageTwo: true, recipients: trimmedRecipients },
    })
  }, [
    dispatch,
    state.daysUntilNextReminder,
    state.daysUntilNextReminderError,
    state.hasAutoReminder,
    state.password,
    state.passwordProtect,
    state.recipients,
    state.reminderInterval,
    state.reminderIntervalError,
  ])

  const goBackToPreviousPage = useCallback(() => {
    if (!isEdit) {
      localDispatch({
        type: UPDATE_STATE,
        payload: { pageTwo: false },
      })
    }
  }, [isEdit])

  const togglePasswordProtectSwitch = useCallback(
    () =>
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          passwordProtect: !state.passwordProtect,
          password: '',
          passwordError: false,
        },
      }),
    [state.passwordProtect]
  )

  const toggleActivityUpdatesSwitch = useCallback(
    () =>
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          activityUpdates: !state.activityUpdates,
        },
      }),
    [state.activityUpdates]
  )

  const toggleRequireEmailSwitch = useCallback(
    () =>
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          requireEmail: !state.requireEmail,
        },
      }),
    [state.requireEmail]
  )

  const toggleRequirePhoneNumberSwitch = useCallback(
    () =>
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          requirePhoneNumber: !state.requirePhoneNumber,
        },
      }),
    [state.requirePhoneNumber]
  )

  const togglePreventDownloadSwitch = useCallback(
    () =>
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          preventDownload: !state.preventDownload,
        },
      }),
    [state.preventDownload]
  )

  const togglePreventFeedbackSwitch = useCallback(
    () =>
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          preventFeedback: !state.preventFeedback,
        },
      }),
    [state.preventFeedback]
  )

  const toggleAutomaticRemindersSwitch = useCallback(
    () =>
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          hasAutoReminder: !state.hasAutoReminder,
        },
      }),
    [state.hasAutoReminder]
  )

  const onChangeValuesAutomaticRemindersSwitch = useCallback(event => {
    const { value, name } = event.target
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const onChangeValues = useCallback(event => {
    const { value, name } = event.target
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const changeSubject = useCallback(event => {
    const { value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: {
        subject: value,
      },
    })
  }, [])

  const changeMessage = useCallback(event => {
    const { value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: {
        message: value,
      },
    })
  }, [])

  const share = useCallback(() => {
    if (!state.message) {
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          messageError: true,
        },
      })

      return dispatch(
        openAlert({
          type: AlertType.EMAIL_WITHOUT_SUBJECT,
          isOpen: true,
          timer: 4000,
        })
      )
    }

    if (!state.subject) {
      return dispatch(
        openAlert({
          type: AlertType.EMAIL_WITHOUT_SUBJECT,
          isOpen: true,
          timer: 4000,
        })
      )
    }
    const payload = {
      recipients: state.recipients.map(item => ({
        name: item.name,
        email: item.email.toLowerCase(),
      })),
      prevent_download: state.preventDownload,
      require_email: state.requireEmail,
      forward_tracking: false,
      title: state.title,
      subject: state.subject,
      message: state.message,
      activity_email_updates: state.activityUpdates,
      prevent_feedback: state.preventFeedback,
      has_auto_reminder: state.hasAutoReminder,
      ...(state.hasAutoReminder && {
        days_until_next_reminder: state.daysUntilNextReminder,
        reminder_interval: state.reminderInterval,
      }),
      ...(state.requirePhoneNumber && { require_phone_number: state.requirePhoneNumber }),
      ...(state.requirePhoneNumberMessage && {
        require_phone_number_message: state.requirePhoneNumberMessage,
      }),
      ...(state.requireEmailMessage && {
        require_email_message: state.requireEmailMessage,
      }),
    }

    if (state.passwordProtect) {
      payload.password_protect = state.password
    }

    return sendEmail(payload)
  }, [dispatch, sendEmail, state])

  const onGetShareLinkSuccess = useCallback(() => {
    if (individualLink && !individualLink.get('isPublic')) {
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          ...state,
          activityEmailUpdates: individualLink.get('activityEmailUpdates'),
          requireEmail: individualLink.get('requireEmail'),
          forwardTracking: individualLink.get('forwardTracking'),
          preventDownload: individualLink.get('preventDownload'),
          isIndexable: individualLink.get('isIndexable'),
          isPublic: individualLink.get('isPublic'),
          title: individualLink.get('title'),
          subject: individualLink.get('subject'),
          message: individualLink.get('message'),
          recipients: [
            {
              email: individualLink.get('email'),
              name: individualLink.get('name'),
            },
          ],
          passwordProtect: !!individualLink.get('passwordProtect'),
          passwordError: false,
          password: individualLink.get('passwordProtect'),
        },
      })
    }
  }, [individualLink, state])

  const [isLoadingGetShareLinkByHash] = useFetchCall(GET_SHARE_LINK_BY_HASH, onGetShareLinkSuccess)

  const renderContent = useMemo(() => {
    let authorPicture = presentation.getIn(['user', 'picture'])
    if (isEdit) {
      authorPicture = user.get('picture')
    }
    if (shareLink) {
      authorPicture = individualLink.getIn(['author', 'picture'])
    }

    return !state.pageTwo ? (
      <EmailModalPageOne
        state={state}
        changeName={changeName}
        changeEmail={changeEmail}
        changeNameAndEmail={changeNameAndEmail}
        addRecipient={addRecipient}
        removeRecipient={removeRecipient}
        nextButtonAction={togglePageTwo}
        onPasswordProtectChange={togglePasswordProtectSwitch}
        onChangePassword={onPasswordChange}
        onActivityUpdatesChange={toggleActivityUpdatesSwitch}
        onRequireEmailChange={toggleRequireEmailSwitch}
        onPreventDownloadChange={togglePreventDownloadSwitch}
        onPreventFeedbackChange={togglePreventFeedbackSwitch}
        toggleAutomaticRemindersSwitch={toggleAutomaticRemindersSwitch}
        onChangeValuesAutomaticRemindersSwitch={onChangeValuesAutomaticRemindersSwitch}
        toggleRequirePhoneNumberSwitch={toggleRequirePhoneNumberSwitch}
        onChangeValues={onChangeValues}
      />
    ) : (
      <EmailModalPageTwo
        recipients={state.recipients}
        backAction={goBackToPreviousPage}
        title={state.title}
        author={getAuthorFullName}
        authorPicture={authorPicture}
        authorJobDetails={user.get('job_details')}
        id={isEdit ? content.presentationId : presentation.get('id') || shareLink.id}
        subject={state.subject}
        changeSubject={changeSubject}
        message={state.message}
        messageError={state.messageError}
        changeMessage={changeMessage}
        sendEmail={share}
        isSharing={isSharing}
      />
    )
  }, [
    addRecipient,
    changeEmail,
    changeMessage,
    changeName,
    changeNameAndEmail,
    changeSubject,
    content.presentationId,
    getAuthorFullName,
    goBackToPreviousPage,
    individualLink,
    isEdit,
    isSharing,
    onChangeValuesAutomaticRemindersSwitch,
    onPasswordChange,
    presentation,
    removeRecipient,
    share,
    shareLink,
    state,
    toggleActivityUpdatesSwitch,
    toggleAutomaticRemindersSwitch,
    togglePageTwo,
    togglePasswordProtectSwitch,
    togglePreventDownloadSwitch,
    togglePreventFeedbackSwitch,
    toggleRequireEmailSwitch,
    user,
    toggleRequirePhoneNumberSwitch,
    onChangeValues,
  ])

  useEffect(() => {
    if (GA_ENABLED) {
      ReactGA.modalview(`${window.location.pathname}/share-email-modal`)
    }
  }, [dispatch, shareLink])

  useEffect(() => {
    if (shareLink) {
      dispatch(getShareLinkByHash(shareLink.id, shareLink.hash))
    }
  }, [dispatch, shareLink])

  return (
    <Modal
      isOpen
      isClosable
      className={styles['share-email-modal']}
      title="Share with email"
      titleIcon={
        <Svg
          icon={EnvelopeIcon}
          className={styles['title-icon']}
          alt="Envelope icon representing the option to share by email"
        />
      }
      onClose={onClose}
    >
      {isLoadingGetShareLinkByHash ? (
        <div className={styles.loading}>
          <Spinner name="circle" color={SPINNER_COLOR} fadeIn="none" />
        </div>
      ) : (
        renderContent
      )}
    </Modal>
  )
}

ShareEmailModalNew.propTypes = {
  onClose: PropTypes.func.isRequired,
  presentation: PropTypes.instanceOf(Map),
  sendEmail: PropTypes.func,
  isSharing: PropTypes.bool,
  isEdit: PropTypes.bool,
  content: PropTypes.shape({
    message: PropTypes.string,
    subject: PropTypes.string,
    title: PropTypes.string,
    presentationId: PropTypes.number,
  }),
  recipient: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  shareLink: PropTypes.objectOf({
    hash: PropTypes.string,
    id: PropTypes.number,
  }),
}

ShareEmailModalNew.defaultProps = {
  presentation: Map(),
  sendEmail: undefined,
  isSharing: false,
  isEdit: false,
  content: {
    message: undefined,
    subject: undefined,
    title: undefined,
    presentationId: null,
  },
  recipient: {
    name: '',
    email: '',
  },
  shareLink: undefined,
}

export default ShareEmailModalNew
