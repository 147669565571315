import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import ArrowIcon from '_assets/icons/arrow-right.svg'
import Svg from '_components/svg'

import styles from './styles.css'

export const ARROW_DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
}

const SliderArrow = ({ onClick, arrowDirection, className }) => (
  <button
    onClick={onClick}
    type="button"
    className={classnames(styles[`button-${arrowDirection}`], className)}
    aria-label={`scroll ${arrowDirection}`}
  >
    <Svg icon={ArrowIcon} className={styles[`icon-${arrowDirection}`]} />
  </button>
)

SliderArrow.propTypes = {
  onClick: PropTypes.func,
  arrowDirection: PropTypes.oneOf(Object.values(ARROW_DIRECTION)),
  className: PropTypes.string,
}

SliderArrow.defaultProps = {
  onClick: () => {},
  arrowDirection: ARROW_DIRECTION.RIGHT,
  className: null,
}

export default SliderArrow
