import { defineAction } from '_privateDependencies/react-utils/utils'
import * as insightsService from '_services/insights'

export const ADD_PRESENTATION_VIEW_DURATION = defineAction('ADD_PRESENTATION_VIEW_DURATION')
export const REGISTER_PRESENTATION_INSIGHTS = defineAction('REGISTER_PRESENTATION_INSIGHTS')
export const REGISTER_ASSET_INSIGHTS = defineAction('REGISTER_ASSET_INSIGHTS')
export const CLEAR_ASSET_INSIGHTS_ID = 'CLEAR_ASSET_INSIGHTS_ID'
export const UPDATE_ASSET_INSIGHTS = defineAction('UPDATE_ASSET_INSIGHTS')
export const LIST_USER_INSIGHTS_ACTIVITIES = defineAction('LIST_USER_INSIGHTS_ACTIVITIES')
export const VIEW_PRESENTATION_ACTIVITY = defineAction('VIEW_PRESENTATION_ACTIVITY')
export const GET_NOTIFICATIONS_INSIGHTS = defineAction('GET_NOTIFICATIONS_INSIGHTS')
export const MARK_ALL_AS_READ = defineAction('MARK_ALL_AS_READ')
export const MARK_AS_READ = defineAction('MARK_AS_READ')
export const APPEND_NOTIFICATION = 'APPEND_NOTIFICATION'
export const LOAD_MORE_NOTIFICATION = defineAction('LOAD_MORE_NOTIFICATION')
export const LOAD_MORE_INSIGHTS = defineAction('LOAD_MORE_INSIGHTS')
export const CREATE_COMMENT = defineAction('CREATE_COMMENT')

export const addPresentationViewDuration = (payload, params) => dispatch =>
  dispatch({
    type: ADD_PRESENTATION_VIEW_DURATION.ACTION,
    payload: insightsService.addPresentationViewDuration(payload, params),
  })

export const registerPresentationInsights = (payload, params) => dispatch =>
  dispatch({
    type: REGISTER_PRESENTATION_INSIGHTS.ACTION,
    payload: insightsService.presentationActivity(payload, params),
  })

export const registerAssetInsights = (payload, params) => dispatch =>
  dispatch({
    type: REGISTER_ASSET_INSIGHTS.ACTION,
    payload: insightsService.assetActivity(payload, params),
  })

export const clearAssetInsightsId = () => ({
  type: CLEAR_ASSET_INSIGHTS_ID,
})

export const updateAssetInsights = (assetId, payload, params) => dispatch =>
  dispatch({
    type: UPDATE_ASSET_INSIGHTS,
    payload: insightsService.updateAssetInsights(assetId, payload, params),
  })

export const listUserInsightsActivities = () => (dispatch, getState) =>
  dispatch({
    type: LIST_USER_INSIGHTS_ACTIVITIES.ACTION,
    payload: insightsService.listUserInsightsActivities(getState().user.get('auth_token'))(),
  })

export const loadMoreInsights = () => (dispatch, getState) =>
  dispatch({
    type: LOAD_MORE_INSIGHTS.ACTION,
    payload: insightsService.listUserInsightsActivities(getState().user.get('auth_token'))(
      getState().insights.get('insightNextPage')
    ),
  })

export const viewPresentationActivity = presentationActivityId => (dispatch, getState) =>
  dispatch({
    type: VIEW_PRESENTATION_ACTIVITY.ACTION,
    payload: insightsService.viewPresentationActivity(getState().user.get('auth_token'))(
      presentationActivityId
    ),
  })

export const getNotifications = () => (dispatch, getState) =>
  dispatch({
    type: GET_NOTIFICATIONS_INSIGHTS,
    payload: insightsService.getNotifications(getState().user.get('auth_token')),
  })

export const markAllAsRead = () => (dispatch, getState) =>
  dispatch({
    type: MARK_ALL_AS_READ,
    payload: insightsService.markAllAsRead(getState().user.get('auth_token')),
  })

export const markAsRead = id => (dispatch, getState) =>
  dispatch({
    type: MARK_AS_READ,
    payload: insightsService.markAsRead(getState().user.get('auth_token'))(id),
    meta: {
      notificationId: id,
    },
  })

export const appendNotification = payload => dispatch =>
  dispatch({
    type: APPEND_NOTIFICATION,
    payload,
  })

export const loadMoreNotifications = () => (dispatch, getState) =>
  dispatch({
    type: LOAD_MORE_NOTIFICATION.ACTION,
    payload: insightsService.loadMoreNotifications(getState().user.get('auth_token'))(
      getState().insights.get('notificationNextPage')
    ),
  })

export const addComment = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_COMMENT.ACTION,
    payload: insightsService.createComment(getState().user.get('auth_token'))(payload),
  })
