import humps from 'humps'

import { get, post, del, patch } from './request'

export const createFolder = (key, payload) =>
  post(['folders'], { key }, humps.decamelizeKeys(payload))

export const updateFolder = key => (folderId, payload) =>
  patch(['folders', folderId], { key }, humps.decamelizeKeys(payload))

export const listFolders = key => params => get(['folders'], { key, params })

export const addPresentationsToFolder = (key, folderId, payload) =>
  post(['folders', folderId, 'add-presentations'], { key }, humps.decamelizeKeys(payload))

export const deleteFolder = key => folderId => del(['folders', folderId], { key })

export const listPresentationsFromFolder = key => folderId => get(['folders', folderId], { key })

export const removePresentationsFromFolder = key => (folderId, payload) =>
  post(['folders', folderId, 'remove-presentations'], { key }, payload)
