import { defineAction } from '_privateDependencies/react-utils/utils'
import * as insightsService from '_services/insights'

export const GET_PRESENTATION_INSIGHTS = defineAction('GET_PRESENTATION_INSIGHTS')
export const RESET_PRESENTATION_INSIGHTS = 'RESET_PRESENTATION_INSIGHTS'

export const getPresentationInsights = presentationId => (dispatch, getState) =>
  dispatch({
    type: GET_PRESENTATION_INSIGHTS.ACTION,
    payload: insightsService.getPresentationInsights(getState().user.get('auth_token'))(
      presentationId
    ),
  })

export const resetPresentationInsights = () => ({
  type: RESET_PRESENTATION_INSIGHTS,
})
