import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

const StepIndicator = ({ className, step }) => (
  <div className={classnames(styles.step, { [styles['active-step']]: step.isActive }, className)}>
    <div className={styles['step-number']}>
      <p>{step.number}</p>
    </div>
    <h2 className={styles.title}>{step.name}</h2>
    <p className={styles.description}>{step.description}</p>
  </div>
)

StepIndicator.propTypes = {
  className: PropTypes.string,
  step: PropTypes.shape({
    number: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
}
StepIndicator.defaultProps = {
  className: '',
}
export default React.memo(StepIndicator)
