import { Map, List, fromJS } from 'immutable'
import humps from 'humps'

import { createReducer } from '_privateDependencies/react-utils/utils'
import {
  LIST_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
} from '_modules/contacts/actions'

const INITIAL_STATE = Map({
  count: 0,
  next: undefined,
  previous: undefined,
  results: List(),
})

export default createReducer(INITIAL_STATE, {
  [LIST_CONTACT.FULFILLED]: (state, { payload, meta }) => {
    const { search } = meta
    const camelizedPayload = humps.camelizeKeys(payload)

    if (search) {
      return state
        .set('results', fromJS(camelizedPayload.results))
        .set('count', camelizedPayload.count)
        .set('next', camelizedPayload.next)
        .set('previous', camelizedPayload.previous)
    }

    return state
      .updateIn(['results'], contacts => {
        const previousPage = payload.previous
        const setNewContacts = previousPage
          ? contacts.toJS().concat(camelizedPayload.results)
          : camelizedPayload.results.concat(contacts.toJS())

        const uniqueJsonStrings = Array.from(new Set(setNewContacts.map(JSON.stringify)))
        const uniqueObjects = uniqueJsonStrings.map(jsonString => JSON.parse(jsonString))
        return fromJS(uniqueObjects)
      })
      .set('count', camelizedPayload.count)
      .set('next', camelizedPayload.next)
      .set('previous', camelizedPayload.previous)
  },
  [CREATE_CONTACT.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state
      .updateIn(['results'], results => results.push(fromJS(camelizedPayload)))
      .updateIn(['count'], count => count + 1)
  },
  [UPDATE_CONTACT.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const indexOfContact = state
      .get('results')
      .findIndex(contact => contact.get('id') === camelizedPayload.id)

    return state.mergeIn(['results', String(indexOfContact)], camelizedPayload)
  },
  [DELETE_CONTACT.FULFILLED]: (state, { meta }) => {
    const { contactId } = meta
    return state
      .updateIn(['results'], results => results.filter(contact => contact.get('id') !== contactId))
      .updateIn(['count'], count => count - 1)
  },
})
