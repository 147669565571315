import React from 'react'
import { Redirect, Route, IndexRoute } from 'react-router'
import Loadable from 'react-loadable'

import App from '_views/app'
import Login from '_views/login'
import SignUp from '_views/sign-up'
import PresentationEdit from '_views/presentation-edit'
import PresentationPreviewNew from '_views/presentation-preview-new'
import { showTrialView, userLoginRequired, showPaymentProblem } from '_utils/routes'
import AsyncLoader from '_components/async-loader'
import { FLAG_SHOW_CREATE_TEAM_FLOW, FLAG_SHOW_ARCHIVE_FEATURE } from '_config/environment'
import { PATHS } from '_utils/constants'
import TrialExpired from '_views/trial-expired'

const defaultLoadableConfig = {
  loading: AsyncLoader,
  delay: 300,
}

const InsightsNew = Loadable({
  loader: () => import('_views/insights-new'),
  ...defaultLoadableConfig,
})
const PageNotFound = Loadable({
  loader: () => import('_views/page-not-found'),
  ...defaultLoadableConfig,
})
const PresentationNotFound = Loadable({
  loader: () => import('_views/presentation-not-found'),
  ...defaultLoadableConfig,
})
const PresentationInsights = Loadable({
  loader: () => import('_views/presentation-insights'),
  ...defaultLoadableConfig,
})
const UserPresentationInsights = Loadable({
  loader: () => import('_views/user-presentation-insights'),
  ...defaultLoadableConfig,
})

const PresentationListFolders = Loadable({
  loader: () => import('_views/presentation-list-folders'),
  ...defaultLoadableConfig,
})
const ForgotPassword = Loadable({
  loader: () => import('_views/forgot-password'),
  ...defaultLoadableConfig,
})
const CheckEmail = Loadable({
  loader: () => import('_views/check-email'),
  ...defaultLoadableConfig,
})
const ChangePassword = Loadable({
  loader: () => import('_views/change-password'),
  ...defaultLoadableConfig,
})
const SetPassword = Loadable({
  loader: () => import('_views/set-password'),
  ...defaultLoadableConfig,
})
const ClientViewPresentation = Loadable({
  loader: () => import('_views/presentation-client-view'),
  ...defaultLoadableConfig,
})
const ClonePresentation = Loadable({
  loader: () => import('_views/clone-presentation'),
  ...defaultLoadableConfig,
})
const Referral = Loadable({ loader: () => import('_views/referral'), ...defaultLoadableConfig })
const ManageRecipients = Loadable({
  loader: () => import('_views/manage-recipients'),
  ...defaultLoadableConfig,
})

const Payment = Loadable({
  loader: () => import('_views/payments'),
  ...defaultLoadableConfig,
})

const ExternalCreateTeam = Loadable({
  loader: () => import('_views/external-create-team'),
  ...defaultLoadableConfig,
})

const SignUpEmbeddable = Loadable({
  loader: () => import('_views/sign-up-embeddable'),
  ...defaultLoadableConfig,
})

const PaymentProblem = Loadable({
  loader: () => import('_views/payment-problem'),
  ...defaultLoadableConfig,
})

const AccountSettings = Loadable({
  loader: () => import('_views/account-settings'),
  ...defaultLoadableConfig,
})

const Contacts = Loadable({
  loader: () => import('_views/contacts'),
  ...defaultLoadableConfig,
})

const Onboarding = Loadable({
  loader: () => import('_views/onboarding'),
  ...defaultLoadableConfig,
})

const LoginEmbeddable = Loadable({
  loader: () => import('_views/embedded-login'),
  ...defaultLoadableConfig,
})

const routes = store => (
  <Route component={App}>
    <Redirect from="/" to="/presentations" exact />
    <Route path="/insights" onEnter={userLoginRequired(store)}>
      <IndexRoute component={InsightsNew} onEnter={userLoginRequired(store)} />
      <Route path=":id" component={PresentationInsights} onEnter={userLoginRequired(store)} />
      <Route
        path=":id/:hash"
        component={UserPresentationInsights}
        onEnter={userLoginRequired(store)}
      />
    </Route>
    {[
      PATHS.PRESENTATIONS_PATHNAME.BASE,
      `${PATHS.PRESENTATIONS_PATHNAME.BASE}/${PATHS.PRESENTATIONS_PATHNAME.FAVORITES}`,
      `${PATHS.PRESENTATIONS_PATHNAME.BASE}/${PATHS.PRESENTATIONS_PATHNAME.RECEIVED}`,
      `${PATHS.PRESENTATIONS_PATHNAME.BASE}/${PATHS.PRESENTATIONS_PATHNAME.TEMPLATE}`,
      `${PATHS.PRESENTATIONS_PATHNAME.BASE}/${PATHS.PRESENTATIONS_PATHNAME.FOLDERS}`,
      `${PATHS.PRESENTATIONS_PATHNAME.BASE}/${PATHS.PRESENTATIONS_PATHNAME.FOLDERS}/:id`,
      ...(FLAG_SHOW_ARCHIVE_FEATURE && [
        `${PATHS.PRESENTATIONS_PATHNAME.BASE}/${PATHS.PRESENTATIONS_PATHNAME.ARCHIVED}`,
      ]),
    ].map(path => (
      <Route path={path} key={path} onEnter={userLoginRequired(store)}>
        <IndexRoute component={PresentationListFolders} onEnter={userLoginRequired(store)} />
        <Route
          path="edit/:presentationId"
          component={PresentationEdit}
          onEnter={userLoginRequired(store)}
        />
        <Route
          path="preview/:id"
          component={PresentationPreviewNew}
          onEnter={userLoginRequired(store)}
        />
        <Route
          path="manage-recipients/:id"
          component={ManageRecipients}
          onEnter={userLoginRequired(store)}
        />
      </Route>
    ))}
    {[
      `${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.EDIT_PROFILE_PATH_NAME}`,
      `${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BILLING_PATH_NAME}`,
      `${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.PASSWORD_PATH_NAME}`,
      `${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.NOTIFICATIONS_PATH_NAME}`,
      `${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.PREFERENCES_PATH_NAME}`,
    ].map(path => (
      <Route path={path} key={path} onEnter={userLoginRequired(store)}>
        <Redirect
          from={PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}
          to={`${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.EDIT_PROFILE_PATH_NAME}`}
        />
        <IndexRoute component={AccountSettings} onEnter={userLoginRequired(store)} />
      </Route>
    ))}
    <Route path="/check-email" component={CheckEmail} />
    <Route path="/change-password/:uid/:token" component={ChangePassword} />
    <Route path="/login" component={Login} />
    <Route path="/sign-up" component={SignUp} />
    <Route path="/sign-up-embeddable" component={SignUpEmbeddable} />
    <Route path="/login-embeddable" component={LoginEmbeddable} />

    <Route path="/referral" component={Referral} />
    <Route
      path="/clone/presentations/:hash/:id"
      component={ClonePresentation}
      onEnter={userLoginRequired(store)}
    />
    {FLAG_SHOW_CREATE_TEAM_FLOW && <Route path="/create-a-team" component={ExternalCreateTeam} />}

    <Route path={PATHS.TRIAL_EXPIRED} component={TrialExpired} onEnter={showTrialView(store)} />
    <Route
      path={PATHS.PAYMENT_PROBLEM}
      component={PaymentProblem}
      onEnter={showPaymentProblem(store)}
    />
    <Route path="/set-password" component={SetPassword} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/client/presentations/404" component={PresentationNotFound} />
    <Route path="/client/presentations/:hash" component={ClientViewPresentation} />
    <Route path="/c/p/404" component={PresentationNotFound} />
    <Route path="/c/p/:hash(/:title)" component={ClientViewPresentation} />

    {['/payment', '/payment/:planId'].map(path => (
      <Route path={path} key={path} component={Payment} onEnter={userLoginRequired(store, path)} />
    ))}
    <Route path="/contacts" component={Contacts} onEnter={userLoginRequired(store)} />
    <Route
      path="/onboarding/:currentStep"
      component={Onboarding}
      onEnter={userLoginRequired(store)}
    />
    <Route path="/404" component={PageNotFound} />
    <Redirect from="*" to="/404" />
  </Route>
)

export default routes
