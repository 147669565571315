import React, { useMemo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import Modal from '_components/ui-kit/modal'
import InputCheckbox from '_components/ui-kit/checkbox'
import Input from '_components/ui-kit/input'
import Button from '_components/button-round'
import { userSelector, isLoggedUserSelector } from '_modules/user/selectors'
import { addComment, CREATE_COMMENT } from '_modules/insights/actions'
import { presentationActivitySelector } from '_modules/insights/selectors'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import useFetchCall from '_hooks/use-fetch-call'
import { ALERT_TYPE } from '_components/toast'
import validateEmail from '_utils/email-validation'

import styles from './styles.css'

const EMOJIS = {
  THUMBS_UP: ':thumbsup:',
  HAPPY: ':happy:',
  LOVE: ':love:',
  SHOCK: ':shock:',
  PRAY: ':pray:',
}

export const EMOJIS_CODE = {
  [EMOJIS.THUMBS_UP]: '0x1F44D',
  [EMOJIS.HAPPY]: '0x1F600',
  [EMOJIS.LOVE]: '0x1F60D',
  [EMOJIS.SHOCK]: '0x1F62E',
  [EMOJIS.PRAY]: '0x1F64F',
}

const EMOJIS_INPUTS = [
  {
    label: '0x1F44D',
    value: EMOJIS.THUMBS_UP,
  },
  {
    label: '0x1F600',
    value: EMOJIS.HAPPY,
  },
  {
    label: '0x1F60D',
    value: EMOJIS.LOVE,
  },
  {
    label: '0x1F62E',
    value: EMOJIS.SHOCK,
  },
  {
    label: '0x1F64F',
    value: EMOJIS.PRAY,
  },
]

const initialState = {
  name: '',
  email: '',
  comment: '',
  emoji: '',
}

const FeedbackModal = ({ onClose, presentation, queryName }) => {
  const user = useSelector(userSelector)
  const isLoggedUser = useSelector(isLoggedUserSelector)
  const presentationActivity = useSelector(presentationActivitySelector)
  const dispatch = useDispatch()
  const [values, setValues] = useState(initialState)

  const [errors, setErrors] = useState({})

  const authorName = useMemo(() => {
    if (presentation) {
      const firstName = presentation.getIn(['user', 'first_name'])
      const lastName = presentation.getIn(['user', 'last_name'])

      return `${firstName} ${lastName}`
    }

    return ''
  }, [presentation])

  const handleChange = useCallback(event => {
    const { value, name } = event.target
    setValues(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleChangeCheckbox = useCallback(event => {
    const { value, name, checked } = event.target
    if (!checked) {
      setValues(prevState => ({ ...prevState, [name]: '' }))
      return
    }

    setValues(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const validate = useCallback(() => {
    const newErrors = {}

    if (!values.name) {
      newErrors.name = 'You must enter a name'
    }

    if (!values.email) {
      newErrors.email = 'You must enter an email'
    }

    const isValidEmail = !validateEmail(values.email)

    if (!isValidEmail) {
      newErrors.email = 'Please type a valid email'
    }
    if (!values.comment && !values.emoji) {
      newErrors.comment = 'Don’t forget to type a comment or select an emoji!'
      newErrors.emoji = 'Don’t forget to type a comment or select an emoji!'
    }

    return newErrors
  }, [values.comment, values.emoji, values.name, values.email])

  const onBlur = useCallback(
    event => {
      const { name } = event.target
      const error = validate()[name]

      if (error) {
        setErrors(prevState => ({ ...prevState, [name]: error }))
        return
      }

      if (name === 'emoji') {
        // eslint-disable-next-line no-unused-vars
        const { [name]: _, comment: commentError, ...newErrors } = errors
        setErrors(newErrors)
        return
      }

      if (name === 'comment') {
        // eslint-disable-next-line no-unused-vars
        const { [name]: _, emoji: emojiError, ...newErrors } = errors
        setErrors(newErrors)
        return
      }

      const { [name]: _, ...newErrors } = errors
      setErrors(newErrors)
    },
    [errors, validate]
  )

  const handleSubmit = useCallback(() => {
    const newErrors = validate()
    if (Object.keys(newErrors).length === 0) {
      if ((presentationActivity && !presentationActivity.get('id')) || !presentationActivity) return
      const presentationActivityId = presentationActivity.get('id')
      const payload = {
        name: values.name,
        email: values.email,
        comment: values.comment,
        emoji: values.emoji,
        presentation_activity: presentationActivityId,
      }
      dispatch(addComment(payload))
      return
    }
    setErrors(newErrors)
  }, [
    dispatch,
    presentationActivity,
    validate,
    values.comment,
    values.emoji,
    values.name,
    values.email,
  ])

  const onCreateSuccessfully = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.SUCCESS,
        message: `Feedback sent successfully.`,
      })
    )

    onClose()
  }, [dispatch, onClose])

  const onReject = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: `Sending feedback failed.`,
      })
    )
    onClose()
  }, [dispatch, onClose])

  const [isLoading] = useFetchCall(CREATE_COMMENT, onCreateSuccessfully, onReject)

  useEffect(() => {
    if (isLoggedUser && user) {
      setValues(prevState => ({
        ...prevState,
        name: `${user.get('first_name')} ${user.get('last_name')}`,
        email: user.get('email'),
      }))
      return
    }
    if (queryName) {
      setValues(prevState => ({
        ...prevState,
        name: queryName,
      }))
    }
  }, [isLoggedUser, queryName, user])

  return (
    <Modal
      isOpen
      isClosable
      className={styles['feedback-modal']}
      onClose={onClose}
      disableOverlay
      disabledScroll={false}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>Give {authorName} feedback</h2>
        <p className={styles.subtitle}>We&apos;ll let them know you left a comment.</p>
        <Input
          id="name"
          name="name"
          value={values.name}
          placeholder="Name"
          onChange={handleChange}
          disabled={isLoggedUser}
          inputClassName={classnames(styles.input, {
            [styles.error]: errors.name,
          })}
          type="text"
          onBlur={onBlur}
          error={errors.name}
        />
        <Input
          id="email"
          name="email"
          value={values.email}
          placeholder="E-mail"
          onChange={handleChange}
          disabled={isLoggedUser}
          inputClassName={classnames(styles.input, {
            [styles.error]: errors.email,
          })}
          type="text"
          onBlur={onBlur}
          error={errors.email}
        />
        <Input
          id="comment"
          name="comment"
          value={values.comment}
          type="textarea"
          placeholder="Comment"
          textareaClassName={classnames(styles.input, styles.textarea, {
            [styles.error]: errors.comment,
          })}
          labelClassName={styles['input-label']}
          draggable="false"
          onChange={handleChange}
          onBlur={onBlur}
          error={errors.comment}
        />
        <div className={styles['emoji-wrapper']}>
          {EMOJIS_INPUTS.map(item => (
            <InputCheckbox
              id={item.value}
              key={`emoji-${item.value}`}
              label={String.fromCodePoint(item.label)}
              name="emoji"
              value={item.value}
              labelClassName={styles['emoji-label']}
              inputClassName={styles['emoji-input']}
              className={styles.emoji}
              onChange={handleChangeCheckbox}
              checked={item.value === values.emoji}
              onBlur={onBlur}
              error={errors.emoji}
            />
          ))}
        </div>
        <Button className={styles.button} onClick={handleSubmit} isLoading={isLoading}>
          Send feedback
        </Button>
      </div>
    </Modal>
  )
}

FeedbackModal.propTypes = {
  presentation: PropTypes.instanceOf(Map).isRequired,
  onClose: PropTypes.func,
  queryName: PropTypes.string,
}

FeedbackModal.defaultProps = {
  onClose: () => {},
  queryName: undefined,
}

export default React.memo(FeedbackModal)
