import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

import { UPDATE_USER_LOGO } from '_modules/user/actions'

import {
  GET_PRESENTATION,
  UPDATE_PRESENTATION,
  LIST_PRESENTATIONS,
  COPY_PRESENTATION,
  CREATE_PRESENTATION_SHARE_LINK,
  SHARE_PRESENTATION_THROUGH_EMAIL,
  CREATE_PRESENTATION,
  CREATE_TEMPLATE,
  ENHANCE_PRESENTATION,
  DELETE_TEMPLATE,
  DELETE_RECEIVED_PRESENTATION,
  DELETE_PRESENTATION,
  RETRIEVE_PRESENTATIONS_STATS,
  ARCHIVE_PRESENTATION,
} from './actions'

export const presentationSelector = (state, presentationId, showHideAsset = true) => {
  if (showHideAsset) {
    return state.presentations.getIn(['presentations', `${presentationId}`])
  }

  const presentation = state.presentations.getIn(['presentations', `${presentationId}`])

  return presentation
    ? presentation.updateIn(['assets'], assets =>
        assets.filter(asset => asset && !asset.get('isHidden'))
      )
    : presentation
}

export const presentationsSelector = state => state.presentations.get('presentations')

export const currentPresentationIdSelector = state =>
  state.presentations.get('currentPresentationId')

export const nextPageSelector = state => state.presentations.getIn(['search', 'nextPage'])

export const listPresentationsSelector = state => state.presentations.getIn(['search', 'results'])

export const getPresentationsSelector = (currentTab = 'presentations') =>
  createSelector(presentationsSelector, listPresentationsSelector, (allPresentations, results) => {
    const ids = results.get(currentTab, List())
    const presentations = ids.map(id => allPresentations.get(`${id}`))
    return presentations
  })

export const getPresentationByFolderId = folderID =>
  createSelector(presentationsSelector, listPresentationsSelector, (presentations, results) => {
    const ids = results.get('presentations', List())
    if (folderID) {
      return presentations
        .filter(
          item =>
            String(item.getIn(['folder', 'id'])) === String(folderID) &&
            ids.includes(Number(item.id))
        )
        .toList()
    }
    return List()
  })

export const getNextPaginationSelector = state => state.presentations.get('next')

export const getPresentationLoadingSelector = state => state.loading.get(GET_PRESENTATION.ACTION)

export const copyPresentationLoadingSelector = state => state.loading.get(COPY_PRESENTATION.ACTION)

export const createPresentationShareLinkLoadingSelector = state =>
  state.loading.get(CREATE_PRESENTATION_SHARE_LINK.ACTION)

export const listPresentationsLoadingSelector = state =>
  state.loading.get(LIST_PRESENTATIONS.ACTION)

export const listPresentationsErrorSelector = state => state.error.get(LIST_PRESENTATIONS.ACTION)

export const updatePresentationLoadingSelector = state =>
  state.loading.get(UPDATE_PRESENTATION.ACTION)

export const updatePresentationErrorSelector = state =>
  state.error.get(UPDATE_PRESENTATION.ACTION, Map())

export const updateLogoLoadingSelector = state => state.loading.get(UPDATE_USER_LOGO.ACTION)

export const listFoldersSelector = state => state.presentations.getIn(['folders', 'results'])

export const isSharingEmailLoadingSelector = state =>
  state.loading.get(SHARE_PRESENTATION_THROUGH_EMAIL.ACTION)

export const createPresentationLoadingSelector = state =>
  state.loading.get(CREATE_PRESENTATION.ACTION) || state.loading.get(CREATE_TEMPLATE.ACTION)

export const enhanceErrorSelector = state => state.error.get(ENHANCE_PRESENTATION.ACTION, Map())

export const enhanceLoadingSelector = state => state.loading.get(ENHANCE_PRESENTATION.ACTION)

export const enhancePresentationSelector = state => state.presentations.get('enhance')

export const getRecentCompaniesSelector = state => state.presentations.get('recent_companies')

export const isDeleteTemplateLoadingSelector = state => state.loading.get(DELETE_TEMPLATE.ACTION)

export const isDeleteReceivedPresentationLoadingSelector = state =>
  state.loading.get(DELETE_RECEIVED_PRESENTATION.ACTION)

export const isDeletePresentationLoadingSelector = state =>
  state.loading.get(DELETE_PRESENTATION.ACTION)

export const isRetrievingPresentationsStats = state =>
  state.loading.get(RETRIEVE_PRESENTATIONS_STATS.ACTION)

export const getPresentationPendingStats = state => state.presentations.get('pendingStats')

export const isArchivingPresentationsSelector = state =>
  state.loading.get(ARCHIVE_PRESENTATION.ACTION)
