/* eslint-disable react/button-has-type */
import React, { useMemo, Fragment, forwardRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Spinner from 'react-spinkit'

import Svg from '_components/svg'

import styles from './styles.css'

export const BUTTON_THEME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  GHOST: 'ghost',
  TRANSPARENT_FILL: 'transparent-fill',
  TRANSPARENT_OUTLINE: 'transparent-outline',
  LINK_BUTTON: 'link-button',
}

export const BUTTON_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
}

const ButtonRound = forwardRef(
  (
    {
      children,
      isLoading,
      startIcon,
      endIcon,
      theme,
      size,
      className,
      type,
      to,
      href,
      startIconClassName,
      endIconClassName,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const buttonProps = useMemo(
      () => ({
        className: classnames(
          styles.button,
          styles[theme],
          styles[size],
          {
            [styles.disabled]: disabled,
          },
          className
        ),
        ...restProps,
      }),
      [className, disabled, restProps, size, theme]
    )

    const spinnerColor = useMemo(() => {
      switch (theme) {
        case BUTTON_THEME.SECONDARY:
          return '#34495e'
        default:
          return '#FFFFFF'
      }
    }, [theme])

    const renderChildren = useMemo(
      () => (
        <Fragment>
          {isLoading ? (
            <div className={styles.loading}>
              <Spinner
                name="circle"
                color={spinnerColor}
                fadeIn="none"
                className={styles['spinner-div']}
              />
            </div>
          ) : (
            <Fragment>
              {startIcon && (
                <Svg
                  className={classnames(styles['start-icon'], startIconClassName)}
                  icon={startIcon}
                />
              )}
              {endIcon && (
                <Svg className={classnames(styles['end-icon'], endIconClassName)} icon={endIcon} />
              )}
              {children}
            </Fragment>
          )}
        </Fragment>
      ),
      [children, endIcon, endIconClassName, isLoading, spinnerColor, startIcon, startIconClassName]
    )

    if (to) {
      return (
        <Link to={to} {...buttonProps}>
          {renderChildren}
        </Link>
      )
    }

    if (href) {
      return (
        <a href={href} {...buttonProps}>
          {renderChildren}
        </a>
      )
    }

    return (
      <button type={type} disabled={disabled} {...buttonProps} ref={ref}>
        {/* {isLoading ? (
          <LoaderSpinner
            color={LOADER_THEME_COLOR[theme] || LOADER_COLOR.WHITE}
            size={LOADER_SIZE.BUTTON}
          />
        ) : ( */}
        {renderChildren}
        {/* )} */}
      </button>
    )
  }
)
ButtonRound.propTypes = {
  children: PropTypes.node.isRequired,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  startIconClassName: PropTypes.string,
  endIconClassName: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(BUTTON_THEME)),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE)),
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
}

ButtonRound.defaultProps = {
  startIcon: null,
  endIcon: null,
  startIconClassName: null,
  endIconClassName: null,
  type: 'button',
  theme: BUTTON_THEME.PRIMARY,
  size: BUTTON_SIZE.MEDIUM,
  className: '',
  to: '',
  href: '',
  isLoading: false,
  disabled: false,
}

export default React.memo(ButtonRound)
