import PropTypes from 'prop-types'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import withViewportSize from '_hocs/with-viewport-size'
import { PATHS } from '_utils/constants'
import { listPresentationsLoadingSelector } from '_modules/presentations/selectors'
import { TEMPLATES_URL, ASSETS_URL, BUILTWITHHIGHNOTE_URL } from '_components/header/constants'

import styles from './styles.css'

const HeaderNavBar = ({ className, location, isSmallViewport }) => {
  const isLoadingPresentations = useSelector(listPresentationsLoadingSelector)
  const [isResourcesDropdownOpen, setIsResourcesDropdownOpen] = useState(false)
  const ref = useRef(null)

  const toggleResourcesDropdown = useCallback(() => {
    setIsResourcesDropdownOpen(prevState => !prevState)
  }, [])

  const handleOutsideClick = useCallback(
    event => {
      const userDropdownOutsideClick =
        !isSmallViewport && ref.current && !ref.current.contains(event.target)

      if (userDropdownOutsideClick && isResourcesDropdownOpen) {
        setIsResourcesDropdownOpen(false)
      }
    },
    [isResourcesDropdownOpen, isSmallViewport]
  )

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    setIsResourcesDropdownOpen(false)
  }, [location, location.pathname])

  return (
    <nav className={className}>
      <ul className={styles['nav-bar']}>
        <li
          className={classnames(styles['nav-item'], { [styles.disabled]: isLoadingPresentations })}
        >
          <Link
            to={
              location.pathname.includes(PATHS.PRESENTATIONS_PATHNAME)
                ? location.pathname
                : PATHS.PRESENTATIONS_PATHNAME.BASE
            }
            className={styles['nav-element']}
            activeClassName={styles.active}
          >
            Presentations
          </Link>
        </li>
        <li className={styles['nav-item']}>
          <Link to="/insights" className={styles['nav-element']} activeClassName={styles.active}>
            Analytics
          </Link>
        </li>
        <li className={styles['nav-item']}>
          <Link to="/contacts" className={styles['nav-element']} activeClassName={styles.active}>
            Contacts
          </Link>
        </li>
        <li className={classnames(styles['nav-item'], styles['resources-link'])} ref={ref}>
          <button onClick={toggleResourcesDropdown} className={styles['nav-element']} type="button">
            Resources
          </button>
          {isResourcesDropdownOpen && (
            <div className={styles.dropdown}>
              <div className={styles['content-wrapper']}>
                <a
                  className={styles.link}
                  href={TEMPLATES_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Templates
                </a>
                <a
                  className={styles.link}
                  href={ASSETS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Assets
                </a>{' '}
                <a
                  className={styles.link}
                  href={BUILTWITHHIGHNOTE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  #builtwithhighnote
                </a>
              </div>
            </div>
          )}
        </li>
      </ul>
    </nav>
  )
}

HeaderNavBar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  isSmallViewport: PropTypes.bool,
}

HeaderNavBar.defaultProps = {
  className: '',
  isSmallViewport: false,
}

export default withViewportSize(React.memo(HeaderNavBar))
