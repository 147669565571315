import React, { useMemo, useCallback, useEffect } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import SuccessIcon from '_assets/icons/check-icon.svg'
import AlertIcon from '_assets/icons/alert-icon.svg'
import CloseIcon from '_assets/icons/close-masonry.svg'
import Svg from '_components/svg'
import { alertSelector } from '_modules/toaster-alert/selectors'
import { closeToasterAlert } from '_modules/toaster-alert/actions'

import styles from './styles.css'

export const ALERT_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const ALERT_MESSAGE = {
  SUCCESS: { DEFAULT: 'Your changes have been applied successfully.' },
  ERROR: { DEFAULT: 'Something went wrong. Please try again.' },
}

const Toast = () => {
  const alert = useSelector(alertSelector)
  const dispatch = useDispatch()

  const icon = useMemo(() => {
    if (alert.get('type') === ALERT_TYPE.SUCCESS) {
      return SuccessIcon
    }

    return AlertIcon
  }, [alert])

  const alertTitle = useMemo(() => {
    if (alert.get('type') === ALERT_TYPE.SUCCESS) {
      return 'Success!'
    }

    return 'Error'
  }, [alert])

  const onClose = useCallback(() => {
    dispatch(closeToasterAlert())
    toast.dismiss()
  }, [dispatch])

  const renderToastAlert = useMemo(
    () => (
      <div className={styles['toaster-wrapper']}>
        <div className={classnames(styles['icon-container'], styles[alert.get('type')])}>
          <Svg className={styles.icon} icon={icon} />
        </div>
        <div className={styles['message-wrapper']}>
          <p className={styles['message-title']}>{alertTitle}</p>
          <p className={styles['message-details']}>{alert.get('message')}</p>
        </div>
        <button
          type="button"
          aria-label="Close Alert"
          className={styles['close-button']}
          onClick={onClose}
        >
          <Svg className={styles['close-icon']} icon={CloseIcon} />
        </button>
      </div>
    ),
    [alert, alertTitle, icon, onClose]
  )

  useEffect(() => {
    if (alert.get('message') && !toast.isActive(alert.get('message'))) {
      toast(renderToastAlert, {
        toastId: alert.get('message'),
      })
    }
  }, [alert, renderToastAlert])

  return (
    <ToastContainer
      toastClassName={styles.toast}
      bodyClassName={styles.body}
      progressClassName={styles.progress}
      hideProgressBar
      autoClose={alert.get('timeAutoClose') ? alert.get('timeAutoClose') : 4000}
      closeButton={false}
      position={alert.get('position') ? alert.get('position') : 'bottom-left'}
      pauseOnHover
      draggable={false}
    />
  )
}

export default Toast
