import { Map, fromJS } from 'immutable'
import { LOCATION_CHANGE } from 'react-router-redux'

import getActionName from '../../utils/get-action-name'

export const INITIAL_STATE = Map()

export default (state = INITIAL_STATE, action) => {
  if (action.error) {
    let error = action.payload

    if (!error) {
      error = {
        error: 'Not Found',
      }
    }

    if (typeof action.payload === 'string') {
      error = {
        error: 'Server Error',
      }
    }

    return state.set(getActionName(action.type), fromJS(error))
  }

  if (getActionName(action.type) === LOCATION_CHANGE) {
    return INITIAL_STATE
  }

  return state.delete(getActionName(action.type))
}
