import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Map } from 'immutable'
import { useDispatch } from 'react-redux'

import PlayIcon from '_assets/icons/play.svg'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import TextEditor from '_components/text-editor'
import Svg from '_components/svg'
import { getAssetThumbnail, getAssetType, getAssetViewText, getAssetFooterInfo } from '_utils/asset'
import { Presentation } from '_models'
import { ASSET_TYPE } from '_constants/presentation'
import withAssetVisualization from '_hocs/with-asset-visualization'
import { getAssetConvertedFile } from '_modules/assets/actions'

import styles from './styles.css'

const BarcelonaStyleSection = ({ className, asset, presentation, toggleVisualization, title }) => {
  const dispatch = useDispatch()

  const onToggleVisualization = useCallback(() => {
    if (asset.get('type') === ASSET_TYPE.FILE) {
      dispatch(
        getAssetConvertedFile({
          assetId: asset.get('id'),
          presentationId: presentation.get('id'),
        })
      )
    }

    toggleVisualization(asset.get('position'))
  }, [asset, presentation, dispatch, toggleVisualization])

  return (
    <div
      key={`asset-${asset.get('id')}-of-presentation-${presentation.get('id')}`}
      className={classnames(
        styles['barcelona-card-wrapper'],
        styles[
          getAssetType({
            filename: asset.get('filename'),
            type: asset.get('type'),
          })
        ],
        className
      )}
    >
      {asset.get('type') && (
        <div className={classnames(styles.thumbnail)} style={getAssetThumbnail(asset)}>
          <span className={styles.type}>
            {getAssetType({
              filename: asset.get('filename'),
              type: asset.get('type'),
            }).toUpperCase()}
          </span>
          <span className={classnames(styles['thumbnail-hover'])}>
            <Svg icon={PlayIcon} className={styles['play-icon']} />
            <Button
              className={styles['view-button']}
              theme={BUTTON_THEME.TRANSPARENT_FILL}
              onClick={onToggleVisualization}
              size={BUTTON_SIZE.SMALL}
            >
              View
            </Button>
          </span>
        </div>
      )}
      <div
        className={styles.title}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <TextEditor
        readOnly
        toolbarHidden
        wrapperClassName={styles.description}
        inputClassName={styles['description-input']}
        initialState={asset.get('description')}
      />
      {asset.get('type') && (
        <div className={styles['footer-content']}>
          <Button
            theme={BUTTON_THEME.SECONDARY}
            onClick={onToggleVisualization}
            size={BUTTON_SIZE.SMALL}
          >
            {getAssetViewText({
              type: asset.get('type'),
              prefix: asset.get('type') === ASSET_TYPE.VIDEO ? 'Watch' : 'View',
            })}
          </Button>
          <span className={styles['footer-info']} title={asset.get('website')}>
            {getAssetFooterInfo(asset)}
          </span>
        </div>
      )}
    </div>
  )
}

BarcelonaStyleSection.propTypes = {
  className: PropTypes.string,
  asset: PropTypes.instanceOf(Map).isRequired,
  presentation: PropTypes.instanceOf(Presentation).isRequired,
  toggleVisualization: PropTypes.func.isRequired,
  title: PropTypes.string,
}

BarcelonaStyleSection.defaultProps = {
  className: '',
  title: '',
}

export default withAssetVisualization(React.memo(BarcelonaStyleSection))
