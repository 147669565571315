import mime from 'mime-types'

const GDOC = 'gdoc'
const GSLIDES = 'gslides'
const GSHEET = 'gsheet'

const documentExtensions = ['doc', 'docx', GDOC, 'pages']
export const imageExtensions = ['bmp', 'jpeg', 'jpg', 'png', 'tif', 'tiff']
const otherExtensions = ['pdf']
const presentationExtensions = ['ppt', 'pptx', GSLIDES]
const spreadsheetExtensions = ['xls', 'xlsx', GSHEET]
const macExtensions = ['pages', 'key', 'numbers']

const isDocument = mimeType =>
  documentExtensions.map(extension => mime.lookup(extension)).includes(mimeType)

const isPresentation = mimeType =>
  presentationExtensions.map(extension => mime.lookup(extension)).includes(mimeType)

const isSpreadsheet = mimeType =>
  spreadsheetExtensions.map(extension => mime.lookup(extension)).includes(mimeType)

export const isGoogle = mimeType =>
  [GDOC, GSLIDES, GSHEET].map(extension => mime.lookup(extension)).includes(mimeType)

export const convertGoogleType = mimeType => {
  switch (mimeType) {
    case 'application/vnd.google-apps.document':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'application/vnd.google-apps.presentation':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    case 'application/vnd.google-apps.spreadsheet':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    default:
      return mimeType
  }
}

export const isImage = mimeType =>
  imageExtensions.map(extension => mime.lookup(extension)).includes(mimeType)

export const isOffice = mimeType =>
  isDocument(mimeType) || isPresentation(mimeType) || isSpreadsheet(mimeType)

export const FILE_TYPES_ALLOWED = documentExtensions
  .concat(
    imageExtensions,
    otherExtensions,
    presentationExtensions,
    spreadsheetExtensions,
    macExtensions
  )
  .reduce((acc, extension) => {
    if (extension === 'numbers') {
      return [
        ...acc,
        { extension, mimeType: 'application/x-iwork-numbers-sffnumbers' },
        { extension, mimeType: 'application/vnd.apple.numbers' },
      ]
    }
    if (extension === 'key') {
      return [
        ...acc,
        { extension, mimeType: 'application/x-iwork-keynote-sffkey' },
        { extension, mimeType: 'application/vnd.apple.keynote' },
      ]
    }
    if (extension === 'pages') {
      return [
        ...acc,
        { extension, mimeType: 'application/x-iwork-pages-sffpages' },
        { extension, mimeType: 'application/vnd.apple.pages' },
      ]
    }
    return [...acc, { extension, mimeType: mime.lookup(extension) }]
  }, [])

export const ALL_TYPES_EXTENSIONS = FILE_TYPES_ALLOWED.reduce(
  (acc, type) => (isGoogle(type.mimeType) ? acc : [...acc, `.${type.extension}`]),
  []
)

export const GOOGLE_ALL_TYPE_EXTENSIONS = FILE_TYPES_ALLOWED.map(type => type.mimeType, [])

export const IMAGE_TYPES_EXTENSIONS = imageExtensions.map(type => `.${type}`, [])

export const GOOGLE_MIME_TYPES = [
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.presentation',
  'application/vnd.google-apps.spreadsheet',
]
