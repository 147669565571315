import React, { useState, useMemo, useCallback, forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AvatarEditor from 'react-avatar-editor'

import ImageResizeSmallIcon from '_assets/icons/image-resize-small.svg'
import ImageResizeBigIcon from '_assets/icons/image-resize-big.svg'
import AspectRatioSquareIcon from '_assets/icons/aspect-ratio-square.svg'
import AspectRatioRectangleIcon from '_assets/icons/aspect-ratio-rectangle.svg'
import RepositionIcon from '_assets/icons/reposition-icon.svg'
import Svg from '_components/svg'

import styles from './styles.css'

export const ASPECT_RATIO = {
  SQUARE: 'square',
  RECTANGLE: 'rectangle',
}

const PictureAdjustment = forwardRef(
  ({ picture, editorOptions, onEditorChange, isOnlySquaredPicture }, ref) => {
    const [displayOverlay, setDisplayOverlay] = useState(true)

    const overlayRef = useRef(null)

    const onPositionChange = useCallback(
      pos => {
        onEditorChange({ position: pos })
      },
      [onEditorChange]
    )

    const editorConfig = useMemo(() => {
      const defaultConfig = {
        borderRadius: 4,
        scale: editorOptions.zoomScale,
        color: [0, 0, 0, 0.64],
        position: editorOptions.position,
      }

      if (editorOptions.aspectRatio === ASPECT_RATIO.SQUARE) {
        return {
          ...defaultConfig,
          height: 216,
          width: 216,
          border: 28,
        }
      }

      return {
        ...defaultConfig,
        height: 152,
        width: 232,
        border: [20, 60],
      }
    }, [editorOptions.aspectRatio, editorOptions.position, editorOptions.zoomScale])

    const onOverlayRemove = useCallback(() => {
      setDisplayOverlay(false)
      // eslint-disable-next-line
      ref.current.state.drag = true
    }, [ref])

    const handleMouseDown = useCallback(
      event => {
        if (
          ref.current &&
          ref.current.canvas &&
          overlayRef &&
          overlayRef.current &&
          !overlayRef.current.contains(event.target) &&
          ref.current.canvas.contains(event.target)
        ) {
          setDisplayOverlay(false)
        }
      },
      [ref]
    )

    useEffect(() => {
      window.addEventListener('mousedown', handleMouseDown)

      return () => {
        window.removeEventListener('mousedown', handleMouseDown)
      }
    }, [handleMouseDown])

    const onSliderChange = useCallback(
      event => {
        onEditorChange({ zoomScale: Number(event.target.value) })
        if (displayOverlay) {
          setDisplayOverlay(false)
        }
      },
      [displayOverlay, onEditorChange]
    )

    const onChangeRatio = useCallback(
      event => {
        const { id } = event.currentTarget

        onEditorChange({ aspectRatio: id })

        if (displayOverlay) {
          setDisplayOverlay(false)
        }
      },
      [displayOverlay, onEditorChange]
    )

    const ratioButtons = useMemo(
      () => [
        {
          label: '1 : 1',
          icon: AspectRatioSquareIcon,
          iconClassName: styles['ratio-icon-square'],
          isSelected: editorOptions.aspectRatio === ASPECT_RATIO.SQUARE,
          id: ASPECT_RATIO.SQUARE,
        },
        {
          label: '3 : 2',
          icon: AspectRatioRectangleIcon,
          iconClassName: styles['ratio-icon-rectangle'],
          isSelected: editorOptions.aspectRatio === ASPECT_RATIO.RECTANGLE,
          id: ASPECT_RATIO.RECTANGLE,
        },
      ],
      [editorOptions.aspectRatio]
    )

    const renderAspectRatioButtons = useMemo(
      () => (
        <div className={styles['ratio-buttons-container']}>
          {!isOnlySquaredPicture &&
            ratioButtons.map(button => (
              <div
                className={classnames(styles['ratio-button-container'], {
                  [styles['selected-ratio']]: button.isSelected,
                })}
                key={button.id}
              >
                <button
                  type="button"
                  className={styles['aspect-ratio-button']}
                  aria-label={`Aspect ratio ${button.label}`}
                  onClick={onChangeRatio}
                  id={button.id}
                >
                  <Svg icon={button.icon} className={button.iconClassName} />
                </button>
                <p className={styles['ratio-label']}>{button.label}</p>
              </div>
            ))}
        </div>
      ),
      [isOnlySquaredPicture, onChangeRatio, ratioButtons]
    )

    return (
      <div className={styles['picture-editor']}>
        <div className={styles['editor-wrapper']}>
          <AvatarEditor
            ref={ref}
            image={picture}
            {...editorConfig}
            onPositionChange={onPositionChange}
            className={styles.editor}
          />
          {displayOverlay && (
            <button
              className={classnames(styles.overlay, {
                [styles['overlay-square']]: editorOptions.aspectRatio === ASPECT_RATIO.SQUARE,
                [styles['overlay-rectangle']]: editorOptions.aspectRatio === ASPECT_RATIO.RECTANGLE,
              })}
              onMouseDown={onOverlayRemove}
              ref={overlayRef}
              type="button"
            >
              <Svg icon={RepositionIcon} className={styles['reposition-icon']} />
              <p className={styles['pan-image']}>Pan image</p>
              <p className={styles['pan-image-description']}>
                Click and hold to move the image inside the square
              </p>
            </button>
          )}
        </div>
        <div className={styles['slider-container']}>
          <Svg icon={ImageResizeSmallIcon} className={styles['icon-small']} />
          <input
            type="range"
            min="0.1"
            max="2.5"
            step="0.01"
            value={editorOptions.zoomScale}
            onChange={onSliderChange}
            className={styles.slider}
          />
          <Svg icon={ImageResizeBigIcon} className={styles['icon-big']} />
        </div>
        {renderAspectRatioButtons}
      </div>
    )
  }
)

PictureAdjustment.propTypes = {
  picture: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  editorOptions: PropTypes.shape({
    zoomScale: PropTypes.number,
    position: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    aspectRatio: PropTypes.string,
  }).isRequired,
  onEditorChange: PropTypes.func.isRequired,
  isOnlySquaredPicture: PropTypes.bool,
}

PictureAdjustment.defaultProps = {
  isOnlySquaredPicture: false,
}
export default React.memo(PictureAdjustment)
