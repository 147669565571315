import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { getTieredPlansSelector } from '_modules/payments/selectors'
import { isLoadingGetCheckoutUrlSelector } from '_modules/user/selectors'
import SliderBar from '_components/ui-kit/slider'
import { currencyFormat } from '_utils/helpers'
import { TIERED_PLAN_OPTIONS } from '_utils/constants'
import Toggle from '_components/ui-kit/toggle'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import { FIELDS_NAMES } from '_constants/create-a-team'

import styles from './styles.css'

const TeamSettings = ({
  externalCreateTeam,
  onClickBack,
  selectedPlan,
  quantity,
  handleChange,
  handleCheckout,
  minQuantity,
}) => {
  const tieredPlans = useSelector(getTieredPlansSelector)
  const isLoadingGetCheckoutUrl = useSelector(isLoadingGetCheckoutUrlSelector)

  const maxAmountMembers = useMemo(() => {
    const plan = tieredPlans[selectedPlan]
    if (plan && plan.size) {
      return plan.get('tiers')[plan.get('tiers').length - 1].interval[0]
    }
    return 1
  }, [selectedPlan, tieredPlans])

  const handleToggle = useCallback(
    event => {
      const { value, name } = event.target
      handleChange(name, value)
    },
    [handleChange]
  )

  const getPrice = useMemo(() => {
    const plan = tieredPlans[selectedPlan]
    if (plan && plan.size) {
      const tier = plan
        .get('tiers')
        .find(item => Number(quantity) >= item.interval[0] && Number(quantity) <= item.interval[1])
      return currencyFormat(tier.price, true)
    }
    return 0
  }, [quantity, selectedPlan, tieredPlans])

  const getInterval = useMemo(() => {
    const plan = tieredPlans[selectedPlan]
    if (plan && plan.size) {
      if (plan.get('interval') === 'month') {
        return 'mo'
      }
      return 'yr'
    }

    return ''
  }, [selectedPlan, tieredPlans])

  const handleSliderChange = useCallback(
    event => {
      const { value, name } = event.target
      handleChange(name, value)
    },
    [handleChange]
  )

  return (
    <div className={externalCreateTeam ? styles['container-external'] : styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Team settings</h2>
        <p className={styles.subtitle}>Billing</p>
        <Toggle
          value={selectedPlan}
          handleToggle={handleToggle}
          options={TIERED_PLAN_OPTIONS}
          name={FIELDS_NAMES.SELECTED_PLAN}
          className={styles.toggle}
          classNameLabel={styles['toggle-label']}
        />
        <p className={styles.subtitle}>Number of team members</p>
        <SliderBar
          minValue={minQuantity}
          maxValue={maxAmountMembers}
          value={quantity}
          scale={1}
          onChange={handleSliderChange}
          label={quantity}
          className={styles.slider}
          color="linear-gradient(var(--seafoam-blue), var(--seafoam-blue))"
          name={FIELDS_NAMES.QUANTITY}
        />
        <p className={styles.text}>{`$${getPrice}/${getInterval} per user`}</p>
        <div className={styles['message-wrapper']}>
          Need more or have questions? <br />
          <span className={styles['message-us']} id="intercom-support">
            Send us a message
          </span>{' '}
          or Book a time with{' '}
          <a
            className={styles['message-us']}
            target="_blank"
            rel="noopener noreferrer"
            href="https://highnote.io/meetwithsales"
          >
            Sales
          </a>{' '}
          or{' '}
          <a
            className={styles['message-us']}
            target="_blank"
            rel="noopener noreferrer"
            href="https://highnote.io/meetwithsuccess"
          >
            Customer Success
          </a>
        </div>
        <div
          className={classnames(styles.actions, {
            [styles['actions-external']]: externalCreateTeam,
          })}
        >
          <Button
            className={styles.button}
            theme={BUTTON_THEME.GHOST}
            onClick={onClickBack}
            isLoading={isLoadingGetCheckoutUrl}
          >
            Back
          </Button>
          <Button
            className={styles.button}
            onClick={handleCheckout}
            isLoading={isLoadingGetCheckoutUrl}
          >
            Continue
          </Button>
        </div>
      </div>
      {!externalCreateTeam && (
        <div className={styles['side-bar']}>
          <div className={styles['container-image']} />
        </div>
      )}
    </div>
  )
}

TeamSettings.propTypes = {
  externalCreateTeam: PropTypes.bool,
  onClickBack: PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckout: PropTypes.func.isRequired,
  minQuantity: PropTypes.number.isRequired,
}

TeamSettings.defaultProps = {
  externalCreateTeam: false,
}

export default TeamSettings
