export const FIRST_QUESTION = 0

export const SECOND_QUESTION = 1

export const FOURTH_QUESTION = 3

export const LAST_QUESTION = 4

export const OTHER = 'Other'

export const REAL_ESTATE = 'real-estate'

export const YES = 'Yes'

export const NO = 'No'

export const URLS = ['usage', 'industry', 'brokerage', 'hear', 'team']

export const REGULAR_STEPS = 4

export const MAX_STEPS = 5

export const QUESTIONS = {
  usage: [
    {
      title: 'What will you mostly use Highnote for?',
      name: 'usage',
      type: 'checkbox',
      options: [
        {
          label: 'Work',
          value: 'Work',
        },
        {
          label: 'Personal',
          value: 'Personal',
        },
        {
          label: 'Education',
          value: 'Education',
        },
      ],
    },
  ],
  industry: [
    {
      title: 'What kind of work do you do?',
      name: 'industry',
      // NOTE: The values here are directly linked to the template categories in WordPress.
      options: [
        {
          label: 'Real Estate',
          value: REAL_ESTATE,
        },
        {
          label: 'Education',
          value: 'education',
        },
        {
          label: 'Sales',
          value: 'sales',
        },
        {
          label: 'Marketing',
          value: 'marketing',
        },
        {
          label: 'Coaching/Consulting',
          value: 'coaching',
        },
        {
          label: 'Freelancing/Professional Services',
          value: 'freelancers',
        },
        {
          label: 'Design and Media',
          value: 'media',
        },
        {
          label: 'Auto',
          value: 'auto',
        },
        {
          label: 'Finance/Mortgage',
          value: 'accounting-finance',
        },
        {
          label: 'Information Technology',
          value: 'information-technology',
        },
        {
          label: 'Other',
          value: OTHER,
        },
      ],
    },
  ],
  brokerage: [
    {
      title: 'Are you affiliated to a brokerage?',
      name: 'inBrokerage',
      type: 'boolean-radio',
      options: [
        {
          label: 'Yes',
          value: YES,
        },
        {
          label: 'No',
          value: NO,
        },
      ],
    },
    {
      title: 'Select which brokerage are you affiliated with',
      name: 'brokerage',
      type: 'select',
      placeholder: 'Select brokerage',
      options: [
        {
          label: 'Keller Williams',
          value: 'Keller Williams',
        },
        {
          label: 'RE/MAX',
          value: 'RE/MAX',
        },
        {
          label: 'Coldwell Banker',
          value: 'Coldwell Banker',
        },
        {
          label: 'eXp Realty',
          value: 'eXp Realty',
        },
        {
          label: 'Century 21',
          value: 'Century 21',
        },
        {
          label: 'Berkshire Hathaway',
          value: 'Berkshire Hathaway',
        },
        {
          label: 'ERA',
          value: 'ERA',
        },
        {
          label: 'Compass',
          value: 'Compass',
        },
        {
          label: "Shotheby's",
          value: "Shotheby's",
        },
        {
          label: 'Weichert',
          value: 'Weichert',
        },
        {
          label: 'Realty ONE Group',
          value: 'Realty ONE Group',
        },
        {
          label: 'BHG Real Estate',
          value: 'BHG Real Estate',
        },
        {
          label: 'Howard Hanna',
          value: 'Howard Hanna',
        },
        {
          label: 'Real Brokerage (REAL)',
          value: 'Real Brokerage (REAL)',
        },
        {
          label: 'Other',
          value: OTHER,
        },
      ],
    },
    {
      title: 'Tell us your affiliation',
      name: 'brokerageInput',
      type: 'input',
      placeholder: 'Name brokerage',
    },
  ],
  hear: [
    {
      title: 'How did you hear about Highnote?',
      name: 'hearAboutHighnote',
      options: [
        {
          label: 'Online search',
          value: 'Online search',
        },
        {
          label: 'It was recommended',
          value: 'It was recommended',
        },
        {
          label: 'Someone shared a presentation',
          value: 'Someone shared a presentation',
        },
        {
          label: 'An ad',
          value: 'An ad',
        },
      ],
    },
  ],
  team: [
    {
      title: 'Do you work with a team?',
      name: 'worksInTeam',
      type: 'boolean-radio',
      options: [
        {
          label: 'Yes',
          value: YES,
        },
        {
          label: 'No',
          value: NO,
        },
      ],
    },
    {
      title: 'Do you need bulk discount for 5 or more accounts?',
      name: 'needsDiscount',
      type: 'boolean-radio',
      options: [
        {
          label: 'Yes',
          value: YES,
        },
        {
          label: 'No',
          value: NO,
        },
      ],
    },
    {
      title: "Tell us your phone and we'll contact you about it!",
      name: 'phoneNumber',
      type: 'input',
      placeholder: 'Phone number',
    },
  ],
}

export const ONBOARDING_VIDEO_URL = 'https://vimeo.com/970911058'

export const QUICK_GUIDE_URL = 'https://help.highnote.io/en/articles/8835971-quick-start-guide'
