import React, { useCallback, useMemo, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import Modal from '_components/ui-kit/modal'
import {
  isTrialActiveSelector,
  isFreeUserSelector,
  userSelector,
  getCheckoutUrlSelector,
} from '_modules/user/selectors'
import { getPlanById } from '_modules/payments/actions'
import { createTeamPayment, CREATE_TEAM_PAYMENT } from '_modules/user/actions'
import { STEPS_NAMES, INTERNAL_CREATE_TEAM_STEP_ORDER } from '_constants/create-a-team'
import CreateTeamStep from '_components/create-a-team-steps/create-team-step'
import TeamSettings from '_components/create-a-team-steps/team-settings'
import UpgradeToContinue from '_components/create-a-team-steps/upgrade-to-continue'
import { PLANS_NAMES } from '_utils/constants'
import { getTieredPlansSelector } from '_modules/payments/selectors'
import useFetchCall from '_hooks/use-fetch-call'

import {
  reducer,
  INITIAL_STATE,
  UPDATE_STATE,
  UPDATE_TEAM_NAME,
  UPDATE_STATE_ERROR,
} from './reducer'
import styles from './styles.css'

const CreateTeamModal = ({ isOpen, onClose, previousInfo }) => {
  const isTrialActive = useSelector(isTrialActiveSelector)
  const isFreemiumUser = useSelector(isFreeUserSelector)
  const user = useSelector(userSelector)
  const tieredPlans = useSelector(getTieredPlansSelector)
  const checkoutUrl = useSelector(getCheckoutUrlSelector)

  const getInitialState = () => {
    if (previousInfo) {
      return {
        ...INITIAL_STATE,
        teamName: previousInfo.teamName,
        selectedPlan: previousInfo.plan,
        quantity: previousInfo.quantity,
        currentStep: STEPS_NAMES.TEAM_SETTINGS,
      }
    }
    return {
      ...INITIAL_STATE,
      currentStep: isFreemiumUser ? STEPS_NAMES.UPGRADE_TO_CONTINUE : STEPS_NAMES.CREATE_TEAM,
      quantity: 2,
    }
  }

  const [state, localDispatch] = useReducer(reducer, getInitialState())

  const STEPS = useMemo(() => {
    if (isFreemiumUser || isTrialActive) {
      return INTERNAL_CREATE_TEAM_STEP_ORDER
    }

    return INTERNAL_CREATE_TEAM_STEP_ORDER.filter(item => item !== STEPS_NAMES.UPGRADE_TO_CONTINUE)
  }, [isFreemiumUser, isTrialActive])

  const dispatch = useDispatch()

  const isInValidState = useCallback(() => {
    if (
      state.errors[state.currentStep] &&
      Object.keys(state.errors[state.currentStep]).length > 0
    ) {
      return true
    }
    return false
  }, [state])

  const onClickNext = useCallback(() => {
    const currentStepIndex = STEPS.findIndex(step => step === state.currentStep)
    const nextStepIndex = currentStepIndex + 1

    if (isInValidState()) {
      return
    }

    localDispatch({ type: UPDATE_STATE, payload: { currentStep: STEPS[nextStepIndex] } })
  }, [STEPS, isInValidState, state.currentStep])

  const onClickBack = useCallback(() => {
    const currentStepIndex = STEPS.findIndex(step => step === state.currentStep)
    const nextStepIndex = currentStepIndex - 1
    localDispatch({ type: UPDATE_STATE, payload: { currentStep: STEPS[nextStepIndex] } })
  }, [STEPS, state.currentStep])

  const onClickCheckout = useCallback(() => {
    const plan = tieredPlans[state.selectedPlan]
    if (plan) {
      const payload = {
        companyName: state.teamName,
        planId: plan.get('id'),
        quantity: state.quantity,
        cancelUrl: `${window.location.origin}?plan=${state.selectedPlan}&quantity=${
          state.quantity
        }&teamName=${encodeURIComponent(state.teamName)}`,
      }

      dispatch(createTeamPayment(payload))
    }
  }, [dispatch, state.quantity, state.selectedPlan, state.teamName, tieredPlans])

  const handleSuccessCreateTeamPayment = useCallback(() => {
    if (checkoutUrl) {
      window.location.replace(checkoutUrl)
    }
  }, [checkoutUrl])
  const handleError = useCallback(() => {}, [])

  useFetchCall(CREATE_TEAM_PAYMENT, handleSuccessCreateTeamPayment, handleError)

  const handleChangeTeamName = useCallback(value => {
    localDispatch({ type: UPDATE_TEAM_NAME, payload: value })
  }, [])

  const handleUpdateErrorsState = useCallback(error => {
    localDispatch({ type: UPDATE_STATE_ERROR, payload: error })
  }, [])

  const handleChange = useCallback((name, value) => {
    localDispatch({ type: UPDATE_STATE, payload: { [name]: value } })
  }, [])

  const renderStep = useMemo(() => {
    switch (state.currentStep) {
      case STEPS_NAMES.CREATE_TEAM:
        return (
          <CreateTeamStep
            setTeamName={handleChangeTeamName}
            values={state}
            errors={state.errors[STEPS_NAMES.CREATE_TEAM]}
            handleErrors={handleUpdateErrorsState}
            onClickNext={onClickNext}
          />
        )
      case STEPS_NAMES.TEAM_SETTINGS:
        return (
          <TeamSettings
            onClickBack={onClickBack}
            handleCheckout={onClickCheckout}
            handleChange={handleChange}
            quantity={state.quantity}
            selectedPlan={state.selectedPlan}
            minQuantity={2}
          />
        )
      case STEPS_NAMES.UPGRADE_TO_CONTINUE:
        return <UpgradeToContinue onClickNext={onClickNext} handLeClose={onClose} />
      default:
        return (
          <CreateTeamStep
            setTeamName={handleChangeTeamName}
            values={state}
            errors={state.errors[STEPS_NAMES.CREATE_TEAM]}
            handleErrors={handleUpdateErrorsState}
            onClickNext={onClickNext}
          />
        )
    }
  }, [
    state,
    handleChangeTeamName,
    handleUpdateErrorsState,
    onClickNext,
    onClickBack,
    onClickCheckout,
    handleChange,
    onClose,
  ])

  useEffect(() => {
    if (user) {
      localDispatch({
        type: UPDATE_STATE,
        payload: { email: user.get('email') },
      })
    }
  }, [user])

  useEffect(() => {
    dispatch(getPlanById({ plan: [PLANS_NAMES.TIERED_MONTHLY, PLANS_NAMES.TIERED_YEARLY] }))
  }, [dispatch])

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose} isClosable>
      <div className={styles['modal-body']}>{renderStep}</div>
    </Modal>
  )
}

CreateTeamModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  previousInfo: PropTypes.shape({
    teamName: PropTypes.string,
    plan: PropTypes.string,
    quantity: PropTypes.number,
  }),
}

CreateTeamModal.defaultProps = {
  previousInfo: undefined,
}

export default React.memo(CreateTeamModal)
