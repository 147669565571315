export const OPEN_ALERT = 'OPEN_ALERT'
export const CLOSE_ALERT = 'CLOSE_ALERT'

export const openAlert = payload => dispatch =>
  dispatch({
    type: OPEN_ALERT,
    payload,
  })

export const closeAlert = () => dispatch =>
  dispatch({
    type: CLOSE_ALERT,
  })
