import { Map } from 'immutable'

import { PLANS_NAMES_KEYS } from '_utils/constants'
import { formatTiersPrice } from '_utils/helpers'

import {
  ADD_CREDIT_CARD,
  START_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION,
  VALIDATE_COUPON_CODE,
  GET_CREDIT_CARD,
  GET_PLAN_BY_ID,
} from './actions'

export const isAddCreditCardLoadingSelector = state => !!state.loading.get(ADD_CREDIT_CARD.ACTION)

export const addCreditCardErrorSelector = state => state.error.get(ADD_CREDIT_CARD.ACTION, Map())

export const lastAddedCardIdSelector = state => state.payments.get('lastAddedCardId')

export const discountCodeValueSelector = state =>
  state.payments.getIn(['discountValue', 'percentOff'])

export const discountCodeSelector = state => state.payments.get('discountValue')

export const isValidatingCouponCodeSelector = state =>
  !!state.loading.get(VALIDATE_COUPON_CODE.ACTION)

export const validateCouponCodeErrorSelector = state =>
  state.error.get(VALIDATE_COUPON_CODE.ACTION, Map())

export const isStartSubscriptionLoadingSelector = state =>
  !!state.loading.get(START_SUBSCRIPTION.ACTION)

export const startSubscriptionErrorSelector = state =>
  state.error.get(START_SUBSCRIPTION.ACTION, Map())

export const getDefaultCreditCardSelector = state =>
  state.payments.get('cardList').find(card => card.get('default'))

export const isCancelSubscriptionLoadingSelector = state =>
  !!state.loading.get(CANCEL_SUBSCRIPTION.ACTION)

export const cancelSubscriptionErrorSelector = state =>
  state.error.get(CANCEL_SUBSCRIPTION.ACTION, Map())

export const isChangeSubscriptionLoadingSelector = state =>
  !!state.loading.get(CHANGE_SUBSCRIPTION.ACTION)

export const changeSubscriptionErrorSelector = state =>
  state.error.get(CHANGE_SUBSCRIPTION.ACTION, Map())

export const userSubscriptionPlanSelector = state => state.user.get('subscription_plan')

export const getCreditCardLoadingSelector = state => !!state.loading.get(GET_CREDIT_CARD.ACTION)

export const skipUpgradeBannerSelector = state => state.payments.get('skipUpgradeBanner')

export const getPlanLoadingSelector = state => !!state.loading.get(GET_PLAN_BY_ID.ACTION)

export const getPlanErrorSelector = state => state.error.get(GET_PLAN_BY_ID.ACTION, Map())

export const getCurrentPlanByName = (state, key) => state.payments.getIn(['currentPlans', `${key}`])

export const getCoreMonthlyCurrentPlan = state =>
  state.payments.getIn(['currentPlans', PLANS_NAMES_KEYS.CORE_MONTHLY])

export const getCoreYearlyCurrentPlan = state =>
  state.payments.getIn(['currentPlans', PLANS_NAMES_KEYS.CORE_YEARLY])

export const getTieredMonthlyCurrentPlan = state => {
  const tiers = state.payments.getIn(['plan', PLANS_NAMES_KEYS.TIERED_MONTHLY, 'tiers'])
  if (tiers) {
    const formattedTiers = formatTiersPrice(tiers.toJS())
    return state.payments
      .getIn(['plan', PLANS_NAMES_KEYS.TIERED_MONTHLY])
      .set('tiers', formattedTiers)
  }

  return Map()
}

export const getTieredYearlyCurrentPlan = state => {
  const tiers = state.payments.getIn(['plan', PLANS_NAMES_KEYS.TIERED_YEARLY, 'tiers'])
  if (tiers) {
    const formattedTiers = formatTiersPrice(tiers.toJS())

    return state.payments
      .getIn(['plan', PLANS_NAMES_KEYS.TIERED_YEARLY])
      .set('tiers', formattedTiers)
  }

  return Map()
}

export const getTieredPlansSelector = state => {
  const tieredMonthlyPlan = getTieredMonthlyCurrentPlan(state)
  const tieredYearlyPlan = getTieredYearlyCurrentPlan(state)

  return {
    [PLANS_NAMES_KEYS.TIERED_MONTHLY]: tieredMonthlyPlan,
    [PLANS_NAMES_KEYS.TIERED_YEARLY]: tieredYearlyPlan,
  }
}

export const getPlanSelector = state => state.payments.get('plan')

export const getPlanForPayment = (state, planId) => {
  if (planId) {
    return state.payments.get('plan')
  }

  return state.payments.getIn(['currentPlans', PLANS_NAMES_KEYS.CORE_MONTHLY])
}

export const getCrossSellSelector = state =>
  state.payments
    .getIn(
      ['plan', 'crossSell'],
      Map({
        priceData: [],
        packages: [],
      })
    )
    .toJS()
