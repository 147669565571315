import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.css'

const DropdownEnhanceOption = ({
  labelText,
  labelEmoji,
  arialLabel,
  onChange,
  checked,
  id,
  name,
  value,
}) => (
  <div className={styles.container}>
    <input
      className={styles.input}
      type="radio"
      checked={checked}
      id={id}
      onChange={onChange}
      name={name}
      value={value}
    />
    <label htmlFor={id} className={styles.label}>
      <span role="img" aria-label={arialLabel} className={styles.emoji}>
        {labelEmoji}
      </span>{' '}
      {labelText}
    </label>
  </div>
)

export default DropdownEnhanceOption

DropdownEnhanceOption.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  labelEmoji: PropTypes.string.isRequired,
  arialLabel: PropTypes.string.isRequired,
}

DropdownEnhanceOption.defaultProps = {
  checked: false,
}
