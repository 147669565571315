import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Modal from '_components/ui-kit/modal'
import {
  PRESENTATION_STYLE_BARCELONA,
  PRESENTATION_STYLE_STANDARD,
} from '_constants/presentation-styles'
import { getPresentationAssetSelector } from '_modules/assets/selectors'
import { presentationSelector } from '_modules/presentations/selectors'
import StandardStyleCard from '_views/presentation-preview-new/standard-style-section'
import BarcelonaStyleCard from '_views/presentation-preview-new/barcelona-style-section'

import styles from './styles.css'

const PreviewAssetModal = ({ presentationId, assetId, onClose }) => {
  const presentation = useSelector(state => presentationSelector(state, presentationId))
  const asset = useSelector(state => getPresentationAssetSelector(state, assetId))

  const renderAssetCard = useMemo(
    () => ({
      [PRESENTATION_STYLE_STANDARD]: (
        <StandardStyleCard
          key={`asset-${asset.get('id')}`}
          asset={asset}
          presentation={presentation}
        />
      ),
      [PRESENTATION_STYLE_BARCELONA]: (
        <BarcelonaStyleCard
          className={styles.card}
          key={`asset-${asset.get('id')}`}
          asset={asset}
          presentation={presentation}
        />
      ),
    }),
    [presentation, asset]
  )

  return (
    <Modal className={styles['preview-asset-modal']} isOpen isClosable onClose={onClose}>
      {renderAssetCard[presentation.get('style')]}
    </Modal>
  )
}

PreviewAssetModal.propTypes = {
  assetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  presentationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(PreviewAssetModal)
