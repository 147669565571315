import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Modal from 'react-modal'
import memoizeOne from 'memoize-one'
import mime from 'mime-types'
import { connect } from 'react-redux'

import FileUpload from '_hocs/file-upload'
import { openAlert } from '_modules/alert-message/actions'
import CloseIcon from '_assets/icons/close.svg'
import GalleryIcon from '_assets/icons/gallery.svg'
import UploadIcon from '_assets/icons/upload.svg'
import CloudIcon from '_assets/icons/cloud.svg'
import UnsplashIcon from '_assets/icons/unsplash.svg'
import VideoIcon from '_assets/icons/video.svg'
import withViewportSize from '_hocs/with-viewport-size'
import { imageExtensions } from '_utils/files'
import loadImage from '_utils/load-image'
import Svg from '_components/svg'
import Tag from '_components/tag'

import Gallery from './gallery'
import styles from './styles.css'
import Upload from './upload'
import Unsplash from './unsplash'
import Video from './video'
import CloudOptionModal from './cloud'

const MENU = {
  GALLERY: 'GALLERY',
  UPLOAD: 'UPLOAD',
  CLOUD: 'CLOUD',
  UNSPLASH: 'UNSPLASH',
  VIDEO: 'VIDEO',
}

const getFileTypes = memoizeOne(extensions =>
  extensions.map(extension => ({
    extension,
    mimeType: mime.lookup(extension),
  }))
)

const getMimeTypes = memoizeOne(extensions => extensions.map(extension => mime.lookup(extension)))

const mapDispatchToProps = { openAlert }

const mapStateToProps = ({ user }) => ({ isBetaTester: user.get('is_beta'), reduxState: user })

class MediaLibrary extends PureComponent {
  handleImageChange = memoizeOne(validateFile => event => {
    event.preventDefault()

    const { selectFile } = this.props
    const file = event.target.files[0]

    if (!validateFile(file)) {
      return
    }

    if (file.type === 'image/tiff') {
      selectFile(null, file)
      return
    }

    loadImage(file, selectFile)
  })

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    dismiss: PropTypes.func.isRequired,
    changeColor: PropTypes.func.isRequired,
    changeVideo: PropTypes.func.isRequired,
    openAlert: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired,
    isSmallViewport: PropTypes.bool.isRequired,
    onToggleIsChangeFile: PropTypes.func,
    presentationId: PropTypes.string,
    presentationTitle: PropTypes.number,
    backgroundVideoUrl: PropTypes.string,
    isBetaTester: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    onToggleIsChangeFile: undefined,
    presentationId: undefined,
    presentationTitle: undefined,
    backgroundVideoUrl: null,
  }

  state = {
    fileRef: undefined,
    selectedMenu: MENU.UNSPLASH,
    menuItems: [
      { icon: UnsplashIcon, title: MENU.UNSPLASH },
      { icon: GalleryIcon, title: MENU.GALLERY },
      { icon: UploadIcon, title: MENU.UPLOAD },
      { icon: CloudIcon, title: MENU.CLOUD },
    ],
  }

  componentDidMount() {
    if (this.props.isBetaTester || process.env.ENVIRONMENT === 'staging') {
      this.setState(prevState => ({
        menuItems: [...prevState.menuItems, { icon: VideoIcon, title: MENU.VIDEO, new: true }],
      }))
    }
  }

  onDownloadFileComplete = file => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const { selectFile } = this.props
      selectFile(reader.result, file)
    }

    reader.readAsDataURL(file)
  }

  setFileRef = ref => {
    this.setState({
      fileRef: ref,
    })
  }

  changeMenu = newMenu => () => {
    this.setState({
      ...(newMenu === MENU.CLOUD
        ? { cloudMenu: newMenu, selectedMenu: null }
        : { selectedMenu: newMenu, cloudMenu: null }),
    })
  }

  selectImage = () => {
    this.state.fileRef.click()
  }

  selectColor = event => {
    const { id } = event.target
    this.props.changeColor(id)
    this.props.dismiss(event)
  }

  selectVideo = videoUrl => {
    const { changeVideo, dismiss } = this.props
    changeVideo(videoUrl)
    dismiss()
  }

  removeOutline = event => {
    event.preventDefault()
  }

  render() {
    const {
      isOpen,
      dismiss,
      openAlert: dispatchOpenAlert,
      isSmallViewport,
      onToggleIsChangeFile,
      presentationId,
      backgroundVideoUrl,
    } = this.props
    const { cloudMenu, selectedMenu, menuItems } = this.state

    const mimeTypes = getMimeTypes(imageExtensions)
    return (
      <Modal
        bodyOpenClassName={styles['no-scroll']}
        className={styles.modal}
        contentLabel="Unsplash library"
        htmlOpenClassName={styles['no-scroll']}
        isOpen={isOpen}
        onRequestClose={dismiss}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <div className={styles.header}>
          <p className={styles.title}>Image Library</p>
          <button
            type="button"
            aria-label="Close"
            className={styles['close-button']}
            onClick={dismiss}
            onMouseDown={this.removeOutline}
          >
            <svg aria-hidden="true" className={styles['close-icon']} focusable="false">
              <use xlinkHref={CloseIcon} />
            </svg>
          </button>
        </div>
        <div className={styles.menu}>
          {menuItems.map(item => (
            <button
              id={item.title}
              key={item.title}
              type="button"
              aria-label={item.title}
              className={classNames(styles['menu-button'], {
                [styles.selected]:
                  item.title === selectedMenu || (item.title === MENU.CLOUD && cloudMenu),
              })}
              onClick={this.changeMenu(item.title)}
            >
              <Svg
                className={classNames(styles.icon, {
                  [styles['unsplash-icon']]: item.title === MENU.UNSPLASH,
                  [styles['cloud-icon']]: item.title === MENU.CLOUD,
                })}
                icon={item.icon}
              />
              {!isSmallViewport && item.title}
              {item.new && (
                <Tag className={styles['tag-beta']} textClassName={styles['tag-text']}>
                  Beta
                </Tag>
              )}
            </button>
          ))}
          {cloudMenu && (
            <CloudOptionModal
              extensions={imageExtensions}
              mimeTypes={mimeTypes}
              downloadDropboxFile={this.downloadDropboxFile}
              onGoogleDriveAction={this.onGoogleDriveAction}
              downloadBoxFile={this.downloadBoxFile}
            />
          )}
        </div>
        <div className={styles.content}>
          <FileUpload
            fileTypes={getFileTypes(imageExtensions)}
            googleDriveExportMimeType="image/jpeg"
            onDownloadFileComplete={this.onDownloadFileComplete}
            openAlert={dispatchOpenAlert}
            // eslint-disable-next-line react/jsx-no-bind
            render={({
              downloadBoxFile,
              downloadDropboxFile,
              downloadUnsplashFile,
              onGoogleDriveAction,
              validateFile,
            }) => {
              this.downloadDropboxFile = downloadDropboxFile
              this.onGoogleDriveAction = onGoogleDriveAction
              this.downloadBoxFile = downloadBoxFile

              switch (selectedMenu) {
                case MENU.UPLOAD:
                  return (
                    <Upload
                      onClick={this.selectImage}
                      onChange={this.handleImageChange(validateFile)}
                      accept={mimeTypes.join(',')}
                      setRef={this.setFileRef}
                    />
                  )
                case MENU.UNSPLASH:
                  return (
                    <Unsplash
                      onRequestClose={dismiss}
                      downloadUnsplashFile={downloadUnsplashFile}
                      onToggleCoverChangingLoading={onToggleIsChangeFile}
                      presentationId={presentationId}
                      presentationTitle={this.props.presentationTitle}
                    />
                  )
                case MENU.VIDEO:
                  return <Video onClick={this.selectVideo} currentUrl={backgroundVideoUrl} />
                default:
                  return <Gallery onClick={this.selectColor} />
              }
            }}
          />
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withViewportSize(MediaLibrary))
