import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import Autosuggest from 'react-autosuggest'
import debounce from 'lodash.debounce'

import RemoveIcon from '_assets/icons/remove_asset_button.svg'
import { requestUserAutocomplete, clearUserAutocomplete } from '_modules/autocomplete/actions'
import AvatarPlaceholder from '_assets/avatar@3x.png'

import styles from './styles.css'

const EmailInput = ({
  className,
  emailId,
  nameId,
  number,
  name,
  namePlaceholder,
  nameError,
  email,
  emailPlaceholder,
  emailError,
  removeInput,
  changeName,
  changeEmail,
  changeNameAndEmail,
  useSuggestions,
  isOnlyRecipient,
}) => {
  const suggestions = useSelector(state => state.autocomplete)

  const dispatch = useDispatch()

  const onSuggestionsClearRequested = useCallback(() => {
    clearUserAutocomplete()
  }, [])

  const onSuggestionsFetchRequested = useCallback(
    debounce(({ value, reason }) => {
      if (reason === 'input-changed') {
        dispatch(requestUserAutocomplete(value))
      }
    }, 300),
    [dispatch]
  )

  const onSuggestionFieldChange = useCallback(
    (event, { newValue, method }) => {
      if (method === 'type') {
        changeName(newValue, number)
      }
    },
    [changeName, number]
  )

  const onSuggestionSelected = useCallback(
    (event, { suggestion }) => {
      changeNameAndEmail(suggestion.get('name'), suggestion.get('email'), number)
    },
    [changeNameAndEmail, number]
  )

  const getSuggestionValue = useCallback(suggestion => suggestion.get('name'), [])

  const setName = useCallback(
    event => {
      const { value } = event.target
      changeName(value, number)
    },
    [changeName, number]
  )

  const setEmail = useCallback(
    event => {
      const { value } = event.target
      changeEmail(value, number)
    },
    [changeEmail, number]
  )

  const removeEmailInput = useCallback(() => {
    removeInput(number)
  }, [number, removeInput])

  const renderSuggestion = useCallback((suggestion, { isHighlighted }) => {
    const suggestionClass = isHighlighted ? styles.highlight : ''
    return (
      <div className={classnames(styles.autocomplete, suggestionClass)}>
        <img
          src={suggestion.get('picture') || AvatarPlaceholder}
          alt="User Avatar"
          className={styles.avatar}
        />
        <p className={styles.name}>{suggestion.get('name')}</p>
      </div>
    )
  }, [])

  const renderSuggestionsContainer = useCallback(
    ({ containerProps, children }) => (
      <div {...containerProps} className={classnames(styles['autocomplete-container'])}>
        {children}
      </div>
    ),
    []
  )

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.temp1}>
        <div className={styles['name-container']}>
          <div className={styles['number-circle']}>
            <p className={styles.number}>{number}</p>
          </div>
          <div className={styles['input-container']}>
            {useSuggestions ? (
              <Autosuggest
                suggestions={suggestions.toArray().slice(0, 5)}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                  id: nameId,
                  placeholder: namePlaceholder,
                  className: classnames(styles['name-input'], [nameError && styles['input-error']]),
                  value: name,
                  onChange: onSuggestionFieldChange,
                }}
                renderSuggestionsContainer={renderSuggestionsContainer}
              />
            ) : (
              <input
                className={classnames(styles['name-input'], [nameError && styles['input-error']])}
                id={nameId}
                onChange={setName}
                value={name}
                placeholder={namePlaceholder}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.temp2}>
        <div className={styles['email-container']}>
          <div className={styles['input-container']}>
            <input
              className={classnames(styles['name-input'], [emailError && styles['input-error']])}
              id={emailId}
              onChange={setEmail}
              value={email}
              placeholder={emailPlaceholder}
            />
          </div>
          <div className={styles.temp3}>
            <button className={styles['remove-button']} onClick={removeEmailInput} type="button">
              <svg alt="media" className={styles.remove}>
                {!isOnlyRecipient && <use xlinkHref={RemoveIcon} />}
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

EmailInput.propTypes = {
  className: PropTypes.string,
  emailId: PropTypes.string,
  nameId: PropTypes.string,
  number: PropTypes.number,
  name: PropTypes.string,
  namePlaceholder: PropTypes.string,
  nameError: PropTypes.bool,
  email: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  emailError: PropTypes.bool,
  removeInput: PropTypes.func,
  changeName: PropTypes.func,
  changeEmail: PropTypes.func,
  changeNameAndEmail: PropTypes.func,
  useSuggestions: PropTypes.bool,
  requestUserAutocomplete: PropTypes.func,
  clearUserAutocomplete: PropTypes.func,
  isOnlyRecipient: PropTypes.bool,
}

EmailInput.defaultProps = {
  className: undefined,
  emailId: undefined,
  nameId: undefined,
  number: 1,
  name: '',
  namePlaceholder: '',
  nameError: false,
  email: '',
  emailPlaceholder: '',
  emailError: false,
  removeInput: () => {},
  changeName: () => {},
  changeEmail: () => {},
  changeNameAndEmail: () => {},
  useSuggestions: false,
  requestUserAutocomplete: () => {},
  clearUserAutocomplete: () => {},
  isOnlyRecipient: false,
}

export default EmailInput
