import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Modal from 'react-modal'
import ReactGA from 'react-ga'

import { GA_ENABLED } from '_config/environment'
import Button, { ButtonTheme } from '_components/button'
import EditIcon from '_assets/icons/edit-icon.svg'
import CloseIcon from '_assets/icons/close.svg'

import styles from './styles.css'

const EditSharedPresentationModal = ({ dismiss }) => {
  useEffect(() => {
    if (GA_ENABLED) {
      ReactGA.modalview(`${window.location.pathname}/delete-modal`)
    }
  }, [])

  return (
    <Modal
      className={styles.modal}
      contentLabel="Edit Presentation"
      overlayClassName={styles.overlay}
      isOpen
      bodyOpenClassName={styles['disable-body-scroll']}
      ariaHideApp={false}
    >
      <div className={styles['title-container']}>
        <svg aria-hidden="true" className={styles['edit-icon']}>
          <use xlinkHref={EditIcon} />
        </svg>
        <h1 className={styles.title}>Edit Presentation</h1>
        <button type="button" onClick={dismiss} className={styles['close-button']}>
          <svg alt="close" className={styles['close-icon']}>
            <use xlinkHref={CloseIcon} />
          </svg>
        </button>
      </div>
      <div className={styles['message-container']}>
        <p className={styles.message}>
          Just to let you know, <br />
          you have already shared this presentation
        </p>
        <p className={styles.description}>
          Changes made to this presentation will be visible for all the recipients who you have
          shared this presentation with.
        </p>
      </div>
      <div className={styles['buttons-container']}>
        <Button
          href="/presentations"
          theme={ButtonTheme.TRANSPARENT}
          classNames={styles['cancel-button']}
        >
          CANCEL
        </Button>
        <Button onClick={dismiss} classNames={styles['edit-button']}>
          EDIT
        </Button>
      </div>
    </Modal>
  )
}

EditSharedPresentationModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
}

export default React.memo(EditSharedPresentationModal)
