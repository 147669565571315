import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close-masonry.svg'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import Modal from '_components/ui-kit/modal'

import styles from './styles.css'

const ConfirmationModal = ({
  image,
  title,
  description,
  isOpen,
  isLoading,
  isClosable,
  onCloseText,
  onFinishText,
  onClose,
  onFinish,
  classNames,
  modalClassNames,
  classNameDescription,
  ...restProps
}) => (
  <Modal className={classnames(styles.modal, modalClassNames)} isOpen={isOpen} {...restProps}>
    <section className={styles['modal-container']}>
      {isClosable && (
        <button
          aria-label="close modal"
          onClick={onClose}
          className={styles['close-button']}
          type="button"
        >
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={CloseIcon} />
          </svg>
        </button>
      )}
      {image && (
        <svg alt="Confirmation Image" className={styles.image}>
          <use xlinkHref={image} />
        </svg>
      )}
      <h1 className={styles.title}>{title}</h1>
      <p className={classnames(classNameDescription, styles.description)}>{description}</p>
    </section>
    <div className={styles['buttons-container']}>
      <Button
        onClick={onClose}
        disabled={isLoading}
        theme={BUTTON_THEME.LINK_BUTTON}
        className={styles['close-button-transparent-background']}
      >
        {onCloseText}
      </Button>
      {onFinish && (
        <Button
          onClick={onFinish}
          isLoading={isLoading}
          className={classnames(classNames, styles['edit-button'])}
        >
          {onFinishText}
        </Button>
      )}
    </div>
  </Modal>
)

ConfirmationModal.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isClosable: PropTypes.bool,
  onCloseText: PropTypes.string.isRequired,
  onFinishText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  classNames: PropTypes.string,
  modalClassNames: PropTypes.string,
  classNameDescription: PropTypes.string,
}

ConfirmationModal.defaultProps = {
  image: undefined,
  description: null,
  classNames: null,
  isLoading: false,
  isClosable: false,
  onFinishText: '',
  onFinish: null,
  modalClassNames: null,
  classNameDescription: null,
}

export default React.memo(ConfirmationModal)
