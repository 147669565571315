/* eslint-disable */
// any index.js / server.js common things should be done here
// e.g
// - set moment locale
// - import some global css

import 'intersection-observer'
import 'normalize.css'

import '_styles/reset.css'
// import ''
import '_styles/tooltip-themes.css'

import '_styles/settings.css'

// eslint-disable-next-line
String.prototype.capitalize = function(lower) {
  return (lower ? this.toLowerCase() : this).replace(/(?:^|\s)\S/g, a => a.toUpperCase())
}

if (typeof HTMLCanvasElement !== 'undefined') {
  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value(callback, type, quality) {
        const canvas = this
        setTimeout(() => {
          const binStr = atob(canvas.toDataURL(type, quality).split(',')[1])
          const len = binStr.length
          const arr = new Uint8Array(len)

          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i)
          }

          callback(new Blob([arr], { type: type || 'image/png' }))
        })
      },
    })
  }
}
