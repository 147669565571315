import { defineAction } from '_privateDependencies/react-utils/utils'
import * as contactService from '_services/contact'

export const CREATE_CONTACT = defineAction('CREATE_CONTACT')
export const UPDATE_CONTACT = defineAction('UPDATE_CONTACT')
export const LIST_CONTACT = defineAction('LIST_CONTACT')
export const DELETE_CONTACT = defineAction('DELETE_CONTACT')

export const createContact = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_CONTACT.ACTION,
    payload: contactService.createContact(getState().user.get('auth_token'), payload),
  })

export const updateContact = (contactId, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_CONTACT.ACTION,
    payload: contactService.updateContact(getState().user.get('auth_token'))(contactId, payload),
  })

export const listContacts = params => (dispatch, getState) =>
  dispatch({
    type: LIST_CONTACT.ACTION,
    payload: contactService.listContacts(getState().user.get('auth_token'))(params),
    meta: {
      search: !!params.search,
    },
  })

export const deleteContact = contactId => (dispatch, getState) =>
  dispatch({
    type: DELETE_CONTACT.ACTION,
    payload: contactService.deleteContact(getState().user.get('auth_token'))(contactId),
    meta: {
      contactId,
    },
  })
