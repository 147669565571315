import {
  REGISTER_ASSET_INSIGHTS,
  LIST_USER_INSIGHTS_ACTIVITIES,
  REGISTER_PRESENTATION_INSIGHTS,
} from './actions'

export const assetInsightsSelector = state => state.insights.get('assetInsightsId')

export const isRegisteringAssetInsightsSelector = state =>
  !!state.loading.get(REGISTER_ASSET_INSIGHTS.ACTION)

export const isRegisteringPresentationInsightsSelector = state =>
  !!state.loading.get(REGISTER_PRESENTATION_INSIGHTS.ACTION)

export const userActivitiesSelector = state => state.insights.get('userActivities')

export const isLoadingUserInsightsSelector = state =>
  state.loading.get(LIST_USER_INSIGHTS_ACTIVITIES.ACTION)

export const insightNextPageSelector = state => !!state.insights.get('insightNextPage')

export const insightsTotalSelector = state => state.insights.get('insightsTotal')

export const presentationActivitySelector = state => state.insights.get('presentationActivity')

export const commentSelector = state => state.insights.get('comment')
