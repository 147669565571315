export const TAB_NAVIGATION = {
  FILE: 'file',
  LINK: 'website',
  CONTACT: 'contact',
}

export const INITIAL_STATE = {
  activeTab: TAB_NAVIGATION.FILE,
  fileWrapperWidth: 0,
  link: '',
  file: undefined,
  replace: false,
  keepCurrentThumbnail: false,
  thumbnailMeta: undefined,
  isThumbnailOnly: false,
}

export const UPDATE_STATE = 'UPDATE_STATE'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      return state
    }
  }
}
