import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import Modal from '_components/ui-kit/modal'
import Svg from '_components/svg'
import Icon from '_assets/icons/undraw_chat.svg'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import Checkbox from '_components/ui-kit/checkbox'
import { PATHS } from '_utils/constants'
import Tag from '_components/tag'

import styles from './styles.css'

const AutoSummarizerModal = ({
  isOpen,
  onClose,
  onConfirm,
  onReject,
  agreeShareThirdPartySummarizer,
  onHandleCheckboxChange,
}) => {
  const handleCheckboxChange = useCallback(
    event => {
      if (onHandleCheckboxChange) {
        const { name, checked } = event.target
        onHandleCheckboxChange(name, checked)
      }
    },
    [onHandleCheckboxChange]
  )

  const handleConfirm = useCallback(() => {
    onConfirm()
    onClose()
  }, [onConfirm, onClose])

  const handleClose = useCallback(() => {
    onReject()
    onClose()
  }, [onClose, onReject])

  const handleReject = useCallback(() => {
    onReject()
    onClose()
  }, [onReject, onClose])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isClosable className={styles.modal}>
      <div className={styles.container}>
        <Svg icon={Icon} className={styles.icon} />
        <div className={styles['title-wrapper']}>
          <h1 className={styles.title}>Use AI auto-summarizer?</h1>
          <Tag>Beta</Tag>
        </div>
        <p className={styles.description}>
          Auto-generate title and description for your upload. You can change this anytime in your{' '}
          <Link
            className={styles.link}
            to={`${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.PREFERENCES_PATH_NAME}`}
          >
            account preferences
          </Link>
          .
        </p>
        <div className={styles.wrapper}>
          <Checkbox
            labelClassName={styles['check-box-label']}
            label="By using AI auto-summarizer, I agree to share file information with third-party companies like ChatGPT"
            checked={agreeShareThirdPartySummarizer}
            name="agreeShareThirdPartySummarizer"
            onChange={handleCheckboxChange}
            id="agreeShareThirdPartySummarizer"
            value={agreeShareThirdPartySummarizer}
          />
        </div>
        <div className={styles.actions}>
          <Button
            onClick={handleReject}
            className={styles['denied-button']}
            theme={BUTTON_THEME.LINK_BUTTON}
          >
            Don&apos;t use AI
          </Button>
          <Button
            onClick={handleConfirm}
            className={styles['confirm-button']}
            disabled={!agreeShareThirdPartySummarizer}
          >
            Use AI summarizer
          </Button>
        </div>
      </div>
    </Modal>
  )
}

AutoSummarizerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  agreeShareThirdPartySummarizer: PropTypes.bool,
  onHandleCheckboxChange: PropTypes.func,
}

AutoSummarizerModal.defaultProps = {
  agreeShareThirdPartySummarizer: false,
  onHandleCheckboxChange: null,
}

export default React.memo(AutoSummarizerModal)
