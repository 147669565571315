import React, { useMemo, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import useModal from '_hooks/use-modal'

import DropdownEnhanceOption from './enhance-options'
import styles from './styles.css'

const EnhanceDropdown = ({ handleChange, options, name, value }) => {
  const buttonRef = useRef(null)
  const [style, setStyle] = useState({})
  const [showOptions, toggleOptions] = useModal()

  const handleClick = useCallback(() => {
    const buttonRect = buttonRef.current.getBoundingClientRect()
    setStyle({
      top: buttonRef.current.offsetTop + buttonRect.height + 2,
    })
    toggleOptions()
  }, [toggleOptions])

  const onChange = useCallback(
    event => {
      handleChange(event)
      toggleOptions()
    },
    [handleChange, toggleOptions]
  )

  const renderLabel = useMemo(() => {
    const option = options.find(item => item.value === value)
    return option ? `${option.labelEmoji} ${option.labelText}` : ''
  }, [options, value])

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        theme={BUTTON_THEME.SECONDARY}
        size={BUTTON_SIZE.MEDIUM}
        onClick={handleClick}
        ref={buttonRef}
      >
        {renderLabel}
      </Button>

      {showOptions && (
        <div className={styles.dropdown} style={style}>
          {options.map(item => (
            <DropdownEnhanceOption
              arialLabel={item.arialLabel}
              key={item.id}
              labelEmoji={item.labelEmoji}
              labelText={item.labelText}
              name={name}
              value={item.value}
              onChange={onChange}
              checked={value === item.value}
              id={item.value}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(EnhanceDropdown)

EnhanceDropdown.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      arialLabel: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      labelEmoji: PropTypes.string.isRequired,
      labelText: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
