import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Svg from '_components/svg'
import CloseIcon from '_assets/icons/close-masonry.svg'
import { cleanAssetSummarizer } from '_modules/assets/actions'

import styles from './styles.css'

const WarningBanner = ({ message, onClose, timer, assetId }) => {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    if (assetId) {
      dispatch(cleanAssetSummarizer(assetId))
    }
    onClose()
  }, [onClose, dispatch, assetId])

  useEffect(() => {
    if (timer) {
      const timeout = setTimeout(() => {
        handleClose()
      }, timer)

      return () => {
        clearTimeout(timeout)
      }
    }

    return () => {}
  }, [timer, handleClose])

  return (
    <div className={styles.container}>
      <p className={styles['warning-message']}>{message}</p>
      <button onClick={onClose} className={styles['close-button']} type="button">
        <Svg icon={CloseIcon} className={styles.icon} />
      </button>
    </div>
  )
}

WarningBanner.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  timer: PropTypes.number,
  assetId: PropTypes.number,
}

WarningBanner.defaultProps = {
  timer: undefined,
  assetId: undefined,
}

export default WarningBanner
