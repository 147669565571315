import { schema } from 'normalizr'

import Model from './model'

class Contact extends Model({
  name: undefined,
  email: undefined,
  id: undefined,
  note: undefined,
}) {}

export default Contact

const contactNewSchema = new schema.Entity(
  'result',
  {},
  {
    idAttribute: 'id',
    processStrategy: ({ id, ...entity }) => new Contact({ id, ...entity }),
    sortComparer: (a, b) => Number(a.id) - Number(b.id),
  }
)

export const contactSchema = [contactNewSchema]
