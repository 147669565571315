import classNames from 'classnames'
import humps from 'humps'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Modal from 'react-modal'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

import CloseIcon from '_assets/icons/close.svg'
import SearchIcon from '_assets/icons/search.svg'
import withViewportSize from '_hocs/with-viewport-size'
import Loading from '_components/loading'
import { downloadUnsplashImagePercentage } from '_modules/assets/actions'

import createPhotoColumns from './create-photo-columns'
import Photo from './photo'
import styles from './styles.css'

const mapDispatchToProps = {
  downloadUnsplashImagePercentage,
}

class UnsplashPicker extends PureComponent {
  searchText = debounce(text => {
    this.setState({ isLoading: true, loadingMore: false })
    fetch(`/unsplash/search/${text}`)
      .then(response => response.json())
      .then(res => {
        const { results, total_pages: totalPages } = res
        this.setState({
          photos: humps.camelizeKeys(results || res),
          totalPages,
          isLoading: false,
        })
      })
  }, 500)

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    downloadUnsplashFile: PropTypes.func.isRequired,
    isSmallViewport: PropTypes.bool.isRequired,
    assetId: PropTypes.number.isRequired,
    downloadUnsplashImagePercentage: PropTypes.func.isRequired,
    assetTitle: PropTypes.number,
  }

  static defaultProps = {
    assetTitle: undefined,
  }

  state = {
    isLoading: true,
    loadingMore: false,
    photos: [],
    photoColumns: createPhotoColumns([], 3),
    photoSelected: null,
    search: this.props.assetTitle || '',
    page: 1,
    totalPages: 0,
    focusInput: false,
  }

  componentDidMount() {
    const { assetTitle } = this.props
    fetch(assetTitle ? `/unsplash/search/${assetTitle}` : '/unsplash/search/')
      .then(response => response.json())
      .then(res => {
        const { results } = res
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ photos: humps.camelizeKeys(results || res), isLoading: false })
      })

    window.addEventListener('resize', this.arrangePhotoInColumns)
  }

  componentDidUpdate(prevProps, prevState) {
    const { photos: prevPhotos } = prevState
    const { photos } = this.state

    if (prevPhotos !== photos) {
      this.arrangePhotoInColumns()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.arrangePhotoInColumns)
  }

  onChangeSearch = event => {
    this.setState({
      isLoading: true,
      photoSelected: null,
      search: event.target.value,
      photos: [],
      page: 1,
      totalPages: 0,
    })
    this.searchText(event.target.value)
  }

  onCheckPhoto = id => {
    this.setState(state => ({ photoSelected: state.photos.find(photo => photo.id === id) }))
  }

  onSelectFile = () => {
    const { photoSelected } = this.state

    this.props.downloadUnsplashImagePercentage({
      assetId: this.props.assetId,
      unsplashPercentage: 1,
    })

    fetch(`/unsplash/download/${photoSelected.id}`)
      .then(response => response.json())
      .then(res => {
        const downloadFile = { ...res, name: photoSelected.id }
        this.props.downloadUnsplashFile(downloadFile)
      })

    this.props.onRequestClose()
  }

  arrangePhotoInColumns = () => {
    const { isSmallViewport } = this.props

    const numOfColumns = isSmallViewport ? 1 : 3
    this.setState(state => ({ photoColumns: createPhotoColumns(state.photos, numOfColumns) }))
  }

  loadMorePhotos = () => {
    const { photos, page, totalPages, search } = this.state
    if (photos.length === 0 || (totalPages && page >= totalPages)) {
      return
    }

    this.setState({ isLoading: true, loadingMore: true })
    fetch(`/unsplash/search${search && `/${search}`}?page=${page + 1}`)
      .then(response => response.json())
      .then(res => {
        const { results, total_pages: totalPage } = res
        this.setState(prevState => ({
          photos: [...prevState.photos, ...humps.camelizeKeys(results || res)],
          totalPages: totalPage,
          page: page + 1,
          isLoading: false,
          loadingMore: false,
        }))
      })
  }

  handleScroll = () => {
    const library = document.getElementById('unsplash-photo-library')
    if (library.scrollHeight - library.scrollTop <= library.clientHeight * 1.1) {
      this.loadMorePhotos()
    }
  }

  toogleSearchBorder = () => this.setState(prevState => ({ focusInput: !prevState.focusInput }))

  render() {
    const { isOpen, onRequestClose, isSmallViewport } = this.props
    const { photoColumns, photoSelected, search, focusInput, isLoading, loadingMore } = this.state

    return (
      <Modal
        bodyOpenClassName={styles['no-scroll']}
        className={styles.modal}
        contentLabel="Unsplash library"
        htmlOpenClassName={styles['no-scroll']}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <div className={styles.header}>
          <p className={styles.title}>Unsplash Library</p>
          <button
            type="button"
            aria-label="Close"
            className={styles['close-button']}
            onClick={onRequestClose}
          >
            <svg aria-hidden="true" className={styles['close-icon']} focusable="false">
              <use xlinkHref={CloseIcon} />
            </svg>
          </button>
        </div>
        <div className={styles.content}>
          <div className={styles['search-container']}>
            <input
              onFocus={this.toogleSearchBorder}
              onBlur={this.toogleSearchBorder}
              className={classNames(styles.search, { [styles.focus]: focusInput })}
              onChange={this.onChangeSearch}
              placeholder="Search photos"
              type="search"
              value={search}
            />
            <svg
              aria-hidden="true"
              className={classNames(styles['search-icon'], { [styles['focus-icon']]: focusInput })}
              focusable="false"
            >
              <use xlinkHref={SearchIcon} />
            </svg>
          </div>
          <p className={styles['unsplash-credits']}>Photos provided by Unsplash</p>
          <div
            className={classNames(styles['photo-library'], { [styles.small]: isSmallViewport })}
            tabIndex="-1"
            id="unsplash-photo-library"
            onScroll={this.handleScroll}
          >
            {(!isLoading || loadingMore) &&
              photoColumns.map((column, columnIndex) => (
                <div
                  className={styles['photo-column']}
                  // eslint-disable-next-line react/no-array-index-key
                  key={columnIndex}
                  role="radiogroup"
                >
                  {column.photos.map((photo, photoIndex) => {
                    const isChecked = photoSelected && photoSelected.id === photo.id
                    const isCheckable = photoSelected
                      ? isChecked
                      : columnIndex === 0 && photoIndex === 0

                    return (
                      <Photo
                        className={styles.photo}
                        key={photo.id}
                        isCheckable={isCheckable}
                        isChecked={isChecked}
                        onCheck={this.onCheckPhoto}
                        {...photo}
                      />
                    )
                  })}
                </div>
              ))}
            {isLoading && (
              <Loading
                className={classNames(styles.loading, { [styles['loading-more']]: loadingMore })}
              />
            )}
          </div>
        </div>
        <div className={styles['action-bar']}>
          <button type="button" className={styles['action-button']} onClick={onRequestClose}>
            Cancel
          </button>
          <button
            type="button"
            className={classNames(styles['action-button'], styles.filled)}
            disabled={!photoSelected}
            onClick={this.onSelectFile}
          >
            Select
          </button>
        </div>
      </Modal>
    )
  }
}

export default connect(null, mapDispatchToProps)(withViewportSize(UnsplashPicker))
