import { Map } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'

import { OPEN_TOASTER, CLOSE_TOASTER } from './actions'

const INITIAL_STATE = Map({
  type: null,
  message: null,
  timeAutoClose: null,
})

export default createReducer(INITIAL_STATE, {
  [OPEN_TOASTER]: (state, { payload }) => Map({ ...INITIAL_STATE.toJS(), ...payload }),

  [CLOSE_TOASTER]: () => INITIAL_STATE,
})
