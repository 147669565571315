import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import gridStyles from '_styles/grid.css'

import styles from './styles.css'

export const FontSize = {
  EXTRA_SMALL: 'extra-small',
  SMALL: 'small',
  LARGE: 'large',
}

export const MediaSize = {
  SMALL: 'media-small',
  LARGE: 'media-large',
  EXTRA_LARGE: 'media-extra-large',
}

export const Color = {
  WHITE: 'white',
  DARK_GRAY_BLUE: 'dark-gray-blue',
}

const MediaBlockVertical = ({
  media,
  text,
  fontSize,
  mediaSize,
  color,
  textClassName,
  classNames,
}) => (
  <div
    className={classnames(
      gridStyles.Grid,
      gridStyles['Grid--alignCenter'],
      styles.container,
      classNames
    )}
  >
    <div className={classnames(gridStyles['Grid-cell'])}>
      <svg alt="media" className={classnames(styles.media, styles[color], styles[mediaSize])}>
        <use xlinkHref={media} />
      </svg>
    </div>
    <div className={classnames(gridStyles['Grid-cell'])}>
      <p className={classnames(styles.text, styles[fontSize], styles[color], textClassName)}>
        {text}
      </p>
    </div>
  </div>
)

MediaBlockVertical.propTypes = {
  media: PropTypes.string,
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.oneOf(Object.keys(FontSize).map(key => FontSize[key])),
  mediaSize: PropTypes.oneOf(Object.keys(MediaSize).map(key => MediaSize[key])),
  color: PropTypes.oneOf(Object.keys(Color).map(key => Color[key])),
  textClassName: PropTypes.string,
  classNames: PropTypes.string,
}

MediaBlockVertical.defaultProps = {
  media: '',
  fontSize: FontSize.SMALL,
  mediaSize: MediaSize.LARGE,
  color: Color.DARK_GRAY_BLUE,
  textClassName: '',
  classNames: '',
}

export default MediaBlockVertical
