const MARGIN_HEIGHT = 20
const WIDTH = 200

export default (photos, columnsCount) => {
  const columns = []
  for (let index = 0; index < columnsCount; index += 1) {
    columns.push({ height: 0, photos: [] })
  }

  if (!photos.length) {
    return columns
  }

  photos.forEach(photo => {
    const height = (photo.height / photo.width) * WIDTH + MARGIN_HEIGHT
    const shorterColumn = columns.reduce((shorter, column) =>
      column.height < shorter.height ? column : shorter
    )

    shorterColumn.height += height
    shorterColumn.photos.push(photo)
  })

  return columns
}
