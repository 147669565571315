import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import ReactGoogleLogin from 'react-google-login'
import { useSelector, useDispatch } from 'react-redux'

import GoogleLogo from '_assets/icons/google.svg'
import { GOOGLE_LOGIN_CLIENT_ID } from '_config/environment'
import Button from '_components/button'
import withBrowserInformation from '_hocs/with-browser-information'
import { loginGoogle, getUserRole, resetUser, unlinkSocialAccount } from '_modules/user/actions'
import {
  isGetUserRoleLoadingSelector,
  getUserRoleErrorSelector,
  isGoogleAccountLoadingSelector,
  linkGoogleAccountErrorSelector,
  unlinkSocialAccountErrorSelector,
  unlinkSocialAccountLoadingSelector,
} from '_modules/user/selectors'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'
import usePrevious from '_hooks/use-previous'

import styles from './styles.css'

// COMPONENT
const GoogleLogin = ({
  className,
  hasGoogleAccount,
  isInternetExplorer,
  text,
  onSuccess,
  iconClassName,
  allowRedirectToLogin,
  onSocialLogin,
  isSignUp,
  isGrayButton,
}) => {
  const dispatch = useDispatch()
  const [loginData, setLoginData] = useState(null)
  const error = useSelector(linkGoogleAccountErrorSelector)
  const isLoading = useSelector(isGoogleAccountLoadingSelector)
  const wasLoading = usePrevious(isLoading)
  const userRoleError = useSelector(getUserRoleErrorSelector)
  const isUserRoleLoading = useSelector(isGetUserRoleLoadingSelector)
  const isUnlinkAccountLoading = useSelector(unlinkSocialAccountLoadingSelector)
  const unlinkAccountError = useSelector(unlinkSocialAccountErrorSelector)

  useEffect(() => {
    if (error.size || unlinkAccountError.size || (userRoleError.size && !onSocialLogin)) {
      const errorMessage =
        error && error.get('error') && error.get('error').first && error.get('error').first()
      const unlinkErrorMessage = unlinkAccountError && unlinkAccountError.first()
      const roleErrorMessage = userRoleError && userRoleError.first()
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.ERROR,
          timeAutoClose: 5000,
          message:
            errorMessage ||
            unlinkErrorMessage ||
            roleErrorMessage ||
            'Something went wrong. Please try again',
        })
      )
    }
  }, [dispatch, error, userRoleError, onSocialLogin, unlinkAccountError])

  useEffect(() => {
    if (
      loginData &&
      loginData.accessToken &&
      allowRedirectToLogin &&
      !userRoleError.size &&
      !isUserRoleLoading
    ) {
      const payload = { access_token: loginData.accessToken }
      if (!isSignUp) {
        dispatch(resetUser())
      }
      dispatch(loginGoogle(payload))
      setLoginData(null)
    }
  }, [allowRedirectToLogin, loginData, userRoleError, isUserRoleLoading, dispatch, isSignUp])

  useEffect(() => {
    if (onSocialLogin && loginData && loginData.profileObj) {
      const { name, email } = loginData.profileObj
      onSocialLogin({ name, email, accessToken: loginData.accessToken, socialProvider: 'google' })
      setLoginData(null)
    }
  }, [loginData, onSocialLogin])

  useEffect(() => {
    if (wasLoading && !isLoading && !error.size) {
      onSuccess()
    }
  }, [wasLoading, isLoading, error, onSuccess])

  const onSuccessLogin = useCallback(
    data => {
      const {
        profileObj: { email },
      } = data

      if (email) {
        if (!isSignUp) {
          dispatch(getUserRole(email))
        }

        setLoginData(data)
      }
    },
    [dispatch, isSignUp]
  )

  const onFailure = useCallback(() => {}, [])

  const unlinkAccount = useCallback(() => {
    dispatch(
      unlinkSocialAccount({
        socialProvider: 'google',
      })
    )
  }, [dispatch])

  const renderButton = useCallback(
    renderProps => (
      <Button
        classNames={classNames(
          isInternetExplorer ? styles.ie : styles.google,
          isGrayButton && styles['gray-button-background'],
          className
        )}
        isLoading={isLoading || isUnlinkAccountLoading}
        {...renderProps}
      >
        <div className={styles.children}>
          <svg aria-hidden="true" className={classNames(styles.logo, iconClassName)}>
            <use xlinkHref={GoogleLogo} />
          </svg>
          <p className={classNames(isGrayButton && styles['gray-button-font'])}>{text}</p>
        </div>
      </Button>
    ),
    [
      className,
      iconClassName,
      isLoading,
      isInternetExplorer,
      text,
      isUnlinkAccountLoading,
      isGrayButton,
    ]
  )

  return hasGoogleAccount ? (
    renderButton({ onClick: () => unlinkAccount() })
  ) : (
    <ReactGoogleLogin
      clientId={GOOGLE_LOGIN_CLIENT_ID}
      onFailure={onFailure}
      onSuccess={onSuccessLogin}
      render={renderButton}
    />
  )
}

GoogleLogin.propTypes = {
  className: PropTypes.string,
  hasGoogleAccount: PropTypes.bool,
  isInternetExplorer: PropTypes.bool.isRequired,
  text: PropTypes.string,
  onSuccess: PropTypes.func,
  iconClassName: PropTypes.string,
  allowRedirectToLogin: PropTypes.bool,
  onSocialLogin: PropTypes.func,
  isSignUp: PropTypes.bool,
  isGrayButton: PropTypes.bool,
}

GoogleLogin.defaultProps = {
  className: '',
  hasGoogleAccount: false,
  text: 'Continue with Google',
  onSuccess: () => {},
  iconClassName: '',
  allowRedirectToLogin: true,
  onSocialLogin: null,
  isSignUp: false,
  isGrayButton: false,
}

export default withBrowserInformation(GoogleLogin)
