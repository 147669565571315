import PropTypes from 'prop-types'
import React, { useEffect, useCallback, useRef, Fragment, useState, useMemo } from 'react'
import classnames from 'classnames'
import { Map } from 'immutable'
import { Link } from 'react-router'
import cookies from 'react-cookies'
import { useSelector, useDispatch } from 'react-redux'

import CompanyInviteModalPicture from '_assets/company_invite_modal_picture.svg'
import ProfileDropdownMobile from '_components/header/profile-dropdown-mobile'
import HeaderNavBar from '_components/header/nav-bar'
import ProfileDropdown from '_components/header/profile-dropdown'
import Logo from '_assets/logo/default-logo.svg'
import HelpIcon from '_assets/icons/help-icon.svg'
import UserPicture from '_assets/avatar@3x.png'
import GiftIcon from '_assets/icons/gift.svg'
import UserMenu from '_components/header/user-menu'
import NotificationComponentNew from '_components/notification-component-new'
import withViewportSize from '_hocs/with-viewport-size'
import { HELP_URL } from '_utils/constants'
import Button, { BUTTON_SIZE } from '_components/ui-kit/button'
import {
  getReferralRockCode,
  GET_REFERRAL_ROCK_CODE,
  GET_USER,
  ACCEPT_COMPANY_INVITE,
  DECLINE_COMPANY_INVITE,
  declineCompanyInvite,
  acceptCompanyInvite,
  getUserInvites,
} from '_modules/user/actions'
import {
  isTrialActiveSelector,
  isFreeUserSelector,
  isLoggedUserSelector,
  companyInviteSelector,
} from '_modules/user/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import { FLAG_SHOW_PROFILE_DROPDOWN_OPTION_REFER_A_FRIEND } from '_config/environment'
import CreateTeamModal from '_components/create-team-modal'
import useModal from '_hooks/use-modal'
import useWindowSize from '_hooks/use-window-size'
import ConfirmationModal from '_components/confirmation-modal'
import { getNotifications } from '_modules/insights/actions'
import { checkIfIsAllowedUser } from '_utils/user'

import styles from './styles.css'

export const GIFT_VALUE = '$20'

const Header = ({ className, logout, user, location, isLargeViewport }) => {
  const ref = useRef(null)
  const mobileRef = useRef(null)
  const { isDesktop } = useWindowSize()

  const [isCreateTeamModalOpen, onToggleCreateTeamModal] = useModal(false)
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false)
  const [isCompanyInviteModalOpen, setIsCompanyInviteModalOpen] = useModal(false)

  const isTrialActive = useSelector(isTrialActiveSelector)
  const isFreeUser = useSelector(isFreeUserSelector)
  const hasUser = useSelector(isLoggedUserSelector)
  const companyInvite = useSelector(companyInviteSelector)
  const isAllowedUser = useMemo(() => checkIfIsAllowedUser(user), [user])

  const dispatch = useDispatch()

  const handleOutsideClick = useCallback(
    event => {
      const userDropdownOutsideClick =
        isLargeViewport && ref.current && !ref.current.contains(event.target)

      if (userDropdownOutsideClick && isUserDropdownOpen) {
        setIsUserDropdownOpen(false)
      }
    },
    [isLargeViewport, isUserDropdownOpen, setIsUserDropdownOpen]
  )

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    if (hasUser) {
      dispatch(getUserInvites())
    }
  }, [dispatch, hasUser])

  useEffect(() => {
    setIsUserDropdownOpen(false)
  }, [location, location.pathname, setIsUserDropdownOpen])

  useEffect(() => {
    if (hasUser) {
      if (companyInvite) {
        setIsCompanyInviteModalOpen(true)
      }
    }
  }, [companyInvite, setIsCompanyInviteModalOpen, hasUser])

  useEffect(() => {
    if (hasUser && isAllowedUser) {
      dispatch(getNotifications())
    }
  }, [isAllowedUser, hasUser, dispatch])

  const toggleUserDropdown = useCallback(() => {
    setIsUserDropdownOpen(prevState => !prevState)
  }, [setIsUserDropdownOpen])

  const referralCookieCheck = useCallback(() => {
    const referralLinkCheck = cookies.load('authUrl')
    if (referralLinkCheck) {
      window.open(decodeURIComponent(referralLinkCheck), '_blank')
    } else {
      dispatch(getReferralRockCode())
    }
  }, [dispatch])

  const [isUserLoading] = useFetchCall(GET_USER)
  const [isLoadingReferralCode] = useFetchCall(GET_REFERRAL_ROCK_CODE, referralCookieCheck)

  const closeCompanyInviteModal = useCallback(() => setIsCompanyInviteModalOpen(false), [
    setIsCompanyInviteModalOpen,
  ])

  const [isDeclineInviteLoading] = useFetchCall(
    DECLINE_COMPANY_INVITE,
    closeCompanyInviteModal,
    closeCompanyInviteModal
  )

  const [isAcceptInviteLoading] = useFetchCall(
    ACCEPT_COMPANY_INVITE,
    closeCompanyInviteModal,
    closeCompanyInviteModal
  )

  const onDeclineCompanyInvite = useCallback(() => {
    dispatch(declineCompanyInvite(companyInvite.get('company_id'), companyInvite.get('id')))
  }, [dispatch, companyInvite])

  const onAcceptCompanyInvite = useCallback(() => {
    dispatch(acceptCompanyInvite(companyInvite.get('company_id'), companyInvite.get('id')))
  }, [dispatch, companyInvite])

  return (
    <header className={classnames(styles.header, className)}>
      <Fragment>
        <div
          className={classnames(styles.grid, {
            [styles['show-upgrade-button']]: isFreeUser && !isTrialActive,
            [styles['show-highnote-ai-button']]: false,
          })}
        >
          <Link to="/presentations" aria-label="Presentation's View">
            <svg className={styles.logo} aria-hidden="true">
              <use xlinkHref={Logo} />
            </svg>
          </Link>
          {hasUser && (
            <Fragment>
              <HeaderNavBar
                location={location}
                className={styles['main-nav']}
                userRole={user.get('role')}
              />
              {isFreeUser && !isTrialActive && (
                <Button to="/payment" className={styles.upgrade} size={BUTTON_SIZE.SMALL}>
                  Upgrade
                </Button>
              )}
              {!isUserLoading &&
                !isTrialActive &&
                isDesktop &&
                FLAG_SHOW_PROFILE_DROPDOWN_OPTION_REFER_A_FRIEND && (
                  <Button
                    isLoading={isLoadingReferralCode}
                    startIcon={GiftIcon}
                    className={styles['gift-button']}
                    startIconClassName={styles['gift-icon']}
                    size={BUTTON_SIZE.SMALL}
                    onClick={referralCookieCheck}
                  >
                    <span className={styles['gift-button-label']}>Get {GIFT_VALUE}</span>
                  </Button>
                )}
              <a
                href={HELP_URL}
                aria-label="Help"
                className={styles['help-link']}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg className={styles['help-icon']} aria-hidden="true">
                  <use xlinkHref={HelpIcon} />
                </svg>
              </a>
              <NotificationComponentNew className={styles.notification} />
              <div className={styles['user-wrapper']} ref={ref}>
                <UserMenu
                  userPicture={
                    user.get('picture') ||
                    user.get('google_account_picture') ||
                    user.get('facebook_account_picture') ||
                    UserPicture
                  }
                  openUserDropdown={toggleUserDropdown}
                  menuOpen={isUserDropdownOpen}
                />
                {isUserDropdownOpen && (
                  <ProfileDropdown
                    className={styles.dropdown}
                    onLogoutClick={logout}
                    picture={user.get('thumbnail_picture') || user.get('picture')}
                    userName={`${user.get('first_name')} ${user.get('last_name')}`}
                    userEmail={user.get('email')}
                    onClickCreateTeam={onToggleCreateTeamModal}
                  />
                )}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
      {isUserDropdownOpen && (
        <ProfileDropdownMobile
          className={styles['dropdown-mobile']}
          logout={logout}
          ref={mobileRef}
          location={location}
          picture={user.get('thumbnail_picture') || user.get('picture')}
          userName={`${user.get('first_name')} ${user.get('last_name')}`}
          userEmail={user.get('email')}
          role={user.get('role')}
          onClickCreateTeam={onToggleCreateTeamModal}
        />
      )}
      {isCreateTeamModalOpen && (
        <CreateTeamModal isOpen={isCreateTeamModalOpen} onClose={onToggleCreateTeamModal} />
      )}
      {isCompanyInviteModalOpen && (
        <ConfirmationModal
          image={CompanyInviteModalPicture}
          title={"You've been invited to a team"}
          description={
            companyInvite
              ? `${companyInvite.get('admin_name') ||
                  companyInvite.get('company_name')} has invited you to join ${companyInvite.get(
                  'company_name'
                )} team. Do you want to join?`
              : ''
          }
          isOpen
          isLoading={isDeclineInviteLoading || isAcceptInviteLoading}
          onCloseText="Decline invite"
          onClose={onDeclineCompanyInvite}
          onFinishText="Accept invite"
          onFinish={onAcceptCompanyInvite}
        />
      )}
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  user: PropTypes.instanceOf(Map),
  logout: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape(),
  }).isRequired,
  isLargeViewport: PropTypes.bool,
}

Header.defaultProps = {
  className: undefined,
  user: Map(),
  isLargeViewport: false,
}

Header.defaultProps = {
  className: undefined,
  user: Map(),
  isLargeViewport: false,
}

export default withViewportSize(React.memo(Header))
