import { ONESIGNAL_APP_ID } from '_config/environment'

const oneSignal = window ? window.OneSignal : []

const WEB_PUSH_NOTIFICATION_NOT_SUPPORTED = 'This browser does not support notifications'
const WEB_PUSH_NOTIFICATION_PERMISSION_DENIED =
  'You have to allow notifications on your browser settings'

export const initOneSignal = () => {
  oneSignal.push(() => {
    oneSignal.init({
      appId: ONESIGNAL_APP_ID,
      autoRegister: false,
    })
  })
}

export const oneSignalSubscriptionChange = callback => {
  oneSignal.push(() => {
    oneSignal.on('subscriptionChange', callback)
  })
}

export const createOneSignalNotificationAlert = openAlert => type =>
  openAlert({ isOpen: true, timer: 3000, type })

export const oneSignalNotificationChange = notify => {
  if (!oneSignal.isPushNotificationsSupported) {
    notify(WEB_PUSH_NOTIFICATION_NOT_SUPPORTED)
    return
  }

  oneSignal.getNotificationPermission(permission => {
    if (permission === 'denied') {
      notify(WEB_PUSH_NOTIFICATION_PERMISSION_DENIED)
      return
    }

    Promise.all([oneSignal.isPushNotificationsEnabled(), oneSignal.isOptedOut()]).then(
      ([isPushEnabled, isOptedOut]) => {
        if (isPushEnabled) {
          /* Subscribed, opt them out */
          oneSignal.setSubscription(false)
        } else if (isOptedOut) {
          /* Opted out, opt them back in */
          oneSignal.setSubscription(true)
        } else {
          /* Unsubscribed, subscribe them */
          oneSignal.registerForPushNotifications()
        }
      }
    )
  })
}

export const oneSignalGetUserId = callback => oneSignal.getUserId(callback)
