import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment, useState, useCallback, useEffect, useMemo } from 'react'
import Spinner from 'react-spinkit'

import ImageUploadModal from '_components/image-upload-modal'
import withViewportSize from '_hocs/with-viewport-size'
import PhotoIcon from '_assets/icons/camera.svg'
import Svg from '_components/svg'
import RemoveIcon from '_assets/icons/close-masonry.svg'

import styles from './styles.css'

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'bmp']

const UploadImage = ({
  className,
  isLargeViewport,
  isLoading,
  isMediumViewport,
  onChangeImage,
  image,
  onRemoveImage,
}) => {
  const [avatarDropdownPosition, setAvatarDropdownPosition] = useState({})
  const [dropDownActive, setDropDownActive] = useState(false)

  const dynamicImageStyle = useMemo(
    () => ({
      backgroundImage: `url(${image})`,
    }),
    [image]
  )
  const closeDropDown = useCallback(() => {
    setDropDownActive(false)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', closeDropDown)

    return () => {
      window.removeEventListener('resize', closeDropDown)
    }
  }, [closeDropDown])

  const onFileSelect = useCallback(
    (dataURL, file) => {
      closeDropDown()

      onChangeImage(dataURL, file)
    },
    [closeDropDown, onChangeImage]
  )

  const setDropdownPosition = useCallback(
    event => {
      let yPosition = event.target.getBoundingClientRect().top + window.pageYOffset + 110

      let xPosition =
        event.currentTarget.getBoundingClientRect().left +
        142 -
        event.currentTarget.getBoundingClientRect().width

      if (isMediumViewport) {
        xPosition =
          event.currentTarget.getBoundingClientRect().left +
          100 -
          event.currentTarget.getBoundingClientRect().width

        yPosition -= 20
      }

      if (isLargeViewport) {
        xPosition =
          event.currentTarget.getBoundingClientRect().left -
          180 +
          event.currentTarget.getBoundingClientRect().width

        yPosition += 10
      }

      return {
        content: {
          top: `${yPosition}px`,
          left: `${xPosition}px`,
        },
        overlay: {
          height: `${window.document.body.scrollHeight}px`,
        },
      }
    },
    [isLargeViewport, isMediumViewport]
  )
  const toggleAvatarDropdown = useCallback(
    event => {
      setDropDownActive(prevState => !prevState)
      setAvatarDropdownPosition(setDropdownPosition(event))
    },
    [setDropdownPosition]
  )

  return (
    <div className={classNames(styles['image-container'], className)} style={dynamicImageStyle}>
      {isLoading ? (
        <div>
          <Spinner className={styles.spinner} color="#e82f72" fadeIn="none" name="cube-grid" />
        </div>
      ) : (
        <Fragment>
          <ImageUploadModal
            dismiss={toggleAvatarDropdown}
            extensions={IMAGE_EXTENSIONS}
            isOpen={dropDownActive}
            selectFile={onFileSelect}
            style={avatarDropdownPosition}
            hasRemoveOption={false}
          />
          {image ? (
            <button
              aria-label="Remove profile picture"
              type="button"
              className={styles['remove-picture-button']}
              onClick={onRemoveImage}
            >
              <Svg icon={RemoveIcon} className={styles['remove-icon']} />
            </button>
          ) : (
            <button onClick={toggleAvatarDropdown} className={styles.empty} type="button">
              <Svg icon={PhotoIcon} className={styles.icon} />
              <p className={styles['empty-text']}>
                <span className={styles.bold}>Click here</span> to upload image
              </p>
            </button>
          )}
        </Fragment>
      )}
    </div>
  )
}
UploadImage.propTypes = {
  className: PropTypes.string,
  isLargeViewport: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isMediumViewport: PropTypes.bool.isRequired,
  onChangeImage: PropTypes.func.isRequired,
  image: PropTypes.string,
  onRemoveImage: PropTypes.func,
}

UploadImage.defaultProps = {
  className: '',
  isLoading: false,
  image: '',
  onRemoveImage: () => {},
}

export default withViewportSize(UploadImage)
