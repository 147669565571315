import React, { useCallback, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useOutsideClick from '_hooks/use-outside-click'
import useToggle from '_hooks/use-modal'
import Svg from '_components/svg'
import NotifyWaiveIcon from '_assets/icons/waive.svg'
import NotifyCampaignIcon from '_assets/icons/campaign.svg'
import NotifyLogo from '_assets/icons/ic-notify-user.svg'
import ConfirmationModal from '_components/confirmation-modal'

import NotifyModal from './notify-modal'
import styles from './styles.css'

const NotifyUserButton = ({
  isOpen,
  isEditSharedPresentationModalOpen,
  presentationId,
  className,
}) => {
  const containerRef = useRef()
  const [isShowingAnimation, toggleAnimation] = useToggle(isOpen)
  const [isConfirmNotifyOpen, onToggleConfirmNotifyModalOpen] = useToggle()
  const [isNotifyModalOpen, onToggleNotifyModalOpen] = useToggle()

  const handleClickOutside = useCallback(() => {
    if (isEditSharedPresentationModalOpen || !isShowingAnimation) {
      return
    }
    toggleAnimation()
  }, [toggleAnimation, isEditSharedPresentationModalOpen, isShowingAnimation])

  useOutsideClick(containerRef, handleClickOutside)

  const handleClick = useCallback(() => {
    toggleAnimation()
    onToggleConfirmNotifyModalOpen()
  }, [toggleAnimation, onToggleConfirmNotifyModalOpen])

  const onMouseEnter = useCallback(() => {
    if (isShowingAnimation) {
      return
    }
    toggleAnimation()
  }, [toggleAnimation, isShowingAnimation])

  const onMouseLeave = useCallback(() => {
    if (isShowingAnimation) {
      toggleAnimation()
    }
  }, [toggleAnimation, isShowingAnimation])

  const handleConfirmNotify = useCallback(() => {
    onToggleConfirmNotifyModalOpen()
    onToggleNotifyModalOpen()
  }, [onToggleNotifyModalOpen, onToggleConfirmNotifyModalOpen])

  return (
    <div
      className={classnames(
        styles.container,
        {
          [styles.animated]: isShowingAnimation,
        },
        className
      )}
      ref={containerRef}
    >
      <button
        type="button"
        className={classnames(styles.button, {
          [styles.animated]: isShowingAnimation,
        })}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span
          className={classnames(styles.text, {
            [styles.animated]: isShowingAnimation,
          })}
        >
          Notify recipient of updates
        </span>
        <div className={styles['icon-wrapper']}>
          <Svg
            icon={NotifyWaiveIcon}
            className={classnames(styles.icon, {
              [styles.animated]: isShowingAnimation,
            })}
          />
          <Svg icon={NotifyCampaignIcon} className={classnames(styles['fixed-icon'])} />
        </div>
      </button>
      {isConfirmNotifyOpen && (
        <ConfirmationModal
          title="Notify changes?"
          description="We noticed you made some changes to this presentation. Do you want to notify the contacts you shared this presentation with about these changes?"
          image={NotifyLogo}
          isOpen
          onCloseText="No, don't notify changes"
          onFinishText="Yes, notify about changes"
          onClose={onToggleConfirmNotifyModalOpen}
          onFinish={handleConfirmNotify}
        />
      )}
      {isNotifyModalOpen && (
        <NotifyModal isOpen onClose={onToggleNotifyModalOpen} presentationId={presentationId} />
      )}
    </div>
  )
}

NotifyUserButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEditSharedPresentationModalOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  presentationId: PropTypes.string.isRequired,
}

NotifyUserButton.defaultProps = {
  className: '',
}

export default NotifyUserButton
