import React from 'react'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'
import DropboxChooser from 'react-dropbox-chooser'

import MediaBlock, { Color, FontSize, MediaSize } from '_components/media-block'
import Button, { ButtonTheme } from '_components/button'
import GoogleDriveLogo from '_assets/icons/google-drive-logo.svg'
import DropboxLogo from '_assets/icons/dropbox_logo.svg'
import { GOOGLE_CLIENT_ID, GOOGLE_API_KEY, DROPBOX_APP_KEY } from '_config/environment'
import GooglePicker from '_components/google-picker'
// TODO: Commented box out since it's not working. Uncomment when redirect is fixed
// import BoxPicker from '_components/box-picker'

import styles from './styles.css'

const getExtensionsWithDot = memoizeOne(extensions => extensions.map(extension => `.${extension}`))

const CloudOptionModal = ({
  // TODO: Commented box out since it's not working. Uncomment when redirect is fixed
  // downloadBoxFile,
  onGoogleDriveAction,
  downloadDropboxFile,
  extensions,
  mimeTypes,
}) => (
  <div className={styles.modal}>
    <GooglePicker
      clientId={GOOGLE_CLIENT_ID}
      developerKey={GOOGLE_API_KEY}
      scope={['https://www.googleapis.com/auth/drive.readonly']}
      onChange={onGoogleDriveAction}
      authImmediate={false}
      navHidden
      mimeTypes={mimeTypes}
      viewId="FOLDERS"
    >
      <Button theme={ButtonTheme.TRANSPARENT} classNames={styles.option}>
        <MediaBlock
          text="Google Drive"
          color={Color.DARK_GRAY_BLUE}
          fontSize={FontSize.LARGE}
          mediaSize={MediaSize.LARGE}
          media={GoogleDriveLogo}
          classNames={styles.media}
        />
      </Button>
    </GooglePicker>
    <DropboxChooser
      appKey={DROPBOX_APP_KEY}
      linkType="direct"
      success={downloadDropboxFile}
      extensions={getExtensionsWithDot(extensions)}
      multiselect={false}
    >
      <Button theme={ButtonTheme.TRANSPARENT} classNames={styles.option}>
        <MediaBlock
          classNames={styles.media}
          text="Dropbox"
          color={Color.DARK_GRAY_BLUE}
          fontSize={FontSize.LARGE}
          mediaSize={MediaSize.LARGE}
          media={DropboxLogo}
        />
      </Button>
    </DropboxChooser>
    {/* TODO: Commented box out since it's not working. Uncomment when redirect is fixed */}
    {/* <BoxPicker
      buttonClassName={styles.option}
      mediaBlockClassName={styles.media}
      onFileSelected={downloadBoxFile}
      mediaSize={MediaSize.LARGE}
      fontSize={FontSize.LARGE}
      theme={ButtonTheme.TRANSPARENT}
    /> */}
  </div>
)

CloudOptionModal.propTypes = {
  // TODO: Commented box out since it's not working. Uncomment when redirect is fixed
  // downloadBoxFile: PropTypes.func.isRequired,
  onGoogleDriveAction: PropTypes.func.isRequired,
  downloadDropboxFile: PropTypes.func.isRequired,
  extensions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mimeTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default CloudOptionModal
