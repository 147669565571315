import React from 'react'
import PropTypes from 'prop-types'

import File from '_assets/icons/ic_file.svg'

import styles from './styles.css'

const Upload = ({ onChange, accept, setRef, onClick }) => (
  <div className={styles.container}>
    <input
      type="file"
      onChange={onChange}
      accept={accept}
      ref={setRef}
      className={styles['select-from-computer']}
    />
    <button onClick={onClick} type="button">
      <svg aria-hidden="true" className={styles.icon} focusable="false">
        <use xlinkHref={File} />
      </svg>
      CHOOSE FILE
    </button>
    <p>The maximum size of an uploaded file is 3MB</p>
  </div>
)

Upload.propTypes = {
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  setRef: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Upload
