import React, { Fragment, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import DropboxChooser from 'react-dropbox-chooser'
import classnames from 'classnames'
import cookies from 'react-cookies'
import { useDispatch } from 'react-redux'

import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE, ALERT_MESSAGE } from '_components/toast'
import ComputerIcon from '_assets/icons/browse-computer.svg'
import DropboxIcon from '_assets/icons/dropbox_logo.svg'
import DriveIcon from '_assets/icons/google-drive-colored-icon.svg'
import UnsplashIcon from '_assets/icons/unsplash.svg'
import Svg from '_components/svg'
import GooglePicker from '_components/google-picker'
import UnsplashPicker from '_components/unsplash-picker'
import { DROPBOX_APP_KEY, GOOGLE_CLIENT_ID, GOOGLE_API_KEY } from '_config/environment'
import useToggle from '_hooks/use-modal'
import useWindowSize from '_hooks/use-window-size'
import { downloadUnsplashImagePercentage } from '_modules/assets/actions'
import {
  ALL_TYPES_EXTENSIONS,
  GOOGLE_ALL_TYPE_EXTENSIONS,
  IMAGE_TYPES_EXTENSIONS,
  GOOGLE_MIME_TYPES,
  imageExtensions,
} from '_utils/files'

import styles from './styles.css'

const PICKED = 'picked'

const GOOGLE_DRIVE_BASE_URL = 'https://www.googleapis.com/drive/v3/files'

export const BROWSE_FILES_THEME = {
  DEFAULT: 'default',
  LIGHT: 'light',
}

const BrowseFiles = ({
  hasUnsplashOption,
  isOnlyImagesAllowed,
  className,
  description,
  theme,
  onSelectFile,
  assetId,
  assetTitle,
  isAIGenerated,
}) => {
  const [isUnsplashModalOpen, onToggleUnsplashModal] = useToggle()
  const inputRef = useRef(null)

  const { isMobile } = useWindowSize()

  const dispatch = useDispatch()

  const onBrowseComputer = useCallback(() => {
    inputRef.current.click()
    if (window && window.posthog) {
      window.posthog.capture('FileUploadComputer')
    }
  }, [])

  const onFileInputChange = useCallback(
    event => {
      const { files } = event.target

      if (files) {
        onSelectFile(files[0])
      }
    },
    [onSelectFile]
  )

  const downloadFile = useCallback(
    (request, name, unsplash = false) => {
      fetch(request)
        .then(res => {
          if (!res.ok) {
            if (res.status === 403 && res.url.includes('export')) {
              throw new Error(
                'File size exceeds 10MB limit for Google Docs, Google Sheets or Google Slides.'
              )
            }
            throw new Error(ALERT_MESSAGE.ERROR.DEFAULT)
          }
          return res.blob()
        })
        .then(blob => {
          const { type } = blob
          onSelectFile(new File([blob], name, { type }))

          if (unsplash) {
            dispatch(
              downloadUnsplashImagePercentage({
                assetId,
                unsplashPercentage: undefined,
              })
            )
          }
        })
        .catch(error => {
          dispatch(
            openToasterAlert({
              type: ALERT_TYPE.ERROR,
              message: error.message,
            })
          )
        })
    },
    [assetId, dispatch, onSelectFile]
  )

  const downloadDropboxFile = useCallback(
    files => {
      const { link, name } = files[0]
      const extension = `.${name.split('.').pop()}`

      const sanitizedName = name.split(' ').join('_')

      const request = new Request(link)
      downloadFile(request, sanitizedName, extension)
    },
    [downloadFile]
  )

  const onGoogleDriveAction = useCallback(
    data => {
      if (data.action === PICKED) {
        const token = cookies.load('googleAccessToken')

        const { id, name, mimeType } = data.docs[0]

        let url = `${GOOGLE_DRIVE_BASE_URL}/${id}?alt=media`
        let fileName = name

        if (GOOGLE_MIME_TYPES.includes(mimeType)) {
          url = `${GOOGLE_DRIVE_BASE_URL}/${id}/export/?mimeType=application/pdf`
          fileName = `${name}.pdf`
        }

        let sanitizedName = fileName.split(' ').join('_')
        sanitizedName = sanitizedName.split('/').join('_')

        const options = {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        }

        const request = new Request(url, options)

        downloadFile(request, sanitizedName, mimeType)
      }
    },
    [downloadFile]
  )

  const downloadUnsplashFile = useCallback(
    file => {
      const { name, url } = file
      const sanitizedName = `${name.split(' ').join('_')}.jpeg`

      downloadFile(new Request(url), sanitizedName, true)
    },
    [downloadFile]
  )

  const handleClick = useCallback(
    type => () => {
      if (type === 'unsplash') {
        onToggleUnsplashModal()
        if (window && window.posthog) {
          window.posthog.capture('FileUploadUnsplash')
        }
      }
      if (type === 'dropbox' && window && window.posthog) {
        window.posthog.capture('FileUploadDropbox')
      }
      if (type === 'google-drive' && window.posthog) {
        window.posthog.capture('FileUploadGoogleDrive')
      }
    },
    [onToggleUnsplashModal]
  )

  return (
    <div className={classnames(styles[theme], className)}>
      <p className={styles.text}>{description}</p>
      <div className={styles['browse-options']}>
        <div className={styles.option}>
          <input
            className="visually-hidden"
            type="file"
            ref={inputRef}
            onChange={onFileInputChange}
            accept={isOnlyImagesAllowed ? IMAGE_TYPES_EXTENSIONS : ALL_TYPES_EXTENSIONS}
          />
          <button
            className={styles['icon-wrapper']}
            type="button"
            onClick={onBrowseComputer}
            aria-label="Computer"
          >
            <Svg icon={ComputerIcon} className={styles['option-icon']} />
          </button>
          <p className={styles.label}>{isMobile ? 'Device' : 'Computer'}</p>
        </div>
        <DropboxChooser
          appKey={DROPBOX_APP_KEY}
          linkType="direct"
          success={downloadDropboxFile}
          extensions={isOnlyImagesAllowed ? IMAGE_TYPES_EXTENSIONS : ALL_TYPES_EXTENSIONS}
          multiselect={false}
          className={styles.option}
        >
          <div className={styles.option}>
            <button
              className={styles['icon-wrapper']}
              type="button"
              aria-label="Computer"
              onClick={handleClick('dropbox')}
            >
              <Svg
                icon={DropboxIcon}
                className={classnames(styles['option-icon'], styles['dropbox-icon'])}
              />
            </button>
            <p className={styles.label}>Dropbox</p>
          </div>
        </DropboxChooser>
        <GooglePicker
          clientId={GOOGLE_CLIENT_ID}
          developerKey={GOOGLE_API_KEY}
          scope={['https://www.googleapis.com/auth/drive.readonly']}
          onChange={onGoogleDriveAction}
          authImmediate={false}
          navHidden
          mimeTypes={isOnlyImagesAllowed ? imageExtensions : GOOGLE_ALL_TYPE_EXTENSIONS}
          viewId="FOLDERS"
        >
          <div className={styles.option}>
            <button
              className={styles['icon-wrapper']}
              type="button"
              aria-label="Computer"
              onClick={handleClick('google-drive')}
            >
              <Svg icon={DriveIcon} className={styles['option-icon']} />
            </button>
            <p className={styles.label}>Drive</p>
          </div>
        </GooglePicker>
        {hasUnsplashOption && (
          <Fragment>
            <div className={styles.option}>
              <button
                className={styles['icon-wrapper']}
                type="button"
                aria-label="Unsplash"
                onClick={handleClick('unsplash')}
              >
                <Svg icon={UnsplashIcon} className={styles['option-icon']} />
              </button>
              <p className={styles.label}>Unsplash</p>
            </div>
            {isUnsplashModalOpen && (
              <UnsplashPicker
                isOpen={isUnsplashModalOpen}
                onRequestClose={onToggleUnsplashModal}
                downloadUnsplashFile={downloadUnsplashFile}
                assetId={assetId}
                {...(isAIGenerated && {
                  assetTitle,
                })}
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  )
}

BrowseFiles.propTypes = {
  hasUnsplashOption: PropTypes.bool,
  isOnlyImagesAllowed: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(BROWSE_FILES_THEME)),
  onSelectFile: PropTypes.func.isRequired,
  assetId: PropTypes.number,
  assetTitle: PropTypes.string,
  isAIGenerated: PropTypes.bool,
}

BrowseFiles.defaultProps = {
  hasUnsplashOption: false,
  isOnlyImagesAllowed: false,
  className: undefined,
  description: 'Browse your files from:',
  theme: BROWSE_FILES_THEME.DEFAULT,
  assetId: undefined,
  assetTitle: undefined,
  isAIGenerated: false,
}

export default React.memo(BrowseFiles)
