import { fromJS } from 'immutable'
import { normalize } from 'normalizr'

export const normalizeItems = (items, modelSchema) => {
  const normalized = normalize(items, modelSchema).entities.result
    ? {
        items: normalize(items, modelSchema).entities.result,
        ids: normalize(items, modelSchema).result,
      }
    : []

  return fromJS(normalized)
}
