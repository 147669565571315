import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Input from '_components/ui-kit/input'
import Modal from '_components/ui-kit/modal'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import { notifyRecipients, NOTIFY_RECIPIENTS } from '_modules/presentations/actions'
import useFetchCall from '_hooks/use-fetch-call'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'

import styles from './styles.css'

const NotifyModal = ({ isOpen, onClose, presentationId }) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')

  const handleSuccess = useCallback(() => {
    onClose()
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.SUCCESS,
        message: 'Notifications sent!',
      })
    )
  }, [dispatch, onClose])

  const handleError = useCallback(() => {
    onClose()
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong.',
      })
    )
  }, [dispatch, onClose])

  const [isLoading] = useFetchCall(NOTIFY_RECIPIENTS, handleSuccess, handleError)

  const handleChange = useCallback(e => {
    setMessage(e.target.value)
  }, [])

  const handleClick = useCallback(() => {
    const payload = {
      message,
    }
    dispatch(notifyRecipients(presentationId, payload))
  }, [dispatch, presentationId, message])

  return (
    <Modal isOpen={isOpen} isClosable onClose={onClose} className={styles.modal}>
      <div className={styles.container}>
        <h2 className={styles.title}>Add your message!</h2>
        <Input
          placeholder="You can write a message if you want"
          value={message}
          onChange={handleChange}
          textareaClassName={styles.input}
          type="textarea"
          className={styles['input-container']}
        />
        <Button
          onClick={onClose}
          theme={BUTTON_THEME.LINK_BUTTON}
          className={styles['cancel-button']}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button onClick={handleClick} isLoading={isLoading} disabled={isLoading}>
          Send notification
        </Button>
      </div>
    </Modal>
  )
}

NotifyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  presentationId: PropTypes.string.isRequired,
}

export default NotifyModal
