export const UPDATE_STATE = 'UPDATE_STATE'
export const TOGGLE_REPOSITION = 'TOGGLE_REPOSITION'
export const TOGGLE_MEDIA_LIBRARY = 'TOGGLE_MEDIA_LIBRARY'
export const TOGGLE_COVER_CHANGE = 'TOGGLE_COVER_CHANGE'
export const TOGGLE_OPACITY_CHANGE = 'TOGGLE_OPACITY_CHANGE'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    case TOGGLE_REPOSITION:
      return { ...state, isRepositioningCover: !state.isRepositioningCover }
    case TOGGLE_MEDIA_LIBRARY:
      return { ...state, isMediaLibraryModalOpen: !state.isMediaLibraryModalOpen }
    case TOGGLE_COVER_CHANGE:
      return { ...state, isCoverChanging: !state.isCoverChanging }
    case TOGGLE_OPACITY_CHANGE:
      return { ...state, isChangeOpacity: !state.isChangeOpacity }
    default:
      return state
  }
}
