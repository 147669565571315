import PropTypes from 'prop-types'
import React, { useEffect, useCallback } from 'react'
import Modal from 'react-modal'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { browserHistory } from 'react-router'

import { GA_ENABLED } from '_config/environment'
import Button, { ButtonTheme } from '_components/button'
import Copy from '_assets/icons/copy.svg'
import CloseIcon from '_assets/icons/close.svg'
import { copyPresentation, COPY_PRESENTATION } from '_modules/presentations/actions' // TODO change action to new module 'presentations'
import {
  currentPresentationIdSelector,
  copyPresentationLoadingSelector,
} from '_modules/presentations/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'
import { PATHS } from '_utils/constants'

import styles from './styles.css'

const INFO_TEXT =
  "This will create a duplicate of this presentation and all its items. This will not create a copy of any links you've created or people you've shared with"

const CopyModal = ({ isOpen, dismiss, presentationName, presentationId }) => {
  const isCopyPresentationLoading = useSelector(copyPresentationLoadingSelector)
  const presentationDuplicatedId = useSelector(currentPresentationIdSelector)

  const dispatch = useDispatch()

  const presentationTitleString = presentationName ? `‘${presentationName}’` : 'this presentation'

  useEffect(() => {
    if (GA_ENABLED) {
      ReactGA.modalview(`${window.location.pathname}/copy-modal`)
    }
  }, [])

  const onCopyClick = useCallback(() => {
    dispatch(copyPresentation(presentationId))
  }, [dispatch, presentationId])

  const onSuccess = useCallback(() => {
    browserHistory.push(
      `${PATHS.PRESENTATIONS_PATHNAME.BASE}/${PATHS.PRESENTATIONS_PATHNAME.EDIT}/${presentationDuplicatedId}`
    )
  }, [presentationDuplicatedId])

  const onReject = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong. Please try again.',
      })
    )

    dismiss()
  }, [dispatch, dismiss])

  useFetchCall(COPY_PRESENTATION, onSuccess, onReject)

  return (
    <Modal
      className={styles.modal}
      contentLabel="Copy presentation confirmation"
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      bodyOpenClassName={styles['disable-body-scroll']}
      ariaHideApp={false}
    >
      <div className={styles['title-container']}>
        <svg aria-hidden="true" className={styles['copy-icon']}>
          <use xlinkHref={Copy} />
        </svg>
        <p className={styles.title}>Duplicate Presentation</p>
        <button onClick={dismiss} type="button" className={styles['close-button']}>
          <svg alt="close" className={styles['close-icon']}>
            <use xlinkHref={CloseIcon} />
          </svg>
        </button>
      </div>
      <div className={styles['message-container']}>
        <p className={styles.message}>Do you want to duplicate {presentationTitleString}?</p>
      </div>
      <p className={styles.info}>{INFO_TEXT}</p>
      <div className={styles['buttons-container']}>
        <Button
          onClick={dismiss}
          theme={ButtonTheme.TRANSPARENT}
          classNames={styles['cancel-button']}
          type="button"
        >
          CANCEL
        </Button>

        <Button
          onClick={onCopyClick}
          isLoading={isCopyPresentationLoading}
          classNames={styles['copy-button']}
        >
          DUPLICATE
        </Button>
      </div>
    </Modal>
  )
}
CopyModal.propTypes = {
  isOpen: PropTypes.bool,
  dismiss: PropTypes.func,
  presentationName: PropTypes.string,
  presentationId: PropTypes.number,
}

CopyModal.defaultProps = {
  isOpen: false,
  dismiss: undefined,
  presentationName: null,
  presentationId: undefined,
}

export default CopyModal
