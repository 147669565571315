import { Map, OrderedMap } from 'immutable'
import humps from 'humps'

import { createReducer } from '_privateDependencies/react-utils/utils'
import { Folder } from '_models/'

import {
  CREATE_FOLDER,
  UPDATE_FOLDER,
  LIST_FOLDERS,
  SEARCH_FOLDERS,
  DELETE_FOLDER,
} from './actions'

const INITIAL_STATE = Map({
  next: undefined,
  folders: Map({
    results: OrderedMap(),
  }),
  allFolders: Map({
    results: OrderedMap(),
  }),
})

const normalizeOrderedMap = (payload, Model) =>
  payload.results
    ? OrderedMap(payload.results.map(item => [item.id, new Model(item)]))
    : OrderedMap()

const setFolders = (state, payload, page) => {
  if (page) {
    return state
      .updateIn(['folders', 'results'], folders => folders.concat(payload))
      .updateIn(['allFolders', 'results'], folders => folders.concat(payload))
  }
  return state.setIn(['folders', 'results'], payload).setIn(['allFolders', 'results'], payload)
}

export default createReducer(INITIAL_STATE, {
  [CREATE_FOLDER.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return setFolders(state, normalizeOrderedMap(camelizedPayload, Folder))
  },

  [UPDATE_FOLDER.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return state
      .mergeIn(['folders', 'results', payload.id], camelizedPayload)
      .mergeIn(['allFolders', 'results', payload.id], camelizedPayload)
  },

  [LIST_FOLDERS.FULFILLED]: (state, { payload, meta }) => {
    const { page } = meta
    const camelizedPayload = humps.camelizeKeys(payload)

    return setFolders(state, normalizeOrderedMap(camelizedPayload, Folder), page).setIn(
      ['next'],
      payload.next
    )
  },

  [SEARCH_FOLDERS.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return state
      .setIn(['allFolders', 'results'], normalizeOrderedMap(camelizedPayload, Folder))
      .setIn(['next'], payload.next)
  },

  [DELETE_FOLDER.FULFILLED]: (state, { meta }) =>
    state
      .deleteIn(['folders', 'results', Number(meta.folderId)])
      .deleteIn(['allFolders', 'results', Number(meta.folderId)]),
})
