import React, { useMemo, useCallback, Fragment, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import DeleteIcon from '_assets/icons/trash-bin.svg'
import MoreIcon from '_assets/icons/more.svg'
import HideIcon from '_assets/icons/hide.svg'
import HideIconModal from '_assets/icons/ic-hide-asset.svg'
import MoreActions from '_components/more-actions'
import Button, { BUTTON_THEME } from '_components/button-round'
import { ALERT_TYPE, ALERT_MESSAGE } from '_components/toast'
import {
  deletePresentationAsset,
  DELETE_ASSET,
  EDIT_ASSET,
  editPresentationAsset,
} from '_modules/assets/actions'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import useToggle from '_hooks/use-modal'
import useFetchCall from '_hooks/use-fetch-call'
import { Asset } from '_models'
import { ASSET_TYPE } from '_constants/presentation'
import { getAssetViewText } from '_utils/asset'
import { watchDeleteAsset } from '_modules/edit-assets/actions'
import ConfirmationModal from '_components/confirmation-modal'

import styles from './styles.css'

// TODO: remove 'isOldModule' when the old presentation route is replaced to the refactored one

const AssetMoreActions = ({
  isOldModule,
  asset,
  className,
  showInfo,
  presentationId,
  onPreview,
  deleteOnly,
}) => {
  const [isDeleteModalOpen, onToggleDeleteModal] = useToggle()
  const [isDeletingAsset, onToggleIsDeletingAsset] = useToggle()
  const [isShowHideModalOpen, onToggleShowHideModal] = useToggle()
  const [isHidingAsset, toggleHidingAsset] = useState(false)

  const dispatch = useDispatch()

  const onSuccess = useCallback(() => {
    if (isHidingAsset) {
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.SUCCESS,
          message: ALERT_MESSAGE.SUCCESS.DEFAULT,
        })
      )
    }
    toggleHidingAsset(false)
  }, [dispatch, isHidingAsset])

  const onReject = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: ALERT_MESSAGE.ERROR.DEFAULT,
      })
    )
    toggleHidingAsset(false)
  }, [dispatch])

  const [isEditing] = useFetchCall(EDIT_ASSET, onSuccess, () => onReject())

  const options = useMemo(() => {
    const deleteOption = {
      onSelect: () => !isDeleteModalOpen && onToggleDeleteModal(),
      disabled: isEditing,
      label: 'Delete',
      icon: DeleteIcon,
    }

    const hideOption = {
      onSelect: () => !isShowHideModalOpen && onToggleShowHideModal(),
      disabled: isEditing,
      label: 'Hide asset',
      icon: HideIcon,
    }

    return deleteOnly ? [deleteOption] : [deleteOption, hideOption]
  }, [
    isDeleteModalOpen,
    isEditing,
    isShowHideModalOpen,
    onToggleDeleteModal,
    onToggleShowHideModal,
    deleteOnly,
  ])

  const onGoToWebsite = useCallback(() => window.open(asset.get('website'), '_blank'), [asset])

  const goButton = useMemo(
    () => ({
      [ASSET_TYPE.LINK]: {
        label: asset.get('website'),
        onClick: onGoToWebsite,
        labelOnClick: onPreview,
      },
      [ASSET_TYPE.VIDEO]: {
        label: asset.get('website'),
        onClick: onPreview,
        labelOnClick: onPreview,
      },
      [ASSET_TYPE.FILE]: {
        label: asset.get('numberOfPages') ? `Number of pages ${asset.get('numberOfPages')}` : '',
        onClick: onPreview,
        labelOnClick: onPreview,
      },
    }),
    [asset, onGoToWebsite, onPreview]
  )

  const onDelete = useCallback(() => {
    if (isOldModule) {
      dispatch(watchDeleteAsset(asset.get('id')))
      // dispatch(deletePresentationAssetOld(presentationId, asset.get('id')))
    } else {
      dispatch(deletePresentationAsset(presentationId, asset.get('id')))
    }

    onToggleIsDeletingAsset()
  }, [isOldModule, asset, dispatch, onToggleIsDeletingAsset, presentationId])

  const onHideAsset = useCallback(() => {
    dispatch(
      editPresentationAsset({
        id: asset.get('id'),
        presentationId,
        params: {},
        payload: { isHidden: !asset.get('isHidden') },
      })
    )
    toggleHidingAsset(true)
    onToggleShowHideModal()
  }, [asset, dispatch, onToggleShowHideModal, presentationId])

  const onDeleteSuccess = useCallback(() => {
    if (isDeleteModalOpen) {
      onToggleDeleteModal()
    }

    if (isDeletingAsset) {
      onToggleIsDeletingAsset()
    }
  }, [isDeleteModalOpen, isDeletingAsset, onToggleDeleteModal, onToggleIsDeletingAsset])

  const onDeleteReject = useCallback(() => {
    if (isDeletingAsset) {
      onToggleIsDeletingAsset()
    }

    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong.',
      })
    )
  }, [dispatch, isDeletingAsset, onToggleIsDeletingAsset])

  useFetchCall(DELETE_ASSET, onDeleteSuccess, onDeleteReject)

  const renderInfo = useMemo(
    () =>
      showInfo && (
        <Fragment>
          {asset.get('type') &&
            (asset.get('type') !== ASSET_TYPE.FILE ||
              (asset.get('type') === ASSET_TYPE.FILE && asset.get('numberOfPages') > 0)) && (
              <div className={styles.go}>
                <Button
                  className={styles['go-button']}
                  theme={BUTTON_THEME.SECONDARY}
                  onClick={goButton[asset.get('type')].onClick}
                >
                  {getAssetViewText({
                    filename: asset.get('filename'),
                    prefix: 'Preview',
                    type: asset.get('type'),
                  })}
                </Button>
                <Button
                  className={styles['go-text']}
                  title={goButton[asset.get('type')].label}
                  theme={BUTTON_THEME.TRANSPARENT_BACKGROUND}
                  onClick={goButton[asset.get('type')].labelOnClick}
                >
                  {goButton[asset.get('type')].label}
                </Button>
              </div>
            )}
        </Fragment>
      ),
    [asset, goButton, showInfo]
  )

  return (
    <div className={classnames(className, styles['actions-wrapper'])}>
      {renderInfo}
      <MoreActions
        title="More actions"
        className={styles['more-actions-icon']}
        icon={MoreIcon}
        options={options}
        menuListClassName={styles['menu-list-options']}
      />
      {isShowHideModalOpen && (
        <ConfirmationModal
          title="Hide asset?"
          description="This will modify any shared link for this presentation."
          isClosable
          isOpen={isShowHideModalOpen}
          onClose={onToggleShowHideModal}
          image={HideIconModal}
          classNameDescription={styles['hide-asset-description']}
          onCloseText="Cancel"
          onFinish={onHideAsset}
          onFinishText="Hide asset"
        />
      )}
      {isDeleteModalOpen && (
        <div className={styles['delete-asset']}>
          <p className={styles.title}>Remove this item?</p>
          <Button
            className={styles['cancel-button']}
            disabled={isDeletingAsset}
            theme={BUTTON_THEME.SECONDARY}
            onClick={onToggleDeleteModal}
          >
            Cancel
          </Button>
          <Button
            isLoading={isDeletingAsset}
            disabled={isDeletingAsset}
            className={styles['delete-button']}
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  )
}

AssetMoreActions.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  className: PropTypes.string,
  presentationId: PropTypes.number.isRequired,
  showInfo: PropTypes.bool,
  onPreview: PropTypes.func,
  isOldModule: PropTypes.bool,
  deleteOnly: PropTypes.bool,
}

AssetMoreActions.defaultProps = {
  className: null,
  showInfo: false,
  isOldModule: false,
  onPreview: () => {},
  deleteOnly: false,
}

export default React.memo(AssetMoreActions)
