import { schema } from 'normalizr'

import Model from './model'

class Asset extends Model({
  cloudconvertDownloadReadyTime: undefined,
  cloudconvertEndTime: undefined,
  cloudconvertStartTime: undefined,
  contact: undefined,
  convertedFiles: undefined,
  createdAt: undefined,
  description: undefined,
  file: undefined,
  filename: undefined,
  generateThumbnailEndTime: undefined,
  generateThumbnailStartTime: undefined,
  id: undefined,
  isHidden: undefined,
  isUsingScreenshot: undefined,
  jobStatus: undefined,
  jobThumbnailId: undefined,
  jobThumbnailStatus: undefined,
  numberOfPages: undefined,
  originalThumbnail: undefined,
  percentageUpload: undefined,
  position: undefined,
  receiveUploadEndTime: undefined,
  receiveUploadStartTime: undefined,
  thumbnail: undefined,
  thumbnailCropped: undefined,
  thumbnailIsOriginal: undefined,
  thumbnailMeta: undefined,
  thumbnailScreenshot: undefined,
  title: undefined,
  type: undefined,
  unsplashAuthor: undefined,
  unsplashAuthorLink: undefined,
  updatedAt: undefined,
  website: undefined,
  unsplashPercentage: undefined,
  uploading: undefined,
  backgroundOpacity: undefined,
  forceUpdate: undefined,
  hasError: undefined,
  showSelectionSummarize: undefined,
  summarizerProgress: undefined,
  aiSummarizerResult: undefined,
  ignoreAssetSummarizer: undefined,
  url: undefined,
  fields: undefined,
  isLoadingS3: undefined,
}) {}

export default Asset

const assetNewSchema = new schema.Entity(
  'result',
  {},
  {
    idAttribute: 'id',
    processStrategy: ({ id, ...entity }) => new Asset({ id, ...entity }),
  }
)

export const assetSchema = [assetNewSchema]
