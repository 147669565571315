import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classnames from 'classnames'

import ViewIcon from '_assets/icons/view.svg'
import gridStyles from '_styles/grid.css'

import styles from './styles.css'

export const InputType = {
  TEXT: 'text',
  PASSWORD: 'password',
}

export const InputTheme = {
  CLOUDY_BLUE: 'cloudy-blue',
  COOL_GRAY: 'cool-gray',
  LIGHT: 'light',
}

class TextInput extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(Object.keys(InputType).map(key => InputType[key])),
    theme: PropTypes.oneOf(Object.keys(InputTheme).map(key => InputTheme[key])),
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    classNames: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
  }

  static defaultProps = {
    name: '',
    type: InputType.TEXT,
    value: '',
    theme: InputTheme.CLOUDY_BLUE,
    disabled: false,
    labelClassName: '',
    inputClassName: '',
    classNames: '',
    placeholder: '',
    error: false,
  }

  state = {
    hideText: true,
  }

  toggleShowText = event => {
    const button = event.target
    this.setState(
      state => ({
        hideText: !state.hideText,
      }),
      () => button.blur()
    )
  }

  render() {
    const {
      type,
      label,
      name,
      value,
      placeholder,
      onChange,
      theme,
      disabled,
      labelClassName,
      inputClassName,
      error,
      classNames,
    } = this.props
    const { hideText } = this.state

    const borderColorError = error && 'red'
    const dynamicInputType = type === 'password' && hideText ? 'password' : 'text'

    return (
      <div className={classnames(classNames)}>
        <p className={classnames(styles.label, styles[theme], labelClassName)}>{label}</p>
        <div
          className={classnames(
            gridStyles.Grid,
            gridStyles['Grid--alignMiddle'],
            styles.container,
            styles[theme]
          )}
          style={{
            borderColor: borderColorError,
          }}
        >
          <input
            type={dynamicInputType}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
            className={classnames(
              gridStyles['Grid-cell'],
              gridStyles['u-sizeFill'],
              styles['text-input'],
              { [styles['password-space']]: type === 'password' },
              inputClassName
            )}
          />
          {type === 'password' && (
            <button
              onClick={this.toggleShowText}
              type="button"
              className={classnames(
                gridStyles['Grid-cell'],
                gridStyles['u-sizeFit'],
                styles['view-button']
              )}
            >
              <svg
                alt="Show"
                className={classnames(styles['view-icon'])}
                style={hideText ? { fill: '#d2d7e0' } : { fill: '#545c65' }}
              >
                <use xlinkHref={ViewIcon} />
              </svg>
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default TextInput
