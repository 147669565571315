import { isPromise } from '_privateDependencies/react-utils/utils'
import { UNAUTHORIZED } from '_modules/user/actions'

export default function unauthorizedMiddleware() {
  return next => action => {
    const result = next(action)

    if (!isPromise(result)) {
      return action
    }

    return result.catch(error => {
      if (error.detail === 'Invalid token.') {
        return next({
          type: UNAUTHORIZED,
          error: false,
        })
      }

      return action
    })
  }
}
