export const CACHE_PDF = 'CACHE_PDF'

export const cachePdf = (name, file) => dispatch =>
  dispatch({
    type: CACHE_PDF,
    payload: {
      name,
      file,
    },
  })
