import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import UserPlaceholder, { PLACEHOLDER_SIZE } from '_components/user-placeholder'
import Button, { BUTTON_THEME } from '_components/button-new'
import onMouseDown from '_utils/on-mouse-down'
import {
  isFreeUserSelector,
  isTrialActiveSelector,
  userHasCompaniesSelector,
  hasAccessToBillingSelector,
} from '_modules/user/selectors'
import { HELP_URL, PATHS } from '_utils/constants'
import {
  FLAG_SHOW_CREATE_TEAM_FLOW,
  FLAG_SHOW_REDIRECT_BILLING_ADMIN,
  ADMIN_PAGE_URL,
} from '_config/environment'

import styles from './styles.css'

const ProfileDropdown = ({
  className,
  picture,
  userName,
  userEmail,
  onLogoutClick,
  onClickCreateTeam,
}) => {
  const isFreemiumUser = useSelector(isFreeUserSelector)
  const userHasCompanies = useSelector(userHasCompaniesSelector)
  const userIsAdmin = useSelector(hasAccessToBillingSelector)
  const isTrialActive = useSelector(isTrialActiveSelector)

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles['profile-content']}>
        {picture ? (
          <img src={picture} alt="User avatar" className={styles.picture} />
        ) : (
          <UserPlaceholder
            backgroundColor="#000000"
            userName={userName}
            size={PLACEHOLDER_SIZE.BIG}
          />
        )}
        <div className={styles.profile}>
          <p className={styles.name}>{userName}</p>
          <p className={styles.email}>{userEmail}</p>
          <Link
            to={`${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.EDIT_PROFILE_PATH_NAME}`}
            className={styles.action}
          >
            View profile
          </Link>
        </div>
      </div>
      <div className={styles['extra-options']}>
        {(isFreemiumUser || isTrialActive) && (
          <Button
            to="/payment"
            theme={BUTTON_THEME.PINK_NO_BACKGROUND_NO_BORDER}
            className={styles['update-now']}
          >
            Upgrade now
          </Button>
        )}

        <Link
          to={PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}
          className={classnames(styles.action, styles.link)}
        >
          Account settings
        </Link>
        {FLAG_SHOW_REDIRECT_BILLING_ADMIN && userIsAdmin && (
          <Link
            to={ADMIN_PAGE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(styles.action, styles.link)}
          >
            Manage Team
          </Link>
        )}
        {FLAG_SHOW_CREATE_TEAM_FLOW && !userHasCompanies && (
          <button
            aria-label="Create a team"
            type="button"
            onClick={onClickCreateTeam}
            className={classnames(styles.action, styles.button)}
          >
            Create a team
          </button>
        )}
        <a
          className={classnames(styles.action, styles.link)}
          href={HELP_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Help
        </a>
        <button
          aria-label="sign out"
          type="button"
          className={classnames(styles.action, styles.button)}
          onClick={onLogoutClick}
          onMouseDown={onMouseDown}
        >
          Sign out
        </button>
      </div>
    </div>
  )
}
ProfileDropdown.propTypes = {
  picture: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  onLogoutClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  onClickCreateTeam: PropTypes.func,
}

// FIXME: The tests are breaking here, it should be required
ProfileDropdown.defaultProps = {
  picture: null,
  userName: '',
  userEmail: '',
  className: '',
  onClickCreateTeam: () => {},
}

export default ProfileDropdown
