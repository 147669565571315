import React, { PureComponent } from 'react'
import classNames from 'classnames'
import humps from 'humps'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

import SearchIcon from '_assets/icons/search.svg'
import withViewportSize from '_hocs/with-viewport-size'
import Loading from '_components/loading'
import createPhotoColumns from '_components/media-library/create-photo-columns'
import Photo from '_components/media-library/photo'
import { updateCoverConversionPercentage } from '_modules/presentations/actions'

import styles from './styles.css'

const mapDispatchToProps = { updateCoverConversionPercentage }
class Unsplash extends PureComponent {
  searchText = debounce(text => {
    this.setState({ isLoading: true, loadingMore: false })
    fetch(`/unsplash/search/${text}`)
      .then(response => response.json())
      .then(res => {
        const { results, total_pages: totalPagesUnsplash } = res
        this.setState({
          photos: humps.camelizeKeys(results || res),
          totalPages: totalPagesUnsplash,
          isLoading: false,
        })
      })
  }, 500)

  static propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    downloadUnsplashFile: PropTypes.func.isRequired,
    isSmallViewport: PropTypes.bool.isRequired,
    presentationId: PropTypes.string,
    isCoverImage: PropTypes.bool,
    onToggleCoverChangingLoading: PropTypes.func,
    updateCoverConversionPercentage: PropTypes.func,
    presentationTitle: PropTypes.number,
  }

  static defaultProps = {
    presentationId: undefined,
    isCoverImage: false,
    onToggleCoverChangingLoading: undefined,
    updateCoverConversionPercentage: undefined,
    presentationTitle: undefined,
  }

  state = {
    isLoading: false,
    loadingMore: false,
    photos: [],
    photoColumns: createPhotoColumns([], 4),
    search: this.props.presentationTitle || '',
    page: 1,
    totalPages: 0,
    focusInput: false,
  }

  componentDidMount() {
    const { presentationTitle } = this.props
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isLoading: true })
    fetch(presentationTitle ? `/unsplash/search/${presentationTitle}` : '/unsplash/search')
      .then(response => response.json())
      .then(res => {
        const { results } = res
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ photos: humps.camelizeKeys(results || res), isLoading: false })
      })

    window.addEventListener('resize', this.arrangePhotoInColumns)
  }

  componentDidUpdate(prevProps, prevState) {
    const { photos: prevPhotos } = prevState
    const { photos } = this.state

    if (prevPhotos !== photos) {
      this.arrangePhotoInColumns()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.arrangePhotoInColumns)
  }

  onChangeSearch = event => {
    this.setState({
      isLoading: true,
      search: event.target.value,
      photos: [],
      page: 1,
      totalPages: 0,
    })
    this.searchText(event.target.value)
  }

  onSelectFile = (event, id) => {
    const { presentationId, onToggleCoverChangingLoading } = this.props
    if (onToggleCoverChangingLoading && presentationId) {
      this.props.updateCoverConversionPercentage({
        percentage: 1,
        presentationId,
      })
      onToggleCoverChangingLoading()
    }

    fetch(`/unsplash/download/${id}`)
      .then(response => response.json())
      .then(res => {
        const downloadFile = { ...res, name: id }
        this.props.downloadUnsplashFile(downloadFile)
      })
    this.props.onRequestClose(event)
  }

  arrangePhotoInColumns = () => {
    const { isSmallViewport } = this.props

    const numOfColumns = isSmallViewport ? 3 : 4
    this.setState(state => ({ photoColumns: createPhotoColumns(state.photos, numOfColumns) }))
  }

  loadMorePhotos = () => {
    const { photos, page, totalPages, search, isLoading } = this.state
    if (photos.length === 0 || (totalPages && page >= totalPages)) {
      return
    }

    if (isLoading) {
      return
    }

    this.setState({ isLoading: true, loadingMore: true })
    fetch(`/unsplash/search${search && `/${search}`}?page=${page + 1}`)
      .then(response => response.json())
      .then(res => {
        const { results, total_pages: totalPagesUnsplash } = res
        this.setState(prevState => ({
          photos: [...prevState.photos, ...humps.camelizeKeys(results || res)],
          totalPages: totalPagesUnsplash,
          page: page + 1,
          isLoading: false,
          loadingMore: false,
        }))
      })
  }

  handleScroll = () => {
    const library = document.getElementById('unsplash-photo-library')
    if (library.scrollHeight - library.scrollTop <= library.clientHeight * 1.1) {
      this.loadMorePhotos()
    }
  }

  toogleSearchBorder = () => this.setState(prevState => ({ focusInput: !prevState.focusInput }))

  render() {
    const { photoColumns, search, focusInput, isLoading, loadingMore } = this.state

    return (
      <div className={styles.container}>
        <div className={styles['search-container']}>
          <input
            onFocus={this.toogleSearchBorder}
            onBlur={this.toogleSearchBorder}
            className={classNames(styles.search, { [styles.focus]: focusInput })}
            onChange={this.onChangeSearch}
            placeholder="Search photos"
            type="search"
            value={search}
          />
          <svg
            aria-hidden="true"
            className={classNames(styles['search-icon'], { [styles['focus-icon']]: focusInput })}
            focusable="false"
          >
            <use xlinkHref={SearchIcon} />
          </svg>
        </div>
        <p className={styles['unsplash-credits']}>Photos provided by Unsplash</p>
        <div
          className={styles['photo-library']}
          tabIndex="-1"
          id="unsplash-photo-library"
          onScroll={this.handleScroll}
        >
          {(!isLoading || loadingMore) &&
            photoColumns.map((column, columnIndex) => (
              <div
                className={styles['photo-column']}
                // eslint-disable-next-line react/no-array-index-key
                key={columnIndex}
                role="radiogroup"
              >
                {column.photos.map(photo => (
                  <Photo
                    onClick={this.onSelectFile}
                    className={styles.photo}
                    key={photo.id}
                    {...photo}
                  />
                ))}
              </div>
            ))}
          {isLoading && (
            <Loading
              className={classNames(styles.loading, { [styles['loading-more']]: loadingMore })}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(withViewportSize(Unsplash))
