import { List } from 'immutable'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import MarkAsReadIcon from '_assets/icons/markallasread.svg'
import NotificationIcon from '_assets/icons/new.svg'
import withBrowserInformation from '_hocs/with-browser-information'
import { loadMoreNotifications, markAllAsRead, markAsRead } from '_modules/insights/actions'
import { onMouseDown } from '_utils/on-mouse-down'
import useModal from '_hooks/use-modal'

import Notification from './notification'
import styles from './styles.css'

const NotificationComponent = ({
  className,
  counter,
  isInternetExplorer,
  insights,
  nextPage,
  markAsRead: readNotification,
  markAllAsRead: markAllNotificationsAsRead,
  loadMoreNotifications: loadNotifications,
}) => {
  const [openedNotification, setOpenedNotification] = useState(false)
  const [isShareLinkModalOpen, onToggleShareLinkModalOpen] = useModal()
  const [isShareLinkPublicModalOpen, onToggleShareLinkPublicModalOpen] = useModal()
  const [isShareEmailModalOpen, onToggleShareEmailModalOpen] = useModal()

  const ref = useRef(null)

  const onCloseNotificationComponent = useCallback(
    event => {
      if (
        (ref.current.contains(event.target) && !event.target.href) ||
        isShareLinkModalOpen ||
        isShareLinkPublicModalOpen ||
        isShareEmailModalOpen
      ) {
        return
      }
      setOpenedNotification(false)
    },

    [isShareEmailModalOpen, isShareLinkModalOpen, isShareLinkPublicModalOpen]
  )

  const onOpenNotification = useCallback(() => {
    if (openedNotification) {
      document.addEventListener('click', onCloseNotificationComponent)
    } else {
      document.removeEventListener('click', onCloseNotificationComponent)
    }

    setOpenedNotification(prevState => !prevState)
  }, [onCloseNotificationComponent, openedNotification])

  useEffect(() => {
    if (openedNotification) {
      document.addEventListener('click', onCloseNotificationComponent)
    }

    return () => {
      document.removeEventListener('click', onCloseNotificationComponent)
    }
  }, [onCloseNotificationComponent, openedNotification])

  const readAll = useCallback(
    event => {
      event.preventDefault()
      if (counter > 0) {
        markAllNotificationsAsRead()
      }
    },
    [counter, markAllNotificationsAsRead]
  )

  const loadMore = useCallback(() => {
    if (nextPage) {
      loadNotifications()
    }
  }, [loadNotifications, nextPage])

  const markNotificationAsRead = useCallback(
    event => {
      event.preventDefault()
      const { id } = event.currentTarget

      const insight = insights.find(activity => activity.get('id') === Number(id))
      if (insight && !insight.get('read')) {
        readNotification(Number(id))
      }
    },
    [insights, readNotification]
  )

  return (
    <div className={classnames(styles.insights, className)} ref={ref}>
      <button
        className={styles['notification-button']}
        type="button"
        onClick={onOpenNotification}
        aria-label="open notifications"
        onMouseDown={onMouseDown}
      >
        <svg
          aria-hidden="true"
          className={classnames(styles['insight-icon'], { [styles.unseen]: counter > 0 })}
        >
          <use xlinkHref={NotificationIcon} />
        </svg>
      </button>
      {counter > 0 && (
        <div className={styles.counter}>
          <p {...(counter > 99 && { className: styles.smaller })}>
            {counter > 99 ? '99+' : counter}
          </p>
        </div>
      )}
      {openedNotification && (
        <div
          className={classnames(styles['notification-wrapper'], {
            [styles['ie-wrapper']]: isInternetExplorer,
          })}
        >
          <button
            type="button"
            className={classnames(styles['notification-actions'], {
              [styles.ie]: isInternetExplorer,
            })}
            onClick={readAll}
          >
            <div className={styles['notification-actions-container']}>
              <svg alt="Mark all as read">
                <use xlinkHref={MarkAsReadIcon} />
              </svg>
              <p className={styles['action-text']}>MARK ALL AS READ</p>
            </div>
          </button>
          <ul className={styles.notifications}>
            {insights.size > 0 ? (
              <Notification
                insights={insights}
                loadMore={loadMore}
                markAsRead={markNotificationAsRead}
                isShareLinkModalOpen={isShareLinkModalOpen}
                isShareLinkPublicModalOpen={isShareLinkPublicModalOpen}
                isShareEmailModalOpen={isShareEmailModalOpen}
                onToggleShareLinkModalOpen={onToggleShareLinkModalOpen}
                onToggleShareLinkPublicModalOpen={onToggleShareLinkPublicModalOpen}
                onToggleShareEmailModalOpen={onToggleShareEmailModalOpen}
              />
            ) : (
              <li className={styles.item}>
                <p className={styles.description}>No updates to show</p>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

NotificationComponent.propTypes = {
  className: PropTypes.string,
  counter: PropTypes.number.isRequired,
  isInternetExplorer: PropTypes.bool.isRequired,
  nextPage: PropTypes.string,
  insights: PropTypes.instanceOf(List).isRequired,
  markAsRead: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  loadMoreNotifications: PropTypes.func.isRequired,
}

NotificationComponent.defaultProps = {
  className: '',
  nextPage: null,
}

const mapStateToProps = ({ insights }) => ({
  counter: insights.get('counter'),
  insights: insights.get('notifications'),
  nextPage: insights.get('notificationNextPage'),
})

const mapDispatchToProps = {
  loadMoreNotifications,
  markAllAsRead,
  markAsRead,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBrowserInformation(NotificationComponent))
