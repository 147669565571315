import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import Modal from 'react-modal'
import Spinner from 'react-spinkit'

import styles from './styles.css'

export const ModalPage = {
  TERMS_OF_USE: 'terms',
  PRIVACY_POLICY: 'privacy',
}

const TermsOfUseURL =
  'https://app.termly.io/document/terms-of-use-for-website/8e6acf7e-18fc-4825-9eff-21610fc4d6a2'
const PrivacyPolicyURL =
  'https://app.termly.io/document/privacy-policy/b4f693d3-0ce9-4d28-92e8-6a07ee43eebd'

class TermsPrivacyModal extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    modalPage: PropTypes.oneOf(Object.keys(ModalPage).map(key => ModalPage[key])),
    dismiss: PropTypes.func,
  }

  static defaultProps = {
    className: null,
    isOpen: false,
    modalPage: ModalPage.TERMS_OF_USE,
    dismiss: undefined,
  }

  state = {
    isLoadingContent: true,
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props
    if (!isOpen && nextProps.isOpen) {
      this.setState({
        isLoadingContent: true,
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    ReactDOM.unmountComponentAtNode(this.modalRef.node)
  }

  onLoadContent = () => {
    if (this.mounted) {
      this.setState({
        isLoadingContent: false,
      })
    }
  }

  setModalRef = ref => {
    this.modalRef = ref
  }

  render() {
    const { isOpen, modalPage, className, dismiss } = this.props
    const { isLoadingContent } = this.state

    const iframeURL = modalPage === ModalPage.TERMS_OF_USE ? TermsOfUseURL : PrivacyPolicyURL

    return (
      <Modal
        className={classnames(styles.modal, className)}
        overlayClassName={classnames(styles.overlay)}
        isOpen={isOpen}
        bodyOpenClassName={classnames(styles['disable-body-scroll'])}
        ref={this.setModalRef}
        contentLabel="Highnote Modal"
        ariaHideApp={false}
      >
        {isLoadingContent && (
          <Spinner name="cube-grid" color="#e82f72" fadeIn="none" className={styles.spinner} />
        )}
        <iframe
          width="100%"
          style={{ height: '95vh' }}
          src={iframeURL}
          frameBorder="0"
          allowFullScreen
          title="Terms and Privacy Policy"
          onLoad={this.onLoadContent}
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
        <button onClick={dismiss} className={classnames(styles['close-button'])} type="button">
          Close
        </button>
      </Modal>
    )
  }
}

export default TermsPrivacyModal
