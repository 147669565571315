/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const Checkbox = ({
  id,
  className,
  label,
  name,
  onChange,
  checked,
  helperText,
  labelClassName,
  inputClassName,
  value,
  onBlur,
  disabled,
}) => (
  <div className={classnames(styles.wrapper, className)}>
    <input
      className={classnames(styles.input, inputClassName)}
      id={`input${id}`}
      type="checkbox"
      name={name}
      onChange={onChange}
      checked={checked}
      value={value}
      onBlur={onBlur}
      disabled={disabled}
    />
    <label htmlFor={`input${id}`} className={classnames(styles.label, labelClassName)}>
      {label}
    </label>
    {helperText && <p className={styles['helper-text']}>{helperText}</p>}
  </div>
)
Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
}

Checkbox.defaultProps = {
  className: '',
  label: '',
  checked: false,
  name: '',
  onChange: () => {},
  id: undefined,
  helperText: '',
  labelClassName: '',
  inputClassName: '',
  onBlur: () => {},
  disabled: false,
}
export default React.memo(Checkbox)
