import React, { Fragment, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'

// eslint-disable-next-line import/no-cycle
import CircularProgressBar from '_components/circular-progress-bar'
import Svg from '_components/svg'
import CloseIcon from '_assets/icons/close-masonry.svg'
import AlertIcon from '_assets/icons/alert-icon.svg'
import { updateAssetConversionPercentageUploadSystemV2 } from '_modules/assets/actions'
import { getPresentationAssetByIdSelector } from '_modules/assets/selectors'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'

import styles from './styles.css'

export const PROGRESS_THEME = {
  BLACK_OPACITY: 'black_opacity',
  PINK: 'pink',
}

const AssetProgressBar = ({
  assetId,
  className,
  progressValue,
  onResend: onResendAsset,
  theme,
  handleClearProgress,
  hideError,
}) => {
  const dispatch = useDispatch()

  const asset = useSelector(state => getPresentationAssetByIdSelector(state, assetId))

  const onClearProgress = useCallback(() => {
    if (handleClearProgress) {
      handleClearProgress()
      return
    }
    dispatch(updateAssetConversionPercentageUploadSystemV2({ assetId, percentage: undefined }))
  }, [assetId, dispatch, handleClearProgress])

  const onClose = useCallback(() => {
    onClearProgress()
  }, [onClearProgress])

  const onResend = useCallback(() => {
    onClearProgress()
    onResendAsset()
  }, [onClearProgress, onResendAsset])

  useEffect(() => {
    if (asset && asset.get('hasError') && hideError) {
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.ERROR,
          message: 'Something went wrong dropping your file. Please try again.',
        })
      )
    }
  }, [asset, hideError, dispatch])

  return (
    <div className={classnames(className, styles['progress-wrapper'], styles[theme])}>
      {asset && asset.get('hasError') && !hideError ? (
        <Fragment>
          <button
            className={styles['close-button']}
            onClick={onClose}
            type="button"
            aria-label="Close upload button"
          >
            <Svg icon={CloseIcon} className={styles['close-button-svg']} />
          </button>
          <p className={styles['progress-text']}>Oops! Your cover pic couldn&apos;t load.</p>
          <span className={styles['progress-small-text']}>
            Something went wrong, try
            <button onClick={onResend} type="button" aria-label="Resent button">
              adding it again
            </button>
          </span>
          <Svg icon={AlertIcon} className={styles['alert-icon-svg']} />
        </Fragment>
      ) : (
        <Fragment>
          <p className={styles['progress-text']}>Whipping up your cover image.</p>
          <p className={styles['progress-small-text']}>It&apos;s in the digital oven!</p>
          <CircularProgressBar progressValue={progressValue} theme={theme} />
        </Fragment>
      )}
    </div>
  )
}

AssetProgressBar.propTypes = {
  assetId: PropTypes.number,
  className: PropTypes.string,
  progressValue: PropTypes.number.isRequired,
  onResend: PropTypes.func.isRequired,
  theme: PropTypes.string,
  handleClearProgress: PropTypes.func,
  hideError: PropTypes.bool,
}

AssetProgressBar.defaultProps = {
  className: null,
  theme: PROGRESS_THEME.PINK,
  handleClearProgress: null,
  assetId: null,
  hideError: false,
}

export default React.memo(AssetProgressBar)
