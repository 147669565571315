import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { List } from 'immutable'

import styles from './styles.css'

const Companies = ({ companies, onClick, name, className }) => (
  <div className={classnames(styles.companies, className)}>
    <p>Choose the company you want to create a template:</p>
    {companies.map(company => (
      <div
        role="button"
        key={company.getIn(['id'])}
        id={company.getIn(['id'])}
        onClick={onClick}
        tabIndex={0}
        name={name}
        aria-hidden="true"
      >
        {company.getIn(['name'])}
      </div>
    ))}
  </div>
)

Companies.propTypes = {
  className: PropTypes.string,
  companies: PropTypes.instanceOf(List).isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

Companies.defaultProps = {
  className: '',
}

export default Companies
