import * as presentationsService from '_services/presentations'
import * as presentationService from '_services/presentation' // TODO: Move this to presentationsService
import { defineAction } from '_privateDependencies/react-utils/utils'

export const GET_PRESENTATION = defineAction('GET_PRESENTATION')
export const UPDATE_PRESENTATION = defineAction('UPDATE_PRESENTATION')
export const LIST_PRESENTATIONS = defineAction('LIST_PRESENTATIONS')
export const FAVORITE_PRESENTATION = defineAction('FAVORITE_PRESENTATION')
export const REMOVE_FAVORITE_PRESENTATION = defineAction('REMOVE_FAVORITE_PRESENTATION')
export const COPY_PRESENTATION = defineAction('COPY_PRESENTATION')
export const CREATE_PRESENTATION_SHARE_LINK = defineAction('CREATE_PRESENTATION_SHARE_LINK')
export const CREATE_FOLDER = defineAction('CREATE_FOLDER')
export const LIST_FOLDERS = defineAction('LIST_FOLDERS')
export const UPDATE_COVER_PERCENTAGE = 'UPDATE_COVER_PERCENTAGE'
export const SHARE_PRESENTATION_THROUGH_EMAIL = defineAction('SHARE_PRESENTATION_THROUGH_EMAIL')
export const DELETE_PRESENTATION = defineAction('DELETE_PRESENTATION')
export const SEND_CONTACT_MESSAGE = defineAction('SEND_CONTACT_MESSAGE')
export const CREATE_PRESENTATION = defineAction('CREATE_PRESENTATION')
export const CREATE_TEMPLATE = defineAction('CREATE_TEMPLATE')
export const ENHANCE_PRESENTATION = defineAction('ENHANCE_PRESENTATION')
export const USE_TEMPLATE = defineAction('USE_TEMPLATE')
export const CLEAN_ENHANCE_PRESENTATION = 'CLEAN_ENHANCE_PRESENTATION'
export const DELETE_RECEIVED_PRESENTATION = defineAction('DELETE_RECEIVED_PRESENTATION')
export const DELETE_TEMPLATE = defineAction('DELETE_TEMPLATE')
export const MAKE_PRESENTATION_TEMPLATE = defineAction('MAKE_PRESENTATION_TEMPLATE')
export const RETRIEVE_PRESENTATION = defineAction('RETRIEVE_PRESENTATION')
export const RETRIEVE_PRESENTATIONS_STATS = defineAction('RETRIEVE_PRESENTATIONS_STATS')
export const NOTIFY_RECIPIENTS = defineAction('NOTIFY_RECIPIENTS')
export const HAS_PENDING_ASSETS = 'HAS_PENDING_ASSETS'
export const ARCHIVE_PRESENTATION = defineAction('ARCHIVE_PRESENTATION')
export const UNARCHIVE_PRESENTATION = defineAction('UNARCHIVE_PRESENTATION')

export const getPresentation = presentationId => (dispatch, getState) =>
  dispatch({
    type: GET_PRESENTATION.ACTION,
    payload: presentationsService.getPresentation(
      getState().user.get('auth_token'),
      presentationId
    ),
  })

export const updatePresentation = (presentationId, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PRESENTATION.ACTION,
    payload: presentationsService.updatePresentation(
      getState().user.get('auth_token'),
      presentationId,
      payload
    ),
  })

export const listPresentations = (params, currentTab) => (dispatch, getState) =>
  dispatch({
    type: LIST_PRESENTATIONS.ACTION,
    payload: presentationsService.listPresentations(getState().user.get('auth_token'))({
      ...params,
      page_size: 5,
    }),
    meta: {
      search: !!params.search,
      nextPage: params.page,
      currentTab,
    },
  })

export const favoritePresentation = id => (dispatch, getState) =>
  dispatch({
    type: FAVORITE_PRESENTATION.ACTION,
    payload: presentationsService.favoritePresentation(getState().user.get('auth_token'))(id),
    meta: { id },
  })

export const removeFavoritePresentation = ({ id, tab }) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_FAVORITE_PRESENTATION.ACTION,
    payload: presentationsService.removeFavoritePresentation(getState().user.get('auth_token'))(id),
    meta: { id, tab },
  })

export const copyPresentation = id => (dispatch, getState) =>
  dispatch({
    type: COPY_PRESENTATION.ACTION,
    payload: presentationsService.copyPresentation(getState().user.get('auth_token'))(id),
  })

export const createFolder = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_FOLDER.ACTION,
    payload: presentationsService.createFolder(getState().user.get('auth_token'), payload),
  })

export const listFolders = params => (dispatch, getState) =>
  dispatch({
    type: LIST_FOLDERS.ACTION,
    payload: presentationsService.listFolders(getState().user.get('auth_token'))(params),
  })

export const updateCoverConversionPercentage = ({ presentationId, percentage }) => ({
  type: UPDATE_COVER_PERCENTAGE,
  payload: {
    presentationId,
    percentage,
  },
})

export const sharePresentationThroughEmail = (presentationId, payload) => (dispatch, getState) =>
  dispatch({
    type: SHARE_PRESENTATION_THROUGH_EMAIL.ACTION,
    payload: presentationsService.sharePresentationThroughEmail(getState().user.get('auth_token'))(
      presentationId,
      payload
    ),
    meta: {
      presentationId: String(presentationId),
    },
  })

export const sendContactMessage = (params, payload) => (dispatch, getState) =>
  dispatch({
    type: SEND_CONTACT_MESSAGE.ACTION,
    payload: presentationsService.sendContactMessage(getState().user.get('auth_token'))(
      params,
      payload
    ),
  })

export const createPresentation = () => (dispatch, getState) =>
  dispatch({
    type: CREATE_PRESENTATION.ACTION,
    payload: presentationsService.createPresentation(getState().user.get('auth_token')),
  })

export const createTemplate = (id, notifyTeamMember) => (dispatch, getState) =>
  dispatch({
    type: CREATE_TEMPLATE.ACTION,
    payload: presentationsService.createTemplate(getState().user.get('auth_token'))(
      id,
      notifyTeamMember
    ),
  })

export const enhancePresentation = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: ENHANCE_PRESENTATION.ACTION,
    payload: presentationsService.enhancePresentation(getState().user.get('auth_token'))(
      id,
      payload
    ),
  })

export const copyTemplate = hash => (dispatch, getState) =>
  dispatch({
    type: USE_TEMPLATE.ACTION,
    payload: presentationsService.useTemplate(getState().user.get('auth_token'))(hash),
  })

export const cleanEnhancePresentation = () => ({
  type: CLEAN_ENHANCE_PRESENTATION,
})

export const makePresentationTemplate = (presentationId, payload) => (dispatch, getState) =>
  dispatch({
    type: MAKE_PRESENTATION_TEMPLATE.ACTION,
    payload: presentationService.makePresentationTemplate(getState().user.get('auth_token'))(
      presentationId,
      payload
    ),
    meta: {
      idList: presentationId,
    },
  })

export const archivePresentation = id => (dispatch, getState) =>
  dispatch({
    type: ARCHIVE_PRESENTATION.ACTION,
    payload: presentationsService.archivePresentation(getState().user.get('auth_token'))(id),
    meta: {
      id,
    },
  })

export const unArchivePresentation = id => (dispatch, getState) =>
  dispatch({
    type: UNARCHIVE_PRESENTATION.ACTION,
    payload: presentationsService.unArchivePresentation(getState().user.get('auth_token'))(id),
    meta: {
      id,
    },
  })

export const deletePresentation = id => (dispatch, getState) =>
  dispatch({
    type: DELETE_PRESENTATION.ACTION,
    payload: presentationsService.deletePresentation(getState().user.get('auth_token'))(id),
    meta: {
      id,
    },
  })

export const deleteTemplate = id => (dispatch, getState) =>
  dispatch({
    type: DELETE_TEMPLATE.ACTION,
    payload: presentationsService.deleteTemplate(getState().user.get('auth_token'))(id, {
      template: true,
    }),
    meta: {
      id,
    },
  })

export const deleteReceivedPresentation = presentationId => (dispatch, getState) =>
  dispatch({
    type: DELETE_RECEIVED_PRESENTATION.ACTION,
    payload: presentationService.deleteReceivedPresentation(
      getState().user.get('auth_token'),
      presentationId
    ),
    meta: {
      presentationId,
    },
  })

export const retrievePresentation = (id, params) => (dispatch, getState) =>
  dispatch({
    type: RETRIEVE_PRESENTATION.ACTION,
    payload: presentationService.retrievePresentation(getState().user.get('auth_token'))(
      id,
      params
    ),
  })

export const retrievePresentationsStats = ids => (dispatch, getState) =>
  dispatch({
    type: RETRIEVE_PRESENTATIONS_STATS.ACTION,
    payload: presentationService.retrievePresentationsStats(getState().user.get('auth_token'))(ids),
  })

export const notifyRecipients = (presentationId, payload) => (dispatch, getState) =>
  dispatch({
    type: NOTIFY_RECIPIENTS.ACTION,
    payload: presentationsService.notifyRecipients(getState().user.get('auth_token'))(
      presentationId,
      payload
    ),
  })

export const setPendingAssets = (presentationId, hasPendingAssets) => ({
  type: HAS_PENDING_ASSETS,
  payload: {
    presentationId,
    hasPendingAssets,
  },
})
