import React, { useState, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { List } from 'immutable'

import Companies from '_components/fab/companies'
import Submenu, { submenuPropType } from '_components/fab/submenu'
import Button from '_components/button'

import styles from './styles.css'

const FAB = ({
  id,
  alt,
  name,
  icon,
  onClick,
  tooltip,
  submenu,
  disabled,
  className,
  isLoading,
  cancelIcon,
  companiesDetails,
  tooltipClassName,
  submenuClassName,
  createPresentation,
  style,
  tooltipStyle,
  containerClass,
  companiesMenuClassName,
  ...rest
}) => {
  const [showDetails, setShowDetails] = useState(false)

  const handleClick = useCallback(
    event => {
      if (createPresentation) {
        onClick(event)
        return
      }

      if (
        (Object.entries(companiesDetails).length === 0 ||
          (companiesDetails.companies && companiesDetails.companies.size === 1)) &&
        submenu.length === 0
      ) {
        onClick(event)
        return
      }

      setShowDetails(prevState => !prevState)
    },
    [companiesDetails, createPresentation, onClick, submenu.length]
  )

  return (
    <div className={classnames(styles.container, containerClass)}>
      <Button
        disabled={disabled}
        classNames={classnames(styles.fab, className, { [styles.clicked]: showDetails })}
        isLoading={isLoading}
        onClick={handleClick}
        name={name}
        id={id}
        style={style}
        {...rest}
      >
        <svg alt={alt}>
          <use xlinkHref={showDetails ? cancelIcon || icon : icon} />
        </svg>
      </Button>
      {!showDetails && tooltip && (
        <div className={classnames(styles.tooltip, tooltipClassName)} style={tooltipStyle}>
          {tooltip}
        </div>
      )}
      {showDetails && Object.entries(companiesDetails).length !== 0 && (
        <Companies {...companiesDetails} className={companiesMenuClassName} />
      )}
      {showDetails && submenu.length !== 0 && (
        <Submenu sectionList={submenu} className={submenuClassName} />
      )}
    </div>
  )
}
FAB.propTypes = {
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  submenuClassName: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  cancelIcon: PropTypes.string,
  tooltip: PropTypes.string,
  alt: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  createPresentation: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  submenu: submenuPropType,
  companiesDetails: PropTypes.shape({
    className: PropTypes.string,
    companies: PropTypes.instanceOf(List),
    onClick: PropTypes.func,
    name: PropTypes.string,
  }),
  style: PropTypes.shape(),
  tooltipStyle: PropTypes.shape(),
  containerClass: PropTypes.string,
  companiesMenuClassName: PropTypes.string,
}

FAB.defaultProps = {
  onClick: null,
  tooltip: '',
  cancelIcon: '',
  submenu: [],
  className: '',
  tooltipClassName: '',
  submenuClassName: '',
  alt: 'floating action button',
  companiesDetails: {},
  isLoading: false,
  disabled: false,
  createPresentation: false,
  name: '',
  id: '',
  style: {},
  tooltipStyle: {},
  containerClass: '',
  companiesMenuClassName: '',
}

export default React.memo(FAB)
