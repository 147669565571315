import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { PRODUCTION_URL, STAGING_URL } from '_utils/constants'
import { STAGING } from '_config/environment'
import highnoteSEO from '_assets/highnote-seo.png'
import favicon16 from '_assets/favicon/favicon.ico'

const DEFAULT_URL = STAGING === 'true' ? STAGING_URL : PRODUCTION_URL

const SEO = ({ title, description, picture, url, noRobots, hasColor }) => {
  const tags = useMemo(() => {
    const defaultTitle = 'Easily Build Winning Presentations'

    const image = hasColor ? null : picture || `${DEFAULT_URL}${highnoteSEO}`

    const defaultMetaTags = [
      {
        name: 'description',
        content: description || '',
      },
      {
        property: 'og:locale',
        content: 'en_US',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:title',
        content: `Highnote - ${title || defaultTitle}`,
      },
      {
        property: 'og:description',
        content: description || '',
      },
      {
        property: 'og:url',
        content: url,
      },
      {
        property: 'og:site-name',
        content: 'Highnote',
      },
      {
        property: 'og:image',
        content: image,
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:description',
        content: description || '',
      },
      {
        name: 'twitter:title',
        content: `Highnote - ${title || defaultTitle}`,
      },
      {
        name: 'twitter:image',
        content: image,
      },
    ]
    // TODO: Remove this one payments are on prod
    if (noRobots) {
      defaultMetaTags.push({ name: 'robots', content: 'noindex, nofollow' })
    }
    return {
      title: title || 'Highnote',
      link: [{ rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 }],
      meta: defaultMetaTags,
    }
  }, [description, noRobots, picture, title, url, hasColor])

  return <Helmet {...tags} />
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  picture: PropTypes.string,
  url: PropTypes.string,
  noRobots: PropTypes.bool,
  hasColor: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  picture: null,
  url: 'https://app.highnote.io/',
  noRobots: false,
  hasColor: false,
}

export default SEO
