import React from 'react'
import PropTypes from 'prop-types'

import Upgrade from '_assets/backgrounds/upgrade-need.png'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import useWindowSize from '_hooks/use-window-size'

import Testimonial, { TESTIMONIAL_POSITION } from '../testimonial'

import styles from './styles.css'

const UpgradeAccount = ({ handLeClose, onClickNext }) => {
  const { isMobile } = useWindowSize()

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>You need to upgrade to continue</h2>
        <p className={styles.subtitle}>
          To create and manage a team you&apos;ll need to have an upgraded account. Upgrade your
          account now to create your team.
        </p>
        <div className={styles.actions}>
          <Button className={styles.button} onClick={handLeClose} theme={BUTTON_THEME.GHOST}>
            Cancel
          </Button>
          <Button className={styles.button} onClick={onClickNext}>
            Upgrade account
          </Button>
        </div>
      </div>
      {!isMobile && (
        <Testimonial
          testimonial="We love Highnote! Having one platform to showcase our services, incorporate videos, and integrate paperwork has saved us time and allowed us to win more business!"
          author="Mike Hegee"
          image={Upgrade}
          imageWidth="38rem"
          imageHeight="34.4rem"
          position={TESTIMONIAL_POSITION.VERTICAL}
          classNameTestimonial={styles['testimonial-text']}
          className={styles['testimonial-container']}
        />
      )}
    </div>
  )
}

UpgradeAccount.propTypes = {
  handLeClose: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
}

export default UpgradeAccount
