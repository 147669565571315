import validateEmail from '_utils/email-validation'
import { cleanObject } from '_utils/helpers'

export const ACCOUNT_TYPES_TOOLTIP = "You can't change the account type of already premium users"

export const STEPS_NAMES = {
  CREATE_ACCOUNT: 'create-account',
  CREATE_TEAM: 'create-team',
  TEAM_SETTINGS: 'team-settings',
  UPGRADE_TO_CONTINUE: 'upgrade-to-continue',
}

export const FIELDS_NAMES = {
  NAME: 'name',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  MOBILE_PHONE: 'mobile',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  TEAM_NAME: 'teamName',
  QUANTITY: 'quantity',
  SELECTED_PLAN: 'selectedPlan',
}

export const FIELDS_LABEL = {
  [FIELDS_NAMES.NAME]: 'First name',
  [FIELDS_NAMES.LAST_NAME]: 'Last name',
  [FIELDS_NAMES.EMAIL]: 'Email address',
  [FIELDS_NAMES.MOBILE_PHONE]: 'Mobile phone',
  [FIELDS_NAMES.PASSWORD]: 'Password',
  [FIELDS_NAMES.CONFIRM_PASSWORD]: 'Confirm password',
  [FIELDS_NAMES.TEAM_NAME]: 'Team name',
}

export const STEPS = {
  [STEPS_NAMES.CREATE_ACCOUNT]: {
    requiredFields: [
      FIELDS_NAMES.NAME,
      FIELDS_NAMES.LAST_NAME,
      FIELDS_NAMES.EMAIL,
      FIELDS_NAMES.PASSWORD,
      FIELDS_NAMES.CONFIRM_PASSWORD,
    ],
  },
  [STEPS_NAMES.CREATE_TEAM]: {
    requiredFields: [FIELDS_NAMES.TEAM_NAME],
  },
  [STEPS_NAMES.TEAM_SETTINGS]: {
    requiredFields: [],
  },
}

export const EXTERNAL_CREATE_TEAM_STEP_ORDER = [
  STEPS_NAMES.CREATE_ACCOUNT,
  STEPS_NAMES.CREATE_TEAM,
  STEPS_NAMES.TEAM_SETTINGS,
]

export const INTERNAL_CREATE_TEAM_STEP_ORDER = [
  STEPS_NAMES.UPGRADE_TO_CONTINUE,
  STEPS_NAMES.CREATE_TEAM,
  STEPS_NAMES.TEAM_SETTINGS,
]

export const validateMembers = members => {
  const errorsMessage = members.reduce((prev, curr, index, array) => {
    if (validateEmail(curr.email)) {
      return { ...prev, [index]: 'Please type a valid email' }
    }
    if (array.filter(item => item.email === curr.email).length > 1) {
      return { ...prev, [index]: 'This email is already used' }
    }
    return { ...prev, [index]: undefined }
  }, {})

  return cleanObject(errorsMessage)
}

export const validateInput = (name, value, currentStep) => {
  const steps = STEPS[currentStep]
  if (steps && steps.requiredFields) {
    if (STEPS[currentStep].requiredFields.includes(name) && !value) {
      return {
        [name]: `${FIELDS_LABEL[name]} is required`,
      }
    }
  }

  return {
    [name]: '',
  }
}

export const validateCreateAccount = (value, name) => {
  if (name === FIELDS_NAMES.EMAIL && validateEmail(value)) {
    return {
      [name]: 'Please type a valid email',
    }
  }

  if (STEPS[STEPS_NAMES.CREATE_ACCOUNT].requiredFields.includes(name) && !value) {
    return {
      [name]: `${FIELDS_LABEL[name]} is required`,
    }
  }

  return {
    [name]: '',
  }
}
