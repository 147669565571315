export default name => {
  if (!name) {
    return `Name field is required`
  }

  if (name.includes('@')) {
    return `The character '@' is not accepted in the name field`
  }

  return null
}
