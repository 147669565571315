import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { browserHistory } from 'react-router'
import { useSelector } from 'react-redux'

import CopyIcon from '_assets/icons/copy.svg'
import EditIcon from '_assets/icons/edit-icon.svg'
import FAB from '_components/fab'
import CopyModal from '_components/copy-modal'
import { Presentation } from '_models'
import useToggle from '_hooks/use-modal'
import { userIdSelector } from '_modules/user/selectors'

import styles from './styles.css'

const Header = ({ presentation, className }) => {
  const [isCopyModalOpen, onToggleCopyModal] = useToggle()

  const userId = useSelector(userIdSelector)

  const isOwnTemplate = useMemo(() => userId === presentation.getIn(['user', 'id']), [
    userId,
    presentation,
  ])

  const onEdit = useCallback(() => {
    browserHistory.push(`/presentations/edit/${presentation.get('id')}`)
  }, [presentation])

  const actionButton = useMemo(() => {
    if (presentation.get('template') && !isOwnTemplate) {
      return {
        label: 'USE TEMPLATE',
        action: onToggleCopyModal,
      }
    }
    return {
      label: 'EDIT',
      action: onEdit,
    }
  }, [onEdit, presentation, isOwnTemplate, onToggleCopyModal])

  const FabIcon = useMemo(() => {
    if (presentation.get('template') && !isOwnTemplate) {
      return CopyIcon
    }

    return EditIcon
  }, [presentation, isOwnTemplate])

  return (
    <div className={classnames(className)}>
      <div
        className={classnames(styles['header-wrapper'], {
          [styles['without-logo']]: !presentation.get('logo'),
        })}
      >
        {presentation.get('logo') && (
          <img className={styles.logo} alt="logo" src={presentation.get('logo')} />
        )}
        <FAB
          id={`${actionButton.label}-presentation`}
          onClick={actionButton.action}
          alt="edit presentation"
          icon={FabIcon}
          tooltip={actionButton.label}
          tooltipClassName={styles['fab-tooltip']}
          containerClassName={styles['fab-container']}
          className={styles['fab-button']}
        />
        {isCopyModalOpen && (
          <CopyModal
            isOpen
            dismiss={onToggleCopyModal}
            presentationId={presentation.get('id')}
            presentationName={presentation.get('title')}
          />
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  presentation: PropTypes.instanceOf(Presentation).isRequired,
}

Header.defaultProps = {
  className: null,
}

export default React.memo(Header)
