import React, { useMemo, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import EmailSkeleton from '_assets/icons/email-skeleton-logo.svg'
import EmailPictureSkeleton from '_assets/icons/email-skeleton-picture.svg'
import Svg from '_components/svg'
import { ASPECT_RATIO } from '_components/edit-picture-modal/picture-adjustment'

import styles from './styles.css'

const ImagePreview = ({ editedPicture, isLogo, ratio }) => {
  const imageUrl = useMemo(() => URL.createObjectURL(editedPicture.file), [editedPicture])

  useEffect(
    () => () => {
      URL.revokeObjectURL(imageUrl)
    },
    [imageUrl]
  )

  if (isLogo) {
    return (
      <div className={styles['preview-wrapper']}>
        <img
          src={imageUrl}
          className={classnames(styles['logo-image'], {
            [styles['logo-image-square']]: ratio === ASPECT_RATIO.SQUARE,
            [styles['logo-image-rectangle']]: ratio === ASPECT_RATIO.RECTANGLE,
          })}
          alt="Logo"
        />
        <Svg icon={EmailSkeleton} className={styles['skeleton-template']} />
      </div>
    )
  }

  return (
    <div className={styles['profile-image-preview']}>
      <Svg icon={EmailPictureSkeleton} className={styles['skeleton-picture-template']} />
      <img src={imageUrl} className={styles['profile-image']} alt="avatar" />
    </div>
  )
}

ImagePreview.propTypes = {
  editedPicture: PropTypes.shape({
    file: PropTypes.shape(),
  }).isRequired,
  isLogo: PropTypes.bool.isRequired,
  ratio: PropTypes.string.isRequired,
}

export default React.memo(ImagePreview)
