import { useEffect } from 'react'

export const useRouteLeave = ({
  router,
  route,
  trigger,
  text = 'Leave page? \nChanges you made may not be saved.',
}) => {
  useEffect(() => {
    router.setRouteLeaveHook(route, () => {
      if (trigger) {
        return text
      }

      return null
    })
  }, [route, router, text, trigger])
}
