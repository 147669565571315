import humps from 'humps'

import { defineAction, AUTH_LOGOUT } from '_privateDependencies/react-utils/utils'
import * as authService from '_services/auth'
import * as userService from '_services/user'

export const AUTH_LOGIN = defineAction('AUTH_LOGIN')
export const GET_USER = defineAction('GET_USER')
export const RESET_USER = 'RESET_USER'
export const UPDATE_USER = defineAction('UPDATE_USER')
export const UPDATE_USER_HIDE_IN_FOLDER = defineAction('UPDATE_USER_HIDE_IN_FOLDER')
export const CHANGE_EMAIL = defineAction('CHANGE_EMAIL')
export const CHANGE_PASSWORD = defineAction('CHANGE_PASSWORD')
export const RESET_PASSWORD = defineAction('RESET_PASSWORD')
export const PASSWORD_RESET_CONFIRM = defineAction('PASSWORD_RESET_CONFIRM')
export const UPDATE_USER_LOGO = defineAction('UPDATE_USER_LOGO')
export const UPDATE_USER_PICTURE = defineAction('UPDATE_USER_PICTURE')
export const SIGN_UP_EMAIL = defineAction('SIGN_UP_EMAIL')
export const LOGIN_FACEBOOK = defineAction('LOGIN_FACEBOOK')
export const UNLINK_SOCIAL_ACCOUNT = defineAction('UNLINK_SOCIAL_ACCOUNT')
export const LOGIN_GOOGLE = defineAction('LOGIN_GOOGLE')
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const REFERRAL_CODE = defineAction('REFERRAL_CODE')
export const UPDATE_REDUCER_USER = 'UPDATE_REDUCER_USER'
export const GET_REFERRAL_ROCK_CODE = defineAction('GET_REFERRAL_ROCK_CODE')
export const CREATE_INTERCOM_CONTACT = defineAction('CREATE_INTERCOM_CONTACT')
export const CREATE_TEAM_PAYMENT = defineAction('CREATE_TEAM_PAYMENT')
export const GET_USER_ROLE = defineAction('GET_USER_ROLE')
export const GET_STRIPE_PORTAL_URL = defineAction('GET_STRIPE_PORTAL_URL')
export const GET_DONE_FOR_YOU_CHECKOUT_PAGE = defineAction('GET_DONE_FOR_YOU_CHECKOUT_PAGE')
export const GET_USER_INVITES = defineAction('GET_USER_INVITES')
export const DECLINE_COMPANY_INVITE = defineAction('DECLINE_COMPANY_INVITE')
export const ACCEPT_COMPANY_INVITE = defineAction('ACCEPT_COMPANY_INVITE')
export const COMPLETE_ONBOARDING = defineAction('COMPLETE_ONBOARDING')

export const login = (email, password) => ({
  type: AUTH_LOGIN,
  payload: authService.login(email, password),
})

export const logout = () => (dispatch, getState) =>
  dispatch({
    type: AUTH_LOGOUT.ACTION,
    payload: authService.logout(getState().user.get('auth_token'))(),
  })

export const getUser = () => (dispatch, getState) =>
  dispatch({
    type: GET_USER.ACTION,
    payload: userService.getUser(getState().user.get('auth_token'))(),
  })

export const resetUser = () => ({
  type: RESET_USER,
})

export const updateUser = (payload, isLoadingHideInFolder = false) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER.ACTION,
    payload: userService.updateUser(getState().user.get('auth_token'))(payload),
    meta: {
      isLoadingHideInFolder,
      isChangingAutoSummarizeAssets: payload.auto_summarize_assets !== undefined,
    },
  })

export const updatePreferenceSummarizer = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER.ACTION,
    payload: userService.updateUser(getState().user.get('auth_token'))(payload),
    meta: {
      isChangingAutoSummarizeAssets: true,
      payload,
    },
  })

export const updateUserHideInFolder = (payload, isLoadingHideInFolder = false) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_USER_HIDE_IN_FOLDER.ACTION,
    payload: userService.updateUser(getState().user.get('auth_token'))(payload),
    meta: {
      isLoadingHideInFolder,
    },
  })

export const changeEmail = payload => (dispatch, getState) =>
  dispatch({
    type: CHANGE_EMAIL.ACTION,
    payload: userService.changeEmail(getState().user.get('auth_token'))(payload),
  })

export const changePassword = payload => (dispatch, getState) =>
  dispatch({
    type: CHANGE_PASSWORD.ACTION,
    payload: userService.changePassword(getState().user.get('auth_token'))(payload),
  })

export const passwordReset = payload => dispatch =>
  dispatch({
    type: RESET_PASSWORD.ACTION,
    payload: userService.passwordReset(payload),
  })

export const passwordResetConfirmation = payload => dispatch =>
  dispatch({
    type: PASSWORD_RESET_CONFIRM.ACTION,
    payload: userService.passwordResetConfirmation(payload),
  })

export const updateUserLogo = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER_LOGO.ACTION,
    payload: userService.updateUserLogo(getState().user.get('auth_token'))(payload),
  })

export const updateUserPicture = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER_PICTURE.ACTION,
    payload: userService.updateUserPicture(getState().user.get('auth_token'))(payload),
  })

export const signUpEmail = payload => dispatch =>
  dispatch({
    type: SIGN_UP_EMAIL.ACTION,
    payload: userService.signUpEmail(payload),
  })

export const loginFacebook = (payload, params) => (dispatch, getState) =>
  dispatch({
    type: LOGIN_FACEBOOK.ACTION,
    payload: userService.loginFacebook(
      payload,
      getState().user.get('auth_token')
    )(humps.decamelizeKeys(params)),
  })

export const loginGoogle = (payload, params) => (dispatch, getState) =>
  dispatch({
    type: LOGIN_GOOGLE.ACTION,
    payload: userService.loginGoogle(
      payload,
      getState().user.get('auth_token')
    )(humps.decamelizeKeys(params)),
  })

export const unlinkSocialAccount = (payload, params) => (dispatch, getState) =>
  dispatch({
    type: UNLINK_SOCIAL_ACCOUNT.ACTION,
    payload: userService.unlinkSocialAccount(
      payload,
      getState().user.get('auth_token')
    )(humps.decamelizeKeys(params)),
  })

export const sendReferralCode = payload => (dispatch, getState) =>
  dispatch({
    type: REFERRAL_CODE.ACTION,
    payload: userService.sendReferralCode(getState().user.get('auth_token'))(payload),
  })

export const updateReducerUser = payload => dispatch =>
  dispatch({
    type: UPDATE_REDUCER_USER,
    payload,
  })

export const getReferralRockCode = () => (dispatch, getState) =>
  dispatch({
    type: GET_REFERRAL_ROCK_CODE.ACTION,
    payload: userService.getReferralRockCode(getState().user.get('auth_token'))(),
  })

export const createIntercomContact = params => (dispatch, getState) =>
  dispatch({
    type: CREATE_INTERCOM_CONTACT.ACTION,
    payload: userService.createIntercomContact(getState().user.get('auth_token'))(
      humps.decamelizeKeys(params)
    ),
    meta: {
      userType: params.userType,
    },
  })

export const createTeamPayment = params => (dispatch, getState) =>
  dispatch({
    type: CREATE_TEAM_PAYMENT.ACTION,
    payload: userService.createTeamPayment(getState().user.get('auth_token'))(
      humps.decamelizeKeys(params)
    ),
  })

export const getUserRole = (params, memberIndex) => dispatch =>
  dispatch({
    type: GET_USER_ROLE.ACTION,
    payload: userService.getUserRole()(params),
    meta: {
      memberIndex,
    },
  })

export const getStripePortalUrl = () => (dispatch, getState) =>
  dispatch({
    type: GET_STRIPE_PORTAL_URL.ACTION,
    payload: userService.getStripePortalUrl(getState().user.get('auth_token')),
  })

export const getDoneForYouCheckoutPage = payload => (dispatch, getState) =>
  dispatch({
    type: GET_DONE_FOR_YOU_CHECKOUT_PAGE.ACTION,
    payload: userService.getDoneForYouCheckoutPage(getState().user.get('auth_token'))(
      humps.decamelizeKeys(payload)
    ),
  })

export const getUserInvites = () => (dispatch, getState) =>
  dispatch({
    type: GET_USER_INVITES.ACTION,
    payload: userService.getUserInvites(getState().user.get('auth_token')),
  })

export const declineCompanyInvite = (companyId, inviteId) => (dispatch, getState) =>
  dispatch({
    type: DECLINE_COMPANY_INVITE.ACTION,
    payload: userService.declineCompanyInvite(
      getState().user.get('auth_token'),
      companyId,
      inviteId
    ),
    meta: {
      inviteId,
    },
  })

export const acceptCompanyInvite = (companyId, inviteId) => (dispatch, getState) =>
  dispatch({
    type: ACCEPT_COMPANY_INVITE.ACTION,
    payload: userService.acceptCompanyInvite(
      getState().user.get('auth_token'),
      companyId,
      inviteId
    ),
    meta: {
      inviteId,
    },
  })

export const completeOnboarding = payload => (dispatch, getState) =>
  dispatch({
    type: COMPLETE_ONBOARDING.ACTION,
    payload: userService.completeOnboarding(
      getState().user.get('auth_token'),
      humps.decamelizeKeys(payload)
    ),
  })
