import React from 'react'
import { MenuList, MenuItem } from '@reach/menu-button'
import PropTypes from 'prop-types'
import '@reach/menu-button/styles.css'
import classnames from 'classnames'

import Svg from '_components/svg'

import styles from './styles.css'

const Menu = ({ className, options, menuListClassName }) => (
  <MenuList className={classnames(className, styles.menu, menuListClassName)}>
    {options.map(item => (
      <MenuItem
        key={item.label}
        onSelect={item.onSelect}
        disabled={item.disabled}
        className={styles['menu-item']}
      >
        {item.icon ? (
          <span className={styles['menu-item-content']}>
            <Svg icon={item.icon} className={styles['menu-item-content-icon']} />
            {item.label}
          </span>
        ) : (
          item.label
        )}
      </MenuItem>
    ))}
  </MenuList>
)

Menu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onSelect: PropTypes.func,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      disabled: PropTypes.bool,
    })
  ).isRequired,
  menuListClassName: PropTypes.string,
}

Menu.defaultProps = {
  className: '',
  menuListClassName: '',
}

export default React.memo(Menu)
