import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect } from 'react'
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useSelector, useDispatch } from 'react-redux'

import {
  isGetUserRoleLoadingSelector,
  getUserRoleErrorSelector,
  linkFacebookAccountErrorSelector,
  isFacebookAccountLoadingSelector,
  unlinkSocialAccountErrorSelector,
  unlinkSocialAccountLoadingSelector,
} from '_modules/user/selectors'
import FacebookLogo from '_assets/icons/facebook.svg'
import { FB_ID } from '_config/environment'
import Button from '_components/button'
import withBrowserInformation from '_hocs/with-browser-information'
import { loginFacebook, unlinkSocialAccount, getUserRole, resetUser } from '_modules/user/actions'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'
import usePrevious from '_hooks/use-previous'

import styles from './styles.css'

const FacebookLogin = ({
  className,
  iconClassName,
  hasFacebookAccount,
  isInternetExplorer,
  renderComponent,
  text,
  onSuccess,
  allowRedirectToLogin,
  onSocialLogin,
  isSignUp,
}) => {
  const [loginData, setLoginData] = useState(null)
  const dispatch = useDispatch()
  const isLoading = useSelector(isFacebookAccountLoadingSelector)
  const wasLoading = usePrevious(isLoading)
  const error = useSelector(linkFacebookAccountErrorSelector)
  const isUserRoleLoading = useSelector(isGetUserRoleLoadingSelector)
  const userRoleError = useSelector(getUserRoleErrorSelector)
  const isUnlinkAccountLoading = useSelector(unlinkSocialAccountLoadingSelector)
  const unlinkAccountError = useSelector(unlinkSocialAccountErrorSelector)

  useEffect(() => {
    if (error.size || unlinkAccountError.size || (userRoleError.size && !onSocialLogin)) {
      const errorMessage =
        error && error.get('error') && error.get('error').first && error.get('error').first()
      const unlinkErrorMessage = unlinkAccountError && unlinkAccountError.first()
      const roleErrorMessage = userRoleError && userRoleError.first()
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.ERROR,
          timeAutoClose: 5000,
          message:
            errorMessage ||
            unlinkErrorMessage ||
            roleErrorMessage ||
            'Something went wrong. Please try again',
        })
      )
    }
  }, [error, userRoleError, dispatch, onSocialLogin, unlinkAccountError])

  useEffect(() => {
    if (
      loginData &&
      loginData.accessToken &&
      allowRedirectToLogin &&
      !userRoleError.size &&
      !isUserRoleLoading
    ) {
      const payload = { access_token: loginData.accessToken }
      if (!isSignUp) {
        dispatch(resetUser())
      }
      dispatch(loginFacebook(payload))
      setLoginData(null)
    }
  }, [allowRedirectToLogin, loginData, userRoleError, isUserRoleLoading, dispatch, isSignUp])

  useEffect(() => {
    if (onSocialLogin && loginData && loginData.name && loginData.email) {
      onSocialLogin({
        name: loginData.name,
        email: loginData.email,
        accessToken: loginData.accessToken,
        socialProvider: 'facebook',
      })
      setLoginData(null)
    }
  }, [loginData, onSocialLogin])

  useEffect(() => {
    if (wasLoading && !isLoading && !error.size) {
      onSuccess()
    }
  }, [wasLoading, isLoading, onSuccess, error])

  const loginCallback = useCallback(
    data => {
      const { email } = data
      if (email) {
        if (!isSignUp) {
          dispatch(getUserRole(email))
        }

        setLoginData(data)
      }
    },
    [isSignUp, dispatch]
  )

  const unlinkAccount = useCallback(() => {
    dispatch(
      unlinkSocialAccount({
        socialProvider: 'facebook',
      })
    )
  }, [dispatch])

  const renderButton = renderProps => (
    <Button
      classNames={classNames(isInternetExplorer ? styles.ie : styles.button, className)}
      isLoading={isLoading || isUnlinkAccountLoading}
      {...renderProps}
    >
      <div className={styles.children}>
        <svg aria-hidden="true" className={classNames(styles.logo, iconClassName)}>
          <use xlinkHref={FacebookLogo} />
        </svg>
        <p>{text}</p>
      </div>
    </Button>
  )
  return hasFacebookAccount ? (
    renderButton({ onClick: () => unlinkAccount() })
  ) : (
    <ReactFacebookLogin
      appId={FB_ID}
      callback={loginCallback}
      render={renderComponent || renderButton}
      version={3.2}
      fields="email,name"
    />
  )
}

FacebookLogin.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  hasFacebookAccount: PropTypes.bool,
  isInternetExplorer: PropTypes.bool.isRequired,
  renderComponent: PropTypes.func,
  text: PropTypes.string,
  onSuccess: PropTypes.func,
  allowRedirectToLogin: PropTypes.bool,
  onSocialLogin: PropTypes.func,
  isSignUp: PropTypes.bool,
}

FacebookLogin.defaultProps = {
  className: '',
  iconClassName: '',
  hasFacebookAccount: false,
  text: 'Continue with Facebook',
  renderComponent: null,
  onSuccess: () => {},
  allowRedirectToLogin: true,
  onSocialLogin: null,
  isSignUp: false,
}

export default withBrowserInformation(FacebookLogin)
