import React, { PureComponent } from 'react'

const MAX_WIDTH_MEDIUM_VIEWPORT = 1024
const MAX_WIDTH_SMALL_VIEWPORT = 480

const isLargeViewport = () =>
  typeof window !== 'undefined' && window.innerWidth > MAX_WIDTH_MEDIUM_VIEWPORT
const isSmallViewport = () =>
  typeof window !== 'undefined' && window.innerWidth <= MAX_WIDTH_SMALL_VIEWPORT
const isMediumViewport = () => {
  if (typeof window === 'undefined') {
    return false
  }

  const { innerWidth } = window
  return innerWidth > MAX_WIDTH_SMALL_VIEWPORT && innerWidth <= MAX_WIDTH_MEDIUM_VIEWPORT
}
const innerWidth = () => typeof window !== 'undefined' && window.innerWidth

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

const withViewportSize = WrappedComponent => {
  class WithViewportSize extends PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        isLarge: isLargeViewport(),
        isMedium: isMediumViewport(),
        isSmall: isSmallViewport(),
        width: innerWidth(),
      }
    }

    componentDidMount() {
      if (window) {
        window.addEventListener('resize', this.onResize)
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize)
    }

    onResize = () => {
      this.setState({
        isLarge: isLargeViewport(),
        isMedium: isMediumViewport(),
        isSmall: isSmallViewport(),
        width: innerWidth(),
      })
    }

    render() {
      const { isLarge, isMedium, isSmall, width } = this.state

      return (
        <WrappedComponent
          {...this.props}
          isLargeViewport={isLarge}
          isMediumViewport={isMedium}
          isSmallViewport={isSmall}
          width={width}
        />
      )
    }
  }

  WithViewportSize.displayName = `WithViewportSize(${getDisplayName(WrappedComponent)})`
  return WithViewportSize
}

export default withViewportSize
