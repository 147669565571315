import { Map } from 'immutable'
import humps from 'humps'

import { createReducer } from '_privateDependencies/react-utils/utils'
import { TYPE_OF } from '_utils/constants'

import {
  ARCHIVE_SHARE_LINK,
  CREATE_SHARE_LINK,
  LIST_SHARE_LINKS,
  UNARCHIVE_SHARE_LINK,
  UPDATE_SHARE_LINK,
  GET_SHARE_LINK_BY_HASH,
} from './actions'

const shareLinkAttrs = {
  id: null,
  shareLink: '',
  isIndexable: false,
}
const shareLinkPublicAttrs = {
  id: null,
  shareLink: '',
  isIndexable: true,
  submittedToGallery: true,
  requirePhoneNumber: false,
  requirePhoneNumberMessage: '',
  requireEmail: false,
  requireEmailMessage: '',
}

const INITIAL_STATE = Map({
  currentLinkIsPublic: false,
  individualLink: Map(shareLinkAttrs),
  publicLink: Map(shareLinkPublicAttrs),
})

const addLink = (state, linkType, payload, meta = {}) =>
  state
    .setIn([linkType, 'id'], payload.id)
    .setIn([linkType, 'isPublic'], payload.isPublic)
    .setIn([linkType, 'shareLink'], payload.url || payload.shareLink)
    .setIn([linkType, 'isIndexable'], payload.isIndexable)
    .setIn([linkType, 'disabled'], payload.disabled)
    .setIn([linkType, 'submittedToGallery'], payload.submittedToGallery)
    .setIn([linkType, 'preventDownload'], payload.preventDownload)
    .setIn(
      [linkType, 'requireEmail'],
      String(typeof meta.requireEmail) === TYPE_OF.BOOLEAN
        ? meta.requireEmail
        : payload.requireEmail
    )
    .setIn(
      [linkType, 'activityEmailUpdates'],
      String(typeof meta.activityEmailUpdates) === TYPE_OF.BOOLEAN
        ? meta.activityEmailUpdates
        : payload.activityEmailUpdates
    )
    .setIn([linkType, 'requirePhoneNumber'], payload.requirePhoneNumber)
    .setIn([linkType, 'requirePhoneNumberMessage'], payload.requirePhoneNumberMessage)
    .setIn([linkType, 'requireEmailMessage'], payload.requireEmailMessage)

export const shareLink = createReducer(INITIAL_STATE, {
  [CREATE_SHARE_LINK.PENDING]: (state, { meta }) =>
    state.set('currentLinkIsPublic', !!meta.isPublic),

  [CREATE_SHARE_LINK.FULFILLED]: (state, { payload, meta }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    if (meta.isPublic) {
      return addLink(state, 'publicLink', camelizedPayload, meta)
    }

    return addLink(state, 'individualLink', camelizedPayload, meta)
  },

  [LIST_SHARE_LINKS.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const publicLink = camelizedPayload.filter(link => link.isPublic && !link.disabled)[0]
    if (publicLink) {
      return addLink(state, 'publicLink', publicLink)
    }

    return state.set('publicLink', Map(shareLinkPublicAttrs))
  },

  [UPDATE_SHARE_LINK.PENDING]: (state, { meta }) =>
    state.set('currentLinkIsPublic', !!meta.isPublic),

  [UPDATE_SHARE_LINK.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const { isPublic } = camelizedPayload
    const linkType = isPublic ? 'publicLink' : 'individualLink'

    if (camelizedPayload) {
      return addLink(state, linkType, camelizedPayload)
    }

    return state
  },

  [ARCHIVE_SHARE_LINK.PENDING]: state => state.setIn(['publicLink'], Map(shareLinkPublicAttrs)),

  [UNARCHIVE_SHARE_LINK.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const { isPublic } = camelizedPayload
    const linkType = isPublic ? 'publicLink' : 'individualLink'

    if (camelizedPayload) {
      return addLink(state, linkType, camelizedPayload)
    }
    return state
  },
  [GET_SHARE_LINK_BY_HASH.PENDING]: state =>
    state
      .setIn(['publicLink'], Map(shareLinkPublicAttrs))
      .setIn(['individualLink'], Map(shareLinkAttrs)),
  [GET_SHARE_LINK_BY_HASH.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const { isPublic, ...rest } = camelizedPayload
    const linkType = isPublic ? 'publicLink' : 'individualLink'

    return state.mergeIn([linkType], {
      isPublic,
      ...rest,
      shareLink: payload.url || payload.shareLink,
    })
  },
})
