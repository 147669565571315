import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classnames from 'classnames'

import Switch from '_components/switch'
import LabeledTextInput, { InputTheme, InputType } from '_components/labeled-text-input'
import gridStyles from '_styles/grid.css'

import styles from './styles.css'

class InputSwitch extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    onChangeSwitch: PropTypes.func.isRequired,
    onChangeInput: PropTypes.func.isRequired,
    inputError: PropTypes.bool.isRequired,
    inputText: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    className: null,
    label: '',
    isChecked: false,
    inputText: '',
    disabled: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      isChecked: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isChecked !== this.state.isChecked) {
      this.setState({ isChecked: nextProps.isChecked })
    }
  }

  changeInput = event => {
    this.props.onChangeInput(event)
  }

  toggleSwitch = () => {
    this.props.onChangeSwitch(!this.state.isChecked)
    this.setState(prevState => ({ isChecked: !prevState.isChecked }))
  }

  render() {
    const { label, inputError, inputText, disabled, className } = this.props
    const { isChecked } = this.state

    return (
      <div className={classnames(className)}>
        <div className={classnames(gridStyles.Grid, gridStyles['Grid--alignMiddle'])}>
          <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFill'])}>
            <p className={classnames(styles['switch-title'])}>{label}</p>
          </div>
          <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFit'])}>
            <Switch
              checked={isChecked}
              onColor="#e82f72"
              offColor="white"
              onChange={this.toggleSwitch}
              pendingOnColor="#e82f72"
              disabled={disabled}
            />
          </div>
        </div>
        {isChecked && (
          <div className={classnames(gridStyles.Grid, gridStyles['Grid--alignMiddle'])}>
            <div className={classnames(gridStyles['Grid-cell'])}>
              <LabeledTextInput
                theme={InputTheme.COOL_GRAY}
                type={InputType.PASSWORD}
                className={classnames(styles.input)}
                onChange={this.changeInput}
                value={inputText}
                error={inputError}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default InputSwitch
