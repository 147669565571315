import React, { useMemo, useCallback, useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'

import Modal from '_components/ui-kit/modal'
import EarthIcon from '_assets/icons/earth.svg'
import Svg from '_components/svg'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import LabeledSwitch from '_components/switch/labeled-switch'
import Input from '_components/ui-kit/input'
import LinkIcon from '_assets/icons/link.svg'
import MailIcon from '_assets/icons/envelope.svg'
import {
  archiveSharedLink,
  ARCHIVE_SHARE_LINK,
  createShareLink,
  CREATE_SHARE_LINK,
  listShareLinks,
  LIST_SHARE_LINKS,
  unarchiveSharedLink,
  UNARCHIVE_SHARE_LINK,
  updateShareLink,
  UPDATE_SHARE_LINK,
  getShareLinkByHash,
  GET_SHARE_LINK_BY_HASH,
} from '_modules/share-link-new/actions'
import { publicLinkSelector } from '_modules/share-link-new/selectors'
import withBrowserInformation from '_hocs/with-browser-information'
import useFetchCall from '_hooks/use-fetch-call'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'
import { TYPE_OF } from '_utils/constants'
import { isFreeSelector } from '_modules/user/selectors'
import { presentationSelector } from '_modules/presentations/selectors'
import { generateEmailPast } from '_utils/helpers'

import Label from '../label'

import styles from './styles.css'

const SPINNER_COLOR = 'var(--pink)'

const PublicLinkModal = ({ presentationId, shareLink, onClose, isFirefox }) => {
  const publicLink = useSelector(publicLinkSelector)
  const presentation = useSelector(state => presentationSelector(state, presentationId))

  const isFreeUser = useSelector(isFreeSelector)

  const INITIAL_PAYLOAD = {
    activityEmailUpdates:
      String(typeof publicLink.get('activityEmailUpdates')) === TYPE_OF.BOOLEAN
        ? publicLink.get('activityEmailUpdates')
        : true,
    requireEmail:
      String(typeof publicLink.get('requireEmail')) === TYPE_OF.BOOLEAN
        ? publicLink.get('requireEmail')
        : false,
    forwardTracking: false,
    preventDownload: publicLink.get('preventDownload') || false,
    isIndexable:
      String(typeof publicLink.get('isIndexable')) === TYPE_OF.BOOLEAN
        ? publicLink.get('isIndexable')
        : true,
    submittedToGallery:
      String(typeof publicLink.get('submittedToGallery')) === TYPE_OF.BOOLEAN
        ? publicLink.get('submittedToGallery')
        : true,
    isPublic: true,
    name: 'Public Link',
    requirePhoneNumber: publicLink.get('requirePhoneNumber') || false,
    requireEmailMessage: publicLink.get('requireEmailMessage') || '',
    requirePhoneNumberMessage: publicLink.get('requirePhoneNumberMessage') || '',
  }

  const [payload, setPayload] = useState(INITIAL_PAYLOAD)

  const dispatch = useDispatch()

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(`${publicLink.get('shareLink')}?cp=link`)
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.SUCCESS,
        message: 'Presentation link successfully copied',
      })
    )
  }, [dispatch, publicLink])

  const onCopyToEmailClipboard = useCallback(() => {
    const title = presentation.get('cleanTitle') || presentation.get('title')
    const author = `${presentation.getIn(['user', 'firstName'])} ${presentation.getIn([
      'user',
      'lastName',
    ])}`
    const thumbnail = presentation.get('background')
    const emailContent = generateEmailPast(
      `${publicLink.get('shareLink')}?cp=email`,
      thumbnail,
      title,
      author
    )

    const { ClipboardItem } = window
    if (ClipboardItem) {
      const data = [
        new ClipboardItem({
          'text/html': new Blob([emailContent], { type: 'text/html' }),
        }),
      ]
      navigator.clipboard.write(data)

      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.SUCCESS,
          message: 'Presentation link successfully copied',
        })
      )
      return
    }
    navigator.clipboard.writeText(publicLink.get('shareLink'))

    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Fail to generate link for email',
      })
    )
  }, [dispatch, presentation, publicLink])

  const onChange = useCallback(
    (name, value) => {
      if (publicLink.get('id') && !publicLink.get('disabled')) {
        dispatch(
          updateShareLink({
            presentationId,
            shareLinkId: publicLink.get('id'),
            payload: { ...payload, [name]: value },
            isPublic: true,
          })
        )
      }

      setPayload(prevState => ({ ...prevState, [name]: value }))
    },
    [dispatch, payload, presentationId, publicLink]
  )

  const onCreateShareLink = useCallback(() => {
    const finalPayload = {
      activityEmailUpdates: payload.activityEmailUpdates,
      requireEmail: payload.requireEmail,
      forwardTracking: payload.forwardTracking,
      preventDownload: payload.preventDownload,
      isIndexable: payload.isIndexable,
      submittedToGallery: payload.submittedToGallery,
      isPublic: payload.isPublic,
      name: payload.name,
      ...(payload.requirePhoneNumber && { requirePhoneNumber: payload.requirePhoneNumber }),
      ...(payload.requirePhoneNumberMessage && {
        requirePhoneNumberMessage: payload.requirePhoneNumberMessage,
      }),
      ...(payload.requireEmailMessage && {
        requireEmailMessage: payload.requireEmailMessage,
      }),
    }
    dispatch(createShareLink({ presentationId, payload: { ...finalPayload }, isPublic: true }))
  }, [dispatch, payload, presentationId])

  const onGenerateLink = useCallback(() => {
    if (publicLink.get('disabled')) {
      if (shareLink) {
        return dispatch(
          unarchiveSharedLink({
            presentationId: shareLink.id,
            shareLinkId: publicLink.get('id'),
            payload,
          })
        )
      }
      return dispatch(
        unarchiveSharedLink({ presentationId, shareLinkId: publicLink.get('id'), payload })
      )
    }

    return onCreateShareLink()
  }, [publicLink, onCreateShareLink, shareLink, dispatch, presentationId, payload])

  const onUnpublishLink = useCallback(() => {
    if (!publicLink.get('disabled')) {
      return dispatch(archiveSharedLink({ presentationId, shareLinkId: publicLink.get('id') }))
    }

    return dispatch(
      updateShareLink({
        presentationId,
        shareLinkId: publicLink.get('id'),
        payload: { ...payload, isIndexable: !publicLink.get('isIndexable') },
        isPublic: true,
      })
    )
  }, [dispatch, payload, presentationId, publicLink])

  const onPublishShareLinkSuccess = useCallback(
    () =>
      publicLink.get('isIndexable') &&
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.SUCCESS,
          message: 'Link published successfully!',
        })
      ),
    [dispatch, publicLink]
  )

  const [isLoadingCreateShareLink] = useFetchCall(CREATE_SHARE_LINK, onPublishShareLinkSuccess)
  const [isLoadingUpdateShareLink] = useFetchCall(UPDATE_SHARE_LINK)
  const [isLoadingListShareLinks] = useFetchCall(LIST_SHARE_LINKS)
  const [isLoadingShareLink] = useFetchCall(GET_SHARE_LINK_BY_HASH)
  const [isLoadingArchiveShareLink] = useFetchCall(ARCHIVE_SHARE_LINK)
  const [isLoadingUnarchiveShareLink] = useFetchCall(UNARCHIVE_SHARE_LINK)

  useEffect(() => {
    if (shareLink) {
      dispatch(getShareLinkByHash(shareLink.id, shareLink.hash))
      return
    }
    if (presentationId) {
      dispatch(listShareLinks(presentationId))
    }
  }, [dispatch, presentationId, shareLink])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPayload(INITIAL_PAYLOAD), [publicLink])

  const labeledSwitchGeneralOptions = useMemo(
    () => [
      {
        label: 'Receive analytics by email',
        name: 'activityEmailUpdates',
        isChecked: payload.activityEmailUpdates,
        onChange: (value, { name }) => onChange(name, value),
      },
      {
        label: <Label name="Disable asset download" isFreeUser={isFreeUser} />,
        name: 'preventDownload',
        isChecked: payload.preventDownload,
        disabled: isFreeUser,
        onChange: (value, { name }) => onChange(name, value),
      },
      {
        label: 'Make page indexable by search engines (e.g., Google)',
        name: 'isIndexable',
        isChecked: payload.isIndexable,
        onChange: (value, { name }) => onChange(name, value),
        visibilitySettings: true,
      },
      {
        label: 'Publish in "Built with Highnote"',
        name: 'submittedToGallery',
        isChecked: payload.submittedToGallery,
        onChange: (value, { name }) => onChange(name, value),
        visibilitySettings: true,
        tooltip: 'Get added visibility by submitting it to www.highnote.io/built-with-highnote',
      },
    ],
    [
      isFreeUser,
      onChange,
      payload.activityEmailUpdates,
      payload.isIndexable,
      payload.preventDownload,
      payload.submittedToGallery,
    ]
  )
  const settings = useCallback(
    (visibilitySettings = false) => {
      if (visibilitySettings) {
        return (
          <div className={styles.options}>
            {labeledSwitchGeneralOptions
              .filter(item => item.visibilitySettings)
              .map(
                option =>
                  option.name && (
                    <LabeledSwitch
                      key={option.name}
                      className={styles['labeled-switch']}
                      label={option.label}
                      name={option.name}
                      isChecked={option.isChecked}
                      onChange={option.onChange}
                      disabled={option.disabled}
                      tooltip={option.tooltip}
                    />
                  )
              )}
          </div>
        )
      }

      return (
        <div className={styles.options}>
          {labeledSwitchGeneralOptions
            .filter(item => !item.visibilitySettings)
            .map(
              option =>
                option.name && (
                  <LabeledSwitch
                    key={option.name}
                    className={styles['labeled-switch']}
                    label={option.label}
                    name={option.name}
                    isChecked={option.isChecked}
                    onChange={option.onChange}
                    disabled={option.disabled}
                  />
                )
            )}
        </div>
      )
    },
    [labeledSwitchGeneralOptions]
  )
  const renderGeneratedLinkContent = useMemo(
    () =>
      publicLink.get('shareLink') &&
      !publicLink.get('disabled') && (
        <div className={styles['generated-link-content']}>
          <Input
            id="generated-link"
            className={styles['generated-link']}
            value={publicLink.get('shareLink')}
          />
          <div className={styles['button-wrapper']}>
            <Button
              className={styles['copy-button']}
              theme={BUTTON_THEME.SECONDARY}
              startIcon={LinkIcon}
              onClick={onCopyToClipboard}
            >
              COPY LINK
            </Button>
            {!shareLink && !isFirefox && (
              <Button
                className={styles['email-button']}
                theme={BUTTON_THEME.SECONDARY}
                startIcon={MailIcon}
                onClick={onCopyToEmailClipboard}
              >
                COPY FOR EMAIL
              </Button>
            )}
          </div>
        </div>
      ),
    [onCopyToClipboard, onCopyToEmailClipboard, publicLink, shareLink, isFirefox]
  )

  const onInputChange = useCallback(event => onChange(event.target.name, event.target.value), [
    onChange,
  ])

  const onBlur = useCallback(() => {
    if (publicLink.get('id') && !publicLink.get('disabled')) {
      dispatch(
        updateShareLink({
          presentationId,
          shareLinkId: publicLink.get('id'),
          payload: { ...payload },
          isPublic: true,
        })
      )
    }
  }, [dispatch, payload, presentationId, publicLink])

  const onInputChangeDebounce = useCallback(
    event => {
      const { name, value } = event.target
      setPayload(prevState => ({ ...prevState, [name]: value }))
    },
    [setPayload]
  )

  const renderLeadCaptureSettings = useMemo(() => {
    const hasLink = publicLink.get('shareLink')
    const options = [
      {
        label: <Label name="Require email to view" isFreeUser={isFreeUser} />,
        name: 'requireEmail',
        isChecked: payload.requireEmail,
        disabled: isFreeUser,
        onChange: (value, { name }) => onChange(name, value),
        input: {
          placeholder: 'Enter your requested message',
          onChange: hasLink ? onInputChangeDebounce : onInputChange,
          value: payload.requireEmailMessage || '',
          name: 'requireEmailMessage',
          ...(hasLink && { onBlur }),
        },
      },
      {
        label: <Label name="Require phone number to view" isFreeUser={isFreeUser} />,
        name: 'requirePhoneNumber',
        disabled: isFreeUser,
        onChange: (value, { name }) => onChange(name, value),
        isChecked: payload.requirePhoneNumber,
        input: {
          placeholder: 'Enter your requested message',
          onChange: hasLink ? onInputChangeDebounce : onInputChange,
          value: payload.requirePhoneNumberMessage || '',
          name: 'requirePhoneNumberMessage',
          ...(hasLink && { onBlur }),
        },
      },
    ]

    return (
      <div className={styles.options}>
        {options.map(option => (
          <React.Fragment key={option.name}>
            <LabeledSwitch
              key={option.name}
              className={styles['labeled-switch']}
              label={option.label}
              name={option.name}
              isChecked={option.isChecked}
              onChange={option.onChange}
              disabled={option.disabled}
              tooltip={option.tooltip}
            />
            {option.input && option.isChecked && (
              <Input
                name={option.input.name}
                placeholder={option.input.placeholder}
                onChange={option.input.onChange}
                value={option.input.value}
                {...(option.input.onBlur && { onBlur: option.input.onBlur })}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    )
  }, [
    isFreeUser,
    onChange,
    payload.requireEmail,
    payload.requireEmailMessage,
    payload.requirePhoneNumberMessage,
    onInputChange,
    payload.requirePhoneNumber,
    onInputChangeDebounce,
    publicLink,
    onBlur,
  ])

  const publicLinkContent = useMemo(
    () => (
      <Fragment>
        <div className={styles['public-link-title']}>
          <p className={styles.description}>Make a webpage and share wherever you want</p>
          {!isLoadingListShareLinks && renderGeneratedLinkContent}
          <p className={styles['sub-description']}>General settings</p>
          {settings()}
          <p className={styles['sub-description']}>Lead capture settings</p>
          {renderLeadCaptureSettings}
          <p className={styles['sub-description']}>Visibility settings</p>
          {settings(true)}
        </div>
        {!publicLink.get('shareLink') ? (
          <div className={styles['publish-link-button-content']}>
            <Button
              className={styles['publish-link-button']}
              isLoading={
                isLoadingUpdateShareLink || isLoadingCreateShareLink || isLoadingUnarchiveShareLink
              }
              onClick={onGenerateLink}
            >
              Publish on web
            </Button>
          </div>
        ) : (
          <div className={styles['unpublish-link-content']}>
            <Button
              className={styles['unpublish-link-button']}
              theme={BUTTON_THEME.GHOST}
              onClick={onUnpublishLink}
              isLoading={isLoadingUpdateShareLink || isLoadingArchiveShareLink}
              disabled={isLoadingUpdateShareLink || isLoadingArchiveShareLink}
            >
              Unpublish link
            </Button>
          </div>
        )}
      </Fragment>
    ),
    [
      isLoadingArchiveShareLink,
      isLoadingCreateShareLink,
      isLoadingListShareLinks,
      isLoadingUnarchiveShareLink,
      isLoadingUpdateShareLink,
      onGenerateLink,
      onUnpublishLink,
      publicLink,
      renderGeneratedLinkContent,
      settings,
      renderLeadCaptureSettings,
    ]
  )

  return (
    <Modal
      isOpen
      isClosable
      className={styles['share-link-modal']}
      title="Publish on web"
      titleIcon={
        <Svg
          icon={EarthIcon}
          className={styles['title-icon']}
          alt="Earth icon representing the option to publish on the web"
        />
      }
      onClose={onClose}
    >
      {isLoadingListShareLinks || isLoadingShareLink ? (
        <div className={styles.loading}>
          <Spinner name="circle" color={SPINNER_COLOR} fadeIn="none" />
        </div>
      ) : (
        <div className={styles.content}>{publicLinkContent}</div>
      )}
    </Modal>
  )
}

PublicLinkModal.propTypes = {
  presentationId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  shareLink: PropTypes.objectOf({
    hash: PropTypes.string,
    id: PropTypes.number,
  }),
  isFirefox: PropTypes.bool.isRequired,
}

PublicLinkModal.defaultProps = {
  shareLink: undefined,
  presentationId: undefined,
}

export default withBrowserInformation(React.memo(PublicLinkModal))
