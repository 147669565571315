import { List } from 'immutable'

import UserPicture from '_assets/avatar@3x.png'

export const getFieldValue = (presentation, field) => {
  const picture = presentation.get('shared_by')
    ? presentation.getIn(['shared_by', field])
    : presentation.getIn(['user', field])

  return picture || UserPicture
}

export const getPresentationValues = presentation => {
  if (!presentation) {
    return {
      styleType: 'outline',
      assets: List(),
      authorAvatar: UserPicture,
      authorAvatarPosition: 'center',
      authorEmail: '',
      authorName: 'Unknown User',
      background: '',
      backgroundColor: '',
      closingMessage: '',
      openingMessage: '',
      title: '',
      logo: '',
      borderColor: '',
    }
  }

  return {
    styleType: presentation.get('style'),
    assets: presentation.get('assets') || List(),
    authorAvatar: getFieldValue(presentation, 'picture') || UserPicture,
    authorAvatarPosition: presentation.get('picture_position'),
    authorEmail: getFieldValue(presentation, 'email'),
    authorName: getFieldValue(presentation, 'first_name'),
    background: presentation.get('background_cropped') || presentation.get('background'),
    backgroundColor: presentation.get('background_color'),
    closingMessage: presentation.get('closing_msg') || '',
    openingMessage: presentation.get('opening_msg') || '',
    title: presentation.get('title') || '',
    logo: presentation.get('logo'),
    borderColor: presentation.get('border_color') || '',
  }
}

export const getPayloadForwardClientPresentation = user => ({
  recipients: [
    {
      name: `${user.get('first_name') && user.get('first_name')} ${user.get('last_name') &&
        user.get('last_name')}`,
      email: `${user.get('email') && user.get('email')}`,
    },
  ],
})
