import React, { Fragment, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Svg from '_components/svg'
import { onMouseDown } from '_utils/aria'

import Arrow, { ARROW_DIRECTION } from './arrow'
import styles from './styles.css'

const SCROLL_AMOUNT = 20

export const TAB_NAVIGATION_THEME = {
  DEFAULT: 'primary',
  LIGHT: 'light',
}

export const TAB_NAVIGATION_ELEMENTS_POSITION = {
  DEFAULT: 'left',
  CENTER: 'center',
  END: 'end',
}

const TabNavigation = ({
  className,
  tabs,
  theme,
  onClick,
  activeTab,
  elementsPosition,
  style,
  isLoading,
  isScrollable,
  tabItemClassName,
}) => {
  const onTabClick = useCallback(
    event => {
      event.preventDefault()
      onClick(event.currentTarget.id)
    },
    [onClick]
  )
  const ref = useRef(null)

  const onClickRight = useCallback(() => {
    ref.current.scrollLeft += SCROLL_AMOUNT
  }, [])

  const onClickLeft = useCallback(() => {
    ref.current.scrollLeft -= SCROLL_AMOUNT
  }, [])

  return (
    <div
      className={classnames(
        styles['tab-navigation'],
        styles[theme],
        styles[elementsPosition],
        className
      )}
      style={style}
    >
      {isScrollable && (
        <Fragment>
          <Arrow
            onClick={onClickRight}
            className={styles['button-right']}
            arrowDirection={ARROW_DIRECTION.RIGHT}
          />
          <Arrow
            onClick={onClickLeft}
            className={styles['button-left']}
            arrowDirection={ARROW_DIRECTION.LEFT}
          />
        </Fragment>
      )}
      <div className={styles.container} ref={ref}>
        {tabs.map(tab => (
          <Fragment key={tab.label}>
            <button
              type="button"
              className={classnames(
                styles.item,
                {
                  [styles.active]: tab.ordering === activeTab,
                },
                tabItemClassName
              )}
              onClick={onTabClick}
              onMouseDown={onMouseDown}
              id={tab.ordering}
              disabled={isLoading}
            >
              {tab.startIcon && (
                <span className={styles['start-icon-wrapper']}>
                  <Svg icon={tab.startIcon} className={styles['start-icon']} />
                </span>
              )}
              {tab.label}
              {tab.endIcon && (
                <span className={styles['end-icon-wrapper']}>
                  <Svg icon={tab.endIcon} className={styles['end-icon']} />
                </span>
              )}
            </button>
            {tab.hasNew && <span className={styles['notification-dot']} />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

TabNavigation.propTypes = {
  isLoading: PropTypes.bool,
  isScrollable: PropTypes.bool,
  activeTab: PropTypes.string,
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      startIcon: PropTypes.node,
      ordering: PropTypes.string,
      hasNew: PropTypes.bool,
      endIcon: PropTypes.node,
    })
  ).isRequired,
  style: PropTypes.shape({}),
  theme: PropTypes.oneOf(Object.values(TAB_NAVIGATION_THEME)),
  elementsPosition: PropTypes.oneOf(Object.values(TAB_NAVIGATION_ELEMENTS_POSITION)),
  onClick: PropTypes.func,
  tabItemClassName: PropTypes.string,
}

TabNavigation.defaultProps = {
  isLoading: false,
  isScrollable: false,
  activeTab: undefined,
  className: '',
  style: {},
  theme: TAB_NAVIGATION_THEME.DEFAULT,
  elementsPosition: TAB_NAVIGATION_ELEMENTS_POSITION.DEFAULT,
  onClick: () => {},
  tabItemClassName: '',
}

export default React.memo(TabNavigation)
