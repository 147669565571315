import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { HIGHNOTE_PINK } from '_constants/colors'

import styles from './styles.css'

export const PLACEHOLDER_SIZE = {
  NORMAL: 'normal',
  SMALL: 'small',
  BIG: 'big',
}
const UserPlaceholder = ({ className, userName, backgroundColor, fontColor, size }) => {
  const backgroundStyles = useMemo(
    () => ({
      backgroundColor: backgroundColor || HIGHNOTE_PINK,
      color: fontColor || '#ffffff',
    }),
    [backgroundColor, fontColor]
  )

  const firstLetter = useMemo(() => userName.charAt(0), [userName])

  return (
    <div
      className={classnames(styles['user-image-container'], styles[size], className)}
      style={backgroundStyles}
    >
      <span className={styles['visually-hidden-label']}>User placeholder avatar</span>
      <span aria-hidden="true">{firstLetter}</span>
    </div>
  )
}

UserPlaceholder.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(Object.values(PLACEHOLDER_SIZE)),
  fontColor: PropTypes.string,
}

UserPlaceholder.defaultProps = {
  className: '',
  backgroundColor: HIGHNOTE_PINK,
  size: PLACEHOLDER_SIZE.NORMAL,
  fontColor: '#FFFFFF',
}

export default React.memo(UserPlaceholder)
