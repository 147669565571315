import React, { useCallback, useState, useMemo, useRef } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { extension } from 'mime-types'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close-masonry.svg'
import BackIcon from '_assets/icons/left_arrow.svg'
import ApplyPictureIcon from '_assets/icons/apply-image-icon.svg'
import FileUploadContainer from '_components/file-upload-container'
import Svg from '_components/svg'
import PictureAdjustment from '_components/adjust-picture'
import Button from '_components/button-round'
import ButtonNew, { BUTTON_THEME } from '_components/button-new'
import { updatePresentation, UPDATE_PRESENTATION } from '_modules/presentations/actions'
import { updatePresentationLoadingSelector } from '_modules/presentations/selectors'
import { updateUser, UPDATE_USER } from '_modules/user/actions'
import { updateUserLoadingSelector } from '_modules/user/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'

import styles from './styles.css'

const STEPS = {
  UPLOAD: 'UPLOAD',
  ADJUST: 'ADJUST',
  APPLY: 'APPLY',
}

const EDITOR_DIMENSIONS = {
  width: 528,
  height: 273,
  border: [150, 32],
}
const UserPictureModal = ({ presentationId, dismiss }) => {
  const isUpdatingPicture = useSelector(updatePresentationLoadingSelector)
  const isSettingAllHeadShotLoading = useSelector(updateUserLoadingSelector)

  const [selectedFile, setSelectedFile] = useState('')
  const [currentStep, setCurrentStep] = useState(STEPS.UPLOAD)

  const editorRef = useRef(null)
  const dispatch = useDispatch()

  const onFinishClick = useCallback(() => {
    if (editorRef.current) {
      const fileType = selectedFile ? selectedFile.type : ''

      editorRef.current.getImage().toBlob(
        blob => {
          const fileName = `avatar.${extension(fileType)}`

          const newFile = new File([blob], fileName, { type: fileType })
          dispatch(updatePresentation(presentationId, { picture: newFile }))
        },
        fileType,
        0.5
      )
    }
  }, [dispatch, presentationId, selectedFile])

  const onSelectFile = useCallback(file => {
    setSelectedFile(file)
    setCurrentStep(STEPS.ADJUST)
  }, [])

  const onReturnClick = useCallback(() => {
    setCurrentStep(STEPS.UPLOAD)
  }, [])

  const onSuccess = useCallback(() => {
    setCurrentStep(STEPS.APPLY)
  }, [])

  const OnReject = useCallback(
    message => {
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.ERROR,
          message,
        })
      )
    },
    [dispatch]
  )

  useFetchCall(
    UPDATE_PRESENTATION,
    () => onSuccess(),
    () => OnReject('Something went uploading your picture. Please try again.')
  )

  useFetchCall(
    UPDATE_USER,
    () => dismiss(),
    () =>
      OnReject('Something went wrong applying your picture to all presentations. Please try again.')
  )

  const onApplyToAllClick = useCallback(() => {
    if (selectedFile) {
      const payload = { picture: selectedFile }
      dispatch(updateUser(payload))
    }
  }, [dispatch, selectedFile])

  const renderStep = useMemo(() => {
    switch (currentStep) {
      case STEPS.ADJUST:
        return (
          <section>
            <header className={styles['upload-picture-header']}>
              <button
                className={styles['upload-image-button']}
                type="button"
                onClick={onReturnClick}
              >
                <Svg icon={BackIcon} className={styles['back-icon']} />
                Upload image
              </button>
              <button
                type="button"
                aria-label="Close modal"
                onClick={dismiss}
                className={styles['close-button']}
              >
                <Svg icon={CloseIcon} className={styles['close-icon']} />
              </button>
            </header>
            <div className={styles['adjust-picture']}>
              <h1 className={styles.title}>Adjust picture</h1>
              <p className={styles.description}>
                Zoom in/out and pan the image to fit how you&apos;d like
              </p>
              <PictureAdjustment
                className={styles['picture-adjustment']}
                sliderContainerClassName={styles['slider-container']}
                picture={selectedFile}
                ref={editorRef}
                dimensions={EDITOR_DIMENSIONS}
              />
              <Button
                onClick={onFinishClick}
                className={styles['finish-button']}
                isLoading={isUpdatingPicture}
                disabled={isUpdatingPicture}
              >
                Finish
              </Button>
            </div>
          </section>
        )
      case STEPS.APPLY:
        return (
          <section className={styles['apply-content']}>
            <Svg icon={ApplyPictureIcon} className={styles.icon} />
            <h1 className={styles.title}>Apply picture on all new presentations?</h1>
            <p className={styles.description}>
              Would you like to make this your Profile Picture from now on? This action will not
              modify any presentations previously created.
            </p>
            <ButtonNew
              theme={BUTTON_THEME.PINK_NO_BACKGROUND_NO_BORDER}
              onClick={dismiss}
              className={styles['dismiss-button']}
            >
              No, apply only to this presentation
            </ButtonNew>
            <Button onClick={onApplyToAllClick} isLoading={isSettingAllHeadShotLoading}>
              Yes, apply to all
            </Button>
          </section>
        )
      default:
        return (
          <section className={styles.content}>
            <h1 className={styles.title}>Upload your picture</h1>
            <button
              type="button"
              aria-label="Close modal"
              onClick={dismiss}
              className={styles['close-button']}
            >
              <Svg icon={CloseIcon} className={styles['close-icon']} />
            </button>
            <p className={styles.description}>
              Please upload <b className={styles.bold}>PNG</b> or{' '}
              <b className={styles.bold}>JPEG</b> files with maximum{' '}
              <b className={styles.bold}>1MB of size</b>. you can also copy and paste the image you
              would like onto this screen.
            </p>
            <FileUploadContainer isOnlyImagesAllowed onSelectFiles={onSelectFile} />
          </section>
        )
    }
  }, [
    currentStep,
    dismiss,
    isSettingAllHeadShotLoading,
    isUpdatingPicture,
    onApplyToAllClick,
    onFinishClick,
    onReturnClick,
    onSelectFile,
    selectedFile,
  ])

  return (
    <Modal
      contentLabel="Presentation Picture"
      isOpen
      className={classnames(styles.modal, { [styles['apply-modal']]: currentStep === STEPS.APPLY })}
      overlayClassName={styles.overlay}
      bodyOpenClassName={styles['no-scroll']}
      ariaHideApp={false}
    >
      {renderStep}
    </Modal>
  )
}

UserPictureModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  presentationId: PropTypes.number.isRequired,
}

export default React.memo(UserPictureModal)
