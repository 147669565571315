import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Asset } from '_models'
import Svg from '_components/svg'
import HideIcon from '_assets/icons/hide.svg'
import ViewIcon from '_assets/icons/view.svg'
import Button, { BUTTON_THEME } from '_components/button-round'
import { editPresentationAsset, EDIT_ASSET } from '_modules/assets/actions'
import ConfirmationModal from '_components/confirmation-modal'
import ShowIconModal from '_assets/icons/ic-show-asset.svg'
import useToggle from '_hooks/use-modal'
import useFetchCall from '_hooks/use-fetch-call'
import { ALERT_TYPE, ALERT_MESSAGE } from '_components/toast'
import { openToasterAlert } from '_modules/toaster-alert/actions'

import styles from './styles.css'

const HideAsset = ({ asset, className, presentationId }) => {
  const dispatch = useDispatch()
  const [isShowHideModalOpen, onToggleShowHideModal] = useToggle()
  const [isHidingAsset, toggleHidingAsset] = useState(false)

  const onSuccess = useCallback(() => {
    if (isHidingAsset) {
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.SUCCESS,
          message: ALERT_MESSAGE.SUCCESS.DEFAULT,
        })
      )
    }
    toggleHidingAsset(false)
  }, [dispatch, isHidingAsset])

  const onReject = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: ALERT_MESSAGE.ERROR.DEFAULT,
      })
    )
    toggleHidingAsset(false)
  }, [dispatch])

  useFetchCall(EDIT_ASSET, onSuccess, onReject)

  const handleShowAsset = useCallback(() => {
    dispatch(
      editPresentationAsset({
        id: asset.get('id'),
        presentationId,
        params: {},
        payload: { isHidden: !asset.get('isHidden') },
      })
    )
    toggleHidingAsset(true)
    onToggleShowHideModal()
  }, [asset, dispatch, onToggleShowHideModal, presentationId])

  return (
    <div className={classnames(className, styles['black-filter'])}>
      <div className={styles.wrapper}>
        <Svg icon={HideIcon} className={styles.icon} />
        <p className={styles.description}>Hidden asset</p>
      </div>
      <Button startIcon={ViewIcon} theme={BUTTON_THEME.WHITE_GHOST} onClick={onToggleShowHideModal}>
        Show
      </Button>
      {isShowHideModalOpen && (
        <ConfirmationModal
          title={asset.get('isHidden') ? 'Show asset?' : 'Hide asset?'}
          description="This will modify any shared link for this presentation."
          isClosable
          isOpen={isShowHideModalOpen}
          onClose={onToggleShowHideModal}
          image={ShowIconModal}
          classNameDescription={styles['hide-asset-description']}
          onCloseText="Cancel"
          onFinish={handleShowAsset}
          onFinishText="Show asset"
        />
      )}
    </div>
  )
}

HideAsset.propTypes = {
  className: PropTypes.string,
  presentationId: PropTypes.number.isRequired,
  asset: PropTypes.instanceOf(Asset).isRequired,
}

HideAsset.defaultProps = {
  className: null,
}

export default React.memo(HideAsset)
