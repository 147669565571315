import { defineAction } from '_privateDependencies/react-utils/utils'
import * as presentationAssetsService from '_services/presentation-assets'
import { authTokenSelector } from '_modules/user/selectors'

export const CREATE_ASSET = defineAction('CREATE_ASSET')
export const EDIT_ASSET = defineAction('EDIT_ASSET')
export const REORDER_ASSETS = defineAction('REORDER_ASSETS')
export const DELETE_ASSET = defineAction('DELETE_ASSET')
export const ENHANCE_ASSET = defineAction('ENHANCE_ASSET')
export const EDIT_ASSET_UPLOAD_SYSTEM_V2 = defineAction('EDIT_ASSET_UPLOAD_SYSTEM_V2')
export const ASSET_FILE_UPLOAD_CONVERT = defineAction('ASSET_FILE_UPLOAD_CONVERT')
export const GET_ASSET_CONVERTED_FILE = defineAction('GET_ASSET_CONVERTED_FILE')
export const DELETE_ASSET_SUMMARIZER = defineAction('DELETE_ASSET_SUMMARIZER')
export const SET_UPLOADING_ON_S3 = 'SET_UPLOADING_ON_S3'
export const UPDATE_ASSET_TITLE_AND_DESCRIPTION = 'UPDATE_ASSET_TITLE_AND_DESCRIPTION'
export const CLEAN_ENHANCE_ASSET = 'CLEAN_ENHANCE_ASSET'
export const CLEAR_PROGRESS = 'CLEAR_PROGRESS'
export const CLEAR_ASSET_SUMMARIZER = 'CLEAR_ASSET_SUMMARIZER'
export const UPDATE_ASSET_SUMMARIZER_PROGRESS = 'UPDATE_ASSET_SUMMARIZER_PROGRESS'
export const UPDATE_ASSET_PERCENTAGE_UPLOAD_SYSTEM_V2 = 'UPDATE_ASSET_PERCENTAGE_UPLOAD_SYSTEM_V2'
export const DOWNLOAD_ASSET_IMAGE_UNSPLASH = 'DOWNLOAD_ASSET_IMAGE_UNSPLASH'
export const SET_ASSET_CONVERT_THUMBNAIL_FAILED = 'SET_ASSET_CONVERT_THUMBNAIL_FAILED'

export const createPresentationAsset = ({ presentationId, currentAssetIndex, payload }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: CREATE_ASSET.ACTION,
    payload: presentationAssetsService.createPresentationAsset(authTokenSelector(getState()))({
      presentationId,
      payload,
    }),
    meta: { currentAssetIndex, presentationId },
  })

export const editPresentationAsset = (
  { currentAssetIndex, id, presentationId, params, payload, isUploadingFile = undefined },
  forceUpdate = false
) => (dispatch, getState) =>
  dispatch({
    type: EDIT_ASSET.ACTION,
    payload: presentationAssetsService.editPresentationAsset(authTokenSelector(getState()))({
      id,
      presentationId,
      params,
      payload,
    }),
    meta: {
      assetId: id,
      isUploadingFile,
      payload,
      presentationId,
      currentAssetIndex,
      forceUpdate,
    },
  })

export const reorderPresentationAssets = ({ assets, params, presentationId }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: REORDER_ASSETS.ACTION,
    payload: presentationAssetsService.reorderPresentationAssets(authTokenSelector(getState()))(
      presentationId,
      assets.map(item => item.get('id')),
      params
    ),
    meta: { assets, presentationId },
  })

export const deletePresentationAsset = (presentationId, id) => (dispatch, getState) =>
  dispatch({
    type: DELETE_ASSET.ACTION,
    payload: presentationAssetsService.deletePresentationAsset(authTokenSelector(getState()))(
      presentationId,
      id
    ),
    meta: {
      assetId: id,
      presentationId,
    },
  })

export const downloadUnsplashImagePercentage = ({ assetId, unsplashPercentage }) => ({
  type: DOWNLOAD_ASSET_IMAGE_UNSPLASH,
  payload: {
    assetId,
    unsplashPercentage,
  },
})

export const enhanceAsset = (presentationId, assetId, payload) => (dispatch, getState) =>
  dispatch({
    type: ENHANCE_ASSET.ACTION,
    payload: presentationAssetsService.enhanceAsset(getState().user.get('auth_token'))(
      presentationId,
      assetId,
      payload
    ),
  })

export const updateAssetTitleAndDescription = payload => ({
  type: UPDATE_ASSET_TITLE_AND_DESCRIPTION,
  payload,
})

export const cleanEnhanceAsset = () => ({
  type: CLEAN_ENHANCE_ASSET,
})

export const clearProgress = assetId => ({
  type: CLEAR_PROGRESS,
  meta: {
    assetId,
  },
})

export const updateAssetSummarizerProgress = payload => ({
  type: UPDATE_ASSET_SUMMARIZER_PROGRESS,
  payload,
})

export const cleanAssetSummarizer = assetId => ({
  type: CLEAR_ASSET_SUMMARIZER,
  meta: {
    assetId,
  },
})

export const editAssetUploadSystemV2 = ({ presentationId, id, payload }) => (dispatch, getState) =>
  dispatch({
    type: EDIT_ASSET_UPLOAD_SYSTEM_V2.ACTION,
    payload: presentationAssetsService.editAssetUploadSystemV2(authTokenSelector(getState()))({
      id,
      presentationId,
      payload,
    }),
    meta: {
      assetId: id,
      presentationId,
    },
  })

export const assetFileUploadConvert = ({ presentationId, assetId, payload }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: ASSET_FILE_UPLOAD_CONVERT.ACTION,
    payload: presentationAssetsService.assetFileUploadConvert(authTokenSelector(getState()))(
      presentationId,
      assetId,
      payload
    ),
    meta: {
      assetId,
      presentationId,
      autoSummarizeAssets: payload.auto_summarize_assets,
      hideSummarizerModal: payload.hide_summarizer_modal,
    },
  })

export const updateAssetConversionPercentageUploadSystemV2 = ({
  assetId,
  percentage,
  extra,
  presentationId,
}) => ({
  type: UPDATE_ASSET_PERCENTAGE_UPLOAD_SYSTEM_V2,
  payload: {
    assetId,
    percentage,
    extra,
    presentationId,
  },
})

export const getAssetConvertedFile = ({ presentationId, assetId }) => (dispatch, getState) =>
  dispatch({
    type: GET_ASSET_CONVERTED_FILE.ACTION,
    payload: presentationAssetsService.getAssetConvertedFile(authTokenSelector(getState()))(
      presentationId,
      assetId
    ),
    meta: {
      assetId,
      presentationId,
    },
  })

export const setIsUploadingOnS3 = assetId => ({
  type: SET_UPLOADING_ON_S3,
  meta: {
    assetId,
  },
})

export const deleteAssetSummarizer = (presentationId, id) => (dispatch, getState) =>
  dispatch({
    type: DELETE_ASSET_SUMMARIZER.ACTION,
    payload: presentationAssetsService.deleteAssetSummarizer(authTokenSelector(getState()))(
      presentationId,
      id
    ),
    meta: {
      assetId: id,
      presentationId,
    },
  })

export const setAssetConvertThumbnailFailed = ({ assetId, presentationId }) => ({
  type: SET_ASSET_CONVERT_THUMBNAIL_FAILED,
  meta: {
    assetId,
    presentationId,
  },
})
