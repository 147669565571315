import { Map, List, fromJS } from 'immutable'
import { normalize, schema } from 'normalizr'
import humps from 'humps'

import { createReducer, AUTH_LOGOUT } from '_privateDependencies/react-utils/utils'
import { CANCEL_SUBSCRIPTION } from '_modules/payments/actions'

import {
  REGISTER_ASSET_INSIGHTS,
  CLEAR_ASSET_INSIGHTS_ID,
  LIST_USER_INSIGHTS_ACTIVITIES,
  VIEW_PRESENTATION_ACTIVITY,
  GET_NOTIFICATIONS_INSIGHTS,
  MARK_ALL_AS_READ,
  MARK_AS_READ,
  APPEND_NOTIFICATION,
  LOAD_MORE_NOTIFICATION,
  LOAD_MORE_INSIGHTS,
  REGISTER_PRESENTATION_INSIGHTS,
  CREATE_COMMENT,
} from './actions'

export const userInsightsSchema = new schema.Entity('userInsights')

export const results = new schema.Entity('results', {
  results: [userInsightsSchema],
})

export const INITIAL_STATE = Map({
  assetInsightsId: -1,
  userActivities: Map(),
  insightNextPage: null,
  notifications: List(),
  notificationNextPage: null,
  counter: 0,
  insightsTotal: undefined,
  presentationActivity: null,
  comment: undefined,
})

export const insights = createReducer(INITIAL_STATE, {
  [REGISTER_PRESENTATION_INSIGHTS.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return state.set('presentationActivity', fromJS(camelizedPayload)).set('comment', undefined)
  },
  [REGISTER_ASSET_INSIGHTS.FULFILLED]: (state, { payload }) =>
    state.set('assetInsightsId', payload.id),

  [CLEAR_ASSET_INSIGHTS_ID]: state => state.set('assetInsightsId', -1),

  [LIST_USER_INSIGHTS_ACTIVITIES.FULFILLED]: (state, { payload }) =>
    state
      .mergeIn(['userActivities'], normalize(payload, results).entities.userInsights)
      .set('insightNextPage', payload.next ? payload.next.split('=')[1] : null)
      .set('insightsTotal', payload.total),

  [LOAD_MORE_INSIGHTS.FULFILLED]: (state, { payload }) =>
    state
      .mergeIn(['userActivities'], normalize(payload, results).entities.userInsights)
      .set('insightNextPage', payload.next ? payload.next.split('=')[1] : null),

  [VIEW_PRESENTATION_ACTIVITY.FULFILLED]: (state, { payload }) =>
    state.mergeIn(['userActivities'], normalize(payload, userInsightsSchema).entities.userInsights),

  [GET_NOTIFICATIONS_INSIGHTS.FULFILLED]: (state, { payload }) =>
    state
      .mergeIn(['notifications'], fromJS(payload.results))
      .set('counter', payload.unread_notifications)
      .set('notificationNextPage', payload.next ? payload.next.split('=')[1] : null),

  [MARK_AS_READ.FULFILLED]: (state, { meta: { notificationId } }) =>
    state
      .mergeIn(
        ['notifications'],
        state
          .get('notifications')
          .map(notification =>
            notification.get('id') === notificationId
              ? notification.set('read', true)
              : notification
          )
      )
      .set('counter', state.get('counter') - 1),

  [MARK_ALL_AS_READ.FULFILLED]: state =>
    state
      .mergeIn(
        ['notifications'],
        state.get('notifications').map(notification => notification.set('read', true))
      )
      .set('counter', 0),

  [APPEND_NOTIFICATION]: (state, { payload }) =>
    state
      .mergeIn(['notifications'], state.get('notifications').unshift(fromJS(payload.notification)))
      .set('counter', state.get('counter') + 1),

  [LOAD_MORE_NOTIFICATION.FULFILLED]: (state, { payload }) =>
    state
      .mergeIn(
        ['notifications'],
        state
          .get('notifications')
          .concat(fromJS(payload.results))
          .toSet()
          .toList()
          .sort((a, b) => b.get('id') - a.get('id'))
      )
      .set('notificationNextPage', payload.next ? payload.next.split('=')[1] : null)
      .set('counter', payload.unread_notifications),

  [AUTH_LOGOUT.FULFILLED]: () => INITIAL_STATE,

  [CANCEL_SUBSCRIPTION.FULFILLED]: () => INITIAL_STATE,

  [CREATE_COMMENT.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return state.set('comment', fromJS(camelizedPayload))
  },
})
