import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import promise from 'redux-promise-middleware'
import * as Sentry from '@sentry/react'

import { errorMiddleware } from '../../private-dependencies/react-utils/middlewares'
import unauthorizedMiddleware from '../middlewares/unauthorized'
import rootReducer from '../modules/reducers'

const router = routerMiddleware(browserHistory)

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const configureStore = preloadedState =>
  createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk, errorMiddleware, unauthorizedMiddleware, promise(), router),
      sentryReduxEnhancer
    )
  )

export default configureStore
