import humps from 'humps'

import { get, post, del, patch, generic } from './request'

const createFormData = data => {
  const formData = new FormData()
  Object.keys(data).forEach(field => {
    const fieldValue = data[field]
    const formDataValue = (() => {
      if (!fieldValue) {
        return ''
      }

      if (fieldValue instanceof Blob || typeof fieldValue !== 'object') {
        return fieldValue
      }

      return JSON.stringify(fieldValue)
    })()

    formData.append(field, formDataValue)
  })

  return formData
}

export const listPresentations = key => params => get(['presentations'], { key, params })
export const nextPagination = (key, next) => get(next, { key, removeTrailingSlash: true })
export const createPresentation = key => post(['presentations'], { key })
export const createTemplate = key => company =>
  post(['presentations'], { key }, { company, template: true })
export const retrievePresentation = key => (id, params) =>
  get(['presentations', id], { key, params })

export const copyPresentation = key => id => post(['presentations', id, 'clone'], { key })
export const editPresentation = key => (id, payload, params) =>
  payload.background || payload.background_cropped || payload.logo
    ? generic(['presentations', id], {
        method: 'PATCH',
        key,
        params,
        headers: {
          'Content-type': undefined,
        },
        body: createFormData(payload),
      })
    : patch(['presentations', id], { key, params }, payload)

export const createPresentationAsset = key => (presentationId, payload) => {
  if (payload.file) {
    const data = new FormData()
    Object.keys(payload).forEach(field => data.append(field, payload[field] ? payload[field] : ''))
    return generic(['presentations', presentationId, 'assets'], {
      method: 'POST',
      key,
      headers: {
        'Content-type': undefined,
      },
      body: data,
    })
  }

  return post(['presentations', presentationId, 'assets'], { key }, payload)
}

export const deletePresentationAsset = key => (presentationId, id) =>
  del(['presentations', presentationId, 'assets', id], { key })
export const resetPresentationAsset = key => (presentationId, id) =>
  post(['presentations', presentationId, 'assets', id, 'rollback-thumbnail'], { key })
export const reorderPresentationAssets = key => (presentationId, idList, params) =>
  post(
    ['presentations', presentationId, 'assets-order'],
    { key, params },
    { position_list: idList }
  )
export const editPresentationAsset = key => (presentationId, id, payload, params) =>
  payload.thumbnail || payload.thumbnail_cropped || payload.file
    ? generic(['presentations', presentationId, 'assets', id], {
        method: 'PATCH',
        key,
        params,
        headers: {
          'Content-type': undefined,
        },
        body: createFormData(payload),
      })
    : patch(['presentations', presentationId, 'assets', id], { key, params }, payload)

export const sharePresentationThroughEmail = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'share-email'], { key }, payload)

export const createPresentationShareLink = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'share-link'], { key }, payload)

export const updatePresentationShareLink = key => (presentationId, payload) =>
  patch(['presentations', presentationId, 'share-link'], { key }, payload)

export const requireEmailPresentation = (hash, payload) =>
  post(['client', 'presentations', hash, 'register-email'], {}, payload)

export const retrieveClientPresentation = key => (hash, params) =>
  get(['client', 'presentations', hash], { key, params })

export const forwardClientPresentation = (hash, params, payload) =>
  post(['client', 'presentations', hash, 'forward'], { params }, payload)

export const saveClientPresentation = key => hash =>
  post(['client', 'presentations', hash, 'save'], { key })

export const favoritePresentation = key => presentationId =>
  post(['presentations', presentationId, 'favorite'], { key })

export const removeFavoritePresentation = key => presentationId =>
  post(['presentations', presentationId, 'remove-favorite'], { key })

export const clonePresentationFromShareLink = key => (hash, id) =>
  post(['presentations', id, 'clone'], { key }, { hash })

export const listSharedLinks = key => presentationId =>
  get(['presentations', presentationId, 'share-links'], { key })

export const deleteSharedLink = key => (presentationId, sharedLinkId) =>
  del(['presentations', presentationId, 'share-links', sharedLinkId], { key })

export const unarchiveSharedLink = key => (presentationId, sharedLinkId) =>
  post(['presentations', presentationId, 'share-links', sharedLinkId, 'restore'], { key })

export const updateSharedLink = key => (presentationId, sharedLinkId, payload) =>
  patch(['presentations', presentationId, 'share-links', sharedLinkId], { key }, payload)

export const resendShareEmail = key => (presentationId, sharedLinkId, payload) =>
  post(['presentations', presentationId, 'share-links', sharedLinkId, 'resend'], { key }, payload)

export const bulkDeleteShare = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'share-links', 'bulk-delete'], { key }, payload)

export const makePresentationTemplate = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'make-template'], { key }, payload)

export const deleteReceivedPresentation = (key, presentationId) =>
  post(['client', 'presentations', presentationId, 'disable'], { key })

export const retrievePresentationsStats = key => payload =>
  post(['presentations', 'stats'], { key }, humps.decamelizeKeys(payload))
