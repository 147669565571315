import { STEPS_NAMES, FIELDS_NAMES } from '_constants/create-a-team'
import { TIERED_PLAN_OPTIONS } from '_utils/constants'

export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME'
export const UPDATE_STATE_ERROR = 'UPDATE_STATE_ERROR'

export const INITIAL_STATE = {
  [FIELDS_NAMES.TEAM_NAME]: '',
  [FIELDS_NAMES.QUANTITY]: 1,
  [FIELDS_NAMES.SELECTED_PLAN]: TIERED_PLAN_OPTIONS[0].value,
  errors: {
    [STEPS_NAMES.CREATE_TEAM]: {},
    [STEPS_NAMES.TEAM_SETTINGS]: {},
  },
  currentStep: STEPS_NAMES.CREATE_TEAM,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    case UPDATE_STATE_ERROR: {
      const currentStepErrors = state.errors[state.currentStep]
      const newErrors = {
        ...currentStepErrors,
        ...action.payload,
      }

      const filteredErrors = Object.fromEntries(
        Object.entries(newErrors).filter(([_, value]) => value !== '')
      )

      return {
        ...state,
        errors: {
          ...state.errors,
          [state.currentStep]: filteredErrors,
        },
      }
    }
    case UPDATE_TEAM_NAME:
      return { ...state, teamName: action.payload }
    default:
      return state
  }
}
