export const ADD_NEW_ASSET = 'ADD_NEW_ASSET'
export const DELETE_ASSET = 'DELETE_ASSET'
export const EDIT_ASSET = 'EDIT_ASSET'
export const REMOVE_ASSET_WATCH = 'REMOVE_ASSET_WATCH'

export const watchAddNewAsset = () => dispatch =>
  dispatch({
    type: ADD_NEW_ASSET,
  })

export const watchDeleteAsset = id => dispatch =>
  dispatch({
    type: DELETE_ASSET,
    id,
  })

export const watchEditAsset = payload => dispatch =>
  dispatch({
    type: EDIT_ASSET,
    payload,
  })

export const unwatchAsset = listIndex => dispatch => {
  dispatch({
    type: REMOVE_ASSET_WATCH,
    listIndex,
  })
}
