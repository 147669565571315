import { Map, fromJS, List } from 'immutable'
import humps from 'humps'
import * as Sentry from '@sentry/react'

import { createReducer } from '_privateDependencies/react-utils/utils'
import {
  CREATE_PRESENTATION,
  GET_PRESENTATION,
  LIST_PRESENTATIONS,
  CREATE_TEMPLATE,
} from '_modules/presentations/actions'
import { normalizeItems } from '_utils/module'
import { assetSchema, Asset } from '_models/'

import {
  CREATE_ASSET,
  EDIT_ASSET,
  REORDER_ASSETS,
  DELETE_ASSET,
  DOWNLOAD_ASSET_IMAGE_UNSPLASH,
  ENHANCE_ASSET,
  UPDATE_ASSET_TITLE_AND_DESCRIPTION,
  CLEAN_ENHANCE_ASSET,
  CLEAR_PROGRESS,
  CLEAR_ASSET_SUMMARIZER,
  UPDATE_ASSET_SUMMARIZER_PROGRESS,
  EDIT_ASSET_UPLOAD_SYSTEM_V2,
  UPDATE_ASSET_PERCENTAGE_UPLOAD_SYSTEM_V2,
  ASSET_FILE_UPLOAD_CONVERT,
  GET_ASSET_CONVERTED_FILE,
  SET_UPLOADING_ON_S3,
  DELETE_ASSET_SUMMARIZER,
  SET_ASSET_CONVERT_THUMBNAIL_FAILED,
} from './actions'

const START_UPLOAD = 1

const INITIAL_STATE = Map({
  assets: Map(),
  search: List(),
  enhance: Map({}),
})

export const normalizeAssets = items => {
  const camelizedItems = humps.camelizeKeys(items)

  return normalizeItems(camelizedItems, assetSchema)
}

export default createReducer(INITIAL_STATE, {
  [GET_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const normalizedAssets = normalizeAssets(payload.assets)

    if (normalizedAssets.size) {
      return state
        .mergeIn(['assets'], normalizedAssets.get('items'))
        .setIn(['search'], normalizedAssets.get('ids'))
    }

    return state
  },

  [CREATE_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const normalizedAssets = normalizeAssets(payload.assets)

    if (normalizedAssets.size) {
      return state
        .mergeIn(['assets'], normalizedAssets.get('items'))
        .setIn(['search'], normalizedAssets.get('ids'))
    }

    return state
  },
  [CREATE_TEMPLATE.FULFILLED]: (state, { payload }) => {
    const normalizedAssets = normalizeAssets(payload.assets)

    if (normalizedAssets.size) {
      return state
        .mergeIn(['assets'], normalizedAssets.get('items'))
        .setIn(['search'], normalizedAssets.get('ids'))
    }

    return state
  },
  [CREATE_ASSET.PENDING]: (state, { meta }) => {
    if (meta && meta.currentAssetIndex) {
      return state.setIn(['currentAssetIndex'], Number(meta.currentAssetIndex))
    }

    return state.setIn(['currentAssetIndex'], undefined)
  },

  [CREATE_ASSET.FULFILLED]: (state, { payload }) => {
    const normalizedAsset = normalizeAssets([humps.camelizeKeys(payload)])
    const asset = normalizedAsset.get('items').first()

    return state
      .setIn(['assets', String(asset.get('id'))], asset)
      .updateIn(['search'], search => search.push(normalizedAsset.get('ids').first()))
  },

  [EDIT_ASSET.PENDING]: (state, { meta }) => {
    const { isUploadingFile, forceUpdate, payload } = meta
    if (isUploadingFile) {
      return state
        .setIn(['assets', String(meta.assetId), 'percentageUpload'], START_UPLOAD)
        .setIn(['assets', String(meta.assetId), 'forceUpdate'], undefined)
    }

    if (forceUpdate) {
      return state
        .setIn(['assets', String(meta.assetId), 'forceUpdate'], true)
        .setIn(['assets', String(meta.assetId), 'showSelectionSummarize'], undefined)
        .setIn(['assets', String(meta.assetId), 'title'], payload.title)
        .setIn(['assets', String(meta.assetId), 'description'], payload.description)
    }

    if ('type' in payload) {
      return state.setIn(['assets', String(meta.assetId), 'type'], payload.type)
    }

    return state
  },

  [EDIT_ASSET.FULFILLED]: (state, { payload, meta }) => {
    const newAsset = new Asset(humps.camelizeKeys(payload))

    return state.updateIn(['assets', String(meta.assetId)], asset => {
      if (asset.get('updatedAt') > newAsset.get('updatedAt')) {
        return asset
      }
      return asset.merge(newAsset)
    })
  },
  [EDIT_ASSET.REJECTED]: (state, { meta }) =>
    state.setIn(['assets', String(meta.assetId), 'hasError'], true),
  [CLEAR_PROGRESS]: (state, { meta }) =>
    state
      .setIn(['assets', String(meta.assetId), 'percentageUpload'], undefined)
      .setIn(['assets', String(meta.assetId), 'hasError'], undefined)
      .setIn(['assets', String(meta.assetId), 'summarizerProgress'], undefined),
  [REORDER_ASSETS.PENDING]: (state, { meta }) => {
    const assets = meta.assets.map((asset, index) => {
      const newAsset = asset.toJS()

      return { ...newAsset, position: index }
    })
    const normalizedAssets = normalizeAssets(assets)

    return state
      .setIn(['assets'], normalizedAssets.get('items'))
      .setIn(['search'], normalizedAssets.get('ids'))
  },
  [REORDER_ASSETS.FULFILLED]: (state, { payload }) => {
    const assets = payload.map(asset => {
      const newAsset = state.getIn(['assets', String(asset.id)]).toJS()

      return { ...newAsset, position: asset.position }
    })
    const normalizedAssets = normalizeAssets(assets)

    return state
      .setIn(['assets'], normalizedAssets.get('items'))
      .setIn(['search'], normalizedAssets.get('ids'))
  },
  [DELETE_ASSET.FULFILLED]: (state, { payload }) => {
    const normalizedAssets = normalizeAssets(humps.camelizeKeys(payload))

    return state
      .setIn(['assets'], normalizedAssets.get('items') ? normalizedAssets.get('items') : Map())
      .setIn(['search'], normalizedAssets.get('ids') ? normalizedAssets.get('ids') : List())
  },
  [DOWNLOAD_ASSET_IMAGE_UNSPLASH]: (state, { payload }) => {
    const { assetId, unsplashPercentage } = payload
    return state.setIn(['assets', String(assetId), 'unsplashPercentage'], unsplashPercentage)
  },
  [LIST_PRESENTATIONS.PENDING]: state => state.set('search', Map()),
  [ENHANCE_ASSET.PENDING]: state => state.set('enhance', Map({})),
  [CLEAN_ENHANCE_ASSET]: state => state.set('enhance', Map({})),
  [ENHANCE_ASSET.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return state.set('enhance', fromJS(camelizedPayload))
  },
  [UPDATE_ASSET_TITLE_AND_DESCRIPTION]: (state, { payload }) => {
    const { asset } = payload

    return state.updateIn(['assets', String(asset.id)], currAsset => {
      if (!currAsset.get('title') && !currAsset.get('description')) {
        return currAsset
          .set('title', asset.title)
          .set('description', asset.description)
          .set('forceUpdate', true)
          .set('summarizerProgress', undefined)
      }

      return currAsset
        .set('showSelectionSummarize', true)
        .set(
          'aiSummarizerResult',
          Map({
            title: asset.title,
            description: asset.description,
          })
        )
        .set('summarizerProgress', undefined)
    })
  },
  [CLEAR_ASSET_SUMMARIZER]: (state, { meta }) => {
    const { assetId } = meta

    return state
      .setIn(['assets', String(assetId), 'showSelectionSummarize'], undefined)
      .setIn(['assets', String(assetId), 'summarizerProgress'], undefined)
      .setIn(['assets', String(assetId), 'aiSummarizerResult'], undefined)
      .setIn(['assets', String(assetId), 'forceUpdate'], undefined)
  },
  [UPDATE_ASSET_SUMMARIZER_PROGRESS]: (state, { payload }) => {
    const { assetId, step } = payload

    return state.setIn(['assets', String(assetId), 'summarizerProgress'], step)
  },
  [DELETE_ASSET_SUMMARIZER.PENDING]: (state, { meta }) => {
    const { assetId } = meta

    return state
      .setIn(['assets', String(assetId), 'showSelectionSummarize'], undefined)
      .setIn(['assets', String(assetId), 'summarizerProgress'], undefined)
      .setIn(['assets', String(assetId), 'aiSummarizerResult'], undefined)
      .setIn(['assets', String(assetId), 'ignoreAssetSummarizer'], true)
  },
  [DELETE_ASSET_SUMMARIZER.FULFILLED]: (state, { payload, meta }) => {
    const newAsset = new Asset(humps.camelizeKeys(payload))

    return state.updateIn(['assets', String(meta.assetId)], asset => {
      if (asset.get('updatedAt') > newAsset.get('updatedAt')) {
        return asset
      }
      return asset.merge(newAsset)
    })
  },
  [EDIT_ASSET_UPLOAD_SYSTEM_V2.PENDING]: (state, { meta }) =>
    state.setIn(['assets', String(meta.assetId), 'percentageUpload'], START_UPLOAD),
  [EDIT_ASSET_UPLOAD_SYSTEM_V2.FULFILLED]: (state, { payload, meta }) =>
    state.updateIn(['assets', String(meta.assetId)], asset =>
      asset.set('url', payload.url).set('fields', payload.fields)
    ),
  [UPDATE_ASSET_PERCENTAGE_UPLOAD_SYSTEM_V2]: (state, { payload }) => {
    const { assetId, percentage, extra } = payload
    const asset = state.getIn(['assets', String(assetId)])
    if (!asset) {
      Sentry.captureMessage(`Asset not found with id ${assetId}`, state)
      return state
    }

    if (percentage === 100 && extra) {
      const camelizedExtra = humps.camelizeKeys(extra)
      return state.setIn(
        ['assets', String(assetId)],
        asset.merge(camelizedExtra).set('percentageUpload', undefined)
      )
    }

    if (percentage === 100) {
      return state.setIn(['assets', String(assetId), 'percentageUpload'], undefined)
    }

    return state.setIn(['assets', String(assetId), 'percentageUpload'], percentage)
  },
  [ASSET_FILE_UPLOAD_CONVERT.FULFILLED]: (state, { payload, meta }) => {
    const newAsset = new Asset(humps.camelizeKeys(payload))

    return state.updateIn(['assets', String(meta.assetId)], asset =>
      asset
        .merge(newAsset)
        .set('percentageUpload', asset.get('percentageUpload'))
        .set('isLoadingS3', false)
        .set('summarizerProgress', asset.get('summarizerProgress'))
    )
  },
  [ASSET_FILE_UPLOAD_CONVERT.REJECTED]: (state, { meta }) =>
    state.setIn(['assets', String(meta.assetId), 'hasError'], true),
  [GET_ASSET_CONVERTED_FILE.FULFILLED]: (state, { payload, meta }) => {
    if (payload.length) {
      return state.updateIn(['assets', String(meta.assetId)], asset =>
        asset.set('convertedFiles', List(payload))
      )
    }

    return state
  },
  [SET_UPLOADING_ON_S3]: (state, { meta }) =>
    state.updateIn(['assets', String(meta.assetId)], asset => asset.set('isLoadingS3', true)),

  [SET_ASSET_CONVERT_THUMBNAIL_FAILED]: (state, { meta }) =>
    state.updateIn(['assets', String(meta.assetId)], asset =>
      asset
        .set('percentageUpload', undefined)
        .set('isLoadingS3', undefined)
        .set('hasError', true)
    ),
})
