import { List, Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'

import AssetVisualizationModal from '_components/presentation-preview/presentation-content-preview/asset-visualization-modal'
import Presentation from '_models/presentation'
import { getFieldValue } from '_utils/presentation'

const getCorrectAssetPosition = (assets, pos) => {
  const index = assets.findIndex(asset => asset.get('position') === pos)
  return index === -1 ? undefined : index
}

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

const removeEmptyAssets = asset =>
  (asset.get('website') !== null || asset.get('file') !== null) && !asset.get('isHidden')

const sortAssetByPosition = (a, b) => {
  if (a.get('position') < b.get('position')) return -1
  if (a.get('position') > b.get('position')) return 1
  return 0
}

const getAssets = assets => assets.filter(removeEmptyAssets).sort(sortAssetByPosition)

const withAssetVisualization = WrappedComponent => {
  class WithAssetVisualization extends PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        assetNumber: 0,
        filteredAssets: props.presentation.get('assets')
          ? getAssets(props.presentation.get('assets'))
          : List(),
        isVisualizationOpen: false,
        videoSecondsWatched: 0,
        viewDuration: 0,
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { presentation, clearAssetInsightsId, registerAssetInsights } = this.props

      const { assetNumber, isVisualizationOpen, viewDuration } = this.state
      const assets = presentation.get('assets')

      const prevAssets = prevProps.presentation.get('assets')
      if (prevAssets !== assets) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          filteredAssets: getAssets(assets),
        })
      }

      if (prevState.isVisualizationOpen === isVisualizationOpen) {
        return
      }

      if (isVisualizationOpen && assetNumber !== undefined) {
        this.interval = setInterval(this.incrementViewDuration, 5000)
        registerAssetInsights(getAssets(assets).getIn([assetNumber, 'id']), viewDuration)
      } else if (!isVisualizationOpen) {
        clearInterval(this.interval)
        clearAssetInsightsId()
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ videoSecondsWatched: 0, viewDuration: 0 })
      }
    }

    incrementViewDuration = () => {
      const { presentation, assetInsightsId, updateAssetViewDuration } = this.props
      const { assetNumber, videoSecondsWatched, viewDuration } = this.state
      const assets = presentation.get('assets')

      const assetType = getAssets(assets).getIn([assetNumber, 'type'])

      if (['video', 'website'].indexOf(assetType) < 0 && assetInsightsId > 0) {
        updateAssetViewDuration(viewDuration + 5)
      } else if (['video'].indexOf(assetType) > -1 && assetInsightsId > 0) {
        updateAssetViewDuration(videoSecondsWatched)
      }

      this.setState(state => ({ viewDuration: state.viewDuration + 5 }))
    }

    jumpToAsset = assetNumber => {
      const assetIndex = getCorrectAssetPosition(this.state.filteredAssets, assetNumber)
      if (assetNumber !== undefined && assetIndex !== undefined) {
        this.setState({ assetNumber: assetIndex })
      }
    }

    resetDurationAndRegisterNewInsights = assetNumber => {
      const { presentation, registerAssetInsights } = this.props
      const assets = presentation.get('assets')

      this.setState({
        assetNumber,
        viewDuration: 0,
        videoSecondsWatched: 0,
      })

      clearInterval(this.interval)
      this.interval = setInterval(this.incrementViewDuration, 5000)

      registerAssetInsights(getAssets(assets).getIn([assetNumber, 'id']), 0)
    }

    toggleVisualization = assetNumber => {
      const { presentation, registerAssetInsights } = this.props
      const { filteredAssets } = this.state

      if (assetNumber !== undefined) {
        const currentAsset = presentation
          .get('assets')
          .find(asset => Number(asset.get('position')) === Number(assetNumber))

        if (currentAsset && currentAsset.get('type') === 'website') {
          registerAssetInsights(currentAsset.get('id'), 0)
          window.open(currentAsset.get('website'), '_blank')
          return
        }
      }

      const assetIndex = getCorrectAssetPosition(filteredAssets, assetNumber)
      if (assetNumber !== undefined && assetIndex !== undefined) {
        this.setState({ assetNumber: assetIndex })
      }

      this.setState(state => ({ isVisualizationOpen: !state.isVisualizationOpen }))
    }

    updateVideoViewDuration = played => {
      this.setState({ videoSecondsWatched: Math.round(played) })
    }

    render() {
      const { updateAssetPagesViewed, presentation } = this.props

      const { assetNumber, filteredAssets, isVisualizationOpen } = this.state

      const authorAvatar = getFieldValue(presentation, 'picture')
      const authorName = getFieldValue(presentation, 'firstName')

      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            assets={filteredAssets}
            isVisualizationOpen={isVisualizationOpen}
            toggleVisualization={this.toggleVisualization}
          />
          <AssetVisualizationModal
            {...this.props}
            styleType={presentation.get('style')}
            assets={filteredAssets}
            authorAvatar={authorAvatar}
            authorName={authorName}
            closingMessage={presentation.get('closing_msg')}
            currentPage={assetNumber}
            dismiss={this.toggleVisualization}
            isOpen={isVisualizationOpen}
            isPreventDownload={presentation.get('preventDownload')}
            jumpToAsset={this.jumpToAsset}
            resetDurationAndRegisterNewInsights={this.resetDurationAndRegisterNewInsights}
            updateAssetPagesViewed={updateAssetPagesViewed}
            updateVideoViewDuration={this.updateVideoViewDuration}
          />
        </Fragment>
      )
    }
  }

  WithAssetVisualization.propTypes = {
    presentation: PropTypes.oneOfType([
      PropTypes.instanceOf(Map),
      PropTypes.instanceOf(Presentation),
    ]),
    assetInsightsId: PropTypes.number,
    clearAssetInsightsId: PropTypes.func,
    registerAssetInsights: PropTypes.func,
    updateAssetPagesViewed: PropTypes.func,
    updateAssetViewDuration: PropTypes.func,
  }

  WithAssetVisualization.defaultProps = {
    presentation: Map(),
    assetInsightsId: -1,
    clearAssetInsightsId: () => {},
    registerAssetInsights: () => {},
    updateAssetPagesViewed: () => {},
    updateAssetViewDuration: () => {},
  }

  WithAssetVisualization.displayName = `WithAssetVisualization(${getDisplayName(WrappedComponent)})`
  return WithAssetVisualization
}

export default withAssetVisualization
