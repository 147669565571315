import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'

import {
  PRESENTATION_STYLE_STANDARD,
  PRESENTATION_STYLE_BARCELONA,
  PRESENTATION_STYLE_OUTLINE,
  getCurrentStyleName,
  getCurrentStyleIcon,
} from '_constants/presentation-styles'

import styles from './styles.css'

const PresentationStyleModal = ({ onClickOption, value, ...restProps }) => (
  <Modal
    {...restProps}
    className={styles.modal}
    contentLabel="Presentation style modal"
    overlayClassName={styles.overlay}
    ariaHideApp={false}
  >
    <button
      className={classNames(styles.option, {
        [styles.selected]: value === PRESENTATION_STYLE_BARCELONA,
      })}
      onClick={onClickOption}
      type="button"
      value={PRESENTATION_STYLE_BARCELONA}
    >
      <svg aria-hidden="true" focusable="false" className={styles.icon}>
        <use xlinkHref={getCurrentStyleIcon[PRESENTATION_STYLE_BARCELONA]} />
      </svg>
      {getCurrentStyleName[PRESENTATION_STYLE_BARCELONA]}
    </button>
    <button
      className={classNames(styles.option, {
        [styles.selected]: value === PRESENTATION_STYLE_STANDARD,
      })}
      onClick={onClickOption}
      type="button"
      value={PRESENTATION_STYLE_STANDARD}
    >
      <svg aria-hidden="true" focusable="false" className={styles.icon}>
        <use xlinkHref={getCurrentStyleIcon[PRESENTATION_STYLE_STANDARD]} />
      </svg>
      {getCurrentStyleName[PRESENTATION_STYLE_STANDARD]}
    </button>
  </Modal>
)

PresentationStyleModal.propTypes = {
  onClickOption: PropTypes.func.isRequired,
  value: PropTypes.oneOf([
    PRESENTATION_STYLE_STANDARD,
    PRESENTATION_STYLE_BARCELONA,
    PRESENTATION_STYLE_OUTLINE,
  ]),
}

PresentationStyleModal.defaultProps = {
  value: PRESENTATION_STYLE_BARCELONA,
}

export default PresentationStyleModal
