import noScroll from 'no-scroll'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classnames from 'classnames'
import Modal from 'react-modal'
import { List } from 'immutable'
import { Link } from 'react-router'
import autobind from 'autobind-decorator'
import ReactGA from 'react-ga'
import debounce from 'lodash.debounce'

import { sortByConvertedFileOrder } from '_utils/sort'
import { GA_ENABLED } from '_config/environment'
import withBrowserInformation from '_hocs/with-browser-information'
import CloseIcon from '_assets/icons/remove_asset_button.svg'
import DownloadIcon from '_assets/icons/ic_download.svg'
import WebsiteIcon from '_assets/icons/link_circle.svg'
import MediaBlockVertical, { MediaSize, FontSize, Color } from '_components/media-block-vertical'

import AssetVisualizationSection from './asset-visualization-section'
import styles from './styles.css'

export const MODAL_ID = 'asset-visualization-modal'

class AssetVisualizationModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    dismiss: PropTypes.func,
    assets: PropTypes.instanceOf(List),
    authorName: PropTypes.string,
    authorAvatar: PropTypes.string,
    currentPage: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    resetDurationAndRegisterNewInsights: PropTypes.func,
    updateAssetPagesViewed: PropTypes.func,
    jumpToAsset: PropTypes.func,
    updateVideoViewDuration: PropTypes.func,
    isSingleAssetPreview: PropTypes.bool,
    isPreventDownload: PropTypes.bool,
  }

  static defaultProps = {
    isOpen: false,
    dismiss: undefined,
    assets: List(),
    authorAvatar: '',
    authorName: 'Author',
    currentPage: 0,
    isSingleAssetPreview: false,
    isPreventDownload: false,
    resetDurationAndRegisterNewInsights: () => {},
    updateAssetPagesViewed: () => {},
    jumpToAsset: () => {},
    updateVideoViewDuration: () => {},
  }

  constructor(props) {
    super(props)
    this.forceScreenUpdate = debounce(this.forceScreenUpdate, 500)
  }

  state = {
    currentPage: 0,
    bottomNavigation: false,
    isLoadingAsset: true,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentPage: nextProps.currentPage,
    })

    if (nextProps.isOpen && !this.props.isOpen) {
      if (GA_ENABLED) {
        ReactGA.modalview(`${window.location.pathname}/item-preview-modal`)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props

    if (prevProps.isOpen === isOpen) {
      return
    }

    if (isOpen) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }

  componentWillUnmount() {
    noScroll.off()
  }

  onLoadAsset = () => {
    this.setState({
      isLoadingAsset: false,
    })
  }

  forceScreenUpdate = () => {
    if (this.props.assets.get(this.state.currentPage, new Map()).get('type') === 'file') {
      this.forceUpdate()
    }
  }

  toggleAssetsBottomNavigation = () => {
    this.setState(prevState => ({
      bottomNavigation: !prevState.bottomNavigation,
    }))
  }

  jumpToAsset = pageNumber => {
    this.setState({
      bottomNavigation: false,
      isLoadingAsset: true,
    })
    this.props.resetDurationAndRegisterNewInsights(pageNumber)
    this.props.jumpToAsset(pageNumber)
  }

  @autobind
  downloadFile() {
    const { assets } = this.props
    const { currentPage } = this.state
    const file = assets
      .get(currentPage)
      .get('file')
      .split('/presentation-asset/')
      .pop()
    const fileName = assets.get(currentPage).get('filename')

    return `/downloadFile/${file}${
      fileName
        ? `?fileName=${decodeURIComponent(encodeURIComponent(fileName)).replace(
            /[^a-zA-Z0-9\s.-_]/g,
            '-'
          )}`
        : ''
    }`
  }

  @autobind
  dismissModal() {
    this.setState({
      bottomNavigation: false,
      isLoadingAsset: true,
    })
    this.props.dismiss()
  }

  renderContent = () => {
    const {
      assets,
      authorName,
      authorAvatar,
      updateAssetPagesViewed,
      updateVideoViewDuration,
      isSingleAssetPreview,
      isPreventDownload,
    } = this.props
    const { currentPage, isLoadingAsset } = this.state

    const convertedFiles = assets.get(currentPage) && assets.get(currentPage).get('convertedFiles')

    return (
      <div className={styles.grid}>
        {assets.size > 0 && (
          <AssetVisualizationSection
            authorName={authorName}
            authorAvatar={authorAvatar}
            convertedFiles={convertedFiles.sort(sortByConvertedFileOrder)}
            description={assets.get(currentPage).get('description')}
            thumbnail={assets.get(currentPage).get('thumbnail')}
            assetType={assets.get(currentPage).get('type')}
            assetLink={
              assets.get(currentPage).get('file') || assets.get(currentPage).get('website')
            }
            updateAssetPagesViewed={updateAssetPagesViewed}
            isLoadingAsset={isLoadingAsset}
            onLoadAsset={this.onLoadAsset}
            updateVideoViewDuration={updateVideoViewDuration}
            isSingleAssetPreview={isSingleAssetPreview}
          />
        )}
        <div className={styles['actions-next-container']}>
          <button
            id="close-preview-asset-modal"
            onClick={this.dismissModal}
            className={styles.close}
            type="button"
          >
            <MediaBlockVertical
              text=""
              media={CloseIcon}
              mediaSize={MediaSize.EXTRA_LARGE}
              fontSize={FontSize.EXTRA_SMALL}
              color={Color.WHITE}
              textClassName={styles.text}
            />
          </button>
          {assets.size > 0 &&
            (assets.get(currentPage).get('type') === 'website' ||
              assets.get(currentPage).get('type') === 'video') && (
              <Link
                to={assets.get(currentPage).get('website')}
                target="_blank"
                className={styles.download}
              >
                <MediaBlockVertical
                  text="Link"
                  media={WebsiteIcon}
                  mediaSize={MediaSize.EXTRA_LARGE}
                  fontSize={FontSize.SMALL}
                  color={Color.WHITE}
                  textClassName={styles.text}
                />
              </Link>
            )}
          {assets.size > 0 &&
            (assets.get(currentPage).get('type') === 'file' ||
              assets.get(currentPage).get('type') === 'map') &&
            !isPreventDownload && (
              <a className={styles.download} href={this.downloadFile()}>
                <MediaBlockVertical
                  text="Download"
                  media={DownloadIcon}
                  mediaSize={MediaSize.EXTRA_LARGE}
                  fontSize={FontSize.SMALL}
                  color={Color.WHITE}
                  textClassName={styles.text}
                />
              </a>
            )}
        </div>
      </div>
    )
  }

  render() {
    const { isOpen } = this.props
    return (
      <Modal
        className={styles.modal}
        contentLabel="Asset visualization"
        overlayClassName={classnames(styles.overlay)}
        isOpen={isOpen}
        bodyOpenClassName={classnames(styles['disable-body-scroll'])}
        htmlOpenClassName={classnames(styles['disable-html-scroll'])}
        ariaHideApp={false}
        id={MODAL_ID}
      >
        {this.renderContent()}
      </Modal>
    )
  }
}

export default withBrowserInformation(AssetVisualizationModal)
