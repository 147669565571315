import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ArrowDownIcon from '_assets/icons/arrow-down.svg'
import Modal from '_components/ui-kit/modal'
import Svg from '_components/svg'
import Input from '_components/ui-kit/input'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import LabeledSwitch from '_components/switch/labeled-switch'
import withBrowserInformation from '_hocs/with-browser-information'
import useToggle from '_hooks/use-modal'
import LinkIcon from '_assets/icons/link.svg'
import MailIcon from '_assets/icons/envelope.svg'
import {
  createShareLink,
  CREATE_SHARE_LINK,
  getShareLinkByHash,
  GET_SHARE_LINK_BY_HASH,
  updateShareLink,
  unarchiveSharedLink,
  UNARCHIVE_SHARE_LINK,
  UPDATE_SHARE_LINK,
} from '_modules/share-link-new/actions'
import { individualLinkSelector } from '_modules/share-link-new/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'
import { isFreeSelector } from '_modules/user/selectors'
import { presentationSelector } from '_modules/presentations/selectors'
import { generateEmailPast } from '_utils/helpers'

import Label from '../label'

import styles from './styles.css'

const INITIAL_STATE = {
  activityEmailUpdates: true,
  requireEmail: false,
  forwardTracking: false,
  preventDownload: false,
  isIndexable: false,
  isPublic: false,
  name: '',
  preventFeedback: false,
  requirePhoneNumber: false,
  requireEmailMessage: '',
  requirePhoneNumberMessage: '',
  passwordProtect: '',
}

const IndividualLinkModal = ({ presentationId, shareLink, onClose, isFirefox }) => {
  const [isGeneratedLink, onToggleGeneratedLink] = useToggle()
  const [isPasswordProtect, onTogglePasswordProtect] = useToggle()
  const [isAdvanceSettingToggle, toggleAdvanceSettingOpen] = useToggle()

  const [payload, setPayload] = useState(INITIAL_STATE)
  const [errors, setErrors] = useState({})

  const individualLink = useSelector(individualLinkSelector)
  const presentation = useSelector(state => presentationSelector(state, presentationId))

  const isFreeUser = useSelector(isFreeSelector)

  const dispatch = useDispatch()

  const validatePayload = useCallback(() => {
    const currentErrors = {}

    if (isPasswordProtect && !payload.passwordProtect) {
      currentErrors.passwordProtect = 'Please type a valid password'
    }

    if (!payload.name) {
      currentErrors.name = 'Please type a valid name'
    }

    setErrors(currentErrors)
    return !Object.keys(currentErrors).length
  }, [isPasswordProtect, payload.name, payload.passwordProtect])

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(`${individualLink.get('shareLink')}?cp=link`)
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.SUCCESS,
        message: 'Presentation link successfully copied',
      })
    )
  }, [dispatch, individualLink])

  const onCopyToEmailClipboard = useCallback(() => {
    const title = presentation.get('cleanTitle') || presentation.get('title')
    const author = `${presentation.getIn(['user', 'firstName'])} ${presentation.getIn([
      'user',
      'lastName',
    ])}`

    const thumbnail = presentation.get('background')

    const emailContent = generateEmailPast(
      `${individualLink.get('shareLink')}?cp=email`,
      thumbnail,
      title,
      author
    )

    const { ClipboardItem } = window
    if (ClipboardItem) {
      const data = [
        new ClipboardItem({
          'text/html': new Blob([emailContent], { type: 'text/html' }),
        }),
      ]
      navigator.clipboard.write(data)

      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.SUCCESS,
          message: 'Presentation link successfully copied',
        })
      )
      return
    }
    navigator.clipboard.writeText(individualLink.get('shareLink'))

    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Fail to generate link for email',
      })
    )
  }, [dispatch, presentation, individualLink])

  const onChange = useCallback((name, value) => {
    setPayload(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const onInputChange = useCallback(event => onChange(event.target.name, event.target.value), [
    onChange,
  ])

  const onChangePasswordProtect = useCallback(() => {
    onTogglePasswordProtect()
  }, [onTogglePasswordProtect])

  const onGenerateLink = useCallback(() => {
    if (!validatePayload()) {
      return
    }

    const finalPayload = {
      activityEmailUpdates: payload.activityEmailUpdates,
      requireEmail: payload.requireEmail,
      forwardTracking: payload.forwardTracking,
      preventDownload: payload.preventDownload,
      isIndexable: payload.isIndexable,
      isPublic: payload.isPublic,
      name: payload.name,
      ...(payload.requirePhoneNumber && { requirePhoneNumber: payload.requirePhoneNumber }),
      ...(payload.requirePhoneNumberMessage && {
        requirePhoneNumberMessage: payload.requirePhoneNumberMessage,
      }),
      ...(payload.requireEmailMessage && {
        requireEmailMessage: payload.requireEmailMessage,
      }),
      preventFeedback: payload.preventFeedback,
      ...(isPasswordProtect && { passwordProtect: payload.passwordProtect }),
    }

    if (shareLink && individualLink.get('id')) {
      if (individualLink.get('disabled')) {
        dispatch(
          unarchiveSharedLink({
            presentationId: shareLink.id,
            shareLinkId: individualLink.get('id'),
            payload: { ...finalPayload },
          })
        )
        return
      }

      dispatch(
        updateShareLink({
          presentationId: shareLink.id,
          shareLinkId: individualLink.get('id'),
          payload: { ...finalPayload },
          isPublic: false,
        })
      )
      return
    }
    dispatch(
      createShareLink({
        presentationId,
        payload: { ...finalPayload },
        isPublic: false,
      })
    )
  }, [
    validatePayload,
    shareLink,
    individualLink,
    dispatch,
    presentationId,
    payload,
    isPasswordProtect,
  ])

  const onGenerateAnotherLink = useCallback(() => {
    setPayload(INITIAL_STATE)
    onToggleGeneratedLink()
  }, [onToggleGeneratedLink])

  useEffect(() => {
    if (shareLink) {
      dispatch(getShareLinkByHash(shareLink.id, shareLink.hash))
    }
  }, [dispatch, presentationId, shareLink])

  const onCreateShareLinkSuccess = useCallback(() => {
    if (individualLink && !individualLink.get('isPublic')) {
      onToggleGeneratedLink()
    }
  }, [individualLink, onToggleGeneratedLink])

  const onGetShareLinkSuccess = useCallback(() => {
    if (individualLink && !individualLink.get('isPublic')) {
      setPayload({
        preventFeedback: individualLink.get('preventFeedback'),
        activityEmailUpdates: individualLink.get('activityEmailUpdates'),
        requireEmail: individualLink.get('requireEmail'),
        forwardTracking: individualLink.get('forwardTracking'),
        preventDownload: individualLink.get('preventDownload'),
        isIndexable: individualLink.get('isIndexable'),
        isPublic: individualLink.get('isPublic'),
        name: individualLink.get('name'),
        ...(individualLink.get('passwordProtect') && {
          passwordProtect: individualLink.get('passwordProtect'),
        }),
        requireEmailMessage: individualLink.get('requireEmailMessage') || '',
        requirePhoneNumber: individualLink.get('requirePhoneNumber'),
        requirePhoneNumberMessage: individualLink.get('requirePhoneNumberMessage') || '',
      })
      if (individualLink.get('passwordProtect')) {
        onTogglePasswordProtect()
      }
    }
  }, [individualLink, onTogglePasswordProtect])

  const [isLoadingCreateShareLink] = useFetchCall(CREATE_SHARE_LINK, onCreateShareLinkSuccess)
  const [isLoadingGetShareLink] = useFetchCall(GET_SHARE_LINK_BY_HASH, onGetShareLinkSuccess)
  useFetchCall(UNARCHIVE_SHARE_LINK, onCreateShareLinkSuccess)
  useFetchCall(UPDATE_SHARE_LINK, onCreateShareLinkSuccess)

  const labeledSwitchOptions = useMemo(
    () => [
      {
        label: 'Receive analytics by email',
        name: 'activityEmailUpdates',
        isChecked: payload.activityEmailUpdates,
        onChange: (value, { name }) => onChange(name, value),
      },
      {
        label: <Label name="Require email to view" isFreeUser={isFreeUser} />,
        name: 'requireEmail',
        isChecked: payload.requireEmail,
        disabled: isFreeUser,
        onChange: (value, { name }) => onChange(name, value),
        input: {
          placeholder: 'Enter your requested message',
          onChange: onInputChange,
          value: payload.requireEmailMessage || '',
          name: 'requireEmailMessage',
        },
      },
      {
        label: <Label name="Require phone number to view" isFreeUser={isFreeUser} />,
        name: 'requirePhoneNumber',
        disabled: isFreeUser,
        onChange: (value, { name }) => onChange(name, value),
        isChecked: payload.requirePhoneNumber,
        input: {
          placeholder: 'Enter your requested message',
          onChange: onInputChange,
          value: payload.requirePhoneNumberMessage || '',
          name: 'requirePhoneNumberMessage',
        },
      },
    ],
    [
      payload.activityEmailUpdates,
      payload.requireEmail,
      isFreeUser,
      onChange,
      onInputChange,
      payload.requirePhoneNumber,
      payload.requireEmailMessage,
      payload.requirePhoneNumberMessage,
    ]
  )

  const labeledSwitchOptionsAdvanced = useMemo(
    () => [
      {
        label: <Label name="Disable asset download" isFreeUser={isFreeUser} />,
        name: 'preventDownload',
        disabled: isFreeUser,
        isChecked: payload.preventDownload,
        onChange: (value, { name }) => onChange(name, value),
      },
      {
        label: <Label name="Disable presentation feedback" />,
        name: 'preventFeedback',
        isChecked: payload.preventFeedback,
        onChange: (value, { name }) => onChange(name, value),
      },
      {
        label: <Label name="Password protect" isFreeUser={isFreeUser} />,
        isChecked: isPasswordProtect,
        name: 'passwordProtect',
        disabled: isFreeUser,
        input: {
          type: 'password',
          placeholder: 'Create a password',
          onChange: onInputChange,
          value: payload.passwordProtect || '',
          name: 'passwordProtect',
        },
        onChange: onChangePasswordProtect,
      },
    ],
    [
      isFreeUser,
      onChange,
      isPasswordProtect,
      onInputChange,
      payload.passwordProtect,
      payload.preventDownload,
      onChangePasswordProtect,
      payload.preventFeedback,
    ]
  )

  const createSwitchComponent = useCallback(
    option =>
      option.name && (
        <Fragment key={option.name}>
          <LabeledSwitch
            className={styles['labeled-switch']}
            label={option.label}
            isChecked={option.isChecked}
            onChange={option.onChange}
            name={option.name}
            disabled={option.disabled}
          />
          {option.input && option.isChecked && (
            <Input
              name={option.input.name}
              type={option.input.type}
              placeholder={option.input.placeholder}
              startIcon={option.input.startIcon}
              onChange={option.input.onChange}
              error={!payload[option.name] && errors[option.name]}
              value={option.input.value}
            />
          )}
        </Fragment>
      ),

    [errors, payload]
  )

  const linkSetting = useMemo(() => labeledSwitchOptions.map(createSwitchComponent), [
    createSwitchComponent,
    labeledSwitchOptions,
  ])

  const linkAdvancedSetting = useMemo(
    () => labeledSwitchOptionsAdvanced.map(createSwitchComponent),
    [createSwitchComponent, labeledSwitchOptionsAdvanced]
  )

  const generatedLinkContent = useMemo(
    () => (
      <div className={styles['generated-link-content']}>
        <Input className={styles['generated-link']} value={individualLink.get('shareLink')} />
        <div className={styles['button-wrapper']}>
          <Button
            className={styles['copy-button']}
            theme={BUTTON_THEME.SECONDARY}
            startIcon={LinkIcon}
            onClick={onCopyToClipboard}
          >
            COPY LINK
          </Button>
          {!shareLink && !isFirefox && (
            <Button
              className={styles['email-button']}
              theme={BUTTON_THEME.SECONDARY}
              startIcon={MailIcon}
              onClick={onCopyToEmailClipboard}
            >
              COPY FOR EMAIL
            </Button>
          )}
        </div>
        <table className={styles['overview-table']}>
          <th className={styles.title} scope="col">
            Link settings overview
          </th>

          <tr>
            <th className={styles.column} scope="col">
              Name
            </th>
            {payload.passwordProtect && (
              <th className={styles.column} scope="col">
                Password
              </th>
            )}
          </tr>
          <tr>
            <td data-label="Name" className={styles.row}>
              {payload.name}
            </td>
            {payload.passwordProtect && (
              <td data-label="Password" className={styles.row}>
                {payload.passwordProtect}
              </td>
            )}
          </tr>
        </table>

        <Button className={styles['another-link-button']} onClick={onGenerateAnotherLink}>
          Generate another link
        </Button>
      </div>
    ),
    [
      individualLink,
      onCopyToClipboard,
      onCopyToEmailClipboard,
      onGenerateAnotherLink,
      payload.name,
      payload.passwordProtect,
      shareLink,
      isFirefox,
    ]
  )

  const linkIndividuallyContent = useMemo(
    () => (
      <Fragment>
        <div className={styles['link-individually-title']}>
          <p className={styles.description}>Copy and send on email or paste on social media</p>
        </div>
        {isGeneratedLink ? (
          generatedLinkContent
        ) : (
          <div className={styles['link-individually-content']}>
            <Input
              name="name"
              className={styles['link-name']}
              label="Name link"
              value={payload.name}
              onChange={onInputChange}
              error={!payload.name && errors.name}
            />
            {linkSetting}
            <div className={styles['advance-setting-wrapper']}>
              <p className={styles['advance-title']}>Advanced</p>
              <button
                className={styles['advance-setting-button']}
                onClick={toggleAdvanceSettingOpen}
                type="button"
              >
                <Svg
                  icon={ArrowDownIcon}
                  className={classnames(styles['advance-setting-icon'], {
                    [styles['icon-inverted']]: isAdvanceSettingToggle,
                  })}
                />
              </button>
            </div>
            <div
              className={classnames(styles['advance-setting'], {
                [styles['show-advance-settings']]: isAdvanceSettingToggle,
              })}
            >
              {linkAdvancedSetting}
            </div>
            <Button
              className={styles['link-individually-button']}
              isLoading={isLoadingCreateShareLink || isLoadingGetShareLink}
              disabled={isLoadingCreateShareLink || isLoadingGetShareLink}
              onClick={onGenerateLink}
            >
              Generate Link
            </Button>
          </div>
        )}
      </Fragment>
    ),
    [
      isGeneratedLink,
      generatedLinkContent,
      payload.name,
      onInputChange,
      errors.name,
      linkSetting,
      isLoadingCreateShareLink,
      isLoadingGetShareLink,
      onGenerateLink,
      isAdvanceSettingToggle,
      linkAdvancedSetting,
      toggleAdvanceSettingOpen,
    ]
  )

  return (
    <Modal
      isOpen
      isClosable
      className={styles['share-link-modal']}
      title="Share link"
      titleIcon={
        <Svg
          icon={LinkIcon}
          className={styles['title-icon']}
          alt="Link icon representing the option to share presentation"
        />
      }
      onClose={onClose}
    >
      <div className={styles.content}>{linkIndividuallyContent}</div>
    </Modal>
  )
}

IndividualLinkModal.propTypes = {
  presentationId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  shareLink: PropTypes.objectOf({
    hash: PropTypes.string,
    id: PropTypes.number,
  }),
  isFirefox: PropTypes.bool.isRequired,
}

IndividualLinkModal.defaultProps = {
  shareLink: undefined,
}

export default withBrowserInformation(React.memo(IndividualLinkModal))
