import React, { useState, useCallback, useMemo, forwardRef } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'
import { useSelector, useDispatch } from 'react-redux'
import cookies from 'react-cookies'

import GiftIcon from '_assets/icons/gift.svg'
import UserPlaceholder, { PLACEHOLDER_SIZE } from '_components/user-placeholder'
import { MAX_WIDTH_SMALL_VIEWPORT } from '_constants/window-size'
import {
  userHasCompaniesSelector,
  isFreeUserSelector,
  hasNewReceivedPresentations,
  hasAccessToBillingSelector,
  isTrialActiveSelector,
} from '_modules/user/selectors'
import { updateUser, getReferralRockCode, GET_REFERRAL_ROCK_CODE } from '_modules/user/actions'
import {
  FLAG_SHOW_PROFILE_DROPDOWN_OPTION_REFER_A_FRIEND,
  FLAG_SHOW_CREATE_TEAM_FLOW,
  ADMIN_PAGE_URL,
  FLAG_SHOW_REDIRECT_BILLING_ADMIN,
} from '_config/environment'
import useToggle from '_hooks/use-modal'
import { TEMPLATES_URL, ASSETS_URL, BUILTWITHHIGHNOTE_URL } from '_components/header/constants'
import Svg from '_components/svg'
import { USER_ROLES } from '_constants/user'
import { HELP_URL, GIFT_VALUE, PATHS } from '_utils/constants'
import useFetchCall from '_hooks/use-fetch-call'

import styles from './styles.css'

const ROUTES = {
  ANALYTICS: 'ANALYTICS',
  PRESENTATIONS: 'PRESENTATIONS',
  ALL_PRESENTATIONS: 'ALL_PRESENTATIONS',
  RECEIVED: 'RECEIVED',
  FAVORITES: 'FAVORITES',
  TEMPLATES: 'TEMPLATES',
  CONTACTS: 'CONTACTS',
}

const BACKGROUND_COLOR = '#000'

const DropDownMenuMobile = forwardRef(
  ({ className, location, picture, userName, userEmail, logout, role, onClickCreateTeam }, ref) => {
    const userHasCompany = useSelector(userHasCompaniesSelector)
    const userIsAdmin = useSelector(hasAccessToBillingSelector)
    const isFreemiumUser = useSelector(isFreeUserSelector)
    const hasNewReceivedNotifications = useSelector(hasNewReceivedPresentations)
    const isTrialActive = useSelector(isTrialActiveSelector)
    const [isResourcesDropdownOpen, toggleResourcesDropdown] = useToggle()

    const [isPresentationOptionsOpen, setPresentationOptionsOpen] = useState(false)

    const { height, width } = useWindowSize()
    const dispatch = useDispatch()

    const referralCookieCheck = useCallback(() => {
      const referralLinkCheck = cookies.load('authUrl')

      if (referralLinkCheck) {
        window.open(decodeURIComponent(referralLinkCheck), '_blank')
      } else {
        dispatch(getReferralRockCode())
      }
    }, [dispatch])

    useFetchCall(GET_REFERRAL_ROCK_CODE, referralCookieCheck)

    const activeRoute = useMemo(() => {
      if (Object.keys(location.query).length) {
        if (!isPresentationOptionsOpen) {
          return ROUTES.PRESENTATIONS
        }

        if (location.query.favorites) {
          return ROUTES.FAVORITES
        }

        if (location.query.templates) {
          return ROUTES.TEMPLATES
        }

        if (location.query.received) {
          return ROUTES.RECEIVED
        }
      }

      if (location.pathname === '/insights') {
        return ROUTES.ANALYTICS
      }

      if (location.pathname === '/contacts') {
        return ROUTES.CONTACTS
      }

      if (location.pathname === '/presentations') {
        if (isPresentationOptionsOpen) {
          return ROUTES.ALL_PRESENTATIONS
        }
        return ROUTES.PRESENTATIONS
      }

      return ''
    }, [isPresentationOptionsOpen, location.pathname, location.query])

    const dropdownStyles = useMemo(() => {
      if (width <= MAX_WIDTH_SMALL_VIEWPORT) {
        return {
          height: height - 62.5,
          minHeight: height - 63,
        }
      }

      return {}
    }, [width, height])

    const onTogglePresentationOptions = useCallback(() => {
      setPresentationOptionsOpen(prevState => !prevState)
    }, [])

    const onReceivedPresentationsClick = useCallback(() => {
      if (hasNewReceivedNotifications) {
        dispatch(
          updateUser({
            has_new_received_presentation: false,
          })
        )
      }
    }, [dispatch, hasNewReceivedNotifications])

    return (
      <section className={classnames(styles.container, className)} ref={ref} style={dropdownStyles}>
        <nav className={styles['nav-links']}>
          <Link
            to="/insights"
            className={classnames(styles.option, {
              [styles.active]: activeRoute === ROUTES.ANALYTICS,
            })}
          >
            Analytics
          </Link>
          <Link
            to="/contacts"
            className={classnames(styles.option, {
              [styles.active]: activeRoute === ROUTES.CONTACTS,
            })}
          >
            Contacts
          </Link>
          <button
            type="button"
            className={classnames(styles.option, styles.button, {
              [styles.active]: activeRoute === ROUTES.PRESENTATIONS,
            })}
            onClick={onTogglePresentationOptions}
          >
            <span className={styles['label-wrapper']}>
              Presentations
              {!isPresentationOptionsOpen && hasNewReceivedNotifications && (
                <span
                  className={classnames(styles['notification-dot'], styles['presentation-dot'])}
                />
              )}
            </span>
          </button>
          {isPresentationOptionsOpen && (
            <div className={styles['presentations-options']}>
              <Link
                to="/presentations"
                className={classnames(styles['dropdown-option'], {
                  [styles.active]: activeRoute === ROUTES.ALL_PRESENTATIONS,
                })}
              >
                My Presentations
              </Link>
              <Link
                to="/presentations/received"
                className={classnames(styles['dropdown-option'], {
                  [styles.active]: activeRoute === ROUTES.RECEIVED,
                })}
                onClick={onReceivedPresentationsClick}
              >
                <span className={styles['label-wrapper']}>
                  Received
                  {hasNewReceivedNotifications && <span className={styles['notification-dot']} />}
                </span>
              </Link>
              {role !== USER_ROLES.FREE_AGENT && (
                <Link
                  to="/presentations/favorites"
                  className={classnames(styles['dropdown-option'], {
                    [styles.active]: activeRoute === ROUTES.FAVORITES,
                  })}
                >
                  Favorites
                </Link>
              )}
              {userHasCompany && (
                <Link
                  to="/presentations/templates"
                  className={classnames(styles['dropdown-option'], {
                    [styles.active]: activeRoute === ROUTES.TEMPLATES,
                  })}
                >
                  Templates
                </Link>
              )}
            </div>
          )}
          <div className={styles['ideas-wrapper']}>
            <button
              onClick={toggleResourcesDropdown}
              className={classnames(styles['resource-button'], styles.option, {
                [styles.active]: activeRoute === ROUTES.ANALYTICS,
              })}
              type="button"
            >
              Resources
            </button>
            {isResourcesDropdownOpen && (
              <div className={styles['resource-links']}>
                <a
                  className={styles.option}
                  href={TEMPLATES_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Templates
                </a>
                <a
                  className={styles.option}
                  href={ASSETS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Assets
                </a>
                <a
                  className={styles.option}
                  href={BUILTWITHHIGHNOTE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  #builtwithhighnote
                </a>
              </div>
            )}
          </div>
        </nav>
        <div className={styles['user-container']}>
          <div>
            <div className={styles['user-wrapper-profile']}>
              {picture ? (
                <img src={picture} alt="User avatar" className={styles.picture} />
              ) : (
                <UserPlaceholder
                  backgroundColor={BACKGROUND_COLOR}
                  userName={userName}
                  size={PLACEHOLDER_SIZE.BIG}
                />
              )}
              <div className={styles['user-information']}>
                <p className={styles['user-name']}>{userName}</p>
                <p className={styles['user-email']}>{userEmail}</p>
                <Link
                  to={`${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}/${PATHS.ACCOUNT_SETTINGS_PATH_NAMES.EDIT_PROFILE_PATH_NAME}`}
                  className={classnames(styles.text, styles['profile-link'])}
                >
                  Edit profile
                </Link>
              </div>
            </div>
            <div className={styles['user-options']}>
              {(isFreemiumUser || isTrialActive) && (
                <Link to="/payment" className={classnames(styles.text, styles['upgrade-link'])}>
                  Upgrade Now
                </Link>
              )}
              <Link
                to={PATHS.ACCOUNT_SETTINGS_PATH_NAMES.BASE}
                className={classnames(styles.text, styles['my-account-link'])}
              >
                Account settings
              </Link>
              {FLAG_SHOW_REDIRECT_BILLING_ADMIN && userIsAdmin && (
                <Link
                  to={ADMIN_PAGE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classnames(styles.text)}
                >
                  Manage Team
                </Link>
              )}
              {FLAG_SHOW_CREATE_TEAM_FLOW && !userHasCompany && (
                <button
                  aria-label="Create a team"
                  type="button"
                  className={classnames(styles.text, styles['create-team'], styles.button)}
                  onClick={onClickCreateTeam}
                >
                  Create a team
                </button>
              )}
              <a
                className={classnames(styles.text, styles['help-link'])}
                href={HELP_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Help
              </a>
              <button
                type="button"
                className={classnames(styles.option, styles.logout, styles.button)}
                onClick={logout}
              >
                Sign out
              </button>
            </div>
          </div>
          {!isTrialActive && FLAG_SHOW_PROFILE_DROPDOWN_OPTION_REFER_A_FRIEND && (
            <button
              aria-label="Refer a friend"
              className={styles.footer}
              onClick={referralCookieCheck}
              type="button"
            >
              <Svg icon={GiftIcon} className={styles.image} />
              <span className={styles['refer-a-friend']}>
                <span className={styles['footer-title']}>Get {GIFT_VALUE}</span>
                <br />
                <span className={styles['footer-description']}>
                  Refer an agent to Highnote and get a reward!
                </span>
              </span>
            </button>
          )}
        </div>
      </section>
    )
  }
)

DropDownMenuMobile.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      templates: PropTypes.string,
      favorites: PropTypes.string,
      received: PropTypes.string,
    }),
  }).isRequired,
  picture: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  logout: PropTypes.func.isRequired,
  role: PropTypes.string,
  onClickCreateTeam: PropTypes.func,
}

DropDownMenuMobile.defaultProps = {
  className: '',
  picture: null,
  userName: '',
  userEmail: '',
  role: '',
  onClickCreateTeam: () => {},
}

export default React.memo(DropDownMenuMobile)
