import { defineAction } from '_privateDependencies/react-utils/utils'
import * as presentationsService from '_services/presentations'

export const CREATE_SHARE_LINK = defineAction('CREATE_SHARE_LINK')
export const UPDATE_SHARE_LINK = defineAction('UPDATE_SHARE_LINK')
export const LIST_SHARE_LINKS = defineAction('LIST_SHARE_LINKS')
export const ARCHIVE_SHARE_LINK = defineAction('ARCHIVE_SHARE_LINK')
export const UNARCHIVE_SHARE_LINK = defineAction('UNARCHIVE_SHARE_LINK')
export const GET_SHARE_LINK_BY_HASH = defineAction('GET_SHARE_LINK_BY_HASH')

export const createShareLink = ({ presentationId, payload, isPublic }) => (dispatch, getState) =>
  dispatch({
    type: CREATE_SHARE_LINK,
    payload: presentationsService.createPresentationShareLink(getState().user.get('auth_token'))(
      presentationId,
      payload
    ),
    meta: {
      isPublic,
      activityEmailUpdates: payload.activityEmailUpdates,
      requireEmail: payload.requireEmail,
      presentationId,
    },
  })

export const updateShareLink = ({ presentationId, shareLinkId, payload, isPublic }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_SHARE_LINK,
    payload: presentationsService.updateShareLink(getState().user.get('auth_token'))(
      presentationId,
      shareLinkId,
      payload
    ),
    meta: {
      isPublic,
    },
  })

export const listShareLinks = presentationId => (dispatch, getState) =>
  dispatch({
    type: LIST_SHARE_LINKS,
    payload: presentationsService.listShareLinks(getState().user.get('auth_token'))(presentationId),
  })

export const archiveSharedLink = ({ presentationId, shareLinkId }) => (dispatch, getState) =>
  dispatch({
    type: ARCHIVE_SHARE_LINK.ACTION,
    payload: presentationsService.archiveSharedLink(getState().user.get('auth_token'))(
      presentationId,
      shareLinkId
    ),
    meta: {
      presentationId,
    },
  })

export const unarchiveSharedLink = ({ presentationId, shareLinkId, payload }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UNARCHIVE_SHARE_LINK.ACTION,
    payload: presentationsService.unarchiveSharedLink(getState().user.get('auth_token'))(
      presentationId,
      shareLinkId,
      payload
    ),
    meta: {
      presentationId,
    },
  })

export const getShareLinkByHash = (presentationId, hash) => (dispatch, getState) =>
  dispatch({
    type: GET_SHARE_LINK_BY_HASH,
    payload: presentationsService.getShareLinkByHash(getState().user.get('auth_token'))(
      presentationId,
      hash
    ),
  })
