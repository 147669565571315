import { fromJS } from 'immutable'

export const getInitialState = path => {
  if (
    typeof window === 'undefined' ||
    (typeof window === 'object' && !Object.keys(window.location).length)
  ) {
    return null
  }
  // eslint-disable-next-line no-underscore-dangle
  return fromJS(window.__INITIAL_STATE__ || {}).get(path)
}
