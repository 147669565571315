import React, { useCallback, useMemo, useRef, useReducer, Fragment } from 'react'
import PropTypes from 'prop-types'
import { extension } from 'mime-types'

import EditIcon from '_assets/icons/edit.svg'
import Button, { ButtonTheme } from '_components/button'
import useStateWithCallback from '_hooks/use-state-with-callback'
import Modal from '_components/ui-kit/modal'
import Svg from '_components/svg'

import styles from './styles.css'
import StepIndicator from './step-indicator'
import PictureAdjustment from './picture-adjustment'
import ImagePreview from './image-preview'
import { reducer, INITIAL_STATE, STEPS, UPDATE_STATE } from './reducer'

const EditPictureModal = ({ onClose, picture, label, onFinish, isOnlySquaredPicture }) => {
  const [editedPicture, setEditedPicture] = useStateWithCallback({})

  const [state, localDispatch] = useReducer(reducer, INITIAL_STATE)

  const editorRef = useRef(null)

  const steps = useMemo(
    () => [
      {
        number: 1,
        name: `Adjust ${label}`,
        description: "Zoom in/out and pan the image to fit how you'd like",
        isActive: state.currentStep === STEPS.ADJUST,
      },
      {
        number: 2,
        name: `Preview it`,
        description: 'See how your logo will look when sending an email',
        isActive: state.currentStep === STEPS.PREVIEW,
      },
    ],
    [label, state.currentStep]
  )

  const onNextClick = useCallback(() => {
    if (editorRef.current) {
      const fileType = picture ? picture.type : ''

      editorRef.current.getImageScaledToCanvas().toBlob(
        blob => {
          if (!blob) return
          const fileName = `avatar.${extension(fileType)}`

          const newFile = new File([blob], fileName, { type: fileType })

          setEditedPicture({ file: newFile, blob }, () => {
            localDispatch({ type: UPDATE_STATE, payload: { currentStep: STEPS.PREVIEW } })
          })
        },
        fileType,
        0.5
      )
    }
  }, [picture, setEditedPicture])

  const onBackClick = useCallback(() => {
    localDispatch({ type: UPDATE_STATE, payload: { currentStep: STEPS.ADJUST } })
  }, [])

  const editorOptions = useMemo(
    () => ({
      aspectRatio: state.aspectRatio,
      zoomScale: state.zoomScale,
      position: state.position,
    }),
    [state.aspectRatio, state.position, state.zoomScale]
  )

  const onEditorChange = useCallback(payload => {
    localDispatch({ type: UPDATE_STATE, payload })
  }, [])

  const onFinishClick = useCallback(() => {
    onFinish(editedPicture)
  }, [editedPicture, onFinish])
  return (
    <Modal
      isOpen
      title={
        <Fragment>
          <Svg className={styles['edit-icon']} icon={EditIcon} />
          Edit {label}
        </Fragment>
      }
      className={styles['edit-picture-modal']}
      isClosable
      onClose={onClose}
    >
      <section className={styles.content}>
        <div className={styles['step-container']}>
          <div className={styles.steps}>
            {steps.map(step => (
              <StepIndicator key={step.number} step={step} className={styles.step} />
            ))}
            <div className={styles['connecting-line']} />
          </div>
          {state.currentStep === STEPS.PREVIEW ? (
            <div>
              <Button
                onClick={onBackClick}
                classNames={styles.button}
                theme={ButtonTheme.TRANSPARENT_BACKGROUND_PINK_TEXT}
              >
                Back
              </Button>
              <Button onClick={onFinishClick} classNames={styles.button}>
                Finish
              </Button>
            </div>
          ) : (
            <Button onClick={onNextClick} classNames={styles.button}>
              Next
            </Button>
          )}
        </div>
        <div className={styles['step-display']}>
          {state.currentStep === STEPS.ADJUST ? (
            <PictureAdjustment
              picture={picture}
              ref={editorRef}
              onEditorChange={onEditorChange}
              editorOptions={editorOptions}
              isOnlySquaredPicture={isOnlySquaredPicture}
            />
          ) : (
            <ImagePreview
              editedPicture={editedPicture}
              isLogo={label === 'logo'}
              ratio={state.aspectRatio}
            />
          )}
        </div>
      </section>
    </Modal>
  )
}

EditPictureModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  picture: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  isOnlySquaredPicture: PropTypes.bool,
}

EditPictureModal.defaultProps = {
  isOnlySquaredPicture: false,
}

export default React.memo(EditPictureModal)
