import { Map, List } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'

import { ADD_NEW_ASSET, REMOVE_ASSET_WATCH, DELETE_ASSET, EDIT_ASSET } from './actions'

export const assetActions = {
  ADD_ASSET: 'ADD_ASSET',
  DELETE_ASSET: 'DELETE_ASSET',
  EDIT_ASSET: 'EDIT_ASSET',
}
const INITIAL_STATE = List()

export const editAssets = createReducer(INITIAL_STATE, {
  [ADD_NEW_ASSET]: state =>
    state.push(
      Map({
        action: assetActions.ADD_ASSET,
      })
    ),

  [DELETE_ASSET]: (state, { id }) =>
    state.push(
      Map({
        action: assetActions.DELETE_ASSET,
        id,
      })
    ),

  [EDIT_ASSET]: (state, { payload }) =>
    state.push(
      Map({
        action: assetActions.EDIT_ASSET,
        ...payload,
      })
    ),

  [REMOVE_ASSET_WATCH]: (state, { listIndex }) => state.delete(listIndex),
})
