import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import styles from './styles.css'

class UnsplashPickerPhoto extends PureComponent {
  static propTypes = {
    altDescription: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    isCheckable: PropTypes.bool,
    isChecked: PropTypes.bool,
    onCheck: PropTypes.func.isRequired,
    urls: PropTypes.shape({
      thumb: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    altDescription: undefined,
    className: undefined,
    isCheckable: false,
    isChecked: false,
  }

  onCheck = () => {
    const { id, onCheck } = this.props
    onCheck(id)
  }

  onClickAuthorLink = event => {
    event.stopPropagation()
  }

  onKeyDown = event => {
    if (event.key === ' ') {
      event.preventDefault()
      this.onCheck()
    }
  }

  render() {
    const { altDescription, className, isCheckable, isChecked, urls, user } = this.props

    return (
      <div
        aria-checked={isChecked}
        className={classNames(className, styles.container, { [styles.selected]: isChecked })}
        onClick={this.onCheck}
        onKeyDown={this.onKeyDown}
        role="radio"
        tabIndex={isCheckable ? 0 : -1}
      >
        <img alt={altDescription || undefined} src={urls.thumb} />
        <a
          className={styles.author}
          href={`${user.links.html}?utm_source=HighNote&utm_medium=referral`}
          onClick={this.onClickAuthorLink}
          rel="nofollow noopener noreferrer"
          tabIndex="-1"
          target="_blank"
        >
          {user.name}
        </a>
      </div>
    )
  }
}

export default UnsplashPickerPhoto
