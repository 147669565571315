import humps from 'humps'

import { get, post, patch, del } from './request'

export const createContact = (key, payload) =>
  post(['contacts'], { key }, humps.decamelizeKeys(payload))

export const updateContact = key => (contactId, payload) =>
  patch(['contacts', contactId], { key }, humps.decamelizeKeys(payload))

export const listContacts = key => params => get(['contacts'], { key, params })

export const deleteContact = key => contactId => del(['contacts', contactId], { key })
