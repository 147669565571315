import React, { useCallback, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import humps from 'humps'
import classnames from 'classnames'

import Svg from '_components/svg'
import WandIcon from '_assets/icons/wand-icon.svg'
import useModal from '_hooks/use-modal'
import EnhanceModal from '_components/enhance/enhance-modal'
import {
  enhancePresentation,
  updatePresentation,
  UPDATE_PRESENTATION,
  cleanEnhancePresentation,
} from '_modules/presentations/actions'
import {
  enhanceAsset,
  editPresentationAsset,
  EDIT_ASSET,
  cleanEnhanceAsset,
} from '_modules/assets/actions'
import useFetchCall from '_hooks/use-fetch-call'
import { ALERT_TYPE } from '_components/toast'
import { openToasterAlert } from '_modules/toaster-alert/actions'
// eslint-disable-next-line import/no-cycle
import { convertInitialState } from '_components/text-editor'
import {
  enhancePresentationSelector,
  enhanceLoadingSelector,
} from '_modules/presentations/selectors'
import { enhanceAssetSelector, enhanceAssetLoadingSelector } from '_modules/assets/selectors'
import useOutsideClick from '_hooks/use-outside-click'
import useWindowSize from '_hooks/use-window-size'

import styles from './styles.css'

const EnhanceText = ({
  editorState,
  presentationId,
  name,
  setState,
  assetId,
  className,
  editorRef,
}) => {
  const [isEnhanceModalOpen, toggleEnhance] = useModal(false)
  const [acceptedSuggestion, setAcceptedSuggestion] = useState(false)
  const dispatch = useDispatch()
  const [setting, setSetting] = useState({
    length: 'same',
    tone: 'same',
  })
  const presentationSuggestion = useSelector(enhancePresentationSelector)
  const assetSuggestion = useSelector(enhanceAssetSelector)
  const isLoadingEnhance = useSelector(enhanceLoadingSelector)
  const isLoadingEnhanceAsset = useSelector(enhanceAssetLoadingSelector)

  const { isMobile } = useWindowSize()

  const suggestion = useMemo(() => {
    if (assetId) {
      return assetSuggestion
    }

    return presentationSuggestion
  }, [assetId, assetSuggestion, presentationSuggestion])

  const buttonRef = useRef()
  const containerRef = useRef()

  const isDisabled = editorState.getCurrentContent().getPlainText().length === 0

  const onSuccessfulUpdatePresentation = useCallback(() => {
    if (
      isEnhanceModalOpen &&
      suggestion.size &&
      acceptedSuggestion &&
      (!isLoadingEnhance || !isLoadingEnhanceAsset)
    ) {
      setState(convertInitialState(suggestion.get('enhancedText')))
      toggleEnhance()
      setAcceptedSuggestion(false)
      if (assetId) {
        dispatch(cleanEnhanceAsset())
        return
      }

      dispatch(cleanEnhancePresentation())
    }
  }, [
    acceptedSuggestion,
    assetId,
    dispatch,
    isEnhanceModalOpen,
    isLoadingEnhance,
    isLoadingEnhanceAsset,
    setState,
    suggestion,
    toggleEnhance,
  ])

  const onErrorUpdatePresentation = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong.',
      })
    )
    if (isEnhanceModalOpen) {
      toggleEnhance()
    }
  }, [dispatch, isEnhanceModalOpen, toggleEnhance])

  const [isLoadingSavePresentationSuggestion] = useFetchCall(
    UPDATE_PRESENTATION,
    onSuccessfulUpdatePresentation,
    onErrorUpdatePresentation
  )
  const [isLoadingSaveAssetSuggestion] = useFetchCall(
    EDIT_ASSET,
    onSuccessfulUpdatePresentation,
    onErrorUpdatePresentation
  )
  const handleEnhance = useCallback(
    (retry = false) => () => {
      const convertedToHtml = editorState.getCurrentContent().getPlainText()
      if (!isEnhanceModalOpen) {
        toggleEnhance()
      }

      if (assetId) {
        dispatch(
          enhanceAsset(presentationId, assetId, {
            type: `asset_${name}`,
            text: convertedToHtml,
            retry,
            tone: setting.tone,
            length: setting.length,
          })
        )
        return
      }

      dispatch(
        enhancePresentation(presentationId, {
          type: humps.decamelize(name),
          text: convertedToHtml,
          retry,
          tone: setting.tone,
          length: setting.length,
        })
      )
    },
    [
      assetId,
      dispatch,
      editorState,
      isEnhanceModalOpen,
      name,
      presentationId,
      setting.length,
      setting.tone,
      toggleEnhance,
    ]
  )

  const handleChangeSetting = useCallback(
    event => {
      const { name: fieldName, value } = event.target
      event.preventDefault()
      editorRef.current.focusEditor()
      setSetting(prevState => ({ ...prevState, [fieldName]: value }))
    },
    [editorRef]
  )

  const onAcceptSuggestion = useCallback(() => {
    setAcceptedSuggestion(true)
    if (assetId) {
      dispatch(
        editPresentationAsset({
          id: assetId,
          presentationId,
          params: {},
          payload: { [name]: suggestion.get('enhancedText') },
        })
      )
      return
    }

    dispatch(updatePresentation(presentationId, { [name]: suggestion.get('enhancedText') }))
  }, [assetId, dispatch, name, presentationId, suggestion])

  const onCloseToolbar = useCallback(() => {
    if (isEnhanceModalOpen) {
      if (suggestion.size) {
        const cleanFunction = assetId ? cleanEnhanceAsset : cleanEnhancePresentation
        dispatch(cleanFunction())
      }
      toggleEnhance()
    }
  }, [assetId, dispatch, isEnhanceModalOpen, suggestion.size, toggleEnhance])

  useOutsideClick(containerRef, onCloseToolbar)

  return (
    <div className={classnames(styles.container, className)} ref={containerRef}>
      <button
        aria-label="enhance-text"
        className={styles.button}
        onClick={toggleEnhance}
        disabled={isDisabled}
        type="button"
        ref={buttonRef}
      >
        <React.Fragment>
          <Svg icon={WandIcon} className={styles.icon} /> {!isMobile && 'Enhance with AI'}
        </React.Fragment>
      </button>
      {isEnhanceModalOpen && (
        <EnhanceModal
          isOpen={isEnhanceModalOpen}
          buttonRef={buttonRef}
          handleChangeSetting={handleChangeSetting}
          onClose={onCloseToolbar}
          handleEnhance={handleEnhance}
          onAcceptSuggestion={onAcceptSuggestion}
          assetId={assetId}
          setting={setting}
          isLoadingSaveSuggestion={
            isLoadingSavePresentationSuggestion || isLoadingSaveAssetSuggestion
          }
        />
      )}
    </div>
  )
}

export default React.memo(EnhanceText)

EnhanceText.propTypes = {
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func.isRequired,
  }).isRequired,
  presentationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  assetId: PropTypes.number,
  className: PropTypes.string,
  editorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.shape({}) }),
  ]).isRequired,
}

EnhanceText.defaultProps = {
  assetId: undefined,
  className: '',
}
