import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router'

import BackArrowSVG from '_assets/icons/back-arrow.svg'
import PreviewSVG from '_assets/icons/preview-icon.svg'
import LoaderSpinner, { LOADER_COLOR, LOADER_SIZE } from '_components/loader'
import onMouseDown from '_utils/on-mouse-down'
import ArrowIcon from '_assets/icons/arrow-right.svg'

import styles from './styles.css'

export const BUTTON_THEME = {
  PINK_WITH_BORDER: 'pink-with-border',
  BLUE: 'blue',
  GRAY: 'gray',
  GRAY_NO_BORDER: 'gray-no-border',
  PINK_NO_BORDER: 'pink-no-border',
  BACK: 'back',
  BACK_PINK: 'back-pink',
  PREVIEW: 'preview',
  LIGHT_PINK_BACKGROUND: 'light-pink-background',
  PINK_BACKGROUND: 'pink-background',
  TRANSPARENT_BACKGROUND: 'transparent-background',
  WHITE_NO_BORDER: 'white-no-border',
  PINK_WHITE_BACKGROUND_NO_BORDER: 'pink-white-background-no-border',
  PINK_NO_BACKGROUND_NO_BORDER: 'pink-no-background-no-border',
  FULL_WIDTH_PINK: 'full-width-pink',
  GREEN_BACKGROUND: 'green-background',
  SECONDARY_BUTTON: 'secondary-button',
}

const ICONS = {
  [BUTTON_THEME.BACK]: BackArrowSVG,
  [BUTTON_THEME.PREVIEW]: PreviewSVG,
  [BUTTON_THEME.BACK_PINK]: ArrowIcon,
}

const LOADER_THEME_COLOR = {
  [BUTTON_THEME.PINK_WITH_BORDER]: LOADER_COLOR.PINK,
  [BUTTON_THEME.GRAY_NO_BORDER]: LOADER_COLOR.GRAY,
  [BUTTON_THEME.PINK_NO_BORDER]: LOADER_COLOR.PINK,
  [BUTTON_THEME.TRANSPARENT_BACKGROUND]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.PINK_WHITE_BACKGROUND_NO_BORDER]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.FULL_WIDTH_PINK]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.PINK_NO_BACKGROUND_NO_BORDER]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.SECONDARY_BUTTON]: LOADER_COLOR.NAVY,
}
const Button = forwardRef(
  (
    { children, disabled, type, className, theme, to, loading, target, rel, ...otherProps },
    ref
  ) => {
    if (to) {
      return (
        <Link
          to={to}
          className={classnames(
            className,
            styles[theme],
            { [styles.disabled]: disabled },
            styles.button
          )}
          {...(target && { target })}
          {...(rel && { rel })}
        >
          {ICONS[theme] && (
            <svg aria-hidden="true" className={styles.icon}>
              <use xlinkHref={ICONS[theme]} />
            </svg>
          )}
          {children}
        </Link>
      )
    }

    return (
      <button
        className={classnames(
          className,
          styles[theme],
          { [styles.disabled]: disabled },
          styles.button
        )}
        // eslint-disable-next-line react/button-has-type
        type={type}
        ref={ref}
        onMouseDown={onMouseDown}
        disabled={disabled}
        {...otherProps}
      >
        {ICONS[theme] && (
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={ICONS[theme]} />
          </svg>
        )}
        {loading ? (
          <LoaderSpinner
            color={LOADER_THEME_COLOR[theme] || LOADER_COLOR.WHITE}
            size={LOADER_SIZE.BUTTON}
          />
        ) : (
          children
        )}
      </button>
    )
  }
)
Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string, state: PropTypes.shape() }),
  ]),
  loading: PropTypes.bool,
  target: PropTypes.string,
  rel: PropTypes.string,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  type: 'button',
  className: '',
  theme: '',
  to: '',
  loading: false,
  target: null,
  rel: null,
  disabled: false,
}

export default React.memo(Button)
