import { defineAction } from '_privateDependencies/react-utils/utils'
import * as presentationService from '_services/presentation'

export const LIST_SHARED_LINKS = defineAction('LIST_SHARED_LINKS')
export const UPDATE_SHARED_LINK = defineAction('UPDATE_SHARED_LINK')
export const RESEND_SHARED_LINK = defineAction('RESEND_SHARED_LINK')
export const DELETE_SHARED_LINK = defineAction('DELETE_SHARED_LINK')
export const BULK_DELETE_SHARED_LINK = defineAction('BULK_DELETE_SHARED_LINK')
export const UNARCHIVE_SHARED_LINK = defineAction('UNARCHIVE_SHARED_LINK')

export const listSharedLinks = presentationId => (dispatch, getState) =>
  dispatch({
    type: LIST_SHARED_LINKS.ACTION,
    payload: presentationService.listSharedLinks(getState().user.get('auth_token'))(presentationId),
  })

export const updateSharedLink = (presentationId, sharedLink, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_SHARED_LINK.ACTION,
    payload: presentationService.updateSharedLink(getState().user.get('auth_token'))(
      presentationId,
      sharedLink,
      payload
    ),
  })

export const resendSharedLink = (presentationId, sharedLink, payload) => (dispatch, getState) =>
  dispatch({
    type: RESEND_SHARED_LINK.ACTION,
    payload: presentationService.resendShareEmail(getState().user.get('auth_token'))(
      presentationId,
      sharedLink,
      payload
    ),
  })

export const deleteSharedLink = (presentationId, sharedLink) => (dispatch, getState) =>
  dispatch({
    type: DELETE_SHARED_LINK.ACTION,
    payload: presentationService.deleteSharedLink(getState().user.get('auth_token'))(
      presentationId,
      sharedLink
    ),
    meta: {
      sharedLink,
    },
  })

export const unarchiveSharedLink = (presentationId, sharedLink) => (dispatch, getState) =>
  dispatch({
    type: UNARCHIVE_SHARED_LINK.ACTION,
    payload: presentationService.unarchiveSharedLink(getState().user.get('auth_token'))(
      presentationId,
      sharedLink
    ),
    meta: {
      sharedLink,
    },
  })

export const bulkDeleteShare = (presentationId, linksList) => (dispatch, getState) =>
  dispatch({
    type: BULK_DELETE_SHARED_LINK.ACTION,
    payload: presentationService.bulkDeleteShare(getState().user.get('auth_token'))(
      presentationId,
      linksList
    ),
    meta: {
      linksList: linksList.share_links,
    },
  })
