import React from 'react'
import PropTypes from 'prop-types'
import memoizeOne from 'memoize-one'

import withViewportSize from '_hocs/with-viewport-size'

import styles from './styles.css'

const Gallery = ({ highnoteLib, companyLib, isSmallViewport, onClick }) => {
  const colors = memoizeOne(smallScreen =>
    smallScreen
      ? ['#01838a', '#e0dfd9', '#64c09f', '#e9af30', '#e55756', '#e82f72']
      : ['#000', '#34495e', '#01838a', '#e0dfd9', '#64c09f', '#e9af30', '#e55756', '#e82f72']
  )

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Colors</h3>
      <div className={styles.colors}>
        {colors(isSmallViewport).map(color => (
          <button
            type="button"
            label="Color"
            key={color}
            onClick={onClick}
            className={styles['color-button']}
            id={color}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
      {highnoteLib.lenght && <h3 className={styles.title}>Highnote Library</h3>}
      {companyLib.lenght && <h3 className={styles.title}>Company Library</h3>}
    </div>
  )
}

Gallery.propTypes = {
  onClick: PropTypes.func.isRequired,
  highnoteLib: PropTypes.arrayOf(PropTypes.shape()),
  companyLib: PropTypes.arrayOf(PropTypes.shape()),
  isSmallViewport: PropTypes.bool.isRequired,
}

Gallery.defaultProps = {
  highnoteLib: [],
  companyLib: [],
}

export default withViewportSize(Gallery)
