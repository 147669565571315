import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Draft from 'draft-js'

import WysiwygTextfield from '_components/wysiwyg-textfield'
import Svg from '_components/svg'
import { Asset } from '_models'
import { editPresentationAsset, cleanAssetSummarizer } from '_modules/assets/actions'
import { getAssetTypeIcon } from '_utils/asset'
import { presentationSelector } from '_modules/presentations/selectors'
import { PRESENTATION_STYLE_STANDARD } from '_constants/presentation-styles'
import AutoSummarizeSelectionModal from '_components/auto-summarizer-selection-modal'
import { HIERARCHY_TYPES_ELEMENTS } from '_components/text-editor'
import WarningBanner from '_components/warning-banner'
import usePrevious from '_hooks/use-previous'

import LoadingSummarizer from './loading-summarizer'
import styles from './styles.css'

const ENTER_KEY_CODE = 13

const AssetTextContainer = ({ asset, presentationId, handleAssetInputChange, className }) => {
  const [isAutoSummarizeModalOpen, onToggleAutoSummarizeModal] = useState(false)
  const [showWarningAiSummarizer, onToggleShowWarningAiSummarizer] = useState(false)
  const presentation = useSelector(state => presentationSelector(state, presentationId))
  const [containerBounding, setContainerBounding] = useState()
  const hasAiSummarizerResult = usePrevious(asset.get('aiSummarizerResult'))

  const dispatch = useDispatch()

  const getContainerBounding = useCallback(node => {
    if (node !== null) {
      const rect = node.getBoundingClientRect()
      setContainerBounding(rect)
    }
  }, [])

  const onInputChange = useCallback(
    ({ name, value, plainText }) => {
      if (plainText) {
        handleAssetInputChange(asset.get('id'), name, value)
        return
      }
      handleAssetInputChange(asset.get('id'), name, plainText)
    },
    [handleAssetInputChange, asset]
  )

  const keyBindingFn = useCallback(event => {
    if (!event.metaKey && event.keyCode === ENTER_KEY_CODE) {
      return false
    }

    return Draft.getDefaultKeyBinding(event)
  }, [])

  const handleAcceptSummarize = useCallback(() => {
    dispatch(
      editPresentationAsset(
        {
          id: asset.get('id'),
          presentationId,
          params: {},
          payload: {
            title: asset.getIn(['aiSummarizerResult', 'title'], '') || asset.get('title'),
            description:
              asset.getIn(['aiSummarizerResult', 'description'], '') || asset.get('description'),
          },
        },
        true
      )
    )
    onToggleAutoSummarizeModal(prevState => !prevState)
  }, [dispatch, asset, presentationId, onToggleAutoSummarizeModal])

  const handleRejectedSummarize = useCallback(() => {
    dispatch(cleanAssetSummarizer(asset.get('id')))
    onToggleAutoSummarizeModal(prevState => !prevState)
  }, [onToggleAutoSummarizeModal, dispatch, asset])

  useEffect(() => {
    if (asset.get('showSelectionSummarize') && asset.get('aiSummarizerResult')) {
      onToggleAutoSummarizeModal(true)
    }
  }, [asset, onToggleAutoSummarizeModal])

  useEffect(() => {
    if (asset.get('forceUpdate')) {
      onToggleShowWarningAiSummarizer(true)
    }
  }, [hasAiSummarizerResult, asset])

  const handleClose = useCallback(() => {
    onToggleShowWarningAiSummarizer(false)
  }, [])

  return (
    <div className={classnames(className, styles['text-wrapper'])} ref={getContainerBounding}>
      {asset.get('summarizerProgress') && (
        <LoadingSummarizer
          step={asset.get('summarizerProgress')}
          assetId={asset.get('id')}
          presentationId={presentationId}
        />
      )}
      {presentation.get('style') === PRESENTATION_STYLE_STANDARD && (
        <div className={styles.type}>
          <Svg icon={getAssetTypeIcon(asset.get('type'))} className={styles['type-icon']} />
        </div>
      )}
      {showWarningAiSummarizer && (
        <WarningBanner
          message="Please review AI-generated content carefully for accuracy."
          timer={5000}
          onClose={handleClose}
          assetId={asset.get('id')}
        />
      )}

      <WysiwygTextfield
        className={styles.title}
        inputClassName={styles['title-input']}
        name="title"
        maxLength={78}
        placeholder="Add Headline"
        value={asset.get('title')}
        onChange={onInputChange}
        keyBindingFn={keyBindingFn}
        presentationId={presentationId}
        assetId={asset.get('id')}
        forceUpdate={asset.get('forceUpdate')}
        type={HIERARCHY_TYPES_ELEMENTS.SUBTITLE}
      />
      <WysiwygTextfield
        isMultiline
        className={styles.description}
        inputClassName={styles['description-input']}
        name="description"
        placeholder="Type something here.(Optional)"
        value={asset.get('description')}
        onChange={onInputChange}
        presentationId={presentationId}
        assetId={asset.get('id')}
        forceUpdate={asset.get('forceUpdate')}
      />

      {isAutoSummarizeModalOpen && (
        <AutoSummarizeSelectionModal
          isOpen={isAutoSummarizeModalOpen}
          onSummarize={handleAcceptSummarize}
          asset={asset}
          onCancel={handleRejectedSummarize}
          containerBounding={containerBounding}
        />
      )}
    </div>
  )
}

AssetTextContainer.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  className: PropTypes.string,
  presentationId: PropTypes.number.isRequired,
  handleAssetInputChange: PropTypes.func.isRequired,
}

AssetTextContainer.defaultProps = {
  className: null,
}

export default React.memo(AssetTextContainer)
