import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close-masonry.svg'
import Svg from '_components/svg'

import styles from './styles.css'

const Modal = ({
  className,
  children,
  title,
  isOpen,
  isClosable,
  onClose,
  disableOverlay,
  disabledScroll,
  titleIcon,
  titleClassName,
}) => (
  <ReactModal
    className={classnames(styles.modal, className)}
    overlayClassName={classnames(styles.overlay, { [styles['disable-overlay']]: disableOverlay })}
    isOpen={isOpen}
    bodyOpenClassName={classnames({ [styles['disable-body-scroll']]: disabledScroll })}
    ariaHideApp={false}
  >
    {isClosable && (
      <button
        aria-label="close modal"
        onClick={onClose}
        className={styles['close-button']}
        type="button"
      >
        <Svg icon={CloseIcon} className={styles.icon} />
      </button>
    )}
    {title && (
      <div className={classnames(styles.titleContainer, titleClassName)}>
        {titleIcon && titleIcon}
        <h2 className={styles.title}>{title}</h2>
      </div>
    )}
    {children}
  </ReactModal>
)

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isClosable: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disableOverlay: PropTypes.bool,
  titleIcon: PropTypes.node,
  disabledScroll: PropTypes.bool,
  titleClassName: PropTypes.string,
}

Modal.defaultProps = {
  className: undefined,
  isClosable: false,
  onClose: undefined,
  title: undefined,
  disableOverlay: false,
  titleIcon: undefined,
  disabledScroll: true,
  titleClassName: '',
}

export default React.memo(Modal)
