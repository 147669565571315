import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import UAParser from 'ua-parser-js'

const CHROME_SIMPLE_NAME = 'chrome'
const INTERNET_EXPLORER_SIMPLE_NAME = 'ie'
const IOS_SIMPLE_NAME = 'ios'
const MAC_SIMPLE_NAME = 'mac'
const SAFARI_SIMPLE_NAME = 'safari'
const WINDOWS_SIMPLE_NAME = 'windows'
const FIREFOX_SIMPLE_NAME = 'firefox'

const BROWSERS_TO_SIMPLE_NAME_MAP = new Map([
  ['Chrome Headless', CHROME_SIMPLE_NAME],
  ['Chrome WebView', CHROME_SIMPLE_NAME],
  ['Chrome', CHROME_SIMPLE_NAME],
  ['Chromium', CHROME_SIMPLE_NAME],
  ['Edge', 'edge'],
  ['Firefox', FIREFOX_SIMPLE_NAME],
  ['IE', INTERNET_EXPLORER_SIMPLE_NAME],
  ['IEMobile', INTERNET_EXPLORER_SIMPLE_NAME],
  ['Mobile Safari', SAFARI_SIMPLE_NAME],
  ['Mozilla', FIREFOX_SIMPLE_NAME],
  ['Opera Coast', 'opera'],
  ['Opera Mini', 'opera'],
  ['Opera Mobi', 'opera'],
  ['Opera Tablet', 'opera'],
  ['Opera', 'opera'],
  ['Safari', SAFARI_SIMPLE_NAME],
])

const OS_TO_SIMPLE_NAME_MAP = new Map([
  ['Android', 'android'],
  ['iOS', IOS_SIMPLE_NAME],
  ['Mac OS', MAC_SIMPLE_NAME],
  ['Ubuntu', 'linux'],
  ['Unix', 'linux'],
  ['Windows', WINDOWS_SIMPLE_NAME],
  ['Windows Phone', 'win_phone'],
  ['Windows Mobile', 'win_phone'],
])

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

export default WrappedComponent => {
  const WithBrowserInformation = ({ userAgentOnFirstRequest, ...restProps }) => {
    const userAgentParserResult = new UAParser().getResult()
    const userAgent = userAgentParserResult.ua ? userAgentParserResult : userAgentOnFirstRequest

    const browserSimpleName = BROWSERS_TO_SIMPLE_NAME_MAP.get(userAgent && userAgent.browser.name)
    const osSimpleName = OS_TO_SIMPLE_NAME_MAP.get(userAgent && userAgent.os.name)

    return (
      <WrappedComponent
        browser={browserSimpleName}
        isChrome={browserSimpleName === CHROME_SIMPLE_NAME}
        isInternetExplorer={browserSimpleName === INTERNET_EXPLORER_SIMPLE_NAME}
        isIos={osSimpleName === IOS_SIMPLE_NAME}
        isMac={osSimpleName === MAC_SIMPLE_NAME}
        isMobile={['tablet', 'mobile'].includes(userAgent && userAgent.device.type)}
        isSafari={browserSimpleName === SAFARI_SIMPLE_NAME}
        isWindows={OS_TO_SIMPLE_NAME_MAP === WINDOWS_SIMPLE_NAME}
        isTabletDevice={['tablet'].includes(userAgent && userAgent.device.type)}
        os={osSimpleName}
        isFirefox={browserSimpleName === FIREFOX_SIMPLE_NAME}
        {...restProps}
      />
    )
  }

  WithBrowserInformation.displayName = `WithBrowserInformation(${getDisplayName(WrappedComponent)})`

  WithBrowserInformation.propTypes = {
    userAgentOnFirstRequest: PropTypes.shape({
      browser: PropTypes.shape({ name: PropTypes.string, version: PropTypes.string }),
      cpu: PropTypes.shape({ architecture: PropTypes.string }),
      device: PropTypes.shape({
        model: PropTypes.string,
        type: PropTypes.string,
        vendor: PropTypes.string,
      }),
      engine: PropTypes.shape({ name: PropTypes.string, version: PropTypes.string }),
      os: PropTypes.shape({ name: PropTypes.string, version: PropTypes.string }),
      ua: PropTypes.string,
    }),
  }

  WithBrowserInformation.defaultProps = {
    userAgentOnFirstRequest: {
      browser: { name: '', version: '' },
      cpu: { architecture: '' },
      device: { model: '', type: '', vendor: '' },
      engine: { name: '', version: '' },
      os: { name: '', version: '' },
      ua: '',
    },
  }

  WithBrowserInformation.getData = WrappedComponent.getData

  const mapStateToProps = state => ({ userAgentOnFirstRequest: state.browser })

  return connect(mapStateToProps)(WithBrowserInformation)
}
