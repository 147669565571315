import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { ChromePicker } from 'react-color'
import PropTypes from 'prop-types'
import { getSelectionCustomInlineStyle } from 'draftjs-utils'
import { EditorState, Modifier, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import classnames from 'classnames'
import tinycolor2 from 'tinycolor2'

import { convertStringToRbga } from '_utils/helpers'
import useOutsideClick from '_hooks/use-outside-click'

import styles from './styles.css'

const INITIAL_COLOR = `rgba(255,255,255, 1)`

const ColorPicker = ({ className, handleChangeColor, editorState, setState, name }) => {
  const [currentColor, setColor] = useState()
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const containerRef = useRef()

  const getBackground = useMemo(() => {
    if (currentColor) {
      return {
        backgroundColor: `rgba(${currentColor.r}, ${currentColor.g}, ${currentColor.b}, ${currentColor.a})`,
      }
    }
    return {
      backgroundColor: INITIAL_COLOR,
    }
  }, [currentColor])

  const onCloseToolbar = useCallback(() => setDisplayColorPicker(false), [])

  const handleShowColorPicker = useCallback(
    () => setDisplayColorPicker(prevState => !prevState),
    []
  )

  const handleChange = useCallback(
    selected => {
      const final = tinycolor2(selected[selected.source]).toRgb()

      setColor(final)
      const selection = editorState.getSelection()
      const currentContent = editorState.getCurrentContent()
      const contentWithColor = Modifier.applyInlineStyle(
        currentContent,
        selection,
        `color-rgba(${final.r}, ${final.g}, ${final.b}, ${final.a ? final.a : 1})`
      )
      const newEditorState = EditorState.push(editorState, contentWithColor, 'change-inline-style')
      setState(newEditorState)
      const convertedToHtml = draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
      const payload = {
        name,
        value: convertedToHtml,
        plainText: newEditorState.getCurrentContent().getPlainText(),
      }

      if (name === 'userName') {
        payload.createdByTextColor = `rgba(${final.r}, ${final.g}, ${final.b}, ${
          final.a ? final.a : 1
        })`
      }
      handleChangeColor(payload)
    },
    [editorState, handleChangeColor, name, setState]
  )

  useEffect(() => {
    if (!currentColor) {
      const getCurrentInlineStyle = getSelectionCustomInlineStyle(editorState, ['COLOR']).COLOR

      if (getCurrentInlineStyle) {
        const color = getCurrentInlineStyle.replace('color-', '')
        const colorObject = convertStringToRbga(color)

        setColor(colorObject)
      }
    }
  }, [editorState, currentColor])

  useOutsideClick(containerRef, onCloseToolbar)

  return (
    <div className={classnames(className, styles.container)} ref={containerRef}>
      <div
        className={styles.swatch}
        onClick={handleShowColorPicker}
        role="button"
        tabIndex="0"
        onKeyDown={handleShowColorPicker}
      >
        A
        <div className={styles.color} style={getBackground} />
      </div>
      {displayColorPicker && (
        <div className={styles.popover}>
          <div
            className={styles.cover}
            onClick={handleShowColorPicker}
            role="button"
            tabIndex="0"
            onKeyDown={handleShowColorPicker}
          />
          <ChromePicker color={currentColor || INITIAL_COLOR} onChange={handleChange} />
        </div>
      )}
    </div>
  )
}

ColorPicker.propTypes = {
  handleChangeColor: PropTypes.func.isRequired,
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func.isRequired,
    getSelection: PropTypes.func.isRequired,
  }),
  setState: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

ColorPicker.defaultProps = {
  editorState: undefined,
  className: '',
}

export default ColorPicker
