// This is the new service for presentation endpoints, as I'll rewrite the reducer
import humps from 'humps'

import { createFormData } from '_utils/helpers'

import { get, post, patch, generic, del } from './request'

export const getPresentation = (key, presentationId) =>
  get(['presentations', presentationId], { key })

export const updatePresentation = (key, presentationId, payload) => {
  if (
    payload.background ||
    payload.backgroundCropped ||
    payload.logo ||
    payload.picture ||
    payload.backgroundVideoUrl
  ) {
    const formattedPayload = createFormData(payload, true)
    return generic(['presentations', presentationId], {
      method: 'PATCH',
      key,
      headers: {
        'Content-type': undefined,
      },
      body: formattedPayload,
    })
  }

  return patch(['presentations', presentationId], { key }, humps.decamelizeKeys(payload))
}

export const listPresentations = key => params =>
  get(['presentations'], { key, params: humps.decamelizeKeys(params) })

export const favoritePresentation = key => presentationId =>
  post(['presentations', presentationId, 'favorite'], { key })

export const removeFavoritePresentation = key => presentationId =>
  post(['presentations', presentationId, 'remove-favorite'], { key })

export const copyPresentation = key => id => post(['presentations', id, 'clone'], { key })

export const createPresentationShareLink = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'share-link'], { key }, humps.decamelizeKeys(payload))

export const updateShareLink = key => (presentationId, shareLinkId, payload) =>
  patch(
    ['presentations', presentationId, 'share-links', shareLinkId],
    { key },
    humps.decamelizeKeys(payload)
  )

export const listShareLinks = key => presentationId =>
  get(['presentations', presentationId, 'share-links'], { key })

export const createFolder = (key, payload) =>
  post(['folders'], { key }, humps.decamelizeKeys(payload))

export const listFolders = key => params => get(['folders'], { key, params })

export const sharePresentationThroughEmail = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'share-email'], { key }, payload)

export const sendContactMessage = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'message'], { key }, payload)

export const createPresentation = key => post(['presentations'], { key })

export const createTemplate = key => (company, notifyTeamMember = false) =>
  post(
    ['presentations'],
    { key },
    humps.decamelizeKeys({ company, template: true, notifyTeamMember })
  )

export const archiveSharedLink = key => (presentationId, sharedLinkId) =>
  del(['presentations', presentationId, 'share-links', sharedLinkId], { key })

export const unarchiveSharedLink = key => (presentationId, sharedLinkId, payload) =>
  post(
    ['presentations', presentationId, 'share-links', sharedLinkId, 'restore'],
    { key },
    humps.decamelizeKeys(payload)
  )

export const getShareLinkByHash = key => (presentationId, hash) =>
  get(['presentations', presentationId, 'share-links', 'by-hash', hash], { key })

export const enhancePresentation = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'enhance'], { key }, humps.decamelizeKeys(payload))

export const useTemplate = key => hash =>
  post(['client', 'presentations', hash, 'use-template'], { key })

export const notifyRecipients = key => (presentationId, payload) =>
  post(['presentations', presentationId, 'notify'], { key }, humps.decamelizeKeys(payload))

export const archivePresentation = key => (id, params = {}) =>
  del(['presentations', id], { key, params })

export const deletePresentation = key => (id, params = {}) =>
  del(['presentations', id, 'delete'], { key, params })

export const unArchivePresentation = key => (id, params = {}) =>
  post(['presentations', id, 'restore'], { key, params })

export const deleteTemplate = key => (id, params = {}) =>
  del(['presentations', id], { key, params })
