import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import classnames from 'classnames'
import { Map } from 'immutable'

import WysiwygTextfield from '_components/wysiwyg-textfield'
import ReorderAssetIcon from '_assets/icons/reorder.svg'
import Svg from '_components/svg'
import TextEditor from '_components/text-editor'
import Asset from '_models/asset'

import HideAsset from '../components/hide-asset'
import AssetMoreActions from '../components/asset-more-actions'

import styles from './styles.css'

export const TextAsset = ({ asset, presentationId, index, handleAssetInputChange, isPreview }) => {
  const onInputChange = useCallback(
    ({ name, value, plainText }) => {
      const text = plainText ? value : plainText

      handleAssetInputChange(asset.get('id'), name, text)
    },
    [handleAssetInputChange, asset]
  )

  const renderPreview = useMemo(
    () => (
      <div className={styles['text-asset-wrapper-preview']}>
        <div className={styles['container-preview']}>
          <TextEditor
            readOnly
            toolbarHidden
            wrapperClassName={styles['title-preview']}
            inputClassName={styles['title-input-preview']}
            initialState={asset.get('description')}
          />
        </div>
      </div>
    ),
    [asset]
  )

  if (isPreview) {
    return renderPreview
  }

  return (
    <Draggable key={`asset-${asset.get('id')}`} draggableId={String(asset.get('id'))} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={styles['text-asset-wrapper']}
        >
          <div className={styles['drag-container']} {...provided.dragHandleProps}>
            <Svg icon={ReorderAssetIcon} className={styles['drag-icon']} />
          </div>
          <div
            className={classnames(styles.container, {
              [styles.hidden]: asset.get('isHidden'),
            })}
          >
            {asset.get('isHidden') && <HideAsset asset={asset} presentationId={presentationId} />}
            <WysiwygTextfield
              className={styles.title}
              inputClassName={styles['title-input']}
              name="description"
              placeholder="Write your text here (optional)"
              value={asset.get('description')}
              onChange={onInputChange}
              presentationId={presentationId}
              assetId={asset.get('id')}
            />
            <AssetMoreActions
              asset={asset}
              className={styles['asset-actions']}
              presentationId={presentationId}
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}

TextAsset.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(Asset)]).isRequired,
  presentationId: PropTypes.number.isRequired,
  handleAssetInputChange: PropTypes.func,
  index: PropTypes.number.isRequired,
  isPreview: PropTypes.bool,
}

TextAsset.defaultProps = {
  isPreview: false,
  handleAssetInputChange: () => {},
}
