import React, { useReducer, useMemo, useCallback } from 'react'
import { useSwipeable } from 'react-swipeable'
import PropTypes from 'prop-types'

import Svg from '_components/svg'
import Arrow from '_assets/icons/arrow-right.svg'

import { reducer, getInitialState, PREV, NEXT } from './reducer'
import styles from './styles.css'

const Carousel = ({ children }) => {
  const numItems = React.Children.count(children)
  const [state, dispatch] = useReducer(reducer, getInitialState(numItems))

  const handlers = useSwipeable({
    onSwipedLeft: () => {},
    onSwipedRight: () => {},
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  const getOrder = useCallback(
    (index, pos) => (index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos),
    [numItems]
  )

  const slide = useCallback(
    dir => () => {
      dispatch({ type: dir, numItems })
      setTimeout(() => {
        dispatch({ type: 'stopSliding' })
      }, 20)
    },
    [dispatch, numItems]
  )

  const getTransform = useMemo(() => {
    if (numItems === 1) return 'translateX(0%)'
    if (!state.sliding) return 'translateX(calc(-85% - 4rem))'
    if (state.dir === PREV) return 'translateX(calc(2 * (-85% - 4rem)))'
    return 'translateX(0%)'
  }, [state.dir, state.sliding, numItems])

  const handleNext = useCallback(dir => slide(dir), [slide])

  return (
    <div {...handlers} className={styles.container}>
      {numItems > 1 && (
        <button onClick={handleNext(PREV)} type="button" className={styles['button-left']}>
          <Svg icon={Arrow} className={styles['arrow-left']} />
        </button>
      )}
      <div className={styles.wrapper}>
        <div
          className={styles.carousel}
          style={{
            transform: getTransform,
            transition: state.sliding ? 'none' : 'transform 1s ease',
          }}
        >
          {React.Children.map(children, (child, index) => (
            <div
              style={{
                flex: '1 0 100%',
                flexBasis: '90%',
                order: getOrder(index, state.pos),
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>

      {numItems > 1 && (
        <button onClick={handleNext(NEXT)} type="button" className={styles['button-right']}>
          <Svg icon={Arrow} className={styles['arrow-right']} />
        </button>
      )}
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(Carousel)
