import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import Spinner from 'react-spinkit'
import { Link } from 'react-router'

import withBrowserInformation from '_hocs/with-browser-information'

import styles from './styles.css'

export const ButtonType = {
  BUTTON: 'button',
  RESET: 'reset',
  SUBMIT: 'submit',
}

export const ButtonTheme = {
  PINK: 'pink',
  TRANSPARENT: 'transparent',
  BLUEY_GRAY: 'bluey-gray',
  SLATE_GRAY: 'slate-gray',
  CLOUDY_BLUE: 'cloudy-blue',
  ROUND_FILLED_DARK_GRAY_BLUE: 'round-filled-dark-gray-blue',
  ROUND_SLATE_GRAY: 'round-slate-gray',
  ROUND_FILLED_BLUEY_GRAY: 'round-filled-bluey-gray',
  ROUND_FILLED_SLATE_GRAY: 'round-filled-slate-gray',
  ROUND_FILLED_CLOUDY_BLUE: 'round-filled-cloudy-blue',
  ROUND_FILLED_WHITE: 'round-filled-white',
  ROUND_FILLED_CORAL_PINK: 'round-filled-coral-pink',
  ROUND_EMPTY_WHITE: 'round-empty-white',
  ROUND_EMPTY_DARK_GRAY_BLUE: 'round-empty-dark-gray-blue',
  SMALL_PADDING: 'small-padding',
  FILLED_WHITE: 'filled-white',
  WHITE_BACKGROUND_PINK_TEXT: 'white-background-pink-text',
  TRANSPARENT_BACKGROUND_PINK_TEXT: 'transparent-background-pink-text',
}

const Button = ({
  id,
  isInternetExplorer,
  type,
  onClick,
  children,
  childrenClassName,
  theme,
  disabled,
  isLoading,
  name,
  selected,
  classNames,
  removeFlexBasis,
  href,
  style,
  isExternalUrl,
}) => {
  if (href) {
    if (isExternalUrl) {
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          id={id}
          disabled={disabled || isLoading}
          className={classnames(
            styles.button,
            Array.isArray(theme) ? theme.map(t => styles[t]) : styles[theme],
            { [styles.selected]: selected },
            classNames
          )}
          style={style}
        >
          <span className={styles['link-content']}>
            <span
              className={classnames(childrenClassName, {
                [styles['ie-button']]: isInternetExplorer && removeFlexBasis,
              })}
            >
              {children}
            </span>
          </span>
        </a>
      )
    }
    return (
      <Link
        to={href}
        id={id}
        disabled={disabled || isLoading}
        className={classnames(
          styles.button,
          Array.isArray(theme) ? theme.map(t => styles[t]) : styles[theme],
          { [styles.selected]: selected },
          classNames
        )}
        style={style}
      >
        <div className={styles['link-content']}>{children}</div>
      </Link>
    )
  }

  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      name={name}
      className={classnames(
        styles.button,
        Array.isArray(theme) ? theme.map(t => styles[t]) : styles[theme],
        [isLoading && styles['button-loading']],
        { [styles.selected]: selected },
        classNames
      )}
      style={style}
    >
      {isLoading ? (
        <Spinner
          name="circle"
          color="#ffffff"
          fadeIn="none"
          className={classnames(styles['spinner-div'])}
        />
      ) : (
        children
      )}
    </button>
  )
}

Button.propTypes = {
  childrenClassName: PropTypes.string,
  id: PropTypes.string,
  isInternetExplorer: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.keys(ButtonType).map(key => ButtonType[key])),
  theme: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ButtonTheme).map(key => ButtonTheme[key]))),
    PropTypes.oneOf(Object.keys(ButtonTheme).map(key => ButtonTheme[key])),
  ]),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  removeFlexBasis: PropTypes.bool,
  href: PropTypes.string,
  style: PropTypes.shape(),
  isExternalUrl: PropTypes.bool,
}

Button.defaultProps = {
  childrenClassName: '',
  id: undefined,
  onClick: undefined,
  removeFlexBasis: false,
  type: ButtonType.BUTTON,
  theme: ButtonTheme.PINK,
  disabled: false,
  classNames: '',
  isLoading: false,
  name: 'button',
  selected: false,
  href: '',
  style: {},
  isExternalUrl: false,
}

export default withBrowserInformation(Button)
