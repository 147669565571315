export const NEXT = 'NEXT'
export const PREV = 'PREV'

export const reducer = (state, action) => {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
      }
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
      }
    case 'stopSliding':
      return { ...state, sliding: false }
    default:
      return state
  }
}

export const getInitialState = numItems => ({
  pos: numItems - 1,
  sliding: false,
  dir: undefined,
})
