import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Svg from '_components/svg'
import Robot from '_assets/smart_toy_white_18dp.svg'
import Button, { BUTTON_THEME } from '_components/ui-kit/button'
import { deleteAssetSummarizer } from '_modules/assets/actions'

import styles from './styles.css'

const LoadingSummarizer = ({ step, assetId, presentationId }) => {
  const dispatch = useDispatch()

  const getStepMessage = useMemo(() => {
    switch (step) {
      case 1:
        return "Hang tight! We're processing your document."
      case 2:
        return 'Our AI is summarizing your document. Almost there!'
      default:
        return 'Processing underway! Stay tuned.'
    }
  }, [step])

  const onClose = useCallback(() => {
    dispatch(deleteAssetSummarizer(presentationId, assetId))
  }, [assetId, dispatch, presentationId])

  return (
    <div className={styles['loading-summarizer']}>
      <Button
        className={styles['close-button']}
        onClick={onClose}
        theme={BUTTON_THEME.TRANSPARENT_FILL}
      >
        Cancel
      </Button>
      <div className={styles.content}>
        <p className={styles.text}>{getStepMessage}</p>
        <div className={styles['loading-wrapper']}>
          <div className={styles.loading} />
          <Svg icon={Robot} className={styles.icon} />
        </div>
      </div>
    </div>
  )
}

LoadingSummarizer.propTypes = {
  step: PropTypes.number,
  assetId: PropTypes.string.isRequired,
  presentationId: PropTypes.number.isRequired,
}

LoadingSummarizer.defaultProps = {
  step: '',
}

export default React.memo(LoadingSummarizer)
