import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

// eslint-disable-next-line import/no-cycle
import { PROGRESS_THEME } from '_components/asset/standard-asset/asset-progress-bar'

import styles from './styles.css'

const CircularProgressBar = ({ className, progressValue, theme }) => {
  const TRAIL_COLOR = {
    [PROGRESS_THEME.BLACK_OPACITY]: 'var(--gray-72)',
    [PROGRESS_THEME.PINK]: 'var(--navy-light)',
  }

  const PATH_COLOR = {
    [PROGRESS_THEME.BLACK_OPACITY]: 'var(--pink)',
    [PROGRESS_THEME.PINK]: 'var(--pink)',
  }

  return (
    <div className={className}>
      <CircularProgressbar
        value={progressValue}
        text={`${progressValue}%`}
        styles={buildStyles({
          strokeLinecap: 'butt',
          trailColor: TRAIL_COLOR[theme],
          pathColor: PATH_COLOR[theme],
          textSize: 20,
        })}
        className={classnames(styles['circular-progress'])}
      />
    </div>
  )
}

CircularProgressBar.propTypes = {
  className: PropTypes.string,
  progressValue: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
}

CircularProgressBar.defaultProps = {
  className: null,
}

export default React.memo(CircularProgressBar)
