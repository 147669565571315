export const sortByUpdatedAt = (a, b) =>
  new Date(b.get('updated_at')) - new Date(a.get('updated_at'))

export const sortByAssetActivity = (a, b) =>
  new Date(b.getIn(['last_activity', 'datetime'])) -
  new Date(a.getIn(['last_activity', 'datetime']))

export const sortByLastAssetAction = (a, b) =>
  new Date(b.get('last_viewed')) - new Date(a.get('last_viewed'))

export const sortByTitle = (a, b) => {
  const aTitle = a.get('title')
  const bTitle = b.get('title')
  if (aTitle) {
    return bTitle ? aTitle.localeCompare(bTitle) : 1
  }
  if (bTitle) {
    return aTitle ? bTitle.localeCompare(aTitle) : -1
  }
  return 0
}

export const sortByLastAccess = (a, b) =>
  new Date(b.get('last_access')) - new Date(a.get('last_access'))

export const sortByConvertedFileOrder = (firstConvertedFile, secondConvertedFile) => {
  const firstFile = firstConvertedFile.get('order')
  const secondFile = secondConvertedFile.get('order')

  if (firstFile < secondFile) {
    return -1
  }
  if (firstFile > secondFile) {
    return 1
  }

  return 0
}
