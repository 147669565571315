import React, { useCallback, useEffect } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmationModal from '_components/confirmation-modal'
import UpdateAccount from '_assets/icons/update_account.svg'
import CompanyInviteModalPicture from '_assets/company_invite_modal_picture.svg'
import useFetchCall from '_hooks/use-fetch-call'
import useModal from '_hooks/use-modal'
import {
  logout,
  ACCEPT_COMPANY_INVITE,
  DECLINE_COMPANY_INVITE,
  declineCompanyInvite,
  acceptCompanyInvite,
  getUserInvites,
} from '_modules/user/actions'
import { companyInviteSelector } from '_modules/user/selectors'

import styles from './styles.css'

const TrialExpired = () => {
  const dispatch = useDispatch()
  const companyInvite = useSelector(companyInviteSelector)
  const [isCompanyInviteModalOpen, setIsCompanyInviteModalOpen] = useModal(false)

  const onClose = useCallback(() => dispatch(logout()), [dispatch])

  const onFinish = useCallback(() => browserHistory.push('/payment'), [])

  useEffect(() => {
    if (companyInvite) {
      setIsCompanyInviteModalOpen(true)
    }
  }, [companyInvite, setIsCompanyInviteModalOpen])

  useEffect(() => {
    dispatch(getUserInvites())
  }, [dispatch])

  const closeCompanyInviteModal = useCallback(() => setIsCompanyInviteModalOpen(false), [
    setIsCompanyInviteModalOpen,
  ])

  const onCompanyInviteAccepted = useCallback(() => browserHistory.push('/presentations'), [])

  const [isDeclineInviteLoading] = useFetchCall(
    DECLINE_COMPANY_INVITE,
    closeCompanyInviteModal,
    closeCompanyInviteModal
  )

  const [isAcceptInviteLoading] = useFetchCall(
    ACCEPT_COMPANY_INVITE,
    onCompanyInviteAccepted,
    closeCompanyInviteModal
  )

  const onDeclineCompanyInvite = useCallback(() => {
    dispatch(declineCompanyInvite(companyInvite.get('company_id'), companyInvite.get('id')))
  }, [dispatch, companyInvite])

  const onAcceptCompanyInvite = useCallback(() => {
    dispatch(acceptCompanyInvite(companyInvite.get('company_id'), companyInvite.get('id')))
  }, [dispatch, companyInvite])

  return (
    <div className={styles['trial-expired-container']}>
      <ConfirmationModal
        isOpen
        disableOverlay
        image={UpdateAccount}
        title="Your free trial is over. Upgrade to keep using Highnote."
        onCloseText="Back to login"
        onClose={onClose}
        onFinishText="Upgrade account"
        onFinish={onFinish}
      />
      {isCompanyInviteModalOpen && (
        <ConfirmationModal
          image={CompanyInviteModalPicture}
          title={"You've been invited to a team"}
          description={
            companyInvite
              ? `${companyInvite.get('admin_name') ||
                  companyInvite.get('company_name')} has invited you to join ${companyInvite.get(
                  'company_name'
                )} team. Do you want to join?`
              : ''
          }
          isOpen
          isLoading={isDeclineInviteLoading || isAcceptInviteLoading}
          onCloseText="Decline invite"
          onClose={onDeclineCompanyInvite}
          onFinishText="Accept invite"
          onFinish={onAcceptCompanyInvite}
        />
      )}
    </div>
  )
}

export default React.memo(TrialExpired)
