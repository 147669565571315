import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Svg from '_components/svg'

import styles from './styles.css'

const Tag = ({ className, name, icon, to }) => {
  const renderContent = useMemo(
    () => (
      <Fragment>
        {name}
        {icon && <Svg className={styles.icon} icon={icon} />}
      </Fragment>
    ),
    [icon, name]
  )

  if (to) {
    return (
      <a
        href={to}
        className={classnames(styles['tag-container'], { [styles['with-href']]: !!to }, className)}
      >
        {renderContent}
      </a>
    )
  }

  return <div className={classnames(styles['tag-container'], className)}>{renderContent}</div>
}

Tag.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  to: PropTypes.string,
}

Tag.defaultProps = {
  className: '',
  icon: null,
  to: null,
}

export default React.memo(Tag)
