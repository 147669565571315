import React from 'react'
import PropTypes from 'prop-types'

import Loading from '_components/loading'

import styles from './styles.css'

const AsyncLoader = ({ pastDelay }) => {
  if (pastDelay) {
    return <Loading className={styles.wrapper} />
  }
  return null
}

AsyncLoader.propTypes = {
  pastDelay: PropTypes.bool.isRequired,
}

export default AsyncLoader
