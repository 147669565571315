import * as foldersService from '_services/folders'
import { defineAction } from '_privateDependencies/react-utils/utils'

export const ADD_PRESENTATIONS_TO_FOLDER = defineAction('ADD_PRESENTATIONS_TO_FOLDER')
export const DELETE_FOLDER = defineAction('DELETE_FOLDER')
export const CREATE_FOLDER = defineAction('CREATE_FOLDER')
export const UPDATE_FOLDER = defineAction('UPDATE_FOLDER')
export const LIST_FOLDERS = defineAction('LIST_FOLDERS')
export const SEARCH_FOLDERS = defineAction('SEARCH_FOLDERS')
export const LIST_PRESENTATIONS_FROM_FOLDERS = defineAction('LIST_PRESENTATIONS_FROM_FOLDERS')
export const REMOVE_PRESENTATIONS_FROM_FOLDER = defineAction('REMOVE_PRESENTATIONS_FROM_FOLDER')

export const createFolder = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_FOLDER.ACTION,
    payload: foldersService.createFolder(getState().user.get('auth_token'), payload),
  })

export const updateFolder = (folderId, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_FOLDER.ACTION,
    payload: foldersService.updateFolder(getState().user.get('auth_token'))(folderId, payload),
  })

export const listFolders = params => (dispatch, getState) =>
  dispatch({
    type: LIST_FOLDERS.ACTION,
    payload: foldersService.listFolders(getState().user.get('auth_token'))(params),
    meta: {
      page: params.page,
    },
  })

export const listSearchFolders = params => (dispatch, getState) =>
  dispatch({
    type: SEARCH_FOLDERS.ACTION,
    payload: foldersService.listFolders(getState().user.get('auth_token'))(params),
  })

export const addPresentationsToFolder = (folder, payload, hideInFolders = false, tabSelected) => (
  dispatch,
  getState
) =>
  dispatch({
    type: ADD_PRESENTATIONS_TO_FOLDER.ACTION,
    payload: foldersService.addPresentationsToFolder(
      getState().user.get('auth_token'),
      folder.get('id'),
      payload
    ),
    meta: {
      folder,
      presentations: payload.presentations,
      hideInFolders,
      tabSelected,
    },
  })

export const deleteFolder = folderId => (dispatch, getState) =>
  dispatch({
    type: DELETE_FOLDER,
    payload: foldersService.deleteFolder(getState().user.get('auth_token'))(folderId),
    meta: { folderId },
  })

export const listPresentationsFromFolder = folderId => (dispatch, getState) =>
  dispatch({
    type: LIST_PRESENTATIONS_FROM_FOLDERS,
    payload: foldersService.listPresentationsFromFolder(getState().user.get('auth_token'))(
      folderId
    ),
  })

export const removePresentationsFromFolder = (
  folderId,
  payload,
  isPresentationFromTabBar = false
) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_PRESENTATIONS_FROM_FOLDER,
    payload: foldersService.removePresentationsFromFolder(getState().user.get('auth_token'))(
      folderId,
      payload
    ),
    meta: {
      presentations: payload.presentations,
      isPresentationFromTabBar,
    },
  })
