export const INITIAL_STATE = {
  position: { x: 0.5, y: 0.5 },
  scale: 1.4,
}

export const UPDATE_STATE = 'UPDATE_STATE'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
