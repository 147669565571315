import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import Modal from 'react-modal'

import MediaBlock, { Color, FontSize } from '_components/media-block'
import AlignLeftIcon from '_assets/icons/align-left.svg'
import AlignCenterIcon from '_assets/icons/align-center.svg'
import AlignRightIcon from '_assets/icons/align-right.svg'
import HideIcon from '_assets/icons/hide.svg'

import styles from './styles.css'

const POSITION_CENTER = 'center'
const POSITION_HIDDEN = 'no_picture'
const POSITION_LEFT = 'left'
const POSITION_RIGHT = 'right'

// eslint-disable-next-line react/prefer-stateless-function
class ImagePositionModal extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    position: PropTypes.string.isRequired,
    dismiss: PropTypes.func.isRequired,
    style: PropTypes.shape({}),
    onPositionClick: PropTypes.func.isRequired,
    showNoPictureOption: PropTypes.func,
  }

  static defaultProps = {
    className: undefined,
    isOpen: true,
    style: {},
    showNoPictureOption: true,
  }

  render() {
    const {
      className,
      isOpen,
      position,
      dismiss,
      style,
      onPositionClick,
      showNoPictureOption,
    } = this.props

    return (
      <Modal
        className={classnames(styles.modal, className)}
        contentLabel="Image positioning"
        overlayClassName={classnames(styles.overlay)}
        isOpen={isOpen}
        onRequestClose={dismiss}
        shouldCloseOnOverlayClick
        style={style}
        ariaHideApp={false}
      >
        <button
          onClick={onPositionClick}
          className={classnames(styles.option, { [styles.active]: position === POSITION_LEFT })}
          name={POSITION_LEFT}
          type="button"
        >
          <MediaBlock
            media={AlignLeftIcon}
            text="Left"
            color={Color.DARK_GRAY_BLUE}
            fontSize={FontSize.SMALL}
            mediaClassName={styles.media}
            classNames={styles['media-block']}
          />
        </button>
        <button
          onClick={onPositionClick}
          className={classnames(styles.option, { [styles.active]: position === POSITION_CENTER })}
          name={POSITION_CENTER}
          type="button"
        >
          <MediaBlock
            media={AlignCenterIcon}
            text="Center"
            color={Color.DARK_GRAY_BLUE}
            fontSize={FontSize.SMALL}
            mediaClassName={styles.media}
            classNames={styles['media-block']}
          />
        </button>
        <button
          onClick={onPositionClick}
          className={classnames(styles.option, { [styles.active]: position === POSITION_RIGHT })}
          name={POSITION_RIGHT}
          type="button"
        >
          <MediaBlock
            media={AlignRightIcon}
            text="Right"
            color={Color.DARK_GRAY_BLUE}
            fontSize={FontSize.SMALL}
            mediaClassName={styles.media}
            classNames={styles['media-block']}
          />
        </button>
        {showNoPictureOption && (
          <button
            onClick={onPositionClick}
            className={classnames(styles.option, { [styles.active]: position === POSITION_HIDDEN })}
            name={POSITION_HIDDEN}
            type="button"
          >
            <MediaBlock
              media={HideIcon}
              text="Hide"
              color={Color.DARK_GRAY_BLUE}
              fontSize={FontSize.SMALL}
              mediaClassName={styles.media}
              classNames={styles['media-block']}
            />
          </button>
        )}
      </Modal>
    )
  }
}

export default ImagePositionModal
