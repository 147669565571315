import { defineAction } from '_privateDependencies/react-utils/utils'
import * as presentationsService from '_services/presentations'

export const CREATE_PRESENTATION_SHARE_LINK = defineAction('CREATE_PRESENTATION_SHARE_LINK')
export const CLEAR_PRESENTATION_SHARE_LINK = 'CLEAR_PRESENTATION_SHARE_LINK'
export const UPDATE_PRESENTATION_SHARE_LINK = defineAction('UPDATE_PRESENTATION_SHARE_LINK')

export const createPresentationShareLink = (presentationId, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_PRESENTATION_SHARE_LINK.ACTION,
    payload: presentationsService.createPresentationShareLink(getState().user.get('auth_token'))(
      presentationId,
      payload
    ),
    meta: {
      presentationId: String(presentationId),
    },
  })

export const clearPresentationShareLinkStore = () => ({
  type: CLEAR_PRESENTATION_SHARE_LINK,
})

export const updatePresentationShareLink = (presentationId, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PRESENTATION_SHARE_LINK.ACTION,
    payload: presentationsService.updateShareLink(getState().user.get('auth_token'))(
      presentationId,
      payload
    ),
    meta: {
      presentationId: String(presentationId),
    },
  })
