import { Map, List, fromJS } from 'immutable'
import humps from 'humps'
import cookies from 'react-cookies'

import { createReducer, AUTH_LOGOUT } from '_privateDependencies/react-utils/utils'
import { Presentation, Asset } from '_models/'
import { normalizePresentations } from '_utils/normalizePresentations'
import {
  LIST_PRESENTATIONS_FROM_FOLDERS,
  ADD_PRESENTATIONS_TO_FOLDER,
  REMOVE_PRESENTATIONS_FROM_FOLDER,
  UPDATE_FOLDER,
} from '_modules/folders/actions'
import {
  DELETE_ASSET,
  EDIT_ASSET,
  CREATE_ASSET,
  REORDER_ASSETS,
  UPDATE_ASSET_TITLE_AND_DESCRIPTION,
  ASSET_FILE_UPLOAD_CONVERT,
  GET_ASSET_CONVERTED_FILE,
  EDIT_ASSET_UPLOAD_SYSTEM_V2,
  UPDATE_ASSET_PERCENTAGE_UPLOAD_SYSTEM_V2,
  SET_ASSET_CONVERT_THUMBNAIL_FAILED,
} from '_modules/assets/actions'
import {
  CREATE_SHARE_LINK,
  ARCHIVE_SHARE_LINK,
  UNARCHIVE_SHARE_LINK,
} from '_modules/share-link-new/actions'
import { COOKIES_OPTIONS } from '_utils/constants'

import {
  GET_PRESENTATION,
  UPDATE_PRESENTATION,
  LIST_PRESENTATIONS,
  FAVORITE_PRESENTATION,
  REMOVE_FAVORITE_PRESENTATION,
  UPDATE_COVER_PERCENTAGE,
  COPY_PRESENTATION,
  CREATE_PRESENTATION,
  CREATE_TEMPLATE,
  SHARE_PRESENTATION_THROUGH_EMAIL,
  ENHANCE_PRESENTATION,
  USE_TEMPLATE,
  CLEAN_ENHANCE_PRESENTATION,
  DELETE_RECEIVED_PRESENTATION,
  DELETE_PRESENTATION,
  DELETE_TEMPLATE,
  MAKE_PRESENTATION_TEMPLATE,
  RETRIEVE_PRESENTATION,
  RETRIEVE_PRESENTATIONS_STATS,
  HAS_PENDING_ASSETS,
  ARCHIVE_PRESENTATION,
  UNARCHIVE_PRESENTATION,
} from './actions'

const INITIAL_STATE = Map({
  currentPresentationId: null,
  presentations: Map(),
  search: Map({
    nextPage: undefined,
    results: Map({
      favorites: List(),
      received: List(),
      presentations: List(),
      templates: List(),
      archived: List(),
    }),
  }),
  enhance: Map({}),
  pendingStats: List(),
})

const getResultIds = payload =>
  payload ? List(payload.map(presentation => presentation.id)) : List()

const filterResults = (state, key, id) =>
  List(state.getIn(['search', 'results', key], List()).filter(currentId => currentId !== id))

const removeId = (state, id) =>
  Map({
    favorites: filterResults(state, 'favorites', id),
    received: filterResults(state, 'received', id),
    presentations: filterResults(state, 'presentations', id),
    templates: filterResults(state, 'templates', id),
    archived: filterResults(state, 'archived', id),
  })

export default createReducer(INITIAL_STATE, {
  [AUTH_LOGOUT.FULFILLED]: () => INITIAL_STATE,

  [GET_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state.setIn(
      ['presentations', `${camelizedPayload.id}`],
      new Presentation({ ...camelizedPayload, isComplete: true })
    )
  },

  [UPDATE_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state.setIn(
      ['presentations', `${camelizedPayload.id}`],
      new Presentation({ ...camelizedPayload, isComplete: true })
    )
  },

  [LIST_PRESENTATIONS.PENDING]: (state, { meta }) =>
    state.setIn(['search', 'nextPage'], meta.nextPage),

  [LIST_PRESENTATIONS.FULFILLED]: (state, { payload, meta }) => {
    const { currentTab } = meta
    const camelizedPayload = humps.camelizeKeys(payload)
    const normalizedPresentations = normalizePresentations(camelizedPayload.results)

    const nextPage = payload.next ? new URL(payload.next).searchParams.get('page') : undefined

    const previousPage = payload.previous

    if (currentTab) {
      const newIds = getResultIds(camelizedPayload.results)
      const newState = state
        .mergeIn(['presentations'], normalizedPresentations)
        .setIn(['search', 'nextPage'], nextPage)
        .updateIn(['search', 'results', currentTab], currentSearch => {
          if (previousPage) {
            return currentSearch.concat(newIds)
          }

          return List(newIds)
        })

      if (['received', 'templates'].includes(currentTab)) {
        return newState
      }

      return newState.set('pendingStats', List(newIds))
    }

    return state
      .mergeIn(['presentations'], normalizedPresentations)
      .setIn(['search', 'nextPage'], nextPage)
  },
  [RETRIEVE_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const normalizedPresentations = normalizePresentations(camelizedPayload.results)

    return state
      .mergeIn(['presentations'], normalizedPresentations)
      .updateIn(['search', 'results'], currentResults => {
        const newIds = getResultIds(camelizedPayload.results)

        return currentResults.concat(newIds)
      })
  },

  [LIST_PRESENTATIONS_FROM_FOLDERS.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const normalizedPresentations = normalizePresentations(camelizedPayload.presentations)

    return state
      .mergeIn(['presentations'], normalizedPresentations)
      .setIn(['search', 'results'], getResultIds(camelizedPayload.presentations))
  },

  [FAVORITE_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state.setIn(
      ['presentations', `${camelizedPayload.id}`, 'favorite'],
      camelizedPayload.favorite
    )
  },

  [REMOVE_FAVORITE_PRESENTATION.FULFILLED]: (state, { payload, meta }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    if (meta.tab === 'favorites') {
      return state.setIn(['search', 'results'], removeId(state, Number(camelizedPayload.id)))
    }

    return state.setIn(
      ['presentations', `${camelizedPayload.id}`, 'favorite'],
      camelizedPayload.favorite
    )
  },

  [DELETE_PRESENTATION.FULFILLED]: (state, { meta }) => {
    const { id } = meta
    const results = removeId(state, Number(id))

    return state.deleteIn(['presentations', String(id)]).setIn(['search', 'results'], results)
  },
  [ARCHIVE_PRESENTATION.FULFILLED]: (state, { meta }) => {
    const { id } = meta
    const results = removeId(state, Number(id))

    return state.deleteIn(['presentations', String(id)]).setIn(['search', 'results'], results)
  },
  [DELETE_RECEIVED_PRESENTATION.FULFILLED]: (state, { meta }) =>
    state
      .deleteIn(['presentations', String(meta.presentationId)])
      .setIn(['search', 'results'], removeId(state, meta.presentationId)),

  [DELETE_TEMPLATE.FULFILLED]: (state, { meta }) =>
    state
      .deleteIn(['presentations', String(meta.id)])
      .setIn(['search', 'results'], removeId(state, Number(meta.id))),
  [UNARCHIVE_PRESENTATION.FULFILLED]: (state, { meta }) => {
    const { id } = meta

    return state
      .setIn(['search', 'results'], removeId(state, Number(id)))
      .updateIn(['search', 'results', 'presentations'], presentations =>
        presentations.concat(List([String(id)]))
      )
  },
  [ADD_PRESENTATIONS_TO_FOLDER.FULFILLED]: (state, { meta }) => {
    const { hideInFolders } = meta

    if (hideInFolders) {
      return state.setIn(['search', 'results'], removeId(state, Number(meta.presentations[0])))
    }

    const results = meta.presentations.map(presentationId =>
      state.getIn(['presentations', String(presentationId)]).set('folder', meta.folder)
    )

    return state.mergeIn(
      ['presentations'],
      results.map(item => [String(item.get('id')), item])
    )
  },

  [REMOVE_PRESENTATIONS_FROM_FOLDER.FULFILLED]: (state, { meta }) => {
    const [currentState] = meta.presentations.map(presentationId => {
      const presentationsWithoutFolder = state.setIn(
        ['presentations', String(presentationId), 'folder'],
        null
      )

      if (meta.isPresentationFromTabBar) {
        return presentationsWithoutFolder
      }

      return presentationsWithoutFolder.setIn(
        ['search', 'results'],
        removeId(state, presentationId)
      )
    })

    return state.merge(currentState)
  },
  [UPDATE_COVER_PERCENTAGE]: (state, { payload }) => {
    const { presentationId, percentage } = payload

    if (
      !state.getIn(['presentations', String(presentationId), 'percentageUpload']) ||
      percentage > state.getIn(['presentations', String(presentationId), 'percentageUpload'])
    ) {
      return state.setIn(['presentations', String(presentationId), 'percentageUpload'], percentage)
    }

    return state
  },
  [UPDATE_FOLDER.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state.updateIn(['presentations'], presentations => {
      const presentationsWithFolder = presentations.filter(
        item => Number(item.getIn(['folder', 'id'])) === Number(camelizedPayload.id)
      )
      const newPresentations = presentationsWithFolder.map(item =>
        item.set('folder', fromJS(camelizedPayload))
      )
      return presentations.merge(newPresentations)
    })
  },

  [COPY_PRESENTATION.FULFILLED]: (state, { payload }) =>
    state.set('currentPresentationId', payload.id),

  [CREATE_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state
      .setIn(
        ['presentations', `${camelizedPayload.id}`],
        new Presentation({ ...camelizedPayload, isComplete: true })
      )
      .set('currentPresentationId', payload.id)
  },

  [CREATE_TEMPLATE.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state
      .setIn(
        ['presentations', `${camelizedPayload.id}`],
        new Presentation({ ...camelizedPayload, isComplete: true })
      )
      .set('currentPresentationId', payload.id)
  },
  [CREATE_ASSET.FULFILLED]: (state, { payload, meta }) => {
    const camelizedAsset = humps.camelizeKeys(payload)
    const asset = new Asset(camelizedAsset)

    return state.updateIn(['presentations', String(meta.presentationId), 'assets'], assets =>
      assets.push(asset)
    )
  },
  [EDIT_ASSET.FULFILLED]: (state, { payload, meta }) => {
    const newAsset = new Asset(humps.camelizeKeys(payload))

    return state.updateIn(['presentations', String(meta.presentationId), 'assets'], assets => {
      const index = assets.findIndex(item => item.get('id') === newAsset.get('id'))
      return assets.setIn([index], newAsset)
    })
  },
  [REORDER_ASSETS.FULFILLED]: (state, { payload, meta }) => {
    const { presentationId } = meta
    const assets = payload.map(item => new Asset(humps.camelizeKeys(item)))

    return state.setIn(['presentations', String(presentationId), 'assets'], List(assets))
  },
  [DELETE_ASSET.FULFILLED]: (state, { meta, payload }) => {
    const { presentationId } = meta
    const assets = payload.map(item => new Asset(humps.camelizeKeys(item)))

    return state.setIn(['presentations', String(presentationId), 'assets'], List(assets))
  },
  [SHARE_PRESENTATION_THROUGH_EMAIL.FULFILLED]: (state, { payload, meta }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return state.setIn(
      ['presentations', String(meta.presentationId), 'numberOfInvites'],
      camelizedPayload.numberOfInvites
    )
  },

  [CREATE_SHARE_LINK.FULFILLED]: (state, { meta }) => {
    const { presentationId, isPublic } = meta
    if (isPublic) {
      return state.setIn(['presentations', String(presentationId), 'isWebpage'], true)
    }
    return state
  },
  [ARCHIVE_SHARE_LINK.FULFILLED]: (state, { meta }) => {
    const { presentationId } = meta
    return state.setIn(['presentations', String(presentationId), 'isWebpage'], false)
  },
  [UNARCHIVE_SHARE_LINK.FULFILLED]: (state, { meta }) => {
    const { presentationId } = meta
    return state.setIn(['presentations', String(presentationId), 'isWebpage'], true)
  },
  [CLEAN_ENHANCE_PRESENTATION]: state => state.set('enhance', Map({})),
  [ENHANCE_PRESENTATION.PENDING]: state => state.set('enhance', Map({})),
  [ENHANCE_PRESENTATION.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    return state.set('enhance', fromJS(camelizedPayload))
  },
  [UPDATE_ASSET_TITLE_AND_DESCRIPTION]: (state, { payload }) => {
    const { presentationId, asset } = payload

    return state.updateIn(
      ['presentations', String(presentationId), 'assets', asset.position],
      currAsset => currAsset.set('title', asset.title).set('description', asset.description)
    )
  },
  [USE_TEMPLATE.FULFILLED]: (state, { payload }) => state.set('currentPresentationId', payload.id),
  [MAKE_PRESENTATION_TEMPLATE.FULFILLED]: (state, { payload }) => {
    const companies = payload.map(template => template.company).slice(0, 1)

    cookies.save('mostRecentCompany', companies, COOKIES_OPTIONS)
    return state.setIn(['recent_companies'], companies)
  },
  [RETRIEVE_PRESENTATIONS_STATS.FULFILLED]: (state, { payload }) => {
    const stats = humps.camelizeKeys(payload).reduce((acc, curr) => acc.set(curr.id, curr), Map())
    return state
      .updateIn(['presentations'], presentations =>
        presentations.map(presentation => {
          if (stats.has(presentation.get('id'))) {
            const stat = stats.get(presentation.get('id'))
            return presentation
              .set('numberOfAccess', stat.numberOfAccess)
              .set('numberOfInvites', stat.numberOfInvites)
              .set('numberOfFeedback', stat.numberOfFeedback)
          }
          return presentation
        })
      )
      .set('pendingStats', List())
  },
  [HAS_PENDING_ASSETS]: (state, { payload }) => {
    const { presentationId, hasPendingAssets } = payload
    return state.setIn(
      ['presentations', String(presentationId), 'hasPendingAssets'],
      hasPendingAssets
    )
  },
  [EDIT_ASSET_UPLOAD_SYSTEM_V2.PENDING]: (state, { meta }) => {
    const { presentationId } = meta
    return state.setIn(['presentations', String(presentationId), 'hasPendingAssets'], true)
  },
  [ASSET_FILE_UPLOAD_CONVERT.FULFILLED]: (state, { payload, meta }) => {
    const newAsset = new Asset(humps.camelizeKeys(payload))

    return state.updateIn(['presentations', String(meta.presentationId), 'assets'], assets => {
      const index = assets.findIndex(item => item.get('id') === newAsset.get('id'))
      return assets.setIn([index], newAsset)
    })
  },
  [UPDATE_ASSET_PERCENTAGE_UPLOAD_SYSTEM_V2]: (state, { payload }) => {
    if (payload.extra) {
      return state.setIn(
        ['presentations', String(payload.presentationId), 'hasPendingAssets'],
        undefined
      )
    }

    return state
  },
  [GET_ASSET_CONVERTED_FILE.FULFILLED]: (state, { payload, meta }) => {
    if (payload.length) {
      return state.updateIn(['presentations', String(meta.presentationId), 'assets'], assets => {
        const index = assets.findIndex(item => String(item.get('id')) === String(meta.assetId))
        return assets.setIn([index, 'convertedFiles'], fromJS(payload))
      })
    }

    return state
  },
  [SET_ASSET_CONVERT_THUMBNAIL_FAILED]: (state, { meta }) =>
    state.setIn(['presentations', String(meta.presentationId), 'hasPendingAssets'], undefined),
})
