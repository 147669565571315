import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Input from '_components/ui-kit/input'
import { STEPS_NAMES, FIELDS_NAMES, FIELDS_LABEL, validateInput } from '_constants/create-a-team'
import Button from '_components/ui-kit/button'
import TeamCreateImage from '_assets/backgrounds/create-team.png'

import Testimonial, { TESTIMONIAL_POSITION } from '../testimonial'

import styles from './styles.css'

const CreateTeamStep = ({
  setTeamName,
  values,
  errors,
  handleErrors,
  descriptionClassName,
  onClickNext,
  externalCreateTeam,
}) => {
  const handleChange = useCallback(
    event => {
      setTeamName(event.target.value)
    },
    [setTeamName]
  )

  const onBlur = useCallback(
    event => {
      const { name, value } = event.target
      const error = validateInput(name, value, STEPS_NAMES.CREATE_TEAM)

      handleErrors(error)
    },
    [handleErrors]
  )

  const hasErrors = useMemo(() => Object.keys(errors).some(key => errors[key]), [errors])

  const handleNext = useCallback(() => {
    const error = validateInput(
      FIELDS_NAMES.TEAM_NAME,
      values[FIELDS_NAMES.TEAM_NAME],
      STEPS_NAMES.CREATE_TEAM
    )

    if (error[FIELDS_NAMES.TEAM_NAME]) {
      handleErrors(error)
      return
    }

    onClickNext()
  }, [handleErrors, onClickNext, values])

  return (
    <div className={externalCreateTeam ? styles['container-external'] : styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Create a team</h2>
        <p className={classnames(styles.description, descriptionClassName)}>
          Get everyone working together by adding them to a team. Collaborate easier, share
          templates, and win together!
        </p>
        <Input
          className={styles.input}
          label={FIELDS_LABEL[FIELDS_NAMES.TEAM_NAME]}
          onChange={handleChange}
          value={values[FIELDS_NAMES.TEAM_NAME]}
          placeholder="Enter your team name"
          name={FIELDS_NAMES.TEAM_NAME}
          error={errors[FIELDS_NAMES.TEAM_NAME]}
          onBlur={onBlur}
          id={FIELDS_NAMES.TEAM_NAME}
        />
        <p className={styles.text}>You can change the team details at any time</p>
        <Button className={styles.button} onClick={handleNext} disabled={hasErrors}>
          Continue
        </Button>
      </div>
      {!externalCreateTeam && (
        <Testimonial
          testimonial="Highnote has been a game changer in my branch in more ways than I can count. It is so
        simple and easy to use. There is nothing out there like this."
          author="Cara Milgate"
          image={TeamCreateImage}
          imageWidth="38rem"
          imageHeight="34.4rem"
          position={TESTIMONIAL_POSITION.VERTICAL}
          classNameTestimonial={styles['testimonial-text']}
          className={styles['testimonial-container']}
        />
      )}
    </div>
  )
}

CreateTeamStep.propTypes = {
  setTeamName: PropTypes.func.isRequired,
  values: PropTypes.shape({
    [FIELDS_NAMES.TEAM_NAME]: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleErrors: PropTypes.func.isRequired,
  descriptionClassName: PropTypes.string,
  onClickNext: PropTypes.func.isRequired,
  externalCreateTeam: PropTypes.bool,
}

CreateTeamStep.defaultProps = {
  descriptionClassName: '',
  externalCreateTeam: false,
}

export default React.memo(CreateTeamStep)
