import React, {
  useState,
  useMemo,
  useCallback,
  forwardRef,
  useEffect,
  useRef,
  useReducer,
} from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'

import ImageResizeSmallIcon from '_assets/icons/image-resize-small.svg'
import ImageResizeBigIcon from '_assets/icons/image-resize-big.svg'
import RepositionIcon from '_assets/icons/reposition-icon.svg'
import Svg from '_components/svg'

import styles from './styles.css'
import { INITIAL_STATE, reducer, UPDATE_STATE } from './reducer'

export const ASPECT_RATIO = {
  SQUARE: 'square',
  RECTANGLE: 'rectangle',
}

const PictureAdjustment = forwardRef(
  ({ picture, dimensions, className, sliderContainerClassName }, ref) => {
    const [displayOverlay, setDisplayOverlay] = useState(true)
    const [state, localDispatch] = useReducer(reducer, INITIAL_STATE)

    const overlayRef = useRef(null)

    const onPositionChange = useCallback(pos => {
      localDispatch({ type: UPDATE_STATE, payload: { position: pos } })
    }, [])

    const overlayStyles = useMemo(
      () => ({
        top: dimensions.border[1],
        left: dimensions.border[0],
        height: dimensions.height - 2 * dimensions.border[1],
        width: dimensions.width - 2 * dimensions.border[0],
      }),
      [dimensions.border, dimensions.height, dimensions.width]
    )

    const editorConfig = useMemo(
      () => ({
        borderRadius: 4,
        scale: state.scale,
        color: [0, 0, 0, 0.64],
        position: state.position,
        height: dimensions.height - 2 * dimensions.border[1],
        width: dimensions.width - 2 * dimensions.border[0],
        border: dimensions.border,
      }),
      [dimensions.border, dimensions.height, dimensions.width, state.position, state.scale]
    )

    const onOverlayRemove = useCallback(() => {
      setDisplayOverlay(false)
      // eslint-disable-next-line
      ref.current.state.drag = true
    }, [ref])

    const handleMouseDown = useCallback(
      event => {
        if (
          ref.current &&
          ref.current.canvas &&
          overlayRef &&
          overlayRef.current &&
          !overlayRef.current.contains(event.target) &&
          ref.current.canvas.contains(event.target)
        ) {
          setDisplayOverlay(false)
        }
      },
      [ref]
    )

    useEffect(() => {
      window.addEventListener('mousedown', handleMouseDown)

      return () => {
        window.removeEventListener('mousedown', handleMouseDown)
      }
    }, [handleMouseDown])

    const onSliderChange = useCallback(
      event => {
        localDispatch({ type: UPDATE_STATE, payload: { scale: Number(event.target.value) } })
        if (displayOverlay) {
          setDisplayOverlay(false)
        }
      },
      [displayOverlay]
    )

    return (
      <div className={classnames(styles['picture-editor'], className)}>
        <div className={styles['editor-wrapper']}>
          <AvatarEditor
            ref={ref}
            image={picture}
            {...editorConfig}
            onPositionChange={onPositionChange}
            className={styles.editor}
          />
          {displayOverlay && (
            <button
              style={overlayStyles}
              className={styles.overlay}
              onMouseDown={onOverlayRemove}
              ref={overlayRef}
              type="button"
            >
              <Svg icon={RepositionIcon} className={styles['reposition-icon']} />
              <p className={styles['pan-image']}>Pan image</p>
              <p className={styles['pan-image-description']}>
                Click and hold to move the image inside the square
              </p>
            </button>
          )}
        </div>
        <div className={classnames(styles['slider-container'], sliderContainerClassName)}>
          <Svg icon={ImageResizeSmallIcon} className={styles['icon-small']} />
          <input
            type="range"
            min="0"
            max="2.5"
            step="0.01"
            value={state.scale}
            onChange={onSliderChange}
            className={styles.slider}
          />
          <Svg icon={ImageResizeBigIcon} className={styles['icon-big']} />
        </div>
      </div>
    )
  }
)

PictureAdjustment.propTypes = {
  className: PropTypes.string,
  sliderContainerClassName: PropTypes.string,
  picture: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    border: PropTypes.arrayOf(PropTypes.number),
  }),
  onEditorChange: PropTypes.func.isRequired,
  isOnlySquaredPicture: PropTypes.bool,
}

PictureAdjustment.defaultProps = {
  className: '',
  sliderContainerClassName: '',
  isOnlySquaredPicture: false,
  dimensions: {
    width: 255,
    height: 255,
    border: [20, 60],
  },
}
export default React.memo(PictureAdjustment)
