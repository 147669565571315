import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'

import ImageUpload from '_components/image-upload'
import { imageExtensions } from '_utils/files'

import styles from './styles.css'

const ImageUploadModal = ({ extensions, isOpen, dismiss, style, selectFile }) => (
  <Modal
    className={styles.modal}
    contentLabel="Upload image"
    overlayClassName={styles.overlay}
    isOpen={isOpen}
    onRequestClose={dismiss}
    shouldCloseOnOverlayClick
    style={style}
    ariaHideApp={false}
  >
    <ImageUpload extensions={extensions} selectFile={selectFile} />
  </Modal>
)

ImageUploadModal.propTypes = {
  extensions: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  dismiss: PropTypes.func,
  style: PropTypes.shape({
    top: PropTypes.string,
    left: PropTypes.string,
  }),
  selectFile: PropTypes.func.isRequired,
}

ImageUploadModal.defaultProps = {
  extensions: imageExtensions,
  isOpen: false,
  dismiss: () => {},
  style: {},
}

export default React.memo(ImageUploadModal)
