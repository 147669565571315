import { Map, fromJS } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'
import {
  UPDATE_SHARED_LINK,
  DELETE_SHARED_LINK,
  UNARCHIVE_SHARED_LINK,
} from '_modules/manage-recipients/actions'
import { CANCEL_SUBSCRIPTION } from '_modules/payments/actions'

import { GET_PRESENTATION_INSIGHTS, RESET_PRESENTATION_INSIGHTS } from './actions'

export const INITIAL_STATE = Map()

export const presentationInsights = createReducer(INITIAL_STATE, {
  [GET_PRESENTATION_INSIGHTS.FULFILLED]: (state, { payload }) => fromJS(payload),

  [UPDATE_SHARED_LINK.FULFILLED]: (state, { payload }) => {
    const shareLinks = state.get('share_links')
    const index = shareLinks.findIndex(recipient => recipient.get('id') === payload.id)

    return state.setIn(['share_links', index], fromJS(payload))
  },

  [DELETE_SHARED_LINK.FULFILLED]: (state, { meta: { sharedLink } }) => {
    const shareLinks = state.get('share_links')

    const index = shareLinks.findIndex(recipient => recipient.get('id') === sharedLink)
    return state.setIn(['share_links', index, 'disabled'], true)
  },

  [UNARCHIVE_SHARED_LINK.FULFILLED]: (state, { meta: { sharedLink } }) => {
    const shareLinks = state.get('share_links')

    const index = shareLinks.findIndex(recipient => recipient.get('id') === sharedLink)
    return state.setIn(['share_links', index, 'disabled'], false)
  },

  [RESET_PRESENTATION_INSIGHTS]: () => INITIAL_STATE,

  [CANCEL_SUBSCRIPTION.FULFILLED]: () => INITIAL_STATE,
})
