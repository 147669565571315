/* eslint-disable jsx-a11y/label-has-for */
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment, useReducer, useCallback, useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import humps from 'humps'

import Modal from '_components/modal'
import ButtonRound from '_components/button-round'
import { UPDATE_USER, updateUser } from '_modules/user/actions'
import { openAlert } from '_modules/alert-message/actions'
import usePrevious from '_hooks/use-previous'
import { AlertType } from '_components/alert-message'
import withViewportSize from '_hocs/with-viewport-size'
import TermsPrivacyModal, { ModalPage } from '_components/terms-privacy-modal'
import UploadImage from '_components/upload-image'
import EditPictureModal from '_components/edit-picture-modal'
import useModal from '_hooks/use-modal'

import styles from './styles.css'

const mapStateToProps = state => ({
  isUpdatingUser: !!state.loading.get(UPDATE_USER.ACTION),
  updateUserErrors: state.error.get(UPDATE_USER.ACTION),
})

const mapDispatchToProps = {
  openAlert,
  updateUser,
}

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  mobilePhone: '',
  tempPicture: '',
  displayedPicture: '',
  picture: '',
  pictureType: '',
}

const UPDATE_STATE = 'UPDATE_STATE'

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const AddProfileModal = ({
  className,
  isOpen,
  isUpdatingUser,
  openAlert: dispatchAlert,
  updateUserErrors,
  updateUser: updateUserAction,
  isSmallViewport,
}) => {
  const [termsAndPrivacyModal, setTermsAndPrivacyModal] = useState({
    isTermsAndPrivacyModalOpen: false,
    modalPage: ModalPage.TERMS_OF_USE,
  })
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobilePhone: '',
    picture: '',
  })

  const [state, stateDispatch] = useReducer(reducer, INITIAL_STATE)
  const [isEditPhotoOpen, toggleEditPhotoOpen] = useModal()

  const wasUpdatingUser = usePrevious(isUpdatingUser)
  const wasOpen = usePrevious(isOpen)

  const onChange = useCallback(event => {
    const { name, value } = event.target

    const payload = {
      [name]: value,
    }
    stateDispatch({ type: UPDATE_STATE, payload })
  }, [])

  const onChangePhoto = useCallback(
    (data, blob) => {
      let payload = {}
      if (blob.type) {
        payload = {
          editAvatarImageType: blob.type,
        }
      }

      URL.revokeObjectURL(state.displayedPicture)

      payload.tempPicture = blob

      stateDispatch({ type: UPDATE_STATE, payload })
      toggleEditPhotoOpen()
    },
    [state.displayedPicture, toggleEditPhotoOpen]
  )

  const onConfirmChangePicture = useCallback(
    ({ file, blob }) => {
      if (!file) return

      URL.revokeObjectURL(state.displayedPicture)

      const payload = {
        tempPicture: '',
        displayedPicture: URL.createObjectURL(blob),
        picture: file,
      }

      toggleEditPhotoOpen()
      stateDispatch({ type: UPDATE_STATE, payload })
    },
    [state.displayedPicture, toggleEditPhotoOpen]
  )

  const onRemovePhoto = useCallback(() => {
    const payload = {
      tempPicture: '',
      displayedPicture: '',
      picture: '',
    }

    stateDispatch({ type: UPDATE_STATE, payload })
  }, [])

  const fields = useMemo(
    () => [
      {
        label: 'First Name',
        name: 'firstName',
        value: state.firstName,
        style: styles['first-name'],
      },
      { label: 'Last Name', name: 'lastName', value: state.lastName, style: styles['last-name'] },
      {
        label: 'Mobile Phone',
        name: 'mobilePhone',
        value: state.mobilePhone,
        style: styles['mobile-phone'],
      },
    ],
    [state.firstName, state.lastName, state.mobilePhone]
  )

  const onSubmit = useCallback(
    event => {
      event.preventDefault()

      if (!state.firstName && !state.lastName) {
        dispatchAlert({
          type: AlertType.ADD_PROFILE,
          isOpen: true,
          timer: 2000,
        })
      } else {
        let payload = {
          first_name: state.firstName,
          last_name: state.lastName,
        }

        if (state.mobilePhone) {
          payload = {
            ...payload,
            mobile_phone: state.mobilePhone,
          }
        }

        if (state.picture) {
          payload.picture = state.picture
          // updateUserPictureAction({ picture: state.picture })
        }

        updateUserAction(payload)
      }
    },
    [
      dispatchAlert,
      state.firstName,
      state.lastName,
      state.mobilePhone,
      state.picture,
      updateUserAction,
    ]
  )

  const formatErrors = useCallback(() => {
    const [...keys] = updateUserErrors.keys()
    let error = {}

    keys.forEach(key => {
      const camelizedKey = humps.camelize(key)
      const userError = updateUserErrors.get(key)

      const userErrorMessage =
        // eslint-disable-next-line no-prototype-builtins
        (userError && userError.hasOwnProperty('first') && userError.first()) || userError

      error = { ...error, [camelizedKey]: userErrorMessage || 'An error occurred!' }
    })
    setErrors(error)
  }, [updateUserErrors])

  useEffect(() => {
    if (wasUpdatingUser && !isUpdatingUser && (isOpen || wasOpen)) {
      if (!updateUserErrors.size) {
        dispatchAlert({
          type: AlertType.CHANGES_SAVED,
          isOpen: true,
          timer: 2000,
        })
      } else {
        formatErrors()
      }
    }
  }, [
    isUpdatingUser,
    dispatchAlert,
    updateUserErrors.size,
    wasUpdatingUser,
    updateUserErrors,
    errors,
    formatErrors,
    isOpen,
    wasOpen,
  ])

  const renderTitle = useMemo(
    () => (
      <span>
        Welcome to Highnote, <br />
        Please complete your profile
      </span>
    ),
    []
  )

  const onTermsClick = useCallback(() => {
    setTermsAndPrivacyModal({
      modalPage: ModalPage.TERMS_OF_USE,
      isTermsAndPrivacyModalOpen: true,
    })
  }, [])

  const onPrivacyPolicyClick = useCallback(() => {
    setTermsAndPrivacyModal({
      modalPage: ModalPage.PRIVACY_POLICY,
      isTermsAndPrivacyModalOpen: true,
    })
  }, [])

  const onCloseModalPage = useCallback(() => {
    setTermsAndPrivacyModal({
      ...termsAndPrivacyModal,
      isTermsAndPrivacyModalOpen: false,
    })
  }, [termsAndPrivacyModal])

  return (
    <Fragment>
      <Modal
        bodyOpenClassName={styles['disable-body-scroll']}
        className={classnames(styles.modal, className)}
        contentLabel="Edit profile"
        isOpen={isOpen}
        title={renderTitle}
        hasHeaderDivider={isSmallViewport}
        headerClassName={styles.title}
        hasCloseButton={false}
        contentClass={styles['modal-content']}
      >
        <form className={styles.content} onSubmit={onSubmit}>
          {fields.map(field => (
            <div key={field.name} className={classnames(styles.field, field.style)}>
              <label className={styles.label} htmlFor={field.name}>
                {field.label}
              </label>
              <input
                className={classnames(styles.input, {
                  [styles['input-error']]: errors[field.name],
                })}
                value={field.value}
                onChange={onChange}
                name={field.name}
                id={field.name}
              />
              {errors[field.name] && (
                <span className={styles['error-message']}>{errors[field.name]}</span>
              )}
            </div>
          ))}
          <div className={classnames(styles.field, styles.picture)}>
            <p className={styles.label}>Profile Photo</p>
            <UploadImage
              onChangeImage={onChangePhoto}
              onRemoveImage={onRemovePhoto}
              image={state.displayedPicture}
            />
          </div>
          <p className={styles['terms-and-policy']}>
            Read our{' '}
            <button type="button" onClick={onTermsClick} className={styles.link}>
              Terms
            </button>{' '}
            and{' '}
            <button type="button" onClick={onPrivacyPolicyClick} className={styles.link}>
              Privacy Policy
            </button>
          </p>
          <ButtonRound className={styles.submit} isLoading={isUpdatingUser} type="submit">
            Submit
          </ButtonRound>
        </form>
      </Modal>
      {isEditPhotoOpen && (
        <EditPictureModal
          label="picture"
          onClose={toggleEditPhotoOpen}
          picture={state.tempPicture}
          onFinish={onConfirmChangePicture}
          isOnlySquaredPicture
        />
      )}
      <TermsPrivacyModal
        isOpen={termsAndPrivacyModal.isTermsAndPrivacyModalOpen}
        dismiss={onCloseModalPage}
        modalPage={termsAndPrivacyModal.modalPage}
      />
    </Fragment>
  )
}

AddProfileModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  openAlert: PropTypes.func.isRequired,
  isUpdatingUser: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserErrors: PropTypes.instanceOf(Map),
  isSmallViewport: PropTypes.bool,
}

AddProfileModal.defaultProps = {
  className: '',
  isOpen: false,
  updateUserErrors: Map(),
  isSmallViewport: false,
}

export default withViewportSize(connect(mapStateToProps, mapDispatchToProps)(AddProfileModal))
