import React, { useMemo, useState, forwardRef, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import HideIcon from '_assets/icons/hide.svg'
import ShowIcon from '_assets/icons/show.svg'
import Button, { BUTTON_THEME } from '_components/button-round'
import TextEditor from '_components/text-editor'

import styles from './styles.css'

const WysiwygTextfield = forwardRef(
  (
    {
      className,
      inputClassName,
      hasHideAction,
      isHidden,
      onShowClick,
      onHideClick,
      value,
      textAlignment,
      isLoading,
      ...inputProps
    },
    ref
  ) => {
    const [hasInputFocus, setInputFocus] = useState(false)
    const [hasButtonsFocus, setButtonsFocus] = useState(false)
    const displayHideButton = hasInputFocus || hasButtonsFocus

    const inputStyling = useMemo(
      () => classnames(styles.input, inputClassName, { [styles['hidden-textfield']]: isHidden }),
      [inputClassName, isHidden]
    )

    const handleClick = useCallback(
      event => {
        if (ref && ref.current && !ref.current.contains(event.target)) {
          setButtonsFocus(false)
          setInputFocus(false)
        }
      },
      [ref]
    )

    useEffect(() => {
      document.addEventListener('mousedown', handleClick)
      document.addEventListener('touchstart', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
        document.removeEventListener('touchstart', handleClick)
      }
    })

    const onInputFocus = useCallback(() => {
      setInputFocus(true)
    }, [])

    const onButtonsFocus = useCallback(() => {
      setButtonsFocus(true)
    }, [])

    return (
      <div className={classnames(styles['input-wrapper'], className)} ref={ref}>
        <TextEditor
          initialState={value}
          className={inputStyling}
          onFocus={onInputFocus}
          isHidden={isHidden}
          textAlignment={textAlignment}
          inputClassName={inputClassName}
          {...inputProps}
        />
        {hasHideAction &&
          displayHideButton &&
          (isHidden ? (
            <Button
              onClick={onShowClick}
              startIcon={ShowIcon}
              theme={BUTTON_THEME.TRANSPARENT_FILL}
              className={classnames(styles['hide-button'], styles[`hide-button-${textAlignment}`])}
              onFocus={onButtonsFocus}
              disabled={isLoading}
            >
              Show
            </Button>
          ) : (
            <Button
              onClick={onHideClick}
              startIcon={HideIcon}
              theme={BUTTON_THEME.TRANSPARENT_FILL}
              className={classnames(styles['hide-button'], styles[`hide-button-${textAlignment}`])}
              onFocus={onButtonsFocus}
              disabled={isLoading}
            >
              Hide
            </Button>
          ))}
      </div>
    )
  }
)

WysiwygTextfield.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  hasHideAction: PropTypes.bool,
  isHidden: PropTypes.bool,
  onShowClick: PropTypes.func,
  onHideClick: PropTypes.func,
  value: PropTypes.string,
  textAlignment: PropTypes.string,
  isLoading: PropTypes.bool,
}

WysiwygTextfield.defaultProps = {
  className: '',
  inputClassName: '',
  hasHideAction: false,
  isHidden: false,
  onShowClick: () => {},
  onHideClick: () => {},
  textAlignment: '',
  value: '',
  isLoading: false,
}

export default React.memo(WysiwygTextfield)
