import classNames from 'classnames'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import AvatarEditor from 'react-avatar-editor'

import Expand from '_assets/icons/expand.svg'
import Screenshot from '_assets/icons/screenshot.svg'
import Button, { BUTTON_THEME } from '_components/button-round'
import Loading from '_components/loading'
import Svg from '_components/svg'

import styles from './styles.css'

class ImageReposition extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    getHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
    getWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
    image: PropTypes.string.isRequired,
    initialPosition: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    initialScale: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    avatarEditorClassName: PropTypes.string,
    imageOverlayClassName: PropTypes.string,
    scaleControlClassName: PropTypes.string,
    scaleControlWrapperClassName: PropTypes.string,
    buttonsControlWrapperClassName: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    avatarEditorClassName: '',
    imageOverlayClassName: '',
    scaleControlClassName: '',
    scaleControlWrapperClassName: '',
    buttonsControlWrapperClassName: '',
    initialPosition: { x: 0.5, y: 0.5 },
    initialScale: 1,
  }

  constructor(props) {
    super(props)

    this.canvasRef = null
    this.editorContainerRef = React.createRef()
    this.hammerManager = null
    this.url = null

    this.state = {
      image: null,
      position: props.initialPosition,
      scale: props.initialScale,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResizeWindow)

    fetch(this.props.image, { cache: 'no-cache' })
      .then(response => response.blob())
      .then(blob => {
        const image = new Image()
        this.url = URL.createObjectURL(blob)

        image.onload = () => {
          if (this.editorContainerRef && this.editorContainerRef.current) {
            this.setState({
              image: this.url,
            })
          }
        }

        image.src = this.url
      })

    if (this.editorContainerRef && this.editorContainerRef.current) {
      import('hammerjs').then(Hammer => {
        this.hammerManager = new Hammer.Manager(this.editorContainerRef.current)
        this.hammerManager.add(new Hammer.Pinch({ enable: true }))

        this.hammerManager.on('pinchin', this.onPinchIn)
        this.hammerManager.on('pinchout', this.onPinchOut)
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow)

    if (this.url) {
      URL.revokeObjectURL(this.url)
    }

    if (this.hammerManager) {
      this.hammerManager.destroy()
    }
  }

  onPinchIn = () => {
    this.setState(({ scale }) => ({ scale: scale - 0.01 }))
  }

  onPinchOut = () => {
    this.setState(({ scale }) => ({ scale: scale + 0.01 }))
  }

  onPositionChange = position => {
    this.setState({ position })
  }

  onResizeWindow = debounce(() => {
    this.forceUpdate()
  }, 250)

  onSave = () => {
    if (!this.canvasRef) {
      return
    }

    this.canvasRef.getImageScaledToCanvas().toBlob(blob => {
      const { position, scale } = this.state
      this.props.onSave({
        blob: new File([blob], 'background.png', { type: 'image/png' }),
        position,
        scale,
        name: 'background.png',
      })
    })
  }

  onScaleChange = event => {
    this.setState({ scale: Number(event.target.value) })
  }

  setCanvasRef = canvas => {
    this.canvasRef = canvas
  }

  getDimension = dimension => (typeof dimension === 'function' ? dimension() : dimension)

  render() {
    const { className, getHeight, getWidth, onCancel, scaleControlClassName } = this.props
    const { image, position, scale } = this.state

    return (
      <Fragment>
        <div className={styles['screen-overlay']} />
        <div className={classNames(styles['editor-container'], className)}>
          <div ref={this.editorContainerRef}>
            {image ? (
              <Fragment>
                <AvatarEditor
                  className={this.props.avatarEditorClassName}
                  border={0}
                  height={this.getDimension(getHeight)}
                  image={image}
                  onPositionChange={this.onPositionChange}
                  position={position}
                  ref={this.setCanvasRef}
                  scale={scale}
                  width={this.getDimension(getWidth)}
                />
                <div className={styles.hint}>
                  <Svg icon={Expand} className={styles.icon} />
                </div>
              </Fragment>
            ) : (
              <Loading
                style={{ height: this.getDimension(getHeight), width: this.getDimension(getWidth) }}
              />
            )}
          </div>
          <div className={classNames(styles['image-overlay'], this.props.imageOverlayClassName)}>
            <div
              className={classNames(
                styles['scale-control'],
                this.props.scaleControlWrapperClassName
              )}
            >
              <svg className={styles['min-range-icon']}>
                <use xlinkHref={Screenshot} />
              </svg>
              <input
                className={classNames(styles['scale-input'], scaleControlClassName)}
                disabled={!image}
                max="2"
                min="0.25"
                onChange={this.onScaleChange}
                step="0.01"
                type="range"
                value={scale}
              />
              <svg className={styles['max-range-icon']}>
                <use xlinkHref={Screenshot} />
              </svg>
            </div>
            <div
              className={classNames(
                styles['buttons-container'],
                this.props.buttonsControlWrapperClassName
              )}
            >
              <Button onClick={onCancel} theme={BUTTON_THEME.SECONDARY}>
                Cancel
              </Button>
              <Button disabled={!image} onClick={this.onSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ImageReposition
