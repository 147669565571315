import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Menu as ReachMenu, MenuButton } from '@reach/menu-button'

import EditIcon from '_assets/icons/pencil.svg'
import UploadIcon from '_assets/icons/upload.svg'
import DeleteIcon from '_assets/icons/trash-bin.svg'
import CameraIcon from '_assets/icons/camera.svg'
import Svg from '_components/svg'
import Menu from '_components/menu'
import useModal from '_hooks/use-modal'

import UserPictureModal from './user-picture-modal'
import styles from './styles.css'

const UserPicture = ({ className, picture, presentationId, onRemove }) => {
  const [isPictureModalOpen, onToggleModal] = useModal()

  const menuOptions = useMemo(
    () => [
      {
        onSelect: onToggleModal,
        label: 'Upload new picture',
        icon: UploadIcon,
      },
      {
        onSelect: onRemove,
        label: 'Remove picture',
        icon: DeleteIcon,
      },
    ],
    [onRemove, onToggleModal]
  )

  return (
    <div className={classnames(className, styles['user-picture-wrapper'])}>
      {picture ? (
        <ReachMenu>
          <MenuButton
            aria-label="Change presentation author image"
            className={styles['avatar-wrapper']}
          >
            <img src={picture} alt="user avatar" className={styles.avatar} />
            <span className={styles['edit-button']}>
              <Svg icon={EditIcon} className={styles['edit-icon']} />
            </span>
          </MenuButton>
          <Menu
            MenuLabel="test"
            options={menuOptions}
            menuListClassName={styles['menu-list-dropdown']}
          />
        </ReachMenu>
      ) : (
        <button type="button" onClick={onToggleModal} className={styles['empty-picture']}>
          <Svg icon={CameraIcon} className={styles['camera-icon']} />
          <span className={styles.bold}>Click here</span> to upload your picture
        </button>
      )}
      {isPictureModalOpen && (
        <UserPictureModal presentationId={presentationId} dismiss={onToggleModal} />
      )}
    </div>
  )
}

UserPicture.propTypes = {
  picture: PropTypes.string,
  className: PropTypes.string,
  presentationId: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
}

UserPicture.defaultProps = {
  picture: '',
  className: '',
}

export default React.memo(UserPicture)
