import { Map, fromJS } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'

import { RETRIEVE_CLIENT_PRESENTATION, CLONE_PRESENTATION_FROM_SHARE_LINK } from './actions'

const INITIAL_STATE = Map({
  clientPresentation: Map(),
})

export const clientPresentation = createReducer(INITIAL_STATE, {
  [RETRIEVE_CLIENT_PRESENTATION.FULFILLED]: (state, { payload }) =>
    state.set('clientPresentation', fromJS({ ...payload })),

  [CLONE_PRESENTATION_FROM_SHARE_LINK.FULFILLED]: (state, { payload }) =>
    state.set('clientPresentation', fromJS({ ...payload })),
})
