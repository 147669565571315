import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const Toggle = ({ id, options, value, name, handleToggle, className, classNameLabel }) => (
  <div className={classnames(styles.container, className)}>
    {React.Children.toArray(
      options.map(item => (
        <label
          htmlFor={`${id}-${item.value}`}
          className={classnames(styles.label, classNameLabel, {
            [styles.checked]: item.value === value,
          })}
        >
          <input
            id={`${id}-${item.value}`}
            type="radio"
            value={item.value}
            checked={item.value === value}
            onChange={handleToggle}
            name={name}
            className={styles.input}
          />
          {item.label}
        </label>
      ))
    )}
  </div>
)
Toggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  handleToggle: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
}

Toggle.defaultProps = {
  className: '',
  classNameLabel: '',
}

export default React.memo(Toggle)
