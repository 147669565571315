import React, { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Svg from '_components/svg'
import PlusIcon from '_assets/icons/plus.svg'
import { ASSET_TYPE } from '_constants/presentation'
import FileIcon from '_assets/icons/ic_file.svg'
import LinkIcon from '_assets/icons/link.svg'
import DividerIcon from '_assets/icons/ic-divider.svg'
import ReorderAssetIcon from '_assets/icons/reorder.svg'
import ContactIcon from '_assets/icons/ic-user.svg'
import TextIcon from '_assets/icons/ic-text.svg'
import { editPresentationAsset } from '_modules/assets/actions'
import Asset from '_models/asset'

import AssetMoreActions from '../asset-more-actions'
import HideAsset from '../hide-asset'

import styles from './styles.css'

export const SelectAssetType = ({ index, asset, presentationId }) => {
  const dispatch = useDispatch()

  const assetTypeOptions = [
    {
      type: ASSET_TYPE.FILE,
      icon: FileIcon,
      label: 'File',
    },
    {
      type: ASSET_TYPE.LINK,
      icon: LinkIcon,
      label: 'Link',
    },
    {
      type: ASSET_TYPE.SEPARATOR,
      icon: DividerIcon,
      label: 'Divider',
    },
    {
      type: ASSET_TYPE.TEXT,
      icon: TextIcon,
      label: 'Text',
    },
    {
      type: ASSET_TYPE.CONTACT,
      icon: ContactIcon,
      label: 'Contact form',
    },
  ]

  const handleClick = useCallback(
    type => () => {
      dispatch(
        editPresentationAsset({
          id: asset.get('id'),
          presentationId,
          payload: { type },
        })
      )
    },
    [asset, dispatch, presentationId]
  )

  return (
    <Draggable key={`asset-${asset.get('id')}`} draggableId={String(asset.get('id'))} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={styles['asset-wrapper']}
        >
          <div className={styles['drag-container']} {...provided.dragHandleProps}>
            <Svg icon={ReorderAssetIcon} className={styles['drag-icon']} />
          </div>
          <div
            className={classnames(styles.container, {
              [styles.hidden]: asset.get('isHidden'),
            })}
          >
            {asset.get('isHidden') && <HideAsset asset={asset} presentationId={presentationId} />}
            <div className={styles.header}>
              <Svg icon={PlusIcon} className={styles['add-asset-icon']} />
              <h2 className={styles.title}>Add asset</h2>
            </div>
            <div className={styles.content}>
              <p className={styles['asset-type-title']}>Choose the type of asset to add:</p>
              <div className={styles['options-wrapper']}>
                {assetTypeOptions.map(({ type, icon, label }) => (
                  <button
                    key={type}
                    className={styles['asset-type-button']}
                    type="button"
                    onClick={handleClick(type)}
                  >
                    <span className={styles['asset-type-button-wrapper']}>
                      <Svg icon={icon} className={styles['asset-type-button-icon']} />
                    </span>
                    {label}
                  </button>
                ))}
              </div>
            </div>
            <AssetMoreActions
              asset={asset}
              className={styles['asset-actions']}
              presentationId={presentationId}
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}

SelectAssetType.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  presentationId: PropTypes.number.isRequired,
}
