import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import 'draft-js/dist/Draft.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Map } from 'immutable'

import BoldIcon from '_assets/baseline_format_bold_white_18dp.png'
import ItalicsIcon from '_assets/baseline_format_italic_white_18dp.png'
import UnderlinedIcon from '_assets/baseline_format_underlined_white_18dp.png'
import LinkIcon from '_assets/icons/link.png'
import LeftIcon from '_assets/baseline_format_align_left_white_18dp.png'
import CenterIcon from '_assets/baseline_format_align_center_white_18dp.png'
import RightIcon from '_assets/baseline_format_align_right_white_18dp.png'
import BulletedListIcon from '_assets/icons/bulleted-list.png'
import EmojiIcon from '_assets/icons/emoji.png'
import usePrevious from '_hooks/use-previous'
import { NO_PICTURE } from '_utils/constants'
import { FLAG_SHOW_ENHANCE_AI_BUTTON } from '_config/environment'

// eslint-disable-next-line import/no-cycle
import EnhanceText from '../enhance/enhance-text'

import ColorPicker from './color-picker'
import styles from './styles.css'

const ALLOW_ENHANCE = ['title', 'description', 'openingMsg', 'closingMsg']

export const HIERARCHY_TYPES_ELEMENTS = {
  TITLE: 'h1',
  SUBTITLE: 'h2',
  PARAGRAPH: 'p',
}

const HIERARCHY_TYPES = {
  [HIERARCHY_TYPES_ELEMENTS.TITLE]: 'header-one',
  [HIERARCHY_TYPES_ELEMENTS.SUBTITLE]: 'header-two',
  [HIERARCHY_TYPES_ELEMENTS.PARAGRAPH]: 'unstyled',
}

export const convertTag = (htmlText, typeToConvert) => {
  const contentBlock = htmlToDraft(htmlText)
  const contentStateInitial = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const editorStateInitial = EditorState.createWithContent(contentStateInitial)
  const selectionState = editorStateInitial.getSelection()
  const editContentState = Modifier.setBlockType(
    contentStateInitial,
    selectionState,
    HIERARCHY_TYPES[typeToConvert]
  )

  return draftToHtml(
    convertToRaw(EditorState.createWithContent(editContentState).getCurrentContent())
  )
}

export const convertInitialState = (initialState, textAlignment) => {
  let initialValue = initialState

  if (!initialState) {
    initialValue = ''
  }
  const contentBlock = htmlToDraft(initialValue)
  const dataBlock = contentBlock.contentBlocks[0].getData()

  const contentStateInitial = ContentState.createFromBlockArray(contentBlock.contentBlocks)

  if (textAlignment && initialState && dataBlock && !dataBlock.get('text-align')) {
    const editorStateInitial = EditorState.createWithContent(contentStateInitial)
    const editContentState = Modifier.setBlockData(
      contentStateInitial,
      editorStateInitial.getSelection(),
      Map([['text-align', textAlignment === NO_PICTURE ? 'center' : textAlignment]])
    )

    return EditorState.createWithContent(editContentState)
  }

  return EditorState.createWithContent(contentStateInitial)
}

export const getPlainText = htmlText => {
  if (htmlText) {
    return convertInitialState(htmlText)
      .getCurrentContent()
      .getPlainText()
  }
  return ''
}

const TextEditor = ({
  initialState,
  onChange,
  name,
  onFocus,
  onBlur,
  isHidden,
  textAlignment,
  inputClassName,
  wrapperClassName,
  maxLength,
  presentationId,
  assetId,
  forceUpdate,
  type,
  ...restProps
}) => {
  const [state, setState] = useState(convertInitialState(initialState, textAlignment))
  const prevForceUpdate = usePrevious(forceUpdate)
  const ref = useRef(null)

  const onEditorChange = useCallback(
    newState => {
      if (
        newState.getLastChangeType() &&
        newState.getCurrentContent() !== state.getCurrentContent()
      ) {
        if (type !== HIERARCHY_TYPES_ELEMENTS.PARAGRAPH) {
          const selectionState = newState.getSelection()
          const editContentState = Modifier.setBlockType(
            newState.getCurrentContent(),
            selectionState,
            HIERARCHY_TYPES[type]
          )
          const convertedToHtml = draftToHtml(convertToRaw(editContentState))
          const payload = {
            name,
            value: convertedToHtml,
            plainText: newState.getCurrentContent().getPlainText(),
          }

          onChange(payload)
          setState(newState)
          return
        }
        const convertedToHtml = draftToHtml(convertToRaw(newState.getCurrentContent()))
        const payload = {
          name,
          value: convertedToHtml,
          plainText: newState.getCurrentContent().getPlainText(),
        }
        onChange(payload)
      }
      setState(newState)
    },
    [name, onChange, state, type]
  )

  const handleBeforeInput = useCallback(
    input => {
      if (input && maxLength && state.getCurrentContent().getPlainText().length >= maxLength) {
        return 'handled'
      }
      return 'not-handled'
    },
    [maxLength, state]
  )

  const handleBeforeReturn = useCallback(
    input => {
      if (!state.getCurrentContent().getPlainText()) {
        return 'handled'
      }
      if (input && maxLength && state.getCurrentContent().getPlainText().length >= maxLength) {
        return 'handled'
      }
      return 'not-handled'
    },
    [maxLength, state]
  )

  const handlePastedText = useCallback(
    text => {
      const overflowChars =
        text.length + state.getCurrentContent().getPlainText().length - maxLength

      if (overflowChars > 0) {
        if (text.length - overflowChars > 0) {
          const newContent = Modifier.insertText(
            state.getCurrentContent(),
            state.getSelection(),
            text.substring(0, text.length - overflowChars)
          )
          setState(EditorState.push(state, newContent, 'insert-characters'))
        }
        return 'handled'
      }
      return 'not-handled'
    },
    [maxLength, state]
  )

  const toolBarOptions = useMemo(
    () => ['fontFamily', 'list', 'link', 'emoji', 'textAlign', 'inline'],
    []
  )

  // TODO: change lib because the current one doesn't deal well with these options that are commented on in the mobile version
  const toolbar = useMemo(
    () => ({
      options: toolBarOptions,
      inline: {
        inDropdown: false,
        className: styles.inline,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline'],
        bold: { icon: BoldIcon, className: styles.option },
        italic: { icon: ItalicsIcon, className: styles.option },
        underline: { icon: UnderlinedIcon, className: styles.option },
      },
      list: {
        inDropdown: false,
        className: styles.list,
        options: ['unordered'],
        unordered: { icon: BulletedListIcon, className: styles.option },
        // ordered: { icon: NumberedListIcon, className: styles.option },
      },
      emoji: {
        className: styles.emoji,
        icon: EmojiIcon,
        popupClassName: styles['emoji-dropdown'],
      },
      link: {
        inDropdown: false,
        className: styles.link,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        popupClassName: styles['link-dropdown'],
        options: ['link'],
        link: { icon: LinkIcon, className: styles.option },
      },
      textAlign: {
        inDropdown: false,
        className: styles['text-align'],
        dropdownClassName: undefined,
        options: ['left', 'center', 'right'],
        left: { icon: LeftIcon, className: styles.option },
        center: { icon: CenterIcon, className: styles.option },
        right: { icon: RightIcon, className: styles.option },
      },
      fontFamily: {
        options: [
          'Arial',
          'Barlow',
          'Comic Neue',
          'Georgia',
          'Josefin Sans',
          'Lato',
          'Lora',
          'Montserrat',
          'Noto Sans',
          'Open Sans',
          'Oxygen',
          'Playfair Display',
          'Roboto',
          'Roboto Mono',
          'Sackers Gothic',
          'Tahoma',
          'Verdana',
        ],
        className: styles['font-family'],
        dropdownClassName: styles['font-family-dropdown'],
      },
    }),
    [toolBarOptions]
  )

  useEffect(() => {
    if (forceUpdate && !prevForceUpdate) {
      setState(convertInitialState(initialState, textAlignment))
    }
  }, [forceUpdate, initialState, prevForceUpdate, textAlignment])

  return (
    <React.Fragment>
      <style>
        @import
        url(&apos;https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&family=Comic+Neue:wght@400;700&family=Josefin+Sans:wght@400;700&family=Lato:wght@400;700&family=Lora:wght@400;700&family=Montserrat:wght@400;700&family=Noto+Sans:wght@400;700&family=Oxygen:wght@400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400&family=Roboto+Mono:wght@400;700&display=swap&apos;);
      </style>
      <Editor
        ref={ref}
        toolbarClassName={classnames(styles.toolbar, {
          [styles['no-enhance']]: !ALLOW_ENHANCE.includes(name) || !FLAG_SHOW_ENHANCE_AI_BUTTON,
        })}
        wrapperClassName={classnames(styles.wrapper, wrapperClassName)}
        editorClassName={classnames(
          styles.editor,
          { [styles['hidden-editor']]: isHidden },
          inputClassName
        )}
        editorState={state}
        onEditorStateChange={onEditorChange}
        toolbar={toolbar}
        toolbarOnFocus
        onFocus={onFocus}
        onBlur={onBlur}
        textAlignment={textAlignment}
        handleBeforeInput={handleBeforeInput}
        {...(maxLength && {
          handlePastedText,
        })}
        handleReturn={handleBeforeReturn}
        {...restProps}
        stripPastedStyles
        toolbarCustomButtons={[
          <ColorPicker
            key="color-picker"
            name={name}
            setState={setState}
            handleChangeColor={onChange}
            className={styles['color-picker']}
          />,
          ...(ALLOW_ENHANCE.includes(name) &&
            FLAG_SHOW_ENHANCE_AI_BUTTON && [
              <EnhanceText
                key="enhance-text"
                name={name}
                presentationId={presentationId}
                setState={setState}
                assetId={assetId}
                className={styles.enhance}
                editorRef={ref}
              />,
            ]),
        ]}
      />
    </React.Fragment>
  )
}

TextEditor.propTypes = {
  onChange: PropTypes.func,
  initialState: PropTypes.string,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isHidden: PropTypes.bool,
  textAlignment: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  presentationId: PropTypes.number,
  assetId: PropTypes.number,
  allowColorPicker: PropTypes.bool,
  forceUpdate: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(HIERARCHY_TYPES_ELEMENTS)),
}

TextEditor.defaultProps = {
  initialState: '',
  name: '',
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  isHidden: false,
  textAlignment: '',
  inputClassName: '',
  wrapperClassName: '',
  className: undefined,
  maxLength: undefined,
  assetId: undefined,
  presentationId: undefined,
  allowColorPicker: false,
  forceUpdate: false,
  type: HIERARCHY_TYPES_ELEMENTS.PARAGRAPH,
}

export default React.memo(TextEditor)
