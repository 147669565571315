import { createFormData } from '_utils/helpers'

import { get, patch, post, upload, del } from './request'

export const getUser = key => () => get(['user'], { key })

export const updateUser = key => payload => {
  if (payload.picture || payload.logo) {
    return upload(['user'], { key, method: 'PATCH' }, createFormData(payload))
  }
  return patch(['user'], { key }, payload)
}

export const changeEmail = key => payload => post(['change-email'], { key }, payload)

export const changePassword = key => payload => post(['change-password'], { key }, payload)

export const passwordReset = payload => post(['password', 'reset'], {}, payload)

export const passwordResetConfirmation = payload =>
  post(['password', 'reset', 'confirm'], {}, payload)

export const updateUserLogo = key => payload => {
  if (payload.picture) {
    const data = new FormData()
    data.append('logo', payload.picture)
    return upload(['user'], { key, method: 'PATCH' }, data)
  }

  return patch(['user'], { key }, { logo: null })
}

export const updateUserPicture = key => payload => {
  const data = new FormData()
  data.append('picture', payload.picture)
  return upload(['user'], { key, method: 'PATCH' }, data)
}

export const userAutocomplete = key => params => get(['autocomplete'], { key, params })

export const signUpEmail = payload => post(['register'], {}, payload)

export const loginFacebook = (payload, key) => params =>
  post(['auth', 'facebook'], { key, params }, payload)

export const loginGoogle = (payload, key) => params =>
  post(['auth', 'google'], { key, params }, payload)

export const unlinkSocialAccount = (payload, key) => params =>
  post(['auth', 'unlink'], { key, params }, payload)

export const sendReferralCode = key => payload => post(['referral-code'], { key }, payload)

export const getReferralRockCode = key => () => get(['referral-rock-auth-url'], { key })

export const createIntercomContact = key => params => post(['intercom-contact'], { key, params })

export const createTeamPayment = key => payload => post(['company'], { key }, payload)

export const getUserRole = () => params => get(['user-role', params])

export const getStripePortalUrl = key => get(['user', 'my-portal'], { key })

export const getDoneForYouCheckoutPage = key => payload =>
  post(['user', 'my-dfy-session'], { key }, payload)

export const getUserInvites = key => get(['invites'], { key })

export const declineCompanyInvite = (key, companyId, inviteId) =>
  del(['company', companyId, 'invited', inviteId, 'decline'], { key })

export const acceptCompanyInvite = (key, companyId, inviteId) =>
  post(['company', companyId, 'invited', inviteId, 'accept'], { key })

export const completeOnboarding = (key, payload) => post(['onboarding'], { key }, payload)
