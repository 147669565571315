export const USER_ROLES = {
  FREE_AGENT: 'free_agent',
  PREMIUM_AGENT: 'premium_agent',
}

export const SUBSCRIPTION_STATUS = {
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  TRIALING: 'TRIALING',
  ACTIVE: 'ACTIVE',
  PAST_DUE: 'PAST_DUE',
  CANCELED: 'CANCELED',
  UNPAID: 'UNPAID',
}

export const ACCOUNT_DETAILS = [
  {
    text: 'Unlimited presentations',
    isFree: true,
  },
  {
    text: 'Unlimited sends',
    isFree: true,
  },
  {
    text: 'Unlimited analytics',
    isFree: false,
  },
  {
    text: 'Personal branding',
    isFree: false,
  },
  {
    text: 'Premium support',
    isFree: false,
  },
  {
    text: 'Favorite presentations',
    isFree: false,
  },
]

export const ACCOUNT_TYPES_LABEL = {
  [USER_ROLES.FREE_AGENT]: 'Free',
  [USER_ROLES.PREMIUM_AGENT]: 'Premium',
}

export const USER_COMPANY_STATUS = {
  INDIVIDUAL: 'individual',
  TEAM: 'team',
  ADMIN: 'admin',
}
