import PropTypes from 'prop-types'
import React, { useState, useCallback, useRef, useEffect } from 'react'
import classnames from 'classnames'

import InfoIcon from '_assets/icons/info-24-px.svg'
import Switch from '_components/switch'
import useOutsideClick from '_hooks/use-outside-click'

import styles from './styles.css'

const LabeledSwitch = ({
  className,
  id,
  label,
  name,
  description,
  isChecked,
  onChange,
  tooltip,
  disabled,
}) => {
  const tooltipRef = useRef(null)
  const [isToggleChecked, setToggleChecked] = useState(isChecked)
  const [isDisplayingTooltip, setDisplayTooltip] = useState(false)

  const toggleSwitch = useCallback(() => {
    onChange(!isToggleChecked, { name })
    setToggleChecked(prevState => !prevState)
  }, [isToggleChecked, name, onChange])

  const onOpenTooltip = useCallback(() => {
    setDisplayTooltip(true)
  }, [])

  const onCloseTooltip = useCallback(() => {
    setDisplayTooltip(false)
  }, [])

  useOutsideClick(tooltipRef, onCloseTooltip)

  useEffect(() => setToggleChecked(isChecked), [isChecked])

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles['information-wrapper']}>
        <div
          className={classnames(styles['switch-title'], {
            [styles['switch-title-disabled']]: disabled,
          })}
          style={description && { fontWeight: 600 }}
        >
          {label}
        </div>
        {tooltip && (
          <div className={styles['tooltip-wrapper']} ref={tooltipRef}>
            <svg aria-hidden="true" className={styles['info-icon']} onClick={onOpenTooltip}>
              <use xlinkHref={InfoIcon} />
            </svg>
            <div
              className={classnames(styles['tooltip-container'], {
                [styles['tooltip-visible']]: isDisplayingTooltip,
              })}
            >
              <p className={styles['tooltip-content']} id="tooltip-id">
                {tooltip}
              </p>
            </div>
          </div>
        )}
        {description && <p className={styles.description}>{description}</p>}
      </div>
      <Switch
        checked={isToggleChecked}
        id={id}
        onColor="#e82f72"
        offColor="white"
        onChange={toggleSwitch}
        pendingOnColor="#e82f72"
        className={styles.switch}
        disabled={disabled}
        {...(tooltip && { ariaDescribedby: 'tooltip-id' })}
      />
    </div>
  )
}
LabeledSwitch.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string,
  description: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
}

LabeledSwitch.defaultProps = {
  className: null,
  id: undefined,
  label: undefined,
  name: undefined,
  description: undefined,
  isChecked: false,
  tooltip: '',
  disabled: false,
}

export default React.memo(LabeledSwitch)
