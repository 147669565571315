import { Map, fromJS, List } from 'immutable'
import humps from 'humps'
import cookies from 'react-cookies'

import { COOKIES_OPTIONS, PLANS_NAMES } from '_utils/constants'
import { createReducer, AUTH_LOGOUT } from '_privateDependencies/react-utils/utils'

import {
  ADD_CREDIT_CARD,
  CHANGE_SUBSCRIPTION,
  START_SUBSCRIPTION,
  VALIDATE_COUPON_CODE,
  GET_CREDIT_CARD,
  SKIP_SUBSCRIPTION_PAST_DUE,
  CANCEL_SUBSCRIPTION,
  SKIP_UPGRADE_BANNER,
  CLEAR_COUPON_CODE,
  GET_PLAN_BY_ID,
} from './actions'

export const INITIAL_STATE = Map({
  discountValue: undefined,
  lastAddedCardId: undefined,
  cardList: List(),
  skipSubscriptionPastDue: !!cookies.load('skipSubscriptionPastDue', COOKIES_OPTIONS) || undefined,
  skipUpgradeBanner: !!cookies.load('skipUpgradeBanner', COOKIES_OPTIONS) || undefined,
  currentPlans: Map(),
  plan: undefined,
})

export default createReducer(
  INITIAL_STATE,
  {
    [ADD_CREDIT_CARD.FULFILLED]: (state, { payload, meta }) => {
      const formattedPayload = fromJS(humps.camelizeKeys(payload.results))

      const { cardId } = meta
      return state.set('lastAddedCardId', cardId).mergeIn(['cardList'], formattedPayload)
    },

    [CHANGE_SUBSCRIPTION.FULFILLED]: state => {
      let previousCard = state.get('cardList').find(card => card.get('default'))
      const lastCardId = state.get('lastAddedCardId')

      if (previousCard && previousCard.get('id') !== lastCardId) {
        previousCard = previousCard.set('default', false)
        const currentCard = state
          .get('cardList')
          .find(card => card.get('id') === lastCardId)
          .set('default', true)

        const indexOldCurrentCard = state.get('cardList').indexOf(previousCard)
        const indexCurrentCard = state
          .get('cardList')
          .findIndex(card => lastCardId === card.get('id'))

        return state
          .mergeIn(['cardList', indexOldCurrentCard], previousCard)
          .mergeIn(['cardList', indexCurrentCard], currentCard)
      }

      const currentCard = state
        .get('cardList')
        .find(card => card.get('id') === lastCardId)
        .set('default', true)

      return state.mergeIn(['cardList'], List([currentCard]))
    },

    [START_SUBSCRIPTION.FULFILLED]: state =>
      // TODO: Update this once flow is decided
      state,

    [VALIDATE_COUPON_CODE.FULFILLED]: (state, { payload }) => {
      const formattedPayload = fromJS(humps.camelizeKeys(payload))

      return state.set('discountValue', formattedPayload)
    },
    [CLEAR_COUPON_CODE]: state => state.set('discountValue', undefined),

    [GET_CREDIT_CARD.FULFILLED]: (state, { payload }) => {
      const formattedPayload = fromJS(humps.camelizeKeys(payload.results))
      return state.set('cardList', formattedPayload)
    },

    [SKIP_SUBSCRIPTION_PAST_DUE]: state => {
      cookies.save('skipSubscriptionPastDue', true, COOKIES_OPTIONS)
      return state.set('skipSubscriptionPastDue', true)
    },

    [CANCEL_SUBSCRIPTION.FULFILLED]: state => {
      cookies.remove('skipSubscriptionPastDue', COOKIES_OPTIONS)

      return state.set('skipSubscriptionPastDue', false)
    },

    [SKIP_UPGRADE_BANNER]: state => {
      cookies.save('skipUpgradeBanner', true, COOKIES_OPTIONS)
      return state.set('skipUpgradeBanner', true)
    },

    [AUTH_LOGOUT.FULFILLED]: () => {
      cookies.remove('skipSubscriptionPastDue', COOKIES_OPTIONS)
      cookies.remove('skipUpgradeBanner', COOKIES_OPTIONS)

      return INITIAL_STATE
    },
    [GET_PLAN_BY_ID.FULFILLED]: (state, { payload, meta }) => {
      const camelizedPayload = humps.camelizeKeys(payload)
      const { crossSell } = camelizedPayload

      if (Object.values(meta).some(plan => Object.values(PLANS_NAMES).includes(plan))) {
        return state.set('currentPlans', fromJS(camelizedPayload))
      }

      if (crossSell) {
        return state.set('plan', fromJS(camelizedPayload)).set('crossSell', fromJS(crossSell))
      }

      return state.set('plan', fromJS(camelizedPayload))
    },
  },
  true
)
