import { normalize } from 'normalizr'
import { fromJS } from 'immutable'

import { presentationSchema } from '_models/'

export const normalizePresentations = presentations => {
  const normalizedClasses = normalize(presentations, presentationSchema).entities.result
    ? normalize(presentations, presentationSchema).entities.result
    : []

  return fromJS(normalizedClasses)
}
