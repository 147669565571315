import { Map } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'

import {
  CREATE_PRESENTATION_SHARE_LINK,
  CLEAR_PRESENTATION_SHARE_LINK,
  UPDATE_PRESENTATION_SHARE_LINK,
} from './actions'

const INITIAL_STATE = Map({
  shareLink: '',
  isIndexable: false,
})

export const shareLink = createReducer(INITIAL_STATE, {
  [CREATE_PRESENTATION_SHARE_LINK.FULFILLED]: (state, { payload }) =>
    Map({
      shareLink: payload.share_link,
      isIndexable: payload.is_indexable,
    }),

  [UPDATE_PRESENTATION_SHARE_LINK.FULFILLED]: (state, { payload }) =>
    Map({
      shareLink: payload.share_link,
      isIndexable: payload.is_indexable,
    }),

  [CLEAR_PRESENTATION_SHARE_LINK]: () => INITIAL_STATE,
})
