import { defineAction } from '_privateDependencies/react-utils/utils'
import * as presentationService from '_services/presentation'

export const RETRIEVE_CLIENT_PRESENTATION = defineAction('RETRIEVE_CLIENT_PRESENTATION')
export const FORWARD_CLIENT_PRESENTATION = defineAction('FORWARD_CLIENT_PRESENTATION')
export const CLONE_PRESENTATION_FROM_SHARE_LINK = defineAction('CLONE_PRESENTATION_FROM_SHARE_LINK')
export const REQUIRE_EMAIL_PRESENTATION = defineAction('REQUIRE_EMAIL_PRESENTATION')
export const SAVE_CLIENT_PRESENTATION = defineAction('SAVE_CLIENT_PRESENTATION')

export const requireEmailPresentation = (hashId, payload) => dispatch =>
  dispatch({
    type: REQUIRE_EMAIL_PRESENTATION.ACTION,
    payload: presentationService.requireEmailPresentation(hashId, payload),
  })

export const retrieveClientPresentation = (hash, params) => (dispatch, getState) =>
  dispatch({
    type: RETRIEVE_CLIENT_PRESENTATION.ACTION,
    payload: presentationService.retrieveClientPresentation(getState().user.get('auth_token'))(
      hash,
      params
    ),
  })

export const forwardClientPresentation = (hash, params, payload) => dispatch =>
  dispatch({
    type: FORWARD_CLIENT_PRESENTATION.ACTION,
    payload: presentationService.forwardClientPresentation(hash, params, payload),
  })

export const clonePresentationFromShareLink = (hash, id) => (dispatch, getState) =>
  dispatch({
    type: CLONE_PRESENTATION_FROM_SHARE_LINK.ACTION,
    payload: presentationService.clonePresentationFromShareLink(getState().user.get('auth_token'))(
      hash,
      id
    ),
  })

export const saveClientPresentation = hash => (dispatch, getState) =>
  dispatch({
    type: SAVE_CLIENT_PRESENTATION.ACTION,
    payload: presentationService.saveClientPresentation(getState().user.get('auth_token'))(hash),
  })
