import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class VisibilitySensor extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    intervalCheck: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    intervalDelay: PropTypes.number,
    refName: PropTypes.string,
  }

  static defaultProps = {
    onChange: () => {},
    intervalCheck: true,
    intervalDelay: 100,
    refName: 'sensor',
  }

  constructor(props) {
    super(props)

    this.state = {
      isVisible: false,
      ref: undefined,
      refName: props.refName,
    }
  }

  componentDidMount() {
    this.startWatching()
  }

  componentWillUnmount() {
    this.stopWatching()
  }

  setRef = ref => {
    this.setState({
      ref,
    })
  }

  startWatching = () => {
    this.interval = setInterval(this.check, this.props.intervalDelay)
  }

  stopWatching = () => {
    this.interval = clearInterval(this.interval)
  }

  check = () => {
    const el = this.state.ref
    // if the component has rendered to null, dont update visibility
    if (!el) {
      return this.state
    }

    const rect = el.parentNode.getBoundingClientRect()

    const containmentRect = {
      top: 0,
      left: 0,
      bottom: window.innerHeight || document.documentElement.clientHeight,
      right: window.innerWidth || document.documentElement.clientWidth,
    }

    const isVisible = rect.top <= containmentRect.bottom && rect.bottom >= containmentRect.top

    let { state } = this
    // notify the parent when the value changes
    if (this.state.isVisible !== isVisible) {
      state = {
        isVisible,
      }
      this.setState(state)
      if (this.props.onChange) this.props.onChange(isVisible, this.state.refName)
    }

    return state
  }

  render() {
    return <span ref={this.setRef} />
  }
}

export default VisibilitySensor
