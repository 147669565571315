import { Map, List } from 'immutable'

import { SUBSCRIPTION_STATUS, USER_ROLES, USER_COMPANY_STATUS } from '_constants/user'

import {
  LOGIN_GOOGLE,
  LOGIN_FACEBOOK,
  UNLINK_SOCIAL_ACCOUNT,
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_PICTURE,
  UPDATE_USER_LOGO,
  GET_REFERRAL_ROCK_CODE,
  GET_USER_ROLE,
  SIGN_UP_EMAIL,
  CHANGE_PASSWORD,
  AUTH_LOGIN,
  CREATE_TEAM_PAYMENT,
  GET_DONE_FOR_YOU_CHECKOUT_PAGE,
} from './actions'

export const userSelector = state => state.user

export const userIdSelector = state => state.user.get('id')

export const authTokenSelector = state => state.user.get('auth_token')

export const isLoggedUserSelector = state => !!state.user.get('auth_token')

export const userCompaniesSelector = state => state.user.get('companies', List())

export const loginErrorSelector = state => state.error.get(AUTH_LOGIN.ACTION, Map())
export const isLoginLoadingSelector = state => !!state.loading.get(AUTH_LOGIN.ACTION)

export const linkFacebookAccountErrorSelector = state =>
  state.error.get(LOGIN_FACEBOOK.ACTION, Map())
export const isFacebookAccountLoadingSelector = state => !!state.loading.get(LOGIN_FACEBOOK.ACTION)

export const linkGoogleAccountErrorSelector = state => state.error.get(LOGIN_GOOGLE.ACTION, Map())
export const isGoogleAccountLoadingSelector = state => !!state.loading.get(LOGIN_GOOGLE.ACTION)

export const isSignupLoadingSelector = state => !!state.loading.get(SIGN_UP_EMAIL.ACTION)
export const unlinkSocialAccountErrorSelector = state =>
  state.error.get(UNLINK_SOCIAL_ACCOUNT.ACTION, Map())
export const unlinkSocialAccountLoadingSelector = state =>
  !!state.loading.get(UNLINK_SOCIAL_ACCOUNT.ACTION)

export const signUpSelector = state => state.error.get(SIGN_UP_EMAIL.ACTION, Map())

export const userHasBillingProblemSelector = state =>
  state.user.get('subscription_status') === SUBSCRIPTION_STATUS.PAST_DUE

export const isFreeUserSelector = state => state.user.get('role') === USER_ROLES.FREE_AGENT

export const isTrialActiveSelector = state => !!state.user.get('is_trial_active')

export const isFreeSelector = state =>
  !state.user.get('is_trial_active') && state.user.get('role') === USER_ROLES.FREE_AGENT

export const userHasCompaniesSelector = state =>
  state.user.get('companies') && !!state.user.get('companies').size

export const userIsAdminSelector = state =>
  state.user.get('companies', List()).filter(company => company.get('is_admin')).size > 0

export const hasNewReceivedPresentations = state => state.user.get('has_new_received_presentation')

export const hasNewTemplatePresentations = state => state.user.get('has_new_presentation_template')

export const getUserLoadingSelector = state => state.loading.get(GET_USER.ACTION)

export const updateUserLoadingSelector = state => state.loading.get(UPDATE_USER.ACTION)

export const updateUserErrorSelector = state => state.error.get(UPDATE_USER.ACTION, Map())

export const updateUserPictureLoadingSelector = state =>
  state.loading.get(UPDATE_USER_PICTURE.ACTION)

export const updateUserLogoLoadingSelector = state => state.loading.get(UPDATE_USER_LOGO.ACTION)

export const updateUserLogoErrorSelector = state => state.error.get(UPDATE_USER_LOGO.ACTION, Map())

export const getReferralRockCodeLoadingSelector = state =>
  !!state.loading.get(GET_REFERRAL_ROCK_CODE.ACTION)

export const getReferralRockCodeSelector = state => state.user.get('authUrl')

export const getUserRoleSelector = state => state.user.get('userRole')
export const isGetUserRoleLoadingSelector = state => state.loading.get(GET_USER_ROLE.ACTION)
export const getUserRoleErrorSelector = state => state.error.get(GET_USER_ROLE.ACTION, Map())

export const changePasswordErrorsSelector = state => state.error.get(CHANGE_PASSWORD.ACTION)

export const isLoadingUserSelector = state => !!state.loading.get(GET_USER.ACTION)

export const isLoadingGetCheckoutUrlSelector = state =>
  !!state.loading.get(CREATE_TEAM_PAYMENT.ACTION)
export const getCheckoutUrlSelector = state => state.user.get('checkoutUrl')

export const getStripePortalUrlSelector = state => state.user.get('stripePortalUrl')
export const isLoadingHideInFoldersSelector = state => state.user.get('isLoadingHideInFolder')

export const hasAccessToBillingSelector = state =>
  state.user
    .get('companies', List())
    .filter(company => company.get('is_admin') && company.get('has_dashboard_access')).size > 0

export const getDoneForYouCheckoutUrlSelector = state => state.user.get('doneForYouCheckoutUrl')

export const isLoadingGetDoneForYouCheckoutUrlSelector = state =>
  state.loading.get(GET_DONE_FOR_YOU_CHECKOUT_PAGE.ACTION)

export const autoSummarizeAssetsSelector = state => state.user.get('auto_summarize_assets')
export const isAdminSelector = state =>
  state.user.get('companies', List()).filter(company => company.get('is_admin')).size > 0

export const isCompanyPaysSelector = state =>
  state.user.get('companies', List()).find(company => company.get('is_company_pays'))

export const companyInviteSelector = state =>
  state.user.get('company_invites') && state.user.get('company_invites', List()).first()

export const getUserCompanyStatusSelector = state => {
  if (state.user.get('companies', List()).size === 0) {
    return USER_COMPANY_STATUS.INDIVIDUAL
  }
  if (hasAccessToBillingSelector(state)) {
    return USER_COMPANY_STATUS.ADMIN
  }
  return USER_COMPANY_STATUS.TEAM
}

export const hideSummarizerModalSelector = state => state.user.get('hide_summarizer_modal')
export const hideInFoldersSelector = state => state.user.get('hide_in_folders')
