import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Stars from '_assets/backgrounds/stars.png'

import styles from './styles.css'

export const TESTIMONIAL_POSITION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

const Testimonial = ({
  testimonial,
  author,
  image,
  imageWidth,
  imageHeight,
  position,
  className,
  classNameImage,
  classNameTestimonial,
}) => (
  <div className={classnames(styles[`container-${position}`], className)}>
    {image && (
      <div
        className={classnames(styles['container-image'], classNameImage)}
        style={{ backgroundImage: `url(${image})`, width: imageWidth, height: imageHeight }}
      />
    )}
    <div className={styles['container-testimonial']}>
      <img src={Stars} className={styles['testimonial-stars']} alt="stars" />
      <p className={classnames(styles['testimonial-title'], classNameTestimonial)}>{testimonial}</p>
      <p className={styles['testimonial-subtitle']}>-{author}</p>
    </div>
  </div>
)
Testimonial.propTypes = {
  testimonial: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageWidth: PropTypes.string.isRequired,
  imageHeight: PropTypes.string.isRequired,
  position: PropTypes.oneOf([TESTIMONIAL_POSITION.HORIZONTAL, TESTIMONIAL_POSITION.VERTICAL]),
  className: PropTypes.string,
  classNameImage: PropTypes.string,
  classNameTestimonial: PropTypes.string,
}

Testimonial.defaultProps = {
  image: '',
  position: TESTIMONIAL_POSITION.HORIZONTAL,
  className: '',
  classNameImage: '',
  classNameTestimonial: '',
}
export default Testimonial
