import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import Reload from '_assets/icons/reload.svg'
import Svg from '_components/svg'

import styles from './styles.css'

export const OPACITY_DEFAULT_VALUE = 0.6

const OpacityChanger = ({
  cover,
  backgroundColor,
  toggleOpacityChange,
  handleOpacitySave,
  className,
  opacity,
  setOpacity,
}) => {
  const onSliderChange = useCallback(
    event => {
      const { value } = event.target

      setOpacity(value)
    },
    [setOpacity]
  )

  const handleClick = useCallback(() => setOpacity(OPACITY_DEFAULT_VALUE), [setOpacity])

  const saveOpacity = useCallback(() => {
    if (handleOpacitySave) {
      handleOpacitySave(opacity)
    }
  }, [handleOpacitySave, opacity])

  const handleCancel = useCallback(() => {
    if (toggleOpacityChange) {
      toggleOpacityChange()
    }
  }, [toggleOpacityChange])

  const coverStyle = useMemo(() => {
    if (cover) {
      const whiteOpacity = Number(opacity) - 0.4 < 0 ? 0 : Number(opacity) - 0.4

      return {
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0,${Number(
          opacity
        )}) 0%, rgba(0, 0, 0,${whiteOpacity}) 134.8%),url(${cover})`,
      }
    }

    return {
      backgroundColor,
    }
  }, [backgroundColor, cover, opacity])

  return (
    <div className={classnames(styles.container, className)} style={coverStyle}>
      <input
        type="range"
        min="0"
        max="1"
        step="0.1"
        value={opacity}
        onChange={onSliderChange}
        className={styles.bar}
      />
      <div className={styles.controls}>
        <Button
          size={BUTTON_SIZE.SMALL}
          theme={BUTTON_THEME.TRANSPARENT_FILL}
          className={styles['cancel-button']}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          size={BUTTON_SIZE.SMALL}
          theme={BUTTON_THEME.TRANSPARENT_FILL}
          className={styles['cancel-button']}
          onClick={handleClick}
        >
          <Svg icon={Reload} className={styles['reload-icon']} />
        </Button>
        <Button
          theme={BUTTON_THEME.PRIMARY}
          size={BUTTON_SIZE.SMALL}
          className={styles['confirm-button']}
          onClick={saveOpacity}
        >
          Change
        </Button>
      </div>
    </div>
  )
}

OpacityChanger.propTypes = {
  cover: PropTypes.string,
  backgroundColor: PropTypes.string,
  toggleOpacityChange: PropTypes.func.isRequired,
  handleOpacitySave: PropTypes.func.isRequired,
  className: PropTypes.string,
  opacity: PropTypes.string.isRequired,
  setOpacity: PropTypes.func.isRequired,
}
OpacityChanger.defaultProps = {
  cover: undefined,
  backgroundColor: undefined,
  className: '',
}

export default React.memo(OpacityChanger)
