import React from 'react'
import { render } from 'react-dom'
import { Router, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import 'regenerator-runtime/runtime'
import 'array-flat-polyfill'
import * as Sentry from '@sentry/browser'
import { install } from 'ga-gtag'

import Toast from '_components/toast'
import {
  GA_ID,
  GA_ENABLED,
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_RELEASE,
  GTAG_ID,
} from '_config/environment'

import './bootstrap'
import configureStore from './store/configure-store'
import routes from './routes'

const store = configureStore()
const history = syncHistoryWithStore(browserHistory, store)

// Initialize Google Analytics
if (GA_ENABLED) {
  ReactGA.initialize(GA_ID)
}

if (GTAG_ID) {
  install(GTAG_ID)
}

const fireTracking = () => {
  if (GA_ENABLED) {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    ignoreErrors: [
      'missing formal parameter (https://app.termly.io/',
      'Termly resource blocker has already been loaded on this page.',
      'us.i.posthog.com',
    ],
    normalizeDepth: 10,
  })
}

const Root = () => (
  <Provider store={store}>
    <Router onUpdate={fireTracking} history={history} routes={routes(store)} />
    <Toast />
  </Provider>
)

render(<Root />, document.getElementById('root'))
