import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

const Tag = ({ className, children, textClassName }) => (
  <div className={classnames(styles.label, className)}>
    <p className={classnames(styles.text, textClassName)}>{children}</p>
  </div>
)

Tag.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  textClassName: PropTypes.string,
}

Tag.defaultProps = {
  className: '',
  textClassName: '',
}

export default React.memo(Tag)
