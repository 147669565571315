import humps from 'humps'

import { get, post } from './request'

export const addCreditCard = key => payload =>
  post(['user', 'cards'], { key }, humps.decamelizeKeys(payload))

export const startSubscription = key => payload =>
  post(['user', 'start-subscription'], { key }, humps.decamelizeKeys(payload))

export const validateCouponCode = key => payload =>
  get(['coupon'], { key, params: humps.decamelizeKeys(payload) })

export const getPlanById = () => payload => get(['plan'], { params: humps.decamelizeKeys(payload) })

export const getCreditCard = key => get(['user', 'cards'], { key })

export const cancelSubscription = key => post(['user', 'cancel-subscription'], { key })

export const changeSubscription = key => payload =>
  post(['user', 'change-subscription'], { key }, humps.decamelizeKeys(payload))
