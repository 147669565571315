import React, { Fragment, useCallback, useState } from 'react'
import classnames from 'classnames'
import { Waypoint } from 'react-waypoint'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'

import { formatNotificationDate } from '_utils/format-date'
import { PRESENTATION_EMPTY_TITLE, ASSET_EMPTY_TITLE } from '_constants/presentation'
import AvatarPlaceholder from '_assets/avatar@3x.png'
import ConfettiGuideNotificationIcon from '_assets/icons/confetti-guide-notification.png'
import PlayVideoNotificationIcon from '_assets/icons/play-video-notification.png'
import IndividualLinkModal from '_components/share-link-modal/individual-link'
import PublicLinkModal from '_components/share-link-modal/public-link'
import ShareEmailModalNew from '_components/share-email-modal'
import { QUICK_GUIDE_URL, ONBOARDING_VIDEO_URL } from '_constants/onboarding'
import {
  sharePresentationThroughEmail,
  SHARE_PRESENTATION_THROUGH_EMAIL,
} from '_modules/presentations/actions'
import { isSharingEmailLoadingSelector } from '_modules/presentations/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'
import { EMOJIS_CODE } from '_components/feedback-modal'

import styles from './styles.css'

const NOTIFICATION_CATEGORY = {
  PRESENTATION_BEING_VIEWED: 'presentation_being_viewed',
  NOT_ACCESS_24H: 'presentation_not_accessed_in_24hrs',
  ASSET_BEING_VIEWED: 'asset_being_viewed',
  ASSET_PDF_BEING_VIEWED: 'asset_pdf_being_viewed',
  ASSET_VIDEO_BEING_VIEWED: 'asset_video_being_viewed',
  ASSET_FILE_BEING_VIEWED: 'asset_file_being_viewed',
  NEW_USER: 'new_user',
  NEW_USER_TEAM: 'new_user_team',
  FIRST_PRESENTATION_CREATED: 'first_presentation_created',
  FIRST_PRESENTATION_SENT: 'first_presentation_sent',
  USER_NEW_TEMPLATE: 'user_new_template',
  USER_FIRST_ANALYTICS: 'user_first_analytics',
  PRESENTATION_FORWARDED: 'presentation_forwarded',
  PRESENTATION_TEMPLATE: 'presentation_template',
  FEEDBACK_RECEIVED: 'feedback_received',
  START_GUIDE: 'start_guide',
  TUTORIAL_VIDEO: 'tutorial_video',
}

const Notification = ({
  insights,
  loadMore,
  markAsRead,
  isShareLinkModalOpen,
  isShareLinkPublicModalOpen,
  isShareEmailModalOpen,
  onToggleShareLinkModalOpen,
  onToggleShareLinkPublicModalOpen,
  onToggleShareEmailModalOpen,
}) => {
  const dispatch = useDispatch()
  const [shareLink, setShareLink] = useState()
  const isSharingEmail = useSelector(isSharingEmailLoadingSelector)

  const handleShareAgain = useCallback(
    insight => () => {
      const body = insight.get('body')
      const shareLinkData = {
        id: body.get('presentation_id'),
        hash: body.get('share_link'),
      }

      if (body.get('name') === 'Public Link') {
        setShareLink(shareLinkData)
        onToggleShareLinkPublicModalOpen()
        return
      }

      if (!body.get('email')) {
        setShareLink(shareLinkData)
        onToggleShareLinkModalOpen()
        return
      }

      setShareLink(shareLinkData)
      onToggleShareEmailModalOpen()
    },
    [onToggleShareEmailModalOpen, onToggleShareLinkModalOpen, onToggleShareLinkPublicModalOpen]
  )

  const onSuccessSendEmail = useCallback(() => {
    onToggleShareEmailModalOpen()
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.SUCCESS,
        message: 'Your presentation was successfully sent',
      })
    )
  }, [dispatch, onToggleShareEmailModalOpen])

  useFetchCall(SHARE_PRESENTATION_THROUGH_EMAIL, onSuccessSendEmail)

  const shareWithEmail = useCallback(
    payload => {
      dispatch(sharePresentationThroughEmail(shareLink.id, payload))
    },
    [dispatch, shareLink]
  )

  const openURL = useCallback(e => {
    const url = e.currentTarget.getAttribute('href')
    window.open(url, '_blank', 'noopener,noreferrer')
  }, [])

  const notificationDescription = useCallback(
    insight => {
      const noticationDate = formatNotificationDate(insight.get('created_at'))
      switch (insight.getIn(['body', 'category'])) {
        case NOTIFICATION_CATEGORY.PRESENTATION_BEING_VIEWED:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>{' '}
                {`viewed your presentation `}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'title'], PRESENTATION_EMPTY_TITLE)}
                </strong>
                .{' '}
                <Link to="/insights" className={styles['notification-link']}>
                  See more...
                </Link>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.ASSET_BEING_VIEWED:
        case NOTIFICATION_CATEGORY.ASSET_FILE_BEING_VIEWED:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>{' '}
                {'viewed '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'asset_title'], ASSET_EMPTY_TITLE)}
                </strong>{' '}
                {'in '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'title'], PRESENTATION_EMPTY_TITLE)}
                </strong>
                {'. '}
                <Link to="/insights" className={styles['notification-link']}>
                  See more...
                </Link>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.ASSET_PDF_BEING_VIEWED:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>{' '}
                {'viewed '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'pages_viewed'])} {'of '}
                  {insight.getIn(['body', 'total_pages'])} {'pages '}
                </strong>
                {'of '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'asset_title'], ASSET_EMPTY_TITLE)}
                </strong>{' '}
                {'in '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'title'], PRESENTATION_EMPTY_TITLE)}
                </strong>
                {'. '}
                <Link to="/insights" className={styles['notification-link']}>
                  See more...
                </Link>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.ASSET_VIDEO_BEING_VIEWED:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>{' '}
                {'watched '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'asset_title'], ASSET_EMPTY_TITLE)}
                </strong>{' '}
                {'for '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'duration'])}
                </strong>{' '}
                {'in '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'title'], PRESENTATION_EMPTY_TITLE)}
                </strong>
                {'. '}
                <Link to="/insights" className={styles['notification-link']}>
                  See more...
                </Link>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.NOT_ACCESS_24H:
          return (
            <Fragment>
              <p className={styles.description}>
                {`It looks like `}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}{' '}
                  {insight.getIn(['body', 'email']) ? `(${insight.getIn(['body', 'email'])})` : ''}
                </strong>
                {` hasn't accessed `}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'title'], PRESENTATION_EMPTY_TITLE)} {`in over 24 hours`}{' '}
                  {insight.getIn(['body', 'time_passed'])}.
                  <button
                    onClick={handleShareAgain(insight)}
                    className={styles['notification-link']}
                    type="button"
                  >
                    Share it again...
                  </button>
                </strong>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.NEW_USER:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>
                {` has joined Highnote. `}
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.NEW_USER_TEAM:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>
                {` has joined `}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'company'])}
                </strong>
                {`. `}
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.FIRST_PRESENTATION_CREATED:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>
                {` created their first presentation. `}
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.FIRST_PRESENTATION_SENT:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>
                {` has sent their first presentation. `}
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.USER_NEW_TEMPLATE:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>
                {` has added a new template. `}
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.USER_FIRST_ANALYTICS:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'name'])}
                </strong>
                {` received their first analytics. `}
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.PRESENTATION_FORWARDED:
          return (
            <Fragment>
              <p className={styles.description}>
                Your presentation{' '}
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'title'], PRESENTATION_EMPTY_TITLE)}
                </strong>{' '}
                has been forwarded.{' '}
                <Link to="/insights" className={styles['notification-link']}>
                  See more...
                </Link>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.PRESENTATION_TEMPLATE:
          return (
            <Fragment>
              <p className={styles.description}>
                <strong className={styles['strong-weight']}>
                  {insight.getIn(['body', 'title']) || 'A new template'}
                </strong>
                {` has added to your templates. `}
                <Link
                  to={`/presentations/preview/${insight.getIn(['body', 'presentation_id'])}`}
                  className={styles['notification-link']}
                >
                  See template...
                </Link>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        case NOTIFICATION_CATEGORY.FEEDBACK_RECEIVED: {
          const email = insight.getIn(['body', 'email'])
            ? `(${insight.getIn(['body', 'email'])})`
            : ''
          const emoji = insight.getIn(['body', 'emoji'])
            ? String.fromCodePoint(EMOJIS_CODE[insight.getIn(['body', 'emoji'])])
            : undefined

          return (
            <Fragment>
              <p className={styles.description}>
                {`${insight.getIn(['body', 'name'])} ${email} gave your presentation`}
                <strong className={styles['strong-weight']}>
                  {' '}
                  {insight.getIn(['body', 'title'])}{' '}
                </strong>
                feedback.
              </p>
              <div className={styles.feedback}>
                {emoji && <div className={styles.emoji}>{emoji}</div>}
                {insight.getIn(['body', 'comment']) && (
                  <p className={styles.comment}>{insight.getIn(['body', 'comment'])}</p>
                )}
              </div>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        }
        case NOTIFICATION_CATEGORY.START_GUIDE: {
          return (
            <Fragment>
              <p className={styles.description}>
                Go to our quick guide and start creating beautiful presentations.{' '}
                <a
                  href={QUICK_GUIDE_URL}
                  onClick={openURL}
                  className={styles['notification-pink-link']}
                >
                  Start guide.
                </a>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        }
        case NOTIFICATION_CATEGORY.TUTORIAL_VIDEO: {
          return (
            <Fragment>
              <p className={styles.description}>
                Learn more about what you can do with Highnote.{' '}
                <a
                  href={ONBOARDING_VIDEO_URL}
                  onClick={openURL}
                  className={styles['notification-pink-link']}
                >
                  Watch tutorial video.
                </a>
              </p>
              <p className={styles.time}>{noticationDate}</p>
            </Fragment>
          )
        }
        default:
          return <div />
      }
    },
    [handleShareAgain, openURL]
  )

  const getAvatar = insight => {
    const category = insight.getIn(['body', 'category'])
    if (category === NOTIFICATION_CATEGORY.START_GUIDE) {
      return ConfettiGuideNotificationIcon
    }
    if (category === NOTIFICATION_CATEGORY.TUTORIAL_VIDEO) {
      return PlayVideoNotificationIcon
    }
    return insight.getIn(['body', 'picture']) || AvatarPlaceholder
  }

  return (
    <Fragment>
      {insights.map((insight, index) => {
        if (index + 1 === insights.size) {
          return (
            <Waypoint onEnter={loadMore} key={insight.get('id')}>
              <li key={insight.get('id')}>
                <button
                  type="button"
                  className={classnames(styles.item, {
                    [styles.unread]: !insight.get('read'),
                  })}
                  id={insight.get('id')}
                  onClick={markAsRead}
                >
                  <div className={styles['notification-image-container']}>
                    <img
                      alt="Notification"
                      src={getAvatar(insight)}
                      className={styles['notification-image']}
                    />
                  </div>
                  <div>{notificationDescription(insight)}</div>
                </button>
              </li>
            </Waypoint>
          )
        }
        return (
          <li key={insight.get('id')}>
            <button
              type="button"
              className={classnames(styles.item, {
                [styles.unread]: !insight.get('read'),
              })}
              id={insight.get('id')}
              onClick={markAsRead}
            >
              <div className={styles['notification-image-container']}>
                <img
                  alt="Notification"
                  src={getAvatar(insight)}
                  className={styles['notification-image']}
                />
              </div>
              <div>{notificationDescription(insight)}</div>
            </button>
          </li>
        )
      })}
      {isShareLinkModalOpen && (
        <IndividualLinkModal onClose={onToggleShareLinkModalOpen} shareLink={shareLink} />
      )}
      {isShareLinkPublicModalOpen && (
        <PublicLinkModal shareLink={shareLink} onClose={onToggleShareLinkPublicModalOpen} />
      )}
      {isShareEmailModalOpen && (
        <ShareEmailModalNew
          onClose={onToggleShareEmailModalOpen}
          shareLink={shareLink}
          sendEmail={shareWithEmail}
          isSharing={isSharingEmail}
        />
      )}
    </Fragment>
  )
}

Notification.propTypes = {
  insights: PropTypes.instanceOf(List).isRequired,
  markAsRead: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  isShareLinkModalOpen: PropTypes.bool.isRequired,
  isShareLinkPublicModalOpen: PropTypes.bool.isRequired,
  isShareEmailModalOpen: PropTypes.bool.isRequired,
  onToggleShareLinkModalOpen: PropTypes.func.isRequired,
  onToggleShareLinkPublicModalOpen: PropTypes.func.isRequired,
  onToggleShareEmailModalOpen: PropTypes.func.isRequired,
}
export default React.memo(Notification)
