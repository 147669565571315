const environment = {
  API_URL: process.env.API_URL,
  HEALTH_CHECK_URL: process.env.HEALTH_CHECK_URL,
  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,
  LANDING_PAGE_URL: process.env.LANDING_PAGE_URL,
  NODE_ENV: process.env.NODE_ENV,
  STAGING: process.env.STAGING === 'true',
  UNSPLASH_ACCESS_KEY: process.env.UNSPLASH_ACCESS_KEY,
  UNSPLASH_SECRET_KEY: process.env.UNSPLASH_SECRET_KEY,
  GA_ID: process.env.GA_ID,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
  DROPBOX_APP_KEY: process.env.DROPBOX_APP_KEY,
  BOX_CLIENT_ID: process.env.BOX_CLIENT_ID,
  BOX_CLIENT_SECRET: process.env.BOX_CLIENT_SECRET,
  WSS_URL: process.env.WSS_URL,
  FB_ID: process.env.FB_ID,
  GOOGLE_LOGIN_CLIENT_ID: process.env.GOOGLE_LOGIN_CLIENT_ID,
  ONESIGNAL_APP_ID: process.env.ONESIGNAL_APP_ID,
  GOOGLE_TAG_MANAGER_KEY: process.env.GOOGLE_TAG_MANAGER_KEY,
  FACEBOOK_PIXEL_CODE_KEY: process.env.FACEBOOK_PIXEL_CODE_KEY,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_ENV: process.env.SENTRY_ENV,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE,
  SENTRY_KEY: process.env.SENTRY_KEY,
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
  FLAG_HIDE_PRESENTATION_HEADER_PREMIUM_USERS:
    process.env.FLAG_HIDE_PRESENTATION_HEADER_PREMIUM_USERS,
  FLAG_CLOUD_CONVERT_V2: process.env.FLAG_CLOUD_CONVERT_V2,
  FLAG_SHOW_LOGO_USAGE: process.env.FLAG_SHOW_LOGO_USAGE === 'true',
  FLAG_SHOW_BANNER_SAVE_SHARED_PRESENTATION:
    process.env.FLAG_SHOW_BANNER_SAVE_SHARED_PRESENTATION === 'true',
  FLAG_SHOW_PROFILE_DROPDOWN_OPTION_REFER_A_FRIEND:
    process.env.FLAG_SHOW_PROFILE_DROPDOWN_OPTION_REFER_A_FRIEND === 'true',
  FLAG_REQUIRE_NAME_IN_REGISTER: process.env.FLAG_REQUIRE_NAME_IN_REGISTER === 'true',
  FLAG_SHOW_PLAN_CANCEL_FLOW: process.env.FLAG_SHOW_PLAN_CANCEL_FLOW === 'true',
  FLAG_SHOW_CREATE_TEAM_FLOW: process.env.FLAG_SHOW_CREATE_TEAM_FLOW === 'true',
  FLAG_SHOW_REDIRECT_BILLING_ADMIN: process.env.FLAG_SHOW_REDIRECT_BILLING_ADMIN === 'true',
  FLAG_SHOW_FACEBOOK_BUTTON: process.env.FLAG_SHOW_FACEBOOK_BUTTON === 'true',
  FLAG_SHOW_GOOGLE_BUTTON: process.env.FLAG_SHOW_GOOGLE_BUTTON === 'true',
  STRIPE_COUPON: process.env.STRIPE_COUPON,
  ADMIN_PAGE_URL: process.env.ADMIN_PAGE_URL,
  LAND_TRIAL_URL: process.env.LAND_TRIAL_URL,
  GTAG_SEND_TO: process.env.GTAG_SEND_TO,
  GTAG_ID: process.env.GTAG_ID,
  LOGO_URL: process.env.LOGO_URL,
  PUBLIC_POSTHOG_KEY: process.env.PUBLIC_POSTHOG_KEY,
  PUBLIC_POSTHOG_HOST: process.env.PUBLIC_POSTHOG_HOST,
  FLAG_SHOW_AUTO_REMINDERS: process.env.FLAG_SHOW_AUTO_REMINDERS === 'true',
  FLAG_SHOW_HIGHNOTE_AI_BUTTON: process.env.FLAG_SHOW_HIGHNOTE_AI_BUTTON === 'true',
  GOOGLE_RECAPTCHA_SECRET_KEY: process.env.GOOGLE_RECAPTCHA_SECRET_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
  FLAG_NEW_TEMPLATE_MODAL: process.env.FLAG_NEW_TEMPLATE_MODAL === 'true',
  FLAG_ACTIVE_SUMMARIZER: process.env.FLAG_ACTIVE_SUMMARIZER === 'true',
  FLAG_SHOW_DROPDOWN_CATEGORY: process.env.FLAG_SHOW_DROPDOWN_CATEGORY === 'true',
  FLAG_SHOW_ENHANCE_AI_BUTTON: process.env.FLAG_SHOW_ENHANCE_AI_BUTTON === 'true',
  FLAG_SHOW_ARCHIVE_FEATURE: process.env.FLAG_SHOW_ARCHIVE_FEATURE === 'true',
}

environment.GA_ENABLED = environment.NODE_ENV === 'production' && environment.STAGING === 'false'

module.exports = environment
