import { Map } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'

import { OPEN_ALERT, CLOSE_ALERT } from './actions'

const INITIAL_STATE = Map({
  onClick: null,
  onSuccessClick: () => {},
  content: null,
  buttonLabel: '',
  successLabel: '',
  isOpen: false,
  timer: null,
  type: 0,
  recipient: '',
  message: '',
})

export const alert = createReducer(INITIAL_STATE, {
  [OPEN_ALERT]: (state, { payload }) => Map({ ...INITIAL_STATE.toJS(), ...payload }),

  [CLOSE_ALERT]: () => INITIAL_STATE,
})
