import { STAGING } from '_config/environment'

export const PRODUCTION_URL = 'https://app.highnote.io'
export const STAGING_URL = 'https://dev-app.highnote.io'
export const HELP_URL = 'https://help.highnote.io'
export const HIGHNOTE_DONE_FOR_YOU_HELPER = `https://land.highnote.io/dfy-sales`

const API_WORDPRESS_URL_STAGING = 'https://highnoterebstg.wpengine.com/wp-json/get'
const API_WORDPRESS_URL_PRODUCTION = 'https://highnote.io/wp-json/get'
export const API_WORDPRESS_VERSION = {
  V1: 'v1',
  V2: 'v2',
}

export const DEFAULT_IMAGE_THUMB = 'https://cupcake-statics.s3.us-west-1.amazonaws.com/5.png'

export const getAPIWordpressURL = (version = API_WORDPRESS_VERSION.V1) => {
  if (STAGING) {
    return `${API_WORDPRESS_URL_STAGING}/${API_WORDPRESS_VERSION.V2}`
  }

  return `${API_WORDPRESS_URL_PRODUCTION}/${version}`
}

const getCookieDomain = () => {
  const location = typeof window !== 'undefined' && window.location && window.location.hostname

  if (location) {
    return location.includes('localhost') ? 'localhost' : '.highnote.io'
  }

  return '.highnote.io'
}

export const COOKIES_OPTIONS = {
  path: '/',
  sameSite: 'lax',
  domain: getCookieDomain(),
  maxAge: 28800,
}

export const cookieUser = user => ({
  id: user.id,
  first_name: user.first_name,
  last_name: user.last_name,
  picture: user.picture,
  is_trial_active: user.is_trial_active,
  role: user.role,
  subscription_status: user.subscription_status,
  companies: user.companies,
})

export const cookieAuth = () => {
  if (STAGING) {
    return 'auth_token_staging'
  }
  return 'auth_token'
}

export const PATHS = {
  ANALYTICS_PATHNAME: '/insights',
  PRESENTATIONS_PATHNAME: {
    BASE: '/presentations',
    FOLDERS: 'folders',
    FAVORITES: 'favorites',
    RECEIVED: 'received',
    TEMPLATE: 'templates',
    ARCHIVED: 'archived',
    EDIT: 'edit',
  },
  EDIT_PROFILE_PATHNAME: '/edit-profile',
  TRIAL_EXPIRED: '/trial-expired',
  NOT_FOUND: '/404',
  PAYMENT_PROBLEM: '/payment-problem',
  ACCOUNT_SETTINGS_PATH_NAMES: {
    BASE: '/settings',
    EDIT_PROFILE_PATH_NAME: 'edit-profile',
    PASSWORD_PATH_NAME: 'password',
    BILLING_PATH_NAME: 'billing',
    NOTIFICATIONS_PATH_NAME: 'notifications',
    PREFERENCES_PATH_NAME: 'preferences',
  },
}

export const SUBSCRIPTION_PLAN_INTERVAL = {
  MONTH: 'month',
  YEAR: 'year',
}

export const OPERATING_SYSTEM = {
  PLATFORM_ANDROID: 'android',
  PLATFORM_IOS: 'ios',
  PLATFORM_WIN_PHONE: 'win_phone',
  PLATFORM_WINDOWS: 'windows',
  PLATFORM_MAC: 'mac',
  PLATFORM_LINUX: 'linux',
}

export const BROWSERS = {
  CHROME: 'chrome',
  SAFARI: 'safari',
}

export const TYPE_OF = {
  BOOLEAN: 'boolean',
}

export const PLANS_NAMES = {
  CORE_MONTHLY: 'core_monthly',
  CORE_YEARLY: 'core_yearly',
  TIERED_MONTHLY: 'tiered_monthly',
  TIERED_YEARLY: 'tiered_yearly',
}

export const PLANS_NAMES_KEYS = {
  CORE_MONTHLY: 'coreMonthly',
  CORE_YEARLY: 'coreYearly',
  TIERED_MONTHLY: 'tieredMonthly',
  TIERED_YEARLY: 'tieredYearly',
}

export const TIERED_PLAN_OPTIONS = [
  {
    label: 'Monthly',
    value: PLANS_NAMES_KEYS.TIERED_MONTHLY,
  },
  {
    label: 'Yearly',
    value: PLANS_NAMES_KEYS.TIERED_YEARLY,
  },
]

export const PLAN_OPTIONS = [
  {
    label: 'Monthly',
    value: PLANS_NAMES.CORE_MONTHLY,
  },
  {
    label: 'Yearly',
    value: PLANS_NAMES.CORE_YEARLY,
  },
]

export const NO_PICTURE = 'no_picture'

export const GIFT_VALUE = '$20'
