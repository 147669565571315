import React from 'react'
import PropTypes from 'prop-types'
import { Menu as ReachMenu, MenuButton } from '@reach/menu-button'
import classnames from 'classnames'

import Menu from '_components/menu'
import Svg from '_components/svg'

import styles from './styles.css'

const MoreActions = ({ icon, title, options, className, children, menuListClassName }) => (
  <ReachMenu>
    <MenuButton
      aria-label="More actions"
      className={classnames(className, styles['more-actions-wrapper'])}
    >
      {children || (
        <span title={title} className={styles['edit-button']}>
          <Svg icon={icon} className={styles['more-actions-icon']} />
        </span>
      )}
    </MenuButton>
    <Menu
      className={styles['more-actions-options']}
      MenuLabel="More actions options"
      options={options}
      menuListClassName={menuListClassName}
    />
  </ReachMenu>
)

MoreActions.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  menuListClassName: PropTypes.string,
}

MoreActions.defaultProps = {
  title: '',
  className: '',
  children: undefined,
  icon: undefined,
  menuListClassName: '',
}

export default React.memo(MoreActions)
