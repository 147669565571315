import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { WSS_URL } from '_config/environment'
import { appendNotification } from '_modules/insights/actions'
import {
  updateAssetTitleAndDescription,
  updateAssetSummarizerProgress,
  updateAssetConversionPercentageUploadSystemV2,
  clearProgress,
  setAssetConvertThumbnailFailed,
} from '_modules/assets/actions'
import { updateCoverConversionPercentage } from '_modules/presentations/actions'
import { updateReducerUser } from '_modules/user/actions'
import { checkIfIsAllowedUser } from '_utils/user'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'

const mapDispatchToProps = {
  appendNotification,
  updateReducerUser,
  updateCoverConversionPercentage,
  updateAssetTitleAndDescription,
  openToasterAlert,
  updateAssetSummarizerProgress,
  updateAssetConversionPercentageUploadSystemV2,
  clearProgress,
  setAssetConvertThumbnailFailed,
}

const mapStateToProps = ({ user, assets }) => ({
  authToken: user.get('auth_token'),
  isAllowedUser: checkIfIsAllowedUser(user),
  assets,
})

const TYPE = {
  ASSET_UPLOAD_PERCENTAGE: 'asset_upload_percentage',
  NOTIFICATION: 'notification',
  ROLE_CHANGE: 'role_change',
  HAS_NEW_RECEIVED_PRESENTATION: 'received_presentation',
  PRESENTATION_UPLOAD_PERCENTAGE: 'presentation_upload_percentage',
  PRESENTATION_TEMPLATE: 'presentation_template',
  UPDATE_CAN_REFER_A_FRIEND: 'update_can_refer_a_friend',
  UPDATE_ASSET_TITLE_AND_DESCRIPTION: 'update_asset_title_and_description',
  ASSET_SUMMARIZER_PROGRESS: 'asset_summarizer_progress',
  ASSET_CONVERT_FAILED: 'asset_convert_failed',
}

const WEBSOCKET_READY_STATE = {
  CONNECTING: 0,
  OPEN: 1,
}

const UNWANTED_NOTIFICATION_CATEGORY = 'presentation_24hrs_views'

class SocketProvider extends PureComponent {
  static propTypes = {
    appendNotification: PropTypes.func.isRequired,
    authToken: PropTypes.string,
    children: PropTypes.node.isRequired,
    updateReducerUser: PropTypes.func.isRequired,
    updateCoverConversionPercentage: PropTypes.func.isRequired,
    updateAssetTitleAndDescription: PropTypes.func.isRequired,
    openToasterAlert: PropTypes.func.isRequired,
    updateAssetSummarizerProgress: PropTypes.func.isRequired,
    assets: PropTypes.instanceOf(Map),
    updateAssetConversionPercentageUploadSystemV2: PropTypes.func.isRequired,
    clearProgress: PropTypes.func.isRequired,
    setAssetConvertThumbnailFailed: PropTypes.func.isRequired,
  }

  static defaultProps = {
    authToken: undefined,
    assets: Map(),
  }

  componentDidMount() {
    this.open()
  }

  componentDidUpdate(prevProps) {
    const { authToken: prevAuthToken } = prevProps
    const { authToken } = this.props

    if (prevAuthToken === authToken) {
      return
    }

    if (authToken) {
      this.open()
    } else {
      this.close()
    }
  }

  componentWillUnmount() {
    this.close()
  }

  onClose = () => {
    this.socket.removeEventListener('close', this.onClose)
    this.socket.removeEventListener('error', this.onError)
    this.socket.removeEventListener('open', this.onOpen)
    this.socket.removeEventListener('message', this.onMessage)

    this.reconnectIntervalId = setInterval(() => {
      this.open()
    }, 5000)
  }

  onError = () => {
    this.socket.close()
  }

  onMessage = message => {
    const payload = JSON.parse(message.data)

    switch (payload.type) {
      case TYPE.ASSET_UPLOAD_PERCENTAGE: {
        this.props.updateAssetConversionPercentageUploadSystemV2({
          assetId: payload.asset_id,
          percentage: payload.percentage,
          presentationId: payload.presentation_id,
          extra: payload.extra,
        })
        break
      }
      case TYPE.NOTIFICATION:
        if (payload && payload.body && payload.body.category === UNWANTED_NOTIFICATION_CATEGORY) {
          return
        }
        this.props.appendNotification(payload)
        break
      case TYPE.ROLE_CHANGE:
        this.props.updateReducerUser({ role: payload.role })
        break
      case TYPE.HAS_NEW_RECEIVED_PRESENTATION:
        this.props.updateReducerUser({ has_new_received_presentation: true })
        break
      case TYPE.PRESENTATION_TEMPLATE:
        this.props.updateReducerUser({ has_new_presentation_template: true })
        break

      case TYPE.PRESENTATION_UPLOAD_PERCENTAGE:
        this.props.updateCoverConversionPercentage({
          percentage: payload.percentage,
          presentationId: payload.presentation_id,
        })
        break

      case TYPE.UPDATE_CAN_REFER_A_FRIEND:
        this.props.updateReducerUser({ can_refer_a_friend: true })
        break

      case TYPE.UPDATE_ASSET_TITLE_AND_DESCRIPTION: {
        const { assets } = this.props

        if (payload.is_error) {
          this.props.clearProgress(payload.asset.id)
          this.props.openToasterAlert({
            type: ALERT_TYPE.ERROR,
            message: 'Oops! Looks like there was a glitch in our AI processing',
          })
          break
        }

        const asset = assets.getIn(['assets', String(payload.asset.id)])
        if (!asset || asset.get('ignoreAssetSummarizer')) {
          break
        }

        this.props.updateAssetTitleAndDescription({
          asset: payload.asset,
          presentationId: payload.presentation_id,
        })
        break
      }
      case TYPE.ASSET_SUMMARIZER_PROGRESS: {
        const { assets } = this.props
        const asset = assets.getIn(['assets', String(payload.asset_id)])
        if (!asset || asset.get('ignoreAssetSummarizer')) {
          break
        }

        this.props.updateAssetSummarizerProgress({
          step: payload.step,
          assetId: payload.asset_id,
        })
        break
      }
      case TYPE.ASSET_CONVERT_FAILED: {
        this.props.setAssetConvertThumbnailFailed({
          assetId: payload.asset_id,
          presentationId: payload.presentation_id,
        })
        this.props.openToasterAlert({
          type: ALERT_TYPE.ERROR,
          message: 'Sorry, we were unable to process your file. Please try again.',
        })
        break
      }

      default:
        break
    }
  }

  onOpen = () => {}

  close = () => {
    if (this.socket) {
      this.socket.close()
    }
  }

  open = () => {
    const { authToken } = this.props

    // Don't open the websocket if there isn't an auth token to send
    if (!authToken) {
      return
    }

    // If there is an open or soon to be open websocket, don't create another
    if (
      this.socket &&
      (this.socket.readyState === WEBSOCKET_READY_STATE.CONNECTING ||
        this.socket.readyState === WEBSOCKET_READY_STATE.OPEN)
    ) {
      return
    }

    // Stop trying to reconnect the websocket, since it is going to create a new connection
    clearInterval(this.reconnectIntervalId)
    this.socket = new WebSocket(`${WSS_URL}/?token=${authToken}`)

    if (this.socket) {
      this.socket.addEventListener('close', this.onClose)
      this.socket.addEventListener('error', this.onError)
      this.socket.addEventListener('open', this.onOpen)
      this.socket.addEventListener('message', this.onMessage)
    }
  }

  render() {
    return this.props.children
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocketProvider)
