import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UpgradeArrowIcon from '_assets/icons/upgrade-arrow.svg'
import Tag from '_components/ui-kit/tag'

import styles from './styles.css'

const Label = ({ name, isFreeUser, className }) => (
  <div className={classnames(styles['label-content'], className)}>
    {name}
    {isFreeUser && (
      <Tag className={styles.tag} name="UPGRADE" icon={UpgradeArrowIcon} to="/payment" />
    )}
  </div>
)

export default Label

Label.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  isFreeUser: PropTypes.bool,
}

Label.defaultProps = {
  isFreeUser: false,
  className: '',
}
