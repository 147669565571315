import React, { useMemo, Fragment, forwardRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Spinner from 'react-spinkit'

import Svg from '_components/svg'
import LoaderSpinner, { LOADER_COLOR, LOADER_SIZE } from '_components/loader'

import styles from './styles.css'

// TODO: Rename those to follow styleguide
export const BUTTON_THEME = {
  DEFAULT: 'primary',
  SECONDARY: 'secondary',
  WHITE_GHOST: 'white-ghost',
  TRANSPARENT_FILL: 'transparent-fill',
  DARK: 'dark',
  DARK_BLUE: 'dark-blue',
  GRAY: 'gray',
  TRANSPARENT_BACKGROUND: 'transparent-background',
  TRANSPARENT_GRAY: 'transparent-gray',
  TRANSPARENT_BACKGROUND_PINK_TEXT: 'transparent-background-pink-text',
}

export const BUTTON_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
}

const LOADER_THEME_COLOR = {
  [BUTTON_THEME.PINK_WITH_BORDER]: LOADER_COLOR.PINK,
  [BUTTON_THEME.SECONDARY]: LOADER_COLOR.NAVY,
  [BUTTON_THEME.GRAY_NO_BORDER]: LOADER_COLOR.GRAY,
  [BUTTON_THEME.PINK_NO_BORDER]: LOADER_COLOR.PINK,
  [BUTTON_THEME.TRANSPARENT_BACKGROUND]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.PINK_WHITE_BACKGROUND_NO_BORDER]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.FULL_WIDTH_PINK]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.PINK_NO_BACKGROUND_NO_BORDER]: LOADER_COLOR.BLACK,
  [BUTTON_THEME.TRANSPARENT_GRAY]: LOADER_COLOR.GRAY,
}

const ButtonRound = forwardRef(
  (
    {
      children,
      isLoading,
      startIcon,
      theme,
      size,
      className,
      type,
      to,
      href,
      startIconClassName,
      ...restProps
    },
    ref
  ) => {
    const buttonProps = useMemo(
      () => ({
        className: classnames(
          styles.button,
          styles[theme],
          styles[size],
          {
            [styles['with-start-adornment']]: !!startIcon && !isLoading,
          },

          className
        ),
        ref,
        ...restProps,
      }),
      [className, isLoading, ref, restProps, size, startIcon, theme]
    )

    const spinnerColor = useMemo(() => {
      switch (theme) {
        case BUTTON_THEME.SECONDARY:
          return '#34495e'
        default:
          return '#FFFFFF'
      }
    }, [theme])

    const renderChildren = useMemo(
      () => (
        <Fragment>
          {isLoading ? (
            <div className={styles.loading}>
              <Spinner
                name="circle"
                color={spinnerColor}
                fadeIn="none"
                className={styles['spinner-div']}
              />
            </div>
          ) : (
            <Fragment>
              {startIcon && (
                <Svg
                  className={classnames(styles['start-icon'], startIconClassName)}
                  icon={startIcon}
                />
              )}
              {children}
            </Fragment>
          )}
        </Fragment>
      ),
      [children, isLoading, spinnerColor, startIcon, startIconClassName]
    )

    if (to) {
      return (
        <Link to={to} {...buttonProps}>
          {renderChildren}
        </Link>
      )
    }

    if (href) {
      return (
        <a href={href} {...buttonProps}>
          {renderChildren}
        </a>
      )
    }

    return (
      // eslint-disable-next-line react/button-has-type
      <button type={type} {...buttonProps}>
        {isLoading ? (
          <LoaderSpinner
            color={LOADER_THEME_COLOR[theme] || LOADER_COLOR.WHITE}
            size={LOADER_SIZE.BUTTON}
          />
        ) : (
          renderChildren
        )}
      </button>
    )
  }
)
ButtonRound.propTypes = {
  children: PropTypes.node.isRequired,
  startIcon: PropTypes.node,
  startIconClassName: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(BUTTON_THEME)),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE)),
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  isLoading: PropTypes.bool,
}
ButtonRound.defaultProps = {
  startIcon: null,
  startIconClassName: null,
  type: 'button',
  theme: BUTTON_THEME.DEFAULT,
  size: BUTTON_SIZE.MEDIUM,
  className: '',
  to: '',
  href: '',
  isLoading: false,
}
export default React.memo(ButtonRound)
