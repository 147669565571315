import { Map, fromJS } from 'immutable'
import humps from 'humps'

import { createReducer } from '_privateDependencies/react-utils/utils'
import { GET_ANNOUNCEMENTS } from '_modules/announcements/actions'

const INITIAL_STATE = Map({})

export default createReducer(INITIAL_STATE, {
  [GET_ANNOUNCEMENTS.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)

    return state.merge(fromJS(camelizedPayload))
  },
})
