import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactPlayer from 'react-player'
import { useWindowSize } from '@reach/window-size'

import styles from './styles.css'

const REACT_PLAYER_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  objectFit: 'cover',
  opacity: 1,
}
const REACT_PLAYER_CONFIG = {
  vimeo: {
    playerOptions: {
      autoplay: true,
      background: true,
      dnt: true,
      loop: true,
      muted: true,
    },
  },
}
const ASPECT_RATIO_WIDTH = 16
const ASPECT_RATIO_HEIGHT = 9
const ASPECT_RATIO = ASPECT_RATIO_WIDTH / ASPECT_RATIO_HEIGHT

const VideoPlayer = ({ backgroundVideoUrl }) => {
  const { width, height } = useWindowSize()
  const [calcWidth, setCalcWidth] = useState(null)

  useEffect(() => {
    const newWidth = height * ASPECT_RATIO
    setCalcWidth(width < newWidth ? newWidth : width)
  }, [width, height])

  return (
    <div className={classnames(styles['cover-video-wrapper'])}>
      <div
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <ReactPlayer
          url={backgroundVideoUrl}
          loop
          playing
          volume={0}
          muted
          height={(calcWidth / ASPECT_RATIO_WIDTH) * ASPECT_RATIO_HEIGHT}
          width={calcWidth}
          style={REACT_PLAYER_STYLE}
          config={REACT_PLAYER_CONFIG}
          playsinline
        />
      </div>
    </div>
  )
}

VideoPlayer.propTypes = {
  backgroundVideoUrl: PropTypes.string.isRequired,
}

export default React.memo(VideoPlayer)
