import cookies from 'react-cookies'

import { COOKIES_OPTIONS, cookieAuth } from '_utils/constants'

import defineAction from '../define-action'

export const AUTH_LOGOUT = defineAction('AUTH_LOGOUT')

export default (initialState, handlers, removeCookies = false) => {
  const newHandlers = {
    [AUTH_LOGOUT.FULFILLED]: () => {
      if (removeCookies) {
        const cookiesAuth = cookieAuth()
        cookies.remove(cookiesAuth, COOKIES_OPTIONS)
        cookies.remove('user', COOKIES_OPTIONS)
      }
      return initialState
    },
    ...handlers,
  }
  const reduxFn = (state = initialState, action) => {
    const reduceFn = newHandlers[action.type]
    return reduceFn ? reduceFn(state, action) : state
  }

  return reduxFn
}
