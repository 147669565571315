import { List } from 'immutable'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router'
import ReactPlayer from 'react-player'
import autobind from 'autobind-decorator'

import ImageViewer from '_components/image-viewer'
import PDFViewer from '_components/pdf'
import Loading from '_components/loading'
import withBrowserInformation from '_hocs/with-browser-information'
import gridStyles from '_styles/grid.css'

import styles from './styles.css'

const getFiles = memoizeOne(convertedFiles => convertedFiles.map(file => file.get('file')))

class AssetVisualizationFrame extends PureComponent {
  static propTypes = {
    convertedFiles: PropTypes.instanceOf(List),
    isInternetExplorer: PropTypes.bool.isRequired,
    thumbnail: PropTypes.string,
    assetType: PropTypes.string,
    assetLink: PropTypes.string,
    updateAssetPagesViewed: PropTypes.func,
    isLoadingAsset: PropTypes.bool,
    onLoadAsset: PropTypes.func,
    updateVideoViewDuration: PropTypes.func,
  }

  static defaultProps = {
    convertedFiles: List(),
    thumbnail: '',
    assetType: undefined,
    assetLink: undefined,
    updateAssetPagesViewed: () => {},
    isLoadingAsset: true,
    onLoadAsset: () => {},
    updateVideoViewDuration: () => {},
  }

  state = {
    lastViewedPage: 0,
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.videoPlayerRef &&
      nextProps.assetType === 'video' &&
      this.props.assetLink === nextProps.assetLink &&
      nextProps.isLoadingAsset
    ) {
      this.videoPlayerRef.seekTo(0)
      this.props.onLoadAsset()
    }
  }

  @autobind
  onVisibilityChange(isVisible, refName) {
    if (!isVisible) {
      return
    }

    if (Number(refName) <= this.state.lastViewedPage) {
      return
    }

    this.setState({ lastViewedPage: Number(refName) })

    this.props.updateAssetPagesViewed(Number(refName))
    this.props.onLoadAsset()
  }

  onProgress = ({ playedSeconds }) => {
    this.props.updateVideoViewDuration(playedSeconds)
  }

  setVideoPlayerRef = ref => {
    this.videoPlayerRef = ref
  }

  renderAsset = () => {
    const {
      assetType,
      assetLink,
      convertedFiles,
      isInternetExplorer,
      thumbnail,
      isLoadingAsset,
      onLoadAsset,
    } = this.props

    if (!convertedFiles.isEmpty() && !isInternetExplorer) {
      return (
        <ImageViewer
          images={getFiles(convertedFiles)}
          onImageVisibilityChange={this.onVisibilityChange}
        />
      )
    }

    switch (assetType) {
      case 'file':
        if (assetLink && assetLink.split('.').pop() === 'pdf') {
          return (
            <PDFViewer
              pdf={assetLink}
              onVisibilityChange={this.onVisibilityChange}
              className={classnames(styles['asset-pdf'], [
                isLoadingAsset && styles['hidden-element'],
              ])}
              onLoad={onLoadAsset}
            />
          )
        }
        if (['jpg', 'jpeg', 'tif', 'tiff', 'bmp', 'png'].includes(assetLink.split('.').pop())) {
          return (
            <ImageViewer
              images={List([assetLink])}
              onImageVisibilityChange={this.onVisibilityChange}
            />
          )
        }
        return (
          <iframe
            title="Office Document"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${assetLink}`}
            onLoad={onLoadAsset}
            className={classnames(styles['office-document'])}
          />
        )

      case 'video':
        return (
          <div
            className={classnames(styles['video-container'], [
              isLoadingAsset && styles['hidden-element'],
            ])}
          >
            <div className={styles.player}>
              <ReactPlayer
                url={assetLink}
                playing
                onProgress={this.onProgress}
                controls
                onReady={onLoadAsset}
                width="100%"
                height="100%"
                ref={this.setVideoPlayerRef}
              />
            </div>
          </div>
        )
      case 'website':
        return (
          <Link to={assetLink} target="_blank">
            <img
              src={thumbnail}
              alt="Asset Thumbnail"
              className={classnames(styles['asset-website'], [
                isLoadingAsset && styles['hidden-element'],
              ])}
              onLoad={onLoadAsset}
            />
          </Link>
        )
      default:
        return <div />
    }
  }

  render() {
    const { isLoadingAsset, assetLink } = this.props
    return (
      <div
        className={classnames(
          gridStyles.Grid,
          gridStyles['Grid--alignCenter'],
          styles['asset-container']
        )}
      >
        <div
          className={classnames(gridStyles['Grid-cell'], styles['thumb-button'])}
          name="asset-preview"
        >
          {this.renderAsset()}
        </div>
        {isLoadingAsset && assetLink && <Loading className={classnames(styles.loading)} />}
      </div>
    )
  }
}

export default withBrowserInformation(AssetVisualizationFrame)
