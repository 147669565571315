import humps from 'humps'

import { createFormData } from '_utils/helpers'

import { post, patch, generic, del, get } from './request'

export const createPresentationAsset = key => ({ presentationId, payload }) =>
  post(['presentations', presentationId, 'assets'], { key }, payload)

export const reorderPresentationAssets = key => (presentationId, idList, params) =>
  post(
    ['presentations', presentationId, 'assets-order'],
    { key, params },
    { position_list: idList }
  )

export const editPresentationAsset = key => ({ presentationId, id, payload, params }) =>
  payload.thumbnail || payload.thumbnailCropped || payload.file
    ? generic(['presentations', presentationId, 'assets', id], {
        method: 'PATCH',
        key,
        params,
        headers: {
          'Content-type': undefined,
        },
        body: createFormData(payload, true),
      })
    : patch(
        ['presentations', presentationId, 'assets', id],
        { key, params },
        humps.decamelizeKeys(payload)
      )

export const editAssetUploadSystemV2 = key => ({ presentationId, id, payload }) =>
  patch(['presentations', presentationId, 'assets', id], { key }, payload)

export const deletePresentationAsset = key => (presentationId, id) =>
  del(['presentations', presentationId, 'assets', id], { key })

export const enhanceAsset = key => (presentationId, assetId, payload) =>
  post(
    ['presentations', presentationId, 'assets', assetId, 'enhance'],
    { key },
    humps.decamelizeKeys(payload)
  )

export const assetFileUploadConvert = key => (presentationId, assetId, payload) =>
  post(['presentations', presentationId, 'assets', assetId, 'convert'], { key }, payload)

export const getAssetConvertedFile = key => (presentationId, assetId) =>
  get(['presentations', presentationId, 'assets', assetId, 'converted'], { key })

export const deleteAssetSummarizer = key => (presentationId, id) =>
  del(['presentations', presentationId, 'assets', id, 'summarizer'], { key })
