import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'

import Expand from '_assets/icons/expand.svg'
import Screenshot from '_assets/icons/screenshot.svg'
import Refresh from '_assets/icons/reload.svg'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import Loading from '_components/loading'
import Svg from '_components/svg'

import styles from './styles.css'

const CoverEdit = ({
  className,
  getHeight,
  getWidth,
  image,
  initialPosition,
  initialScale,
  onCancel,
  onSave,
  avatarEditorClassName,
  scaleControlClassName,
  opacity,
  setOpacity,
}) => {
  const [imageUrl, setImageUrl] = useState(null)
  const [position, setPosition] = useState(initialPosition)
  const [scale, setScale] = useState(initialScale)
  const canvasRef = useRef(null)
  const editorContainerRef = useRef(null)
  const hammerManagerRef = useRef(null)
  const urlRef = useRef(null)

  const onPinchIn = () => {
    setScale(prevScale => prevScale - 0.01)
  }

  const onPinchOut = () => {
    setScale(prevScale => prevScale + 0.01)
  }

  const onPositionChange = useCallback(newPosition => {
    setPosition(newPosition)
  }, [])

  const onSaveImage = useCallback(() => {
    if (!canvasRef.current) {
      return
    }

    canvasRef.current.getImageScaledToCanvas().toBlob(blob => {
      onSave({
        blob: new File([blob], 'background.png', { type: 'image/png' }),
        position,
        scale,
        opacity,
      })
    })
  }, [onSave, opacity, position, scale])

  const onScaleChange = useCallback(event => setScale(Number(event.target.value)), [])

  const onSliderChange = useCallback(
    event => {
      const { value } = event.target

      setOpacity(value)
    },
    [setOpacity]
  )

  const coverStyle = useMemo(
    () => ({
      background: `linear-gradient(180deg, rgba(0, 0, 0,${Number(
        opacity
      )}) 0%, rgba(0, 0, 0,${Number(opacity) - 0.4}) 134.8%)`,
    }),
    [opacity]
  )

  const getDimension = dimension => (typeof dimension === 'function' ? dimension() : dimension)

  const handleResetOpacityClick = useCallback(() => setOpacity(0.6), [setOpacity])

  useEffect(() => {
    fetch(image, { cache: 'no-cache' })
      .then(response => response.blob())
      .then(blob => {
        const imageElement = new Image()
        urlRef.current = URL.createObjectURL(blob)

        imageElement.onload = () => {
          if (editorContainerRef.current) {
            setImageUrl(urlRef.current)
          }
        }

        imageElement.src = urlRef.current
      })

    if (editorContainerRef.current) {
      import('hammerjs').then(Hammer => {
        hammerManagerRef.current = new Hammer.Manager(editorContainerRef.current)
        hammerManagerRef.current.add(new Hammer.Pinch({ enable: true }))

        hammerManagerRef.current.on('pinchin', onPinchIn)
        hammerManagerRef.current.on('pinchout', onPinchOut)
      })
    }

    return () => {
      if (urlRef.current) {
        URL.revokeObjectURL(urlRef.current)
      }

      if (hammerManagerRef.current) {
        hammerManagerRef.current.destroy()
      }
    }
  }, [image])

  return (
    <div className={classNames(styles['editor-container'], className)}>
      <div ref={editorContainerRef}>
        <div style={coverStyle} className={styles.overlay} />
        {imageUrl ? (
          <React.Fragment>
            <AvatarEditor
              className={avatarEditorClassName}
              border={0}
              height={getDimension(getHeight)}
              image={imageUrl}
              onPositionChange={onPositionChange}
              position={position}
              ref={canvasRef}
              scale={scale}
              width={getDimension(getWidth)}
            />
            <div className={styles.hint}>
              <Svg icon={Expand} className={styles.icon} />
            </div>
          </React.Fragment>
        ) : (
          <Loading style={{ height: getDimension(getHeight), width: getDimension(getWidth) }} />
        )}
      </div>
      <div className={styles.controls}>
        <div className={styles['opacity-control']}>
          <p className={styles.description}>Brightness:</p>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={opacity}
            onChange={onSliderChange}
            className={styles.bar}
          />
          <Button
            className={styles.button}
            onClick={handleResetOpacityClick}
            theme={BUTTON_THEME.TRANSPARENT_FILL}
            size={BUTTON_SIZE.SMALL}
          >
            <Svg className={styles['refresh-icon']} icon={Refresh} />
          </Button>
        </div>
        <div className={styles['scale-control']}>
          <p className={styles.description}>Image size:</p>
          <Svg className={styles['min-range-icon']} icon={Screenshot} />
          <input
            className={classNames(styles['scale-input'], scaleControlClassName)}
            disabled={!imageUrl}
            max="2"
            min="0.25"
            onChange={onScaleChange}
            step="0.01"
            type="range"
            value={scale}
          />
          <Svg className={styles['max-range-icon']} icon={Screenshot} />
        </div>
        <div className={styles['buttons-container']}>
          <Button
            className={styles.button}
            onClick={onCancel}
            theme={BUTTON_THEME.TRANSPARENT_FILL}
            size={BUTTON_SIZE.SMALL}
          >
            Cancel
          </Button>
          <Button
            disabled={!imageUrl}
            onClick={onSaveImage}
            className={styles.button}
            theme={BUTTON_THEME.TRANSPARENT_FILL}
            size={BUTTON_SIZE.SMALL}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

CoverEdit.propTypes = {
  className: PropTypes.string,
  getHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
  getWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.number]).isRequired,
  image: PropTypes.string.isRequired,
  initialPosition: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  initialScale: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  avatarEditorClassName: PropTypes.string,
  scaleControlClassName: PropTypes.string,
  opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setOpacity: PropTypes.func.isRequired,
}

CoverEdit.defaultProps = {
  className: '',
  avatarEditorClassName: '',
  scaleControlClassName: '',
  initialPosition: { x: 0.5, y: 0.5 },
  initialScale: 1,
}

export default CoverEdit
