import { Map } from 'immutable'

import { createReducer } from '_privateDependencies/react-utils/utils'

import { CACHE_PDF } from './actions'

const INITIAL_STATE = Map()

const pdfCache = createReducer(INITIAL_STATE, {
  [CACHE_PDF]: (state, { payload }) => state.set(payload.name, payload.file),
})

export default pdfCache
