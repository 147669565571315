export const PRESENTATION_MENU_OPTIONS = {
  PRESENTATIONS: 'PRESENTATIONS',
  FAVORITES: 'FAVORITES',
  TEMPLATES: 'TEMPLATES',
  RECEIVED: 'RECEIVED',
}

export const IDEAS_URL = 'https://resources.highnote.io/'

export const TEMPLATES_URL = 'https://highnote.io/templates/'

export const ASSETS_URL = 'https://highnote.io/assets/'

export const BUILTWITHHIGHNOTE_URL = 'https://highnote.io/builtwithhighnote/'
