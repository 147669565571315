import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { browserHistory, Link } from 'react-router'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  announcementsSelector,
  announcementsLoadingSelector,
} from '_modules/announcements/selectors'
import FacebookLogin from '_components/facebook-login'
import GoogleLogin from '_components/google-login'
import SimpleModal, { SimpleModalTheme } from '_components/simple-modal'
import Button from '_components/ui-kit/button'
import { LANDING_PAGE_URL, LAND_TRIAL_URL, FLAG_SHOW_GOOGLE_BUTTON } from '_config/environment'
import { login, AUTH_LOGIN, LOGIN_FACEBOOK, LOGIN_GOOGLE, resetUser } from '_modules/user/actions'
import {
  isLoginLoadingSelector,
  userSelector,
  isCompanyPaysSelector,
} from '_modules/user/selectors'
import Logo from '_assets/logo/default-logo.svg'
import validateEmail from '_utils/email-validation'
import useFetchCall from '_hooks/use-fetch-call'
import Input from '_components/ui-kit/input'
import { getAnnouncements } from '_modules/announcements/actions'
import Carousel from '_components/carousel'
import Announcement from '_components/announcement'
import Loading from '_components/loading'

import styles from './styles.css'

const mapStateToProps = ({ loading, error, user, routing }) => {
  let nextRoute
  if (
    routing.locationBeforeTransitions &&
    routing.locationBeforeTransitions.state &&
    routing.locationBeforeTransitions.state.nextRoute
  ) {
    nextRoute = fromJS(routing.locationBeforeTransitions.state.nextRoute)
  } else if (
    routing.locationBeforeTransitions &&
    routing.locationBeforeTransitions.query &&
    routing.locationBeforeTransitions.query.redirect
  ) {
    nextRoute = fromJS({ pathname: routing.locationBeforeTransitions.query.redirect })
  } else if (
    routing.locationBeforeTransitions &&
    routing.locationBeforeTransitions.query &&
    routing.locationBeforeTransitions.query.REFERRALCODE
  ) {
    // strictly used for Referral Rock integration
    // eslint-disable-next-line no-unused-vars
    const { redirect, ...queryParams } = routing.locationBeforeTransitions.query
    nextRoute = fromJS({
      pathname: '/payment',
      search: `?${new URLSearchParams(queryParams).toString()}`,
    })
  } else {
    nextRoute = fromJS({ pathname: '/presentations' })
  }
  return {
    isLoading: loading.get(AUTH_LOGIN.ACTION),
    errors: error.get(AUTH_LOGIN.ACTION),
    user,
    nextRoute: nextRoute && nextRoute.toJS(),
    isLoginInWithFacebook: !!loading.get(LOGIN_FACEBOOK.ACTION),
    facebookLoginErrors: error.get(LOGIN_FACEBOOK.ACTION),
    isLoginInWithGoogle: !!loading.get(LOGIN_GOOGLE.ACTION),
    googleLoginErrors: error.get(LOGIN_GOOGLE.ACTION),
  }
}

const Login = ({ location, nextRoute }) => {
  const dispatch = useDispatch()
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState({})
  const [hasPassword, setHasPassword] = useState(true)
  const isLoading = useSelector(isLoginLoadingSelector)
  const user = useSelector(userSelector)
  const announcements = useSelector(announcementsSelector)
  const isCompanyPays = useSelector(isCompanyPaysSelector)
  const isAnnouncementsLoading = useSelector(announcementsLoadingSelector)

  const updateEmailWithNonFieldError = useCallback(newError => {
    const nonFieldErrors = newError.get('non_field_errors')
    const providerErrorMessages = newError.get('provider')
    const errorMessages = newError.get('message')

    if (nonFieldErrors) {
      setErrors({ email: nonFieldErrors.first() })
      return
    }

    if (providerErrorMessages) {
      const words = providerErrorMessages.first().split(' ')
      const provider = words[words.length - 1]
      const providerCapitalized = `${provider.charAt(0).toUpperCase()}${provider.slice(
        1,
        provider.length - 1
      )}`

      setErrors({
        email: `You signed up with ${providerCapitalized}, please use the button below to sign in.`,
      })
      return
    }

    if (errorMessages) {
      setErrors({
        email: 'Invalid Email or Password',
      })
    }
  }, [])

  const getUserType = useCallback(() => {
    if (isCompanyPays) {
      return 'team'
    }
    return user.get('role')
  }, [isCompanyPays, user])

  const onSuccess = useCallback(() => {
    const { state } = location
    if (user.get('auth_token')) {
      if (window.posthog) {
        window.posthog.identify(`'${user.get('id')}'`, {
          email: user.get('email'),
          user_type: getUserType(),
        })
      }

      if (state && state.hash) {
        browserHistory.push({
          pathname: '/presentations',
          state: { hash: state.hash },
        })
        return
      }
      if (user.get('show_onboarding')) {
        browserHistory.push('/onboarding/usage')
        return
      }
      browserHistory.replace(nextRoute.pathname ? nextRoute.pathname : nextRoute)
    }
  }, [location, nextRoute, user, getUserType])

  const onReject = useCallback(
    error => {
      if (error.get('change_password_required')) {
        browserHistory.replace({
          state: {
            ...error.get('change_password_required').toJS(),
            nextRoute,
          },
          pathname: '/set-password',
        })
        return
      }
      updateEmailWithNonFieldError(error)
    },
    [nextRoute, updateEmailWithNonFieldError]
  )

  useFetchCall(LOGIN_FACEBOOK, onSuccess, onReject)
  useFetchCall(LOGIN_GOOGLE, onSuccess, onReject)
  useFetchCall(AUTH_LOGIN, onSuccess, onReject)

  useEffect(() => {
    dispatch(getAnnouncements())
  }, [dispatch])

  useEffect(() => {
    if (location && location.query && location.query.setPassword) {
      const { email: parsedEmail, hasPassword: parsedHasPassword } = JSON.parse(
        decodeURIComponent(location.query.setPassword)
      )
      setHasPassword(parsedHasPassword)
      setInputs(prevState => ({ ...prevState, email: parsedEmail }))
    }
  }, [location])

  useEffect(() => {
    const { email, password } = inputs
    if (!hasPassword) {
      dispatch(login(email, password))
    }
  }, [hasPassword, dispatch, inputs, location])

  useEffect(() => {
    if (user && user.get('auth_token')) {
      browserHistory.replace(nextRoute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validate = useCallback(() => {
    const { email, password } = inputs
    const newErrors = {}
    if (validateEmail(email)) {
      newErrors.email = 'Please enter a valid email address'
    }
    if (!password) {
      newErrors.password = 'Please enter a valid password.'
    }

    return newErrors
  }, [inputs])

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      const newErrors = validate()
      const { email, password } = inputs
      if (Object.keys(newErrors).length === 0) {
        dispatch(resetUser())
        dispatch(login(email, password))
        return
      }
      setErrors(newErrors)
    },
    [dispatch, inputs, validate]
  )

  const onBlur = useCallback(
    event => {
      const { name } = event.target
      const error = validate()[name]
      if (error) {
        setErrors(prevState => ({ ...prevState, [name]: error }))
        return
      }
      const { [name]: _, ...newErrors } = errors
      setErrors(newErrors)
    },
    [errors, validate]
  )

  const handleInput = useCallback(event => {
    const { name, value } = event.target
    setInputs(prevState => ({ ...prevState, [name]: value }))
  }, [])

  return (
    <div className={styles.background}>
      <div className={styles['side-panel']}>
        <div className={styles['side-title']}>
          <a href={LANDING_PAGE_URL} className={styles.logo}>
            <svg alt="Project Highnote" className={styles.logo}>
              <use xlinkHref={Logo} />
            </svg>
          </a>
          <h2 className={styles.slogan}>Elevating Your Presentations</h2>
        </div>
        {isAnnouncementsLoading ? (
          <Loading className={styles.loading} />
        ) : (
          !!announcements.size && (
            <Carousel>
              {announcements
                .filter(item => item.get('isActive'))
                .map(item => (
                  <Announcement
                    key={`announcements-${item.get('id')}`}
                    description={item.get('content')}
                    title={item.get('title')}
                    image={item.get('image')}
                    link={item.get('ctaUrl')}
                  />
                ))}
            </Carousel>
          )
        )}
      </div>
      <SimpleModal theme={SimpleModalTheme.NEW_LAYOUT_THEME} classNames={styles['login-modal']}>
        <form onSubmit={onSubmit} className={styles.form}>
          <h1 className={styles.title}>
            Welcome back{' '}
            <span role="img" aria-label="waving hand emoji">
              &#x1F44B;
            </span>
          </h1>
          <p className={styles.paragraph}>Enter your details to login</p>
          <Input
            label="Email address"
            onChange={handleInput}
            onBlur={onBlur}
            name="email"
            value={inputs.email}
            className={styles['text-input-login']}
            error={errors.email}
            id="login-email"
          />
          <Input
            label="Password"
            onChange={handleInput}
            onBlur={onBlur}
            name="password"
            value={inputs.password}
            className={styles['text-input-login']}
            error={errors.password}
            type="password"
            id="login-password"
          />
          <Link to="/forgot-password" className={styles['forgot-password']}>
            Forgot password
          </Link>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={onSubmit}
            isLoading={isLoading}
            className={styles.login}
          >
            Login
          </Button>
          <div className={styles['or-container']}>
            <div className={styles.divider} />
            <p className={styles.or}>Or</p>
            <div className={styles.divider} />
          </div>
          <div
            className={classnames(styles['social-buttons'], {
              [styles['hide-button']]: !FLAG_SHOW_GOOGLE_BUTTON,
            })}
          >
            <FacebookLogin className={styles['facebook-login']} />
            {FLAG_SHOW_GOOGLE_BUTTON && (
              <GoogleLogin className={styles['google-login']} isGrayButton />
            )}
          </div>
          <p className={styles.signup}>
            {`Don't have an account? `}
            <a href={LAND_TRIAL_URL} className={styles['signup-link']}>
              Create account
            </a>
          </p>
        </form>
      </SimpleModal>
    </div>
  )
}

Login.propTypes = {
  location: PropTypes.shape().isRequired,
  nextRoute: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({}),
  }),
}

Login.defaultProps = {
  nextRoute: undefined,
}

export default connect(mapStateToProps, null)(Login)
