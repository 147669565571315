import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import styles from './styles.css'

export const SimpleModalTheme = {
  DARK_GRAY_BLUE: 'dark-gray-blue',
  NEW_LAYOUT_THEME: 'new-layout-theme',
}

const SimpleModal = ({ children, theme, classNames }) => (
  <div className={classnames(styles.modal, styles[theme], classNames)}>{children}</div>
)

SimpleModal.propTypes = {
  theme: PropTypes.oneOf(Object.keys(SimpleModalTheme).map(key => SimpleModalTheme[key])),
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string,
}

SimpleModal.defaultProps = {
  theme: SimpleModalTheme.DARK_GRAY_BLUE,
  classNames: '',
}

export default SimpleModal
