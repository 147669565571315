export const PRESENTATION_EMPTY_TITLE = 'Add a Title'

export const ASSET_EMPTY_TITLE = 'Item Title'

export const ASSET_TYPE = {
  FILE: 'file',
  LINK: 'website',
  VIDEO: 'video',
  CONTACT: 'form',
  SEPARATOR: 'separator',
  TEXT: 'text',
}
