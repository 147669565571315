import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import Intercom, { IntercomAPI } from 'react-intercom'
import { browserHistory } from 'react-router'
import cookies from 'react-cookies'
import classnames from 'classnames'

import Header from '_components/header'
import AddProfileModal from '_components/add-profile-modal'
import {
  logout,
  GET_USER,
  getUser,
  resetUser,
  AUTH_LOGIN,
  CREATE_INTERCOM_CONTACT,
} from '_modules/user/actions'
import { userHasBillingProblemSelector } from '_modules/user/selectors'
import { isIndependentScreen } from '_utils/routes'
import {
  INTERCOM_APP_ID,
  NODE_ENV,
  STAGING,
  BOX_CLIENT_ID,
  BOX_CLIENT_SECRET,
  FLAG_HIDE_PRESENTATION_HEADER_PREMIUM_USERS,
  FLAG_REQUIRE_NAME_IN_REGISTER,
} from '_config/environment'
import SocketProvider from '_hocs/socket-provider'
import { AUTH_LOGOUT } from '_privateDependencies/react-utils/utils'
import AlertMessage from '_components/alert-message'
import { retrieveClientPresentation } from '_services/presentation'
import { clientPresentation as clientPresentationReducer } from '_modules/presentation-client/reducers'
import {
  RETRIEVE_CLIENT_PRESENTATION,
  forwardClientPresentation,
  FORWARD_CLIENT_PRESENTATION,
} from '_modules/presentation-client/actions'
import SEO from '_utils/seo'
import { getPayloadForwardClientPresentation } from '_utils/presentation'
import withBrowserInformation from '_hocs/with-browser-information'
import { PRODUCTION_URL, STAGING_URL, COOKIES_OPTIONS } from '_utils/constants'
import { USER_ROLES } from '_constants/user'
import TrialBanner from '_components/trial-banner'

import styles from './styles.css'

const mapStateToProps = state => ({
  user: state.user,
  isLoggingOut: !!state.loading.get(AUTH_LOGOUT.ACTION),
  isLoggingIn: !!state.loading.get(AUTH_LOGIN.ACTION),
  isRetrievingUser: !!state.loading.get(GET_USER.ACTION),
  generalErrors: state.error,
  clientPresentation: state.clientPresentation.get('clientPresentation'),
  userHasBillingProblem: userHasBillingProblemSelector(state),
  skipSubscriptionPastDue: state.payments.get('skipSubscriptionPastDue'),
  isLoadingCreateIntercomContact: !!state.loading.get(CREATE_INTERCOM_CONTACT.ACTION),
  isLoadingForwardClientPresentation: !!state.loading.get(FORWARD_CLIENT_PRESENTATION.ACTION),
  createIntercomContactError: state.error.get(CREATE_INTERCOM_CONTACT.ACTION),
})

const mapDispatchToProps = {
  logout,
  getUser,
  resetUser,
  forwardClientPresentation,
}

const NO_TITLE = 'No Title'

class App extends PureComponent {
  static propTypes = {
    user: PropTypes.instanceOf(Map),
    children: PropTypes.node,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape(),
      state: PropTypes.shape(),
    }),
    isLoggingOut: PropTypes.bool.isRequired,
    isLoggingIn: PropTypes.bool.isRequired,
    isInternetExplorer: PropTypes.bool.isRequired,
    isRetrievingUser: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    resetUser: PropTypes.func.isRequired,
    generalErrors: PropTypes.instanceOf(Map),
    clientPresentation: PropTypes.instanceOf(Map),
    userHasBillingProblem: PropTypes.bool.isRequired,
    viewedPresentation: PropTypes.instanceOf(Map),
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    skipSubscriptionPastDue: PropTypes.bool,
    forwardClientPresentation: PropTypes.func.isRequired,
    isLoadingCreateIntercomContact: PropTypes.bool.isRequired,
    createIntercomContactError: PropTypes.instanceOf(Map),
    isLoadingForwardClientPresentation: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    children: null,
    location: null,
    user: Map(),
    generalErrors: Map(),
    clientPresentation: Map(),
    viewedPresentation: Map(),
    params: {},
    skipSubscriptionPastDue: false,
    createIntercomContactError: null,
  }

  state = {}

  static getData({ hash }) {
    return [
      retrieveClientPresentation()(hash).then(payload => ({
        clientPresentation: clientPresentationReducer(undefined, {
          type: RETRIEVE_CLIENT_PRESENTATION.FULFILLED,
          payload,
        }),
      })),
    ]
  }

  componentDidMount() {
    const { location, user } = this.props
    const { query } = location

    if (user.get('auth_token')) {
      this.props.getUser()
    }

    if ('code' in query) {
      const params = {
        grant_type: 'authorization_code',
        code: query.code,
        client_id: BOX_CLIENT_ID,
        client_secret: BOX_CLIENT_SECRET,
        state: 'boxAuth',
      }
      fetch('https://api.box.com/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: Object.keys(params)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&'),
      })
        .then(res => res.json())
        .then(data => {
          if (!data.error) {
            cookies.save('boxAccessToken', data.access_token, {
              maxAge: Number(data.expires_in),
              ...COOKIES_OPTIONS,
            })
            browserHistory.push(location.pathname)
          }
        })
        .catch(err => console.info(err))
    }

    if (this.props.location.pathname === '/sign-up-embeddable') {
      IntercomAPI('update', {
        hide_default_launcher: true,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user, isLoggingOut, isLoadingForwardClientPresentation } = nextProps
    const { location } = this.props

    // if (typeof window !== 'undefined' && window.profitwell && user.get('email')) {
    //   window.profitwell('start', { user_email: user.get('email') })
    // }
    if (this.props.isLoggingOut && !isLoggingOut) {
      browserHistory.push('/login')
    }

    if (user.get('shouldLogout')) {
      this.props.resetUser()
      browserHistory.push('/login')
    }

    if (
      location &&
      location.state &&
      location.state.hash &&
      user.get('auth_token') &&
      !isLoadingForwardClientPresentation
    ) {
      if (!FLAG_REQUIRE_NAME_IN_REGISTER && user === this.props.user) {
        return
      }

      const payload = getPayloadForwardClientPresentation(user)

      this.props.forwardClientPresentation(this.props.location.state.hash, {}, payload)
      browserHistory.push({
        state: null,
      })
    }

    if (user && user.get('auth_token') && window.dataLayer) {
      window.dataLayer.push({
        transactionID: user.get('id'),
      })
    }
  }

  getIntercom = (user, isRetrievingUser) => {
    if (
      ['development', 'test'].includes(NODE_ENV) ||
      STAGING === 'true' ||
      /^\/client\/presentations\/*/.test(this.props.location.pathname) ||
      /^\/c\/p\/.+/.test(this.props.location.pathname)
    ) {
      return null
    }

    const intercomUser = {
      user_id: user.get('id'),
      email: user.get('email'),
      name: `${user.get('first_name')} ${user.get('last_name')}`,
    }

    if (user.get('auth_token') && !isRetrievingUser) {
      return <Intercom appID={INTERCOM_APP_ID} {...intercomUser} />
    }
    return <Intercom appID={INTERCOM_APP_ID} />
  }

  seoTags = () => {
    const { clientPresentation, location } = this.props
    const url = STAGING === 'true' ? STAGING_URL : PRODUCTION_URL
    const isSharedPresentation = /^\/c\/p\/(?!404).+/.test(location.pathname)
    // TODO: Remove this one payments are on prod
    const isSignUp = location.pathname === '/sign-up'

    const title = clientPresentation.get('clean_title') || clientPresentation.get('title')

    if (isSharedPresentation && clientPresentation.size > 0) {
      return {
        title:
          title !== NO_TITLE
            ? title
            : `${clientPresentation.getIn(['user', 'first_name'])} ${clientPresentation.getIn([
                'user',
                'last_name',
              ])}`,
        description:
          clientPresentation.get('clean_opening_msg') || clientPresentation.get('opening_msg'),
        hasColor: !!clientPresentation.get('background_color'),
        picture:
          clientPresentation.get('background_thumbnail') || clientPresentation.get('background'),
        url: `${url}${location.pathname}`,
        ...(!clientPresentation.get('is_indexable') && { noRobots: true }),
      }
    }
    return {
      description:
        'Thousands of people use Highnote to easily build stunning presentations with existing materials. No design skills required.',
      url,
      noRobots: isSignUp,
    }
  }

  wasSharedByPremiumUser = () => {
    if (FLAG_HIDE_PRESENTATION_HEADER_PREMIUM_USERS !== 'true') {
      return false
    }

    const {
      clientPresentation,
      viewedPresentation,
      params: { id },
      location: { pathname },
    } = this.props
    if (
      pathname.includes('/c/p') &&
      clientPresentation.getIn(['user', 'role']) === USER_ROLES.PREMIUM_AGENT &&
      !this.props.user.get('auth_token')
    ) {
      return true
    }

    const currentPresentationUserRole =
      viewedPresentation && viewedPresentation.getIn([id, 'shared_by_user', 'role'])

    if (
      pathname.includes('/presentations/preview') &&
      currentPresentationUserRole === USER_ROLES.PREMIUM_AGENT
    ) {
      return true
    }

    return false
  }

  render() {
    const { children, location, user, isRetrievingUser, isInternetExplorer } = this.props
    const { pathname } = location
    const wasSharedByPremiumUser = this.wasSharedByPremiumUser()
    const independentScreens =
      pathname && isIndependentScreen(pathname, !!user.get('auth_token'), wasSharedByPremiumUser)

    const dynamicHeader = <Header location={location} logout={this.props.logout} user={user} />

    const isProfileModalOpen =
      !independentScreens &&
      user.get('auth_token') &&
      !user.get('first_name') &&
      !FLAG_REQUIRE_NAME_IN_REGISTER

    return (
      <SocketProvider>
        <div
          className={classnames(styles.app, {
            [styles['trial-banner']]: user.get('is_trial_active'),
          })}
          id="mainApp"
        >
          <SEO {...this.seoTags()} />
          {!independentScreens && dynamicHeader}
          {user.get('is_trial_active') &&
            pathname !== '/sign-up-embeddable' &&
            pathname !== '/create-a-team' &&
            pathname !== '/onboarding' && <TrialBanner daysLeft={user.get('trial_remaining')} />}
          <main
            key="base-app-content"
            className={isInternetExplorer ? styles['ie-support-full-screen'] : undefined}
          >
            {children}
          </main>
          <AddProfileModal isOpen={isProfileModalOpen} />
          {/* <AddUserTypeModal isOpen={isAddUserTypeModalOpen} /> */}
          <div key="intercom">
            {typeof window !== 'undefined' &&
              Object.keys(window.location).length &&
              this.getIntercom(user, isRetrievingUser)}
          </div>
          <AlertMessage />
          {/* {displayBillingProblemModal && <BillingProblemModal />} */}
        </div>
      </SocketProvider>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withBrowserInformation(App))
