import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classnames from 'classnames'
import Modal from 'react-modal'
import ReactGA from 'react-ga'

import { GA_ENABLED } from '_config/environment'
import Button, { ButtonTheme } from '_components/button'
import Copy from '_assets/icons/copy.svg'
import CloseIcon from '_assets/icons/close.svg'
import gridStyles from '_styles/grid.css'

import styles from './styles.css'

class DragAndDropModal extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    dismiss: PropTypes.func,
    dragAndDropAction: PropTypes.func,
    droppedFiles: PropTypes.number,
  }

  static defaultProps = {
    className: null,
    isOpen: false,
    dismiss: undefined,
    dragAndDropAction: undefined,
    droppedFiles: 0,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      if (GA_ENABLED) {
        ReactGA.modalview(`${window.location.pathname}/dnd-modal`)
      }
    }
  }

  render() {
    const { isOpen, dismiss, dragAndDropAction, droppedFiles, className } = this.props
    const infoText =
      'This will create one new entry for each file you selected. You can edit titles and descriptions after adding them.'
    return (
      <div className={classnames(className)}>
        <Modal
          className={classnames(styles.modal)}
          contentLabel="Drag and drop"
          overlayClassName={classnames(styles.overlay)}
          isOpen={isOpen}
          ariaHideApp={false}
        >
          <div
            className={classnames(
              gridStyles.Grid,
              gridStyles['Grid--alignMiddle'],
              styles['title-container']
            )}
          >
            <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFit'])}>
              <svg alt="icon" className={classnames(styles['copy-icon'])}>
                <use xlinkHref={Copy} />
              </svg>
            </div>
            <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFill'])}>
              <p className={classnames(styles.title)}>
                {droppedFiles > 1 ? 'Drop Files' : 'Drop File'}
              </p>
            </div>
            <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFit'])}>
              <button onClick={dismiss} className={styles['close-button']} type="button">
                <svg alt="close" className={classnames(styles['close-icon'])}>
                  <use xlinkHref={CloseIcon} />
                </svg>
              </button>
            </div>
          </div>
          <div
            className={classnames(
              gridStyles.Grid,
              gridStyles['Grid--alignMiddle'],
              gridStyles['Grid--alignCenter']
            )}
          >
            <div className={classnames(gridStyles['Grid-cell'], styles['message-container'])}>
              <p className={classnames(styles.message)}>
                {droppedFiles > 1
                  ? 'Do you want to create new items with these files?'
                  : 'Do you want to create a new item with this file?'}
              </p>
            </div>
            <div className={classnames(gridStyles['Grid-cell'])}>
              <p className={classnames(styles.info)}>{infoText}</p>
            </div>
          </div>
          <div
            className={classnames(
              gridStyles.Grid,
              gridStyles['Grid--alignMiddle'],
              gridStyles['Grid--alignRight']
            )}
          >
            <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFit'])}>
              <Button
                onClick={dismiss}
                theme={ButtonTheme.TRANSPARENT}
                classNames={classnames(styles['cancel-button'])}
              >
                CANCEL
              </Button>
            </div>
            <div className={classnames(gridStyles['Grid-cell'], gridStyles['u-sizeFit'])}>
              <Button
                onClick={dragAndDropAction}
                classNames={classnames(styles['confirmation-button'])}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default DragAndDropModal
